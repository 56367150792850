import { extend, localize } from 'vee-validate'
import {
  required,
  regex,
  min,
  min_value,
  max_value,
  max,
  email,
  numeric,
  excluded,
  is,
  length,
} from 'vee-validate/dist/rules'

import PatientRuleValidators from '@/modules/patient/validators'

import fr from 'vee-validate/dist/locale/fr.json'

import { isValid, isFuture, parse } from 'date-fns'
import { pluralize } from '@/utils/functions/words'
import { isValidNir } from '@/utils/functions/nir'

Object.values(PatientRuleValidators).forEach(validator => {
  validator.register()
})

localize('fr', fr)

extend('valid_nir', {
  validate (value, { gender, invalidMessage }) {
    return isValidNir(value, gender) ||
      (invalidMessage ?? 'Le numéro de sécurité sociale n\'est pas valide')
  },
  params: ['gender', 'invalidMessage'],
})

extend('required', {
  ...required,
  message: (fieldName) => {
    if (fieldName !== '{field}') {
      return `Le champ ${fieldName} est obligatoire`
    }
    return 'Ce champ est obligatoire'
  },
})

extend('hourIsAfter', {
  validate (value, { target }) {
    if (target && value.replace(/[^0-9]+/g, '') > target.replace(/[^0-9]+/g, '')) {
      return true
    }
    return 'L\'heure de {_field_} doit être supérieure à {target}'
  },
  params: ['target'],
})

extend('requiredModel', model => {
  return model['@id'] !== null || 'Ce champ est requis'
})

extend('regex', regex)
extend('min', min)
extend('length', length)

/**
 * La régle min_value doit être overridée
 * a cause des points remplacés par des virgules
 */
extend('min_value', {
  ...min_value,
  validate (value, { min }) {
    const formatedMin = min.replace('.', ',')
    if (parseFloat(value.toString().replace(',', '.')) >= min) {
      return true
    }
    return 'La valeur doit être au moins égale à ' + formatedMin
  },
  params: ['min'],
})

extend('max_value', max_value)

extend('min_length', {
  validate (value, { length }) {
    if (value.length >= length) {
      return true
    }
    return `Le champ "{_field_}" nécessite au moins ${length} ${pluralize('valeur', parseInt(length))}`
  },
  params: ['length'],
})

extend('excluded', {
  ...excluded,
  message (fieldName, { _value_ }) {
    return `"${_value_}" est déja présent dans la liste`
  },
})

extend('is', is)

extend('checked', {
  ...is,
  validate (value) {
    return value || 'Le champ doit être coché'
  },
})

extend('max', {
  ...max,
  message (fieldName, { length }) {
    return `Le champ "${fieldName}" ne peut pas contenir plus de ${length} ${pluralize('caractère', parseInt(length))}`
  },
  params: ['length'],
})

extend('email', email)

extend('email_mss', {
  validate (value) {
    return (email.validate(value) && /mssante\.fr$/.test(value)) || 'L\'adresse doit être un e-mail mssante valide'
  },
})

extend('numeric', {
  ...numeric,
  message: 'Le champ "{_field_}" ne peut contenir que des caractères numériques',
})

extend('time_after', (value, [min]) => {
  const [valueHour, valueMinute] = value.split(':')
  const [minHour, minMinute] = min.split(':')

  const valueSeconds = parseInt(valueHour * 60 + valueMinute)
  const minSeconds = parseInt(minHour * 60 + minMinute)

  if (valueSeconds <= minSeconds) {
    return 'L\'heure doit être supérieure à ' + min
  }

  return true
})

extend('date_format', {
  validate (date, { allowLunarDate }) {
    const errorMessage = 'La date entrée est invalide'
    const expectedDateFormat = 'dd/MM/yyyy'
    if (date.match(/[0-9]{2}(\/){1}[0-9]{2}(\/){1}[0-9]{4}/g)) {
      if (allowLunarDate) {
        const dateElements = date.split('/')
        // Une date lunaire accepte les mois supérieurs à 12 et les jours supérieurs au dernier jour valide du mois
        const validDayMonth = [dateElements[0], dateElements[1]].every(date => parseInt(date) >= 1)
        return validDayMonth || errorMessage
      }
      return isValid(parse(date, expectedDateFormat, new Date())) || errorMessage
    }
    return errorMessage
  },
  params: ['allowLunarDate'],
})

extend('before_now', {
  validate (date) {
    const parsedDate = parse(date, 'dd/MM/yyyy', new Date())
    if (isFuture(parsedDate)) {
      return 'La date ne peut pas être dans le futur'
    }
    return true
  },
})

extend('phone_ec', {
  validate (value) {
    const regex = /^((\+{1}\d{11})|(\d{10}))$/g
    if (regex.test(value.replaceAll(' ', '').trim())) {
      return true
    }
    return 'Le numéro de téléphone n\'a pas un format valide'
  },
})