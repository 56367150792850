<template>
  <app-form :submit-method="addCorrespondents" class="correspondent-form">
    <app-switch
      :value="isDisplayingHealthProfessionals"
      label="Rechercher dans l'Annuaire Santé"
      class="correspondent-form__switch"
      @change="switchContactMode"
    />

    <p class="correspondent-form__description">
      {{ description }}
    </p>

    <correspondent-input :label="label" :url="url" @change="addCorrespondent" />

    <p v-if="isDisplayCorrespondentsListInfo" class="correspondents-list-info" data-test="correspondents-list-info">
      {{ correspondentsToAdd.length || 'Aucun' }} {{ correspondentsSelectedLabel }}
    </p>

    <transition name="list">
      <div v-if="correspondentsToAdd.length" class="correspondent-list">
        <transition-group name="list" tag="ul" class="correspondent-to-add-list">
          <li v-for="correspondent in correspondentsToAdd" :key="correspondent['@id']" class="correspondent">
            <correspondent-form-item
              :correspondent="correspondent"
              @remove="removeCorrespondent"
            />
          </li>
        </transition-group>
      </div>
    </transition>
  </app-form>
</template>

<script>
import NovaTools from '@/nova-tools/NovaTools'
import AppForm from '@/components/ui/form/AppForm.vue'
import CorrespondentInput from '@/modules/patient/modules/patientFile/modules/correspondents/components/correspondentsPanel/forms/CorrespondentInput.vue'
import CorrespondentFormItem from '@/modules/patient/modules/patientFile/modules/correspondents/components/correspondentsPanel/forms/CorrespondentFormItem.vue'
import AppSwitch from '@/components/ui/form/switch/AppSwitch.vue'

import Patient from '@/modules/patient/models/Patient'
import Correspondent from '@/modules/contact/models/NewHealthProfessional'

import { pluralize } from '@/utils/functions/words'
import { putToAPI } from '@/services/api'

export default {
  name: 'AddCorrespondentsForm',
  components: {
    AppSwitch,
    AppForm,
    CorrespondentInput,
    CorrespondentFormItem,
  },
  props: {
    /**
     * Patient avec lequel le correspondent sera lié
     */
    value: {
      type: Patient,
      required: true,
    },
    isCanceled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      correspondentsToAdd: [],
      isDisplayingHealthProfessionals: false,
      label: 'Recherchez dans l\'annuaire local',
      url: '/api/correspondents',
      description: 'Votre recherche va s\'effectuer dans votre annuaire local',
      isDisplayCorrespondentsListInfo: false,
    }
  },
  computed: {
    correspondentsSelectedLabel () {
      return [pluralize('correspondant', this.correspondentsToAdd.length), pluralize('sélectionné', this.correspondentsToAdd.length)].join(' ')
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler () {
        this.correspondentsToAdd = []
      },
    },
    isCanceled: {
      deep: true,
      immediate: true,
      handler () {
        this.isDisplayCorrespondentsListInfo = false
        this.isDisplayingHealthProfessionals = false
        this.changeContactModeParameters()
      },
    },
  },
  methods: {
    addCorrespondent (sentCorrespondent) {
      this.isDisplayCorrespondentsListInfo = true
      const type = sentCorrespondent['@type']
      const id = ('Correspondent' === type) ? sentCorrespondent?.contact?.ppNationalIdentifier : sentCorrespondent?.id

      if (id) {
        let correspondent = sentCorrespondent
        if ('Correspondent' === type) {
          correspondent = {
            '@id': '/api/new_health_professionals/' + id,
            '@type': 'HealthProfessional',
            contact: sentCorrespondent.contact,
            id: id,
          }
        }
        const alreadyAdded = this.correspondentsToAdd.some((correspondentAdded) => correspondent['@id'] === correspondentAdded['@id'])
        if (! alreadyAdded) {
          this.correspondentsToAdd.push(new Correspondent(correspondent))
        }
      } else {
        NovaTools.notify.error('Ce correspondant ne contient pas les informations nécessaires pour un rattachement au patient')
      }
    },
    removeCorrespondent (correspondent) {
      this.correspondentsToAdd = this.correspondentsToAdd.filter((correspondentToAdd) => correspondentToAdd['@id'] !== correspondent['@id'])
    },
    async addCorrespondents () {
      if (this.correspondentsToAdd.length > 0) {
        await putToAPI(this.value.getIri(), { data: { correspondentHealthProfessional: this.correspondentsToAdd } })
        const successMessage = this.correspondentsToAdd.length > 1 ? 'Les correspondants ont été ajoutés avec succès' : 'Le correspondant a été ajouté avec succès'
        NovaTools.notify.success(successMessage)
      }
      this.isDisplayCorrespondentsListInfo = false
      this.isDisplayingHealthProfessionals = false
      this.changeContactModeParameters()
    },
    switchContactMode () {
      this.isDisplayingHealthProfessionals = ! this.isDisplayingHealthProfessionals
      this.changeContactModeParameters()
    },
    changeContactModeParameters () {
      this.label = this.isDisplayingHealthProfessionals ? 'Recherchez dans l\'annuaire santé' : 'Recherchez dans l\'annuaire local'
      this.url = this.isDisplayingHealthProfessionals ? 'api/new_health_professionals' : '/api/correspondents'
      this.description = this.isDisplayingHealthProfessionals ? 'Votre recherche va s\'effectuer dans l\'annuaire santé' : 'Votre recherche va s\'effectuer dans votre annuaire local'
    },
  },
}
</script>

<style lang="scss" scoped>
.correspondent-form {
  display: flex;
  flex-direction: column;

  &__switch {
    margin-bottom: 16px !important;
  }
  &__description {
    font-weight: bold;
    margin-bottom: 16px !important;
  }
}
.correspondents-list-info {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.list-enter-active, .list-leave-active {
  transition: all .3s;
}
.list-enter, .list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.correspondent-to-add-list {
  display: flex;
  flex-direction: column-reverse;
  padding-left: 0;

  .correspondent {
    list-style: none;
    margin-bottom: 5px;
  }
}
</style>