<template>
  <v-sheet
    :elevation="isFlat ? 0 : 3"
    class="panel rounded-lg"
    :color="color"
    :class="{
      'panel--dense': dense,
      'panel--outlined': isOutlined,
      'panel--expandable': expandable,
    }"
    :style="{ color: panelTextColor }"
    :outlined="isOutlined"
  >
    <header
      v-if="(title || $scopedSlots.title) || (actions || $scopedSlots.actions)"
      v-test="'panel-header'"
      class="panel__header"
      :class="{ 'panel__header--divided': headerDivided, 'mb-0': expandable && ! isPanelExpanded }"
    >
      <div v-test="'panel-headline'" class="panel__header__headline" @click="handleHeadlineClick">
        <app-icon
          v-if="expandable"
          v-test="'expandable-chevron'"
          icon="chevron-down"
          color="secondary lighten-4"
          :class="{
            'panel__header__headline__chevron--expanded': isPanelExpanded,
            'mr-4': ! dense,
          }"
          class="panel__header__headline__chevron"
        />
        <app-icon
          v-if="icon"
          v-test="'panel-icon'"
          :size="dense ? 16 : 20"
          :icon="icon"
          :color="panelTitleTextColor"
          class="mr-3"
        />
        <h2 v-test="'panel-title'" class="panel__title">
          <slot name="title">
            <span v-tooltip="{ text: titleTooltipText, disabled: !titleTooltipEnabled }" :style="{ color: panelTitleTextColor }">
              {{ title }}
            </span>
          </slot>
          <span v-if="quantity !== null" v-test="'panel-quantity'" class="panel__title__quantity">
            {{ quantity }}
          </span>
          <span v-if="$slots['title-append']" class="ml-4">
            <slot name="title-append" />
          </span>
        </h2>
      </div>
      <div v-if="actions || $scopedSlots.actions" v-test="'panel-actions'" class="panel__actions">
        <slot name="actions">
          <div class="panel__action-menu">
            <app-actions-menu :max-actions-visible="maxActionsVisible" :actions="actions" />
          </div>
        </slot>
      </div>
    </header>
    <v-expand-transition>
      <main v-if="(expandable ? expandable && isPanelExpanded : true)" v-test="'panel-content'">
        <slot name="default" />
      </main>
    </v-expand-transition>
    <footer v-if="$slots['footer']" class="panel__footer" :class="{ 'panel__footer--divided': footerDivided }">
      <slot name="footer" />
    </footer>
  </v-sheet>
</template>

<script>
import AppActionsMenu from '@/components/ui/actionsMenu/AppActionsMenu.vue'

import { getColorContrast } from '@/utils/functions/colors'

import localCopyMixin from '@novalys/src/mixins/local-copy-mixin'

/**
 * Permet de créer un petit panel de l'application
 * Avec une ombre, il permet de regrouper des élément de l'interface
 */
export default {
  name: 'AppPanel',
  components: { AppActionsMenu },

  mixins: [
    localCopyMixin({
      propertyName: 'expanded',
      copyPropertyName: 'isPanelExpanded',
    }),
  ],
  props: {
    /**
     * Le titre du panel
     * Affiché en haut de celui-ci
     */
    title: {
      type: String,
      default: null,
    },
    titleTooltipEnabled: {
      type: Boolean,
      default: false,
    },
    titleTooltipText: {
      type: String,
      default: '',
    },
    /**
     * L'icône du panel
     */
    icon: {
      type: String,
      default: null,
    },
    /**
     * La couleur de fond
     */
    color: {
      type: String,
      default: 'white',
    },
    titleColor: {
      type: String,
      default: null,
    },
    /**
     * Permet d'afficher un nombre d'éléments
     */
    quantity: {
      type: Number,
      default: null,
    },
    maxActionsVisible: {
      type: Number,
      default: 1,
    },
    /**
     * Réduit la taille des marges du composant
     */
    dense: {
      type: Boolean,
      default: false,
    },
    /**
     * Permet de plier/déplier le panneau
     */
    expandable: {
      type: Boolean,
      default: false,
    },
    /**
     * Indique si le panneau doit être plié ou déplié
     */
    expanded: {
      type: Boolean,
      default: false,
    },
    divided: {
      type: Boolean,
      default: false,
    },
    /**
     * Retire l'élévation
     */
    isFlat: {
      type: Boolean,
      default: false,
    },
    /**
     * Ajoute un contour
     */
    isOutlined: {
      type: Boolean,
      default: false,
    },
    /**
     * Les actions du panel
     * @see AppActionsMenu
     */
    actions: {
      type: [Object, Array],
      default: null,
    },
    /**
     * Applique une bordure en bas du header pour diviser le panel
     */
    headerDivided: {
      type: Boolean,
      default: false,
    },
    /**
     * Applique une bordure en haut du footer pour diviser le panel
     */
    footerDivided: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    panelTextColor () {
      return getColorContrast(this.color || 'white')
    },
    panelTitleTextColor () {
      return this.titleColor ?? this.panelTextColor
    },
  },
  methods: {
    handleHeadlineClick () {
      if (this.expandable) {
        this.isPanelExpanded = ! this.isPanelExpanded
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$panel-spacer-default: map-get($spacers, 4);
$panel-spacer-dense: map-get($spacers, 3);

// Espace entre l'icône et le conteneur du bouton
$button-icon-space: 10px;

.panel {
  position: relative;
  padding: $panel-spacer-default;
  border-radius: 5px;
  background-color: #fff;

  &--expandable &__header__headline {
    user-select: none;
    cursor: pointer;
  }

  &--dense {
    padding: $panel-spacer-dense;

    .panel__action-menu {
      top: calc(#{$panel-spacer-dense} - #{$button-icon-space});
      right: calc(#{$panel-spacer-dense} - #{$button-icon-space});
    }
    .panel__header {
      margin-bottom: $panel-spacer-dense;
    }

    .panel__header--divided {
      margin: 0 -#{$panel-spacer-dense} $panel-spacer-dense -#{$panel-spacer-dense};
      padding: 0 $panel-spacer-dense $panel-spacer-dense $panel-spacer-dense;
    }

    .panel__header__headline {
      font-size: 13px;
      font-weight: 600;
    }
    .panel__title {
      font-size: 13px;
      font-weight: 600;
    }

    .panel__footer {
      margin: $panel-spacer-dense -#{$panel-spacer-dense} 0 -#{$panel-spacer-dense};
      padding: $panel-spacer-dense $panel-spacer-dense 0 $panel-spacer-dense;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: $panel-spacer-default;
    gap: $panel-spacer-default;

    &--divided {
      margin: 0 -#{$panel-spacer-default} $panel-spacer-default -#{$panel-spacer-default};
      padding: 0 $panel-spacer-default $panel-spacer-default $panel-spacer-default;
      border-bottom: 1px solid var(--v-divider-base);
    }

    &__headline {
      display: flex;
      align-items: center;
      flex-grow: 1;
      overflow: hidden;

      &__chevron {
        transition: transform .3s ease;
        transform: rotate(-90deg);

        &--expanded {
          transform: rotate(0deg);
        }
      }
    }
  }

  &.panel--outlined {
    border-width: 1px;
    border-color: var(--v-divider-base) !important;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &__quantity {
      font-size: 13px;
      font-weight: 400;
      color: var(--v-secondary-lighten4);
      margin-left: map-get($spacers, 2);
    }
  }

  &__action-menu {
    position: absolute;
    top: calc(#{$panel-spacer-default} - #{$button-icon-space});
    right: calc(#{$panel-spacer-default} - #{$button-icon-space});
  }

  &__footer {
    margin: $panel-spacer-default -#{$panel-spacer-default} 0 -#{$panel-spacer-default};
    padding: $panel-spacer-default $panel-spacer-default 0 $panel-spacer-default;

    &--divided {
      border-top: 1px solid var(--v-divider-base);
    }
  }
}
</style>