<template>
  <app-panel title="Messagerie">
    <app-tabs outlined class="mx-n4">
      <app-tabs-item
        v-test="'pending-documents-tab'"
        label="En attente"
        badge-color="error"
        :badge-content="pendingDocuments.length"
      >
        <pending-mss-documents-table
          :loading="pendingDocumentsLoading"
          :documents="pendingDocuments"
          :is-mobile="isMobile"
          @document-attach-failed="document => $emit('document-attach-failed', document)"
          @document-ready-to-attach="document => $emit('document-ready-to-attach', document)"
          @update:documents="documents => $emit('update:pending-documents', documents)"
          @refresh="$emit('refresh')"
        />
      </app-tabs-item>
      <app-tabs-item v-test="'life-cycle-report-documents-tab'" label="Journal des traces">
        <document-life-cycle-reports-table :is-mobile="isMobile" />
      </app-tabs-item>
    </app-tabs>
  </app-panel>
</template>

<script>
import AppPanel from '@/components/ui/panel/AppPanel.vue'
import PendingMssDocumentsTable from '@/modules/users/components/dashboard/documents/PendingMssDocumentsTable.vue'
import DocumentLifeCycleReportsTable from '@/modules/users/components/dashboard/documents/DocumentLifeCycleReportsTable.vue'

import PendingMssDocument from '@/modules/secureMessaging/modules/documents/models/PendingMssDocument'

import { mapState } from 'vuex'

export default {
  name: 'DashboardDocumentsPanel',
  components: {
    AppPanel,
    PendingMssDocumentsTable,
    DocumentLifeCycleReportsTable,
  },
  props: {
    pendingDocuments: {
      type: Array,
      default: () => [],
      validator: documents => documents.every(document => document instanceof PendingMssDocument),
    },
    pendingDocumentsLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: { ...mapState('app', ['isMobile']) },
}
</script>