import NovaTools from '@/nova-tools/NovaTools'

import Vue from 'vue'

import Novalys from '@novalys'
import novalysOptions from '../config/novalys'
import store from './store/instance'
import router from './router/instance'

import './plugins'
import './utils/directives'
import './registerServiceWorker'

import './assets/styles/main.scss'
import './services/vendors/doctolib'

import App from './App.vue'

import apiAuth from '@/services/apiAuth'
/**
 * Global components
 */
import Default from '@/layout/default/Default.vue'
import AppButton from '@/components/ui/buttons/AppButton.vue'
import AppLink from '@/components/ui/typography/link/AppLink.vue'
import AppIcon from '@/components/ui/icon/AppIcon.vue'
import AppRow from '@/components/ui/grid/AppRow.vue'
import AppCol from '@/components/ui/grid/AppCol.vue'
import AppTabs from '@/components/ui/tabs/AppTabs.vue'
import AppTabsItem from '@/components/ui/tabs/AppTabsItem.vue'
import AppList from '@/components/ui/list/AppList.vue'
import AppListItem from '@/components/ui/list/AppListItem.vue'
// Ajoute les composants N dans le projet easy-care
import * as Components from '@/nova-ui'

Vue.use(Novalys, novalysOptions)

Vue.use(apiAuth)

Vue.config.productionTip = false

Vue.component('DefaultLayout', Default)
Vue.component('AppButton', AppButton)
Vue.component('AppLink', AppLink)
Vue.component('AppIcon', AppIcon)
Vue.component('AppRow', AppRow)
Vue.component('AppCol', AppCol)
Vue.component('AppTabs', AppTabs)
Vue.component('AppTabsItem', AppTabsItem)
Vue.component('AppList', AppList)
Vue.component('AppListItem', AppListItem)

Object.values(Components).forEach(Component => Vue.component(Component.name, Component))

window.store = store

NovaTools.traces.initialise()

const app = new Vue({
  router,
  store,
  ...Novalys.mapDependenciesInstances(),
  render: h => h(App),
})

app.$mount('#app')

const loadSVGSprite = async () => {
  try {
    const response = await fetch('/img/sprite.svg')
    const responseText = await response.text()
    window.trustedTypes && window.trustedTypes.createPolicy
      ? document.querySelector('.sprite-svg-container').innerHTML = app.$trustedPolicy.createHTML(responseText)
      : document.querySelector('.sprite-svg-container').innerHTML = responseText
  } catch (error) {
    throw new Error('Failed to load SVG sprite:', error)
  }
}

loadSVGSprite()