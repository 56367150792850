<template>
  <app-container class="home">
    <dashboard-header v-test="'dashboard-header'" />
    <section v-if="haveAccessToDashboard" class="mt-6">
      <user-dashboard v-test="'user-dashboard'" />
    </section>
    <section v-else>
      <app-row>
        <app-col cols="12">
          <app-panel>Aucun élément à afficher</app-panel>
        </app-col>
      </app-row>
    </section>
  </app-container>
</template>

<script>
import { mapGetters } from 'vuex'
import apiAuth from '@/services/apiAuth'

import AppContainer from '@/components/ui/container/AppContainer.vue'
import AppPanel from '@/components/ui/panel/AppPanel.vue'
import DashboardHeader from '@/modules/users/components/dashboard/header/DashboardHeader.vue'
import UserDashboard from '@/views/UserDashboard.vue'

export default {
  name: 'TheHomePage',
  components: {
    AppContainer,
    AppPanel,
    UserDashboard,
    DashboardHeader,
  },
  computed: {
    ...mapGetters('auth', ['isCurrentUserPractitioner', 'isCurrentUserSecretary']),
    haveAccessToDashboard () {
      return this.isCurrentUserPractitioner || this.isCurrentUserSecretary
    },
  },
  async created () {
    const gcuValidationNeeded = localStorage.getItem('gcuValidationNeeded') ?? false
    if (gcuValidationNeeded) {
      const authPayload = apiAuth.getAuthPayload()
      localStorage.removeItem('gcuValidationNeeded')
      if (authPayload.gcu?.accepted !== true) {
        await apiAuth.redirectToAuthentication()
      }
    }
  },
}
</script>