<template>
  <div class="patient-health-book-tendance-chart">
    <label v-test="'chart-label'" class="patient-health-book-tendance-chart__label subtitle-3">{{ referenceData.label }}</label>
    <app-icon v-test="'gender-icon'" :icon="chartGenderIcon" class="patient-health-book-tendance-chart__gender-icon" />
    <app-line-chart
      :chart-data="chartData"
      :chart-options="chartOptions"
      class="fill-height patient-health-book-tendance-chart__canvas"
      :y-label="label"
      :y-unit="unit"
      :x-unit="healthBookAgeRange.unit.label"
    />
    <p class="patient-health-book-tendance-chart__legend">
      {{ sourceData }}
    </p>
  </div>
</template>

<script>
import AppLineChart from '@/components/ui/chart/AppLineChart.vue'

import { hexToRgba } from '@/utils/functions/colors'
import { getNumberEvolution } from '@/utils/functions/number'
import { differenceInMonths } from 'date-fns'
import { inRange } from 'lodash'

import Patient from '@/modules/patient/models/Patient'
import Measure from '@/modules/patient/models/Measure'

import { GENDERS, COLORS } from '@/constants'
import { HEALTH_BOOK_AVERAGE_DATA, HEALTH_BOOK_AGE_RANGES, HEALTH_BOOK_CATEGORIES } from '@/modules/patient/constants'

const MILLIMETER_LINE_PER_UNIT = 5

/**
 * Représente un graphique du carnet de santé pour un patient
 */
export default {
  name: 'PatientHealthBookTendanceChart',
  components: { AppLineChart },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
    measures: {
      type: Array,
      default: () => [],
      validator: measures => measures.every(measure => measure instanceof Measure),
    },
    category: {
      type: String,
      required: true,
      validator: name => Object.values(HEALTH_BOOK_CATEGORIES).map(c => c.name).includes(name),
    },
    healthBookAgeRange: {
      type: Object,
      required: true,
      validator: range => Object.values(HEALTH_BOOK_AGE_RANGES).includes(range),
    },
  },
  data () {
    return { sourceData: 'Courbes de croissance AFPA - CRESS/INSERM - CompuGroup Medical, 2018 [enfants nés à plus de 2500g et suivis par des médecins sur le territoire métropolitain]' }
  },
  computed: {
    ageRange () {
      return {
        min: this.healthBookAgeRange.axisValue[0],
        max: this.healthBookAgeRange.axisValue[1],
      }
    },
    healthBookCategory () {
      return HEALTH_BOOK_CATEGORIES[this.category.toUpperCase()]
    },
    healthBookCategoryRange () {
      return this.healthBookCategory.range[this.healthBookAgeRange.categoryName]
    },
    datasetRange () {
      const [min, max] = this.healthBookCategoryRange.value
      return {
        min,
        max,
      }
    },
    chartGenderIcon () {
      return this.patient.gender === GENDERS.MALE.value ? 'male' : 'female'
    },
    label () {
      return this.healthBookCategory.label
    },
    unit () {
      return this.healthBookCategory.unit
    },
    referenceData () {
      return HEALTH_BOOK_AVERAGE_DATA[this.patient.gender.toUpperCase()][this.category.toUpperCase()][this.healthBookAgeRange.categoryName]
    },
    distributionUnit () {
      return this.referenceData.distributionUnit
    },
    normalDistributionRates () {
      return this.referenceData.distributionRates
    },
    rawDatasets () {
      return this.referenceData.data
    },
    medianDataset () {
      return this.rawDatasets.find(dataset => dataset[0] === 50)[1]
    },
    maxTicksLimit () {
      // Permet de n'afficher que nmax unités sur x quelque soit le nombre de labels
      return (this.ageRange.max / (this.healthBookAgeRange.tickStepSize || 1)) + 1
    },
    chartLabels () {
      // Permet de générer des labels par mois ou en année
      return Array(this.ageRange.max * 12 * this.healthBookAgeRange.unit.yearCoefficient + 1).fill().map((v, i) => i / (12 * this.healthBookAgeRange.unit.yearCoefficient))
    },
    sortedNormalDistributionRates () {
      // Permet de générer une suite logique de pourcentage pour l'ensemble des courbes
      // exemple [0.8, 0.5, 0.4, 0, -0.4, -0.5, -0.8] à partir de [0.4, 0.5, 0.8] (symétrie des courbes)
      return this.normalDistributionRates.length > 0 ? [
        ...[...this.normalDistributionRates].sort((a, b) => b - a),
        0,
        ...[...this.normalDistributionRates].sort((a, b) => a - b).map(rate => rate * - 1),
      ] : []
    },
    processedDatasets () {
      if (this.sortedNormalDistributionRates.length > 0) {
        return this.sortedNormalDistributionRates.map((rate) => {
          return [rate, this.medianDataset.map(y => getNumberEvolution(y, rate))]
        })
      }
      return this.rawDatasets
    },
    sortedDatasets () {
      return [
        ...[...this.processedDatasets].sort((a, b) => b[0] - a[0]),
      ]
    },
    referenceAnnotations () {
      return this.sortedDatasets.reduce((acc, value, index) => {
        return {
          ...acc,
          [`annotation${index}`]: {
            type: 'label',
            xValue: this.ageRange.max * 12 * this.healthBookAgeRange.unit.yearCoefficient,
            yValue: value[1][value[1].length - 1],
            xAdjust: ({ element }) => - (element.width / 2),
            yAdjust: ({ element }) => - (element.height / 2),
            content: this.setLineLegend(value[0], index),
            padding: {
              x: 2,
              y: 0,
            },
            color: COLORS.content.base,
            font: {
              size: 12,
              weight: '500',
              family: 'Poppins',
            },
          },
        }
      }, {})
    },
    referenceDatasets () {
      const solidLineIndexes = [1, Math.floor(this.sortedDatasets.length / 2), this.sortedDatasets.length - 2]
      const thickLineIndexes = [Math.floor(this.sortedDatasets.length / 2)]
      const lightDashIndexes = [0, this.sortedDatasets.length - 1]

      // On admet que la courbe se remplira toujours à partir de la deuxième courbe en partant du haut jusqu'a l'avant dernière courbe
      const fillingIndex = 1

      return this.sortedDatasets.map((dataset, index) => {
        const isBordered = ! solidLineIndexes.includes(index)
        const fill = index === fillingIndex ? `+${this.sortedDatasets.length - 3}` : null
        const widthOptions = (thickLineIndexes.includes(index)) ? 2 : 1.5
        const dashOptions = (lightDashIndexes.includes(index)) ? [1, 1] : [2, 2]
        return this.generateDataset(this.getFormattedDataset(dataset[1]), {
          bordered: isBordered,
          fill,
          widthOptions: widthOptions,
          dashOptions: dashOptions,
        })
      })
    },
    chartData () {
      // Traite les mesures brutes pour transformer les dates en coordonnées x (= âge de la mesure)
      // et exclure les mesures qui n'appartiennent pas à l'intervalle d'âge affiché
      const measuresDataset = this.measures.map((m) => ({
        y: m.value,
        x: this.calculateXValue(m.measuredAt),
      })).filter((m) => inRange(m.x, this.ageRange.min, this.ageRange.max))

      return {
        labels: this.chartLabels,
        datasets: [
          {
            data: measuresDataset,
            pointRadius: 4,
            borderWidth: 2,
            borderColor: COLORS.primary.base,
            pointHoverRadius: 5,
          },
          ...this.referenceDatasets,
        ],
      }
    },
    chartOptions () {
      const yScale = {
        max: this.datasetRange.max,
        min: this.datasetRange.min,
        ticks: { stepSize: this.healthBookCategoryRange.stepSize },
        title: {
          display: true,
          text: `${this.healthBookCategory.label} (${this.healthBookCategory.unit})`,
        },
      }

      const options = {
        plugins: { annotation: { annotations: { ...this.referenceAnnotations } } },
        scales: {
          y: yScale,
          yRight: {
            ...yScale,
            position: 'right',
          },
          x: {
            max: this.ageRange.max,
            min: this.ageRange.min,
            title: {
              display: true,
              text: `Âge (${this.healthBookAgeRange.unit.label})`,
              padding: {
                top: 4,
                bottom: 8,
              },
            },
            ticks: {
              maxTicksLimit: this.maxTicksLimit,
              maxRotation: 0,
            },
          },
          millimeterGraduation: {
            position: 'right',
            type: 'linear',
            max: MILLIMETER_LINE_PER_UNIT * ((this.datasetRange.max - this.datasetRange.min) / MILLIMETER_LINE_PER_UNIT),
            min: 0,
            grid: { drawTicks: false },
            ticks: {
              display: false,
              showLabelBackdrop: false,
              stepSize: this.healthBookCategoryRange.secondaryStepSize,
            },
          },
          timeGraduation: {
            position: 'top',
            max: this.ageRange.max,
            min: this.ageRange.min,
            grid: { drawTicks: false },
            ticks: { display: false },
          },
        },
      }
      // Gestion différentielle entre l'unité de temps mois vs année
      if (this.healthBookAgeRange.unit.yearCoefficient === 1) {
        // On masque les lignes verticales des x correspondants à un mois impair si l'unité de temps est l'année
        options.scales.timeGraduation.grid.color = function (context) {
          return context.index % 2 > 0 ? 'white' : COLORS.primary.lighten4
        }
        options.scales.timeGraduation.grid.z = - 2

        // On personnalise le tooltip
        options.plugins = {
          ...options.plugins,
          tooltip: { callbacks: { title: context => this.getTooltipTitleFromDecimalYearValue(context[0].label) } },
        }
      } else {
        // On affiche un deuxième axe en abcisses si l'unité de temps n'est pas l'année
        options.scales = {
          ...options.scales,
          ageGraduation: {
            position: 'bottom',
            max: this.ageRange.max * this.healthBookAgeRange.unit.yearCoefficient,
            min: this.ageRange.min * this.healthBookAgeRange.unit.yearCoefficient,
            title: {
              display: true,
              text: 'Âge (années)',
              padding: {
                top: - 8,
                bottom: 0,
              },
            },
            grid: {
              color: '#000',
              drawTicks: false,
            },
          },
        }
      }
      return options
    },
  },
  methods: {
    // Permet d'obtenir un tableau contenant l'ensemble des coordonnées graphiques
    // à partir d'un tableau de données de référence
    // (x: âge ; y: valeur correspondante du jeu de données)
    // note : le filtre sur les entiers garantit l'adéquation de length entre les x et les valeurs y (cas unité année)
    getFormattedDataset (dataset) {
      return dataset.reduce((acc, value, index) => [
        ...acc,
        {
          y: value,
          x: index + (this.chartLabels.filter(label => Number.isInteger(label)).length - dataset.length),
        },
      ], [])
    },
    generateDataset (data, options = {}) {
      return {
        data,
        borderDash: options.bordered ? options.dashOptions : undefined,
        fill: options.fill ? {
          target: options.fill,
          above: hexToRgba(COLORS.secondary.base, 0.15),
        } : undefined,
        borderColor: hexToRgba(COLORS.secondary.base, 1),
        borderWidth: options.widthOptions,
        pointRadius: 2,
        pointBorderWidth: 1,
        pointBackgroundColor: hexToRgba(COLORS.secondary.base, 1),
        pointColor: hexToRgba(COLORS.secondary.base, 1),
      }
    },
    setLineLegend (value, index) {
      // Construit l'annotation affichée pour les courbes de références
      if (this.sortedNormalDistributionRates.length > 0 && value !== 0) {
        const normalDistributionLevel = (index - Math.floor(this.sortedNormalDistributionRates.length / 2)) * - 1
        const sign = normalDistributionLevel > 0 ? '+' : '-'

        return `${sign}${Math.abs(normalDistributionLevel)}σ`
      } else if (this.sortedNormalDistributionRates.length === 0 && this.distributionUnit === '%' && value !== 50) {
        return value + this.distributionUnit
      } else if (this.sortedNormalDistributionRates.length === 0 && this.distributionUnit === 'σ' && value !== 0) {
        const sign = value > 0 ? '+' : ''
        return `${sign}${value}σ`
      } else if (this.sortedNormalDistributionRates.length === 0 && value === 50) {
        return 'M (50%)'
      }
      return 'M'
    },
    calculateXValue (measureDate) {
      // Permet de retourner un valeur en mois ou en années (avec décimales)
      return differenceInMonths(new Date(measureDate), new Date(this.patient.birthDate)) / (this.healthBookAgeRange.unit.yearCoefficient * 12)
    },
    getTooltipTitleFromDecimalYearValue (decimalYearValue) {
      // Permet d'afficher en tooltip année + mois dans le cas de valeur en année décimale
      const yearNumber = Math.trunc(decimalYearValue)
      const yearLabel = yearNumber + ' an' + ((yearNumber === 1) ? '' : 's')
      const monthsNumber = Math.round((decimalYearValue - yearNumber) * 12)
      const monthsLabel = (monthsNumber === 0) ? '' : `et ${monthsNumber} mois`
      return `${yearLabel} ${monthsLabel}`
    },
  },
}
</script>

<style lang="scss" scoped>
.patient-health-book-tendance-chart {
  position: relative;

  &__label {
    position: absolute;
    left: calc(2% + 56px);
    top: calc(2% + 10px);
    background-color: white;
    padding: map-get($spacers, 1) map-get($spacers, 3);
  }

  &__gender-icon {
    position: absolute;
    bottom: calc(5% + 80px);
    right: calc(5% + 30px);
    font-size: 150px;
    opacity: .15;

    ::v-deep {
      .v-icon {
        font-size: inherit;
      }
    }
  }

  &__canvas {
    padding-bottom: 10px;
  }

  &__legend {
    font-size: 85%;
    font-style: italic;
    padding-bottom: 10px;
  }
}
</style>