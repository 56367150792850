import ApiFile from '@/models/ApiFile'

import { getFormattedSize } from '@/utils/functions/files'
export default class MssMessageAttachment extends ApiFile {
  constructor (options = {}) {
    super(options)
    this.fileName = options.fileName || null
    this.size = options.size || null
    this.documentSource = options.documentSource || null
  }

  /**
   * Retourne la taille de la pièce jointe au format "taille unité"
   * @returns {String}
   */
  getFormattedSize () {
    return getFormattedSize(this.size)
  }
}