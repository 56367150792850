<template>
  <app-form :submit-method="submit">
    <div
      v-for="measure in localMeasures"
      :key="measure['@id']"
      v-test="'consultation-measures-edit-form__item'"
      class="consultation-measures-edit-form__item"
    >
      <app-number-field
        :key="measure['@id']"
        v-model="measure.value"
        v-test="`consultation-measures-edit-form-input-${measure.name}`"
        :label="measure.label"
        type="text"
        :suffix="measure.unit"
        rules="min_value:0.1"
        :disabled="! measure.editable"
        @input="() => handleMeasureInput(measure)"
      />
    </div>
  </app-form>
</template>

<script>
import { mapActions } from 'vuex'
import AppForm from '@/components/ui/form/AppForm.vue'
import AppNumberField from '@/components/ui/form/AppNumberField.vue'

import { parseNumber } from '@/utils/functions/number'
import NovaTools from '@/nova-tools/NovaTools'
import { makeBmiFromMeasures } from '@/modules/patient/utils/patientMeasures'
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin'

import Measure from '@/modules/patient/models/Measure'
import Consultation from '@/modules/patient/models/Consultation'

export default {
  name: 'ConsultationMeasuresEditForm',
  components: {
    AppForm,
    AppNumberField,
  },
  mixins: [
    localCopyMixin({
      copyPropertyName: 'localMeasures',
      propertyName: 'value',
    }),
  ],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    consultation: {
      type: Consultation,
      required: true,
    },
  },
  methods: {
    ...mapActions('patient', [
      'updatePatientMeasure',
      'insertPatientMeasure',
      'deletePatientMeasure',
      'fetchPatientMeasures',
      'fetchPatientIndicators',
    ]),
    async submit () {
      try {
        await Promise.all(this.localMeasures.
          filter((measure) => measure.changed && measure.name && measure.editable).
          map(async measureChanged => {
            let value
            // Cas d'un ajout de mesures non présente auparavant > Insert
            if (measureChanged['@id'] === null) {
              value = parseNumber(measureChanged.value)
              const measureToInsert = new Measure({
                name: measureChanged.name,
                value,
                consultation: this.consultation.getIri(),
              })
              const insertedMeasure = await this.insertPatientMeasure({
                patientMeasure: measureToInsert,
                fetchIndicators: false,
              })
              measureChanged = {
                ...measureChanged,
                ...insertedMeasure,
              }
              delete measureChanged.changed
              this.$emit('update:measure', measureChanged)
            } else {
              // Sinon on fait une mise à jour
              delete measureChanged.changed
              if (! measureChanged.value) {
                // valeur vide : on supprime la mesure
                await this.deletePatientMeasure({
                  patientMeasure: measureChanged,
                  fetchIndicators: false,
                })
                this.$emit('delete:measure', measureChanged)
              } else {
                value = parseNumber(measureChanged.value)
                // valeur non vide : update
                const measureToUpdate = new Measure({
                  ...measureChanged,
                  value,
                })
                await this.updatePatientMeasure({
                  patientMeasure: measureToUpdate,
                  fetchIndicators: false,
                })
                this.$emit('update:measure', measureToUpdate)
              }
            }
          }))
        // maj measures et indicator
        await this.fetchPatientMeasures(this.consultation.patient.getIri())
        await this.fetchPatientIndicators(this.consultation.patient.getIri())
        NovaTools.notify.success('Les données de suivi ont été modifiées avec succès')
      } catch {
        NovaTools.notify.error('Une erreur est survenue durant la mise à jour des données de suivi')
      }
    },
    handleMeasureInput (measure) {
      if (! measure.editable) { // Pas possible normalement car input disabled avec même condition mais garde fou
        return
      }
      measure.changed = true
      if (['size', 'weight'].includes(measure.name)) {
        const bmi = this.localMeasures.find(m => m.name === 'bmi')
        if (bmi) {
          bmi.value = makeBmiFromMeasures(this.localMeasures)
        }
      }
    },
  },
}
</script>