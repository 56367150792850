export default {
  methods: {
    /**
     * Permet d'ajouter des erreurs de l'api aux validation providers du formulaire
     * Il suffit de bien mapper le vid du provider avec le nom de la propriété de l'api
     * ex :  <validation-provider vid="birthDate" v-slot="{ errors }">
     * @param {Error} error l'objet erreur renvoyé par l'api
     * @param {Object} observerRef La ref de l'observeur veeValidate
     */
    setViolations (error, observerRef) {
      if (
        ! error.response ||
        ! error.response.data ||
        ! error.response.data.violations
      ) {
        return
      }
      const violationsArray = error.response.data.violations
      violationsArray.forEach(err => {
        observerRef.setErrors({ [err.propertyPath]: [err.message] })
      })
    },
  },
}