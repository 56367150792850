import ApiEntity from '@/models/ApiEntity'

export default class PatientRiskFactor extends ApiEntity {
  constructor (options = {}) {
    super(options)

    this.label = options.label || null
    this.note = options.note || null
    this.patient = options.patient || null
  }
}