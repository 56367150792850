<template>
  <app-modal-form
    :title="title"
    :is-opened="isOpen"
    :form="SendMessageForm"
    :value="message"
    :patient="patient"
    width="60%"
    submit-text="Envoyer"
    @cancel="close"
    @submitSuccess="close"
  />
</template>

<script>
import AppModalForm from '@/components/ui/modal/AppModalForm.vue'
import SendMessageForm from '@/modules/secureMessaging/modules/messaging/forms/SendMessageForm.vue'

import MssMessage from '@/modules/secureMessaging/modules/messaging/models/MssMessage'
import Patient from '@/modules/patient/models/Patient'

export default {
  name: 'SendMessageModalForm',
  components: { AppModalForm },
  props: {
    message: {
      type: MssMessage,
      required: true,
    },
    title: {
      type: String,
      default: 'Envoyer un message',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    patient: {
      type: Patient || null,
      default: null,
    },
  },
  data () {
    return { SendMessageForm }
  },
  methods: {
    close () {
      this.$emit('update:is-open', false)
    },
  },
}
</script>