export const SENT_PATIENT_DOC_LOG_TYPES = Object.freeze({
  PATIENT_MSS: {
    label: 'Envoyés par MSS Patient',
    value: 'patient_mss',
  },
  PRO_MSS: {
    label: 'Envoyés par MSS Pro',
    value: 'pro_mss',
  },
  DMP: {
    label: 'Envoyés au DMP Patient',
    value: 'dmp',
  },
})