import ApiEntity from '@/models/ApiEntity'
import GrandRegime from '@/models/GrandRegime'

/**
 * @typedef {Object} PatientBillDatasetType
 * @property {GrandRegimeType} grandRegime
 * @property {String} birthRank
 * @property {String} fund
 * @property {String} center
 * @property {String} patient
 */
export default class PatientBillDataset extends ApiEntity {

  /**
   * @param {PatientBillDatasetType} options
   */
  constructor (options = {}) {
    super(options)
    this.birthDate = options.birthDate || null
    this.birthRank = options.birthRank || null
    this.center = options.center || null
    this.grandRegime = options.grandRegime ? new GrandRegime(options.grandRegime) : null
    this.fund = options.fund || null
    this.quality = isNaN(parseInt(options.quality, 10)) ? null : options.quality
  }
}