<template>
  <section>
    <div class="health-summary-infos">
      <health-summary-vigilance-points :vigilance-points.sync="vigilancePoints" />
      <p class="mb-1">
        Consultez puis validez les informations ci-dessous afin de générer le VSM.
      </p>
      <dmp-sending-action-message
        v-model="localDmpSync"
        v-test="'ins-alert'"
        :patient-ins="patientIns"
        is-desynchronizable
      />
    </div>
    <pdf-viewer v-if="!isLoading" v-test="'health-summary-preview'" :base64-file-content="healthSummaryBase64FileContent" />
    <div v-else class="health-summary-loading">
      <app-circular-loader
        v-test="'health-summary-loading'"
        :size="64"
        :width="4"
      />
    </div>
  </section>
</template>

<script>
import { getFromAPI } from '@/services/api'

import AppCircularLoader from '@/components/ui/loaders/AppCircularLoader.vue'
import DmpSendingActionMessage from '@/modules/patient/components/insIdentity/DmpSendingActionMessage.vue'
import HealthSummaryVigilancePoints from '@/modules/patient/modules/patientFile/modules/documents/components/healthSummariesPanel/HealthSummaryVigilancePoints.vue'

import PatientInsIdentity from '@/modules/patient/models/PatientInsIdentity'
import PdfViewer from '@/components/ui/viewer/PdfViewer.vue'
import { LocalCopyMixin } from '@novalys/src/mixins'

export default {
  name: 'HealthSummaryPreview',
  components: {
    PdfViewer,
    AppCircularLoader,
    DmpSendingActionMessage,
    HealthSummaryVigilancePoints,
  },
  mixins: [LocalCopyMixin({
    propertyName: 'dmpSync',
    copyPropertyName: 'localDmpSync',
  })],
  props: {
    patientIri: {
      type: String,
      required: true,
    },
    patientIns: {
      type: PatientInsIdentity,
      required: true,
    },
    dmpSync: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isLoading: false,
      healthSummaryBase64FileContent: null,
      vigilancePoints: null,
    }
  },
  watch: {
    patientIri: {
      immediate: true,
      handler () {
        this.loadVsmPreview()
      },
    },
    vigilancePoints: {
      handler () {
        this.loadVsmPreview()
        this.$emit('add-vigilance-points', this.vigilancePoints)
      },
    },
  },
  methods: {
    async loadVsmPreview () {
      this.isLoading = true
      try {
        const queryParams = this.vigilancePoints ? { vigilancePoints: this.vigilancePoints } : {}
        const { data } = await getFromAPI(`${this.patientIri}/health_summary_preview`, queryParams)
        this.healthSummaryBase64FileContent = data.healthSummaryBase64FileContent
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.health-summary-infos {
  margin-bottom: map-get($spacers, 5) !important;
}
.health-summary-preview {
  min-width: 100%;
  height: 100%;
}
.health-summary-loading {
  display: flex;
  justify-content: center;
  padding-top: map-get($spacers, 5);
  height: 60vh;
}
</style>