/**
 * Ecoute le changement de watchedProperty
 * Et modifie le contenu de l'input en fonction du callback textTransform
 */
export default (watchedProperty) => ({
  props: {
    textTransform: {
      type: Function,
      default: null,
    },
  },
  watch: {
    [watchedProperty] (value) {
      if (this.textTransform) {
        const newValue = this.textTransform(value)

        if (newValue !== value) {
          this[watchedProperty] = newValue
          const inputEl = this.$refs['input'].$el.querySelector('input')
          const caretPosition = inputEl.selectionStart
          inputEl.value = this[watchedProperty]
          inputEl.dispatchEvent(new Event('input'))
          inputEl.selectionStart = caretPosition
          inputEl.selectionEnd = caretPosition
        }
      }
    },
  },
})