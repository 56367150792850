import { INS_IDENTITY_STATUS } from '@/modules/patient/constants'

import RuleValidator from '@/utils/classes/RuleValidator'

export const validInsStatus = new RuleValidator('valid_ins_status',
  (status, { device }) => {
    const strongInsStatus = [INS_IDENTITY_STATUS.VALIDATED.value, INS_IDENTITY_STATUS.QUALIFIED.value]
    const isStrongTrustStatus = strongInsStatus.includes(status)
    if (isStrongTrustStatus && ! device?.strongTrustLevel) {
      return 'Ce statut ne peut être attribué que si un justificatif d\'identité à fort degré de confiance a été fourni.'
    }
    return true
  },
  { params: ['device'] },
)