<template>
  <app-dialog
    :is-open.sync="opened"
    fullscreen
    persistent
    class="app-modal-patient-form"
  >
    <patient-form-view
      v-if="opened"
      data-test="patient-form-view"
      ignore-redirection
      :patient="patient"
      @cancel="handleCancel"
      @submit-success="patient => $emit('patient-set', patient)"
    />
  </app-dialog>
</template>

<script>
import PatientFormView from '@/modules/patient/views/PatientFormView.vue'
import AppDialog from '@/components/ui/modal/AppDialog.vue'
import ModalMixin from '@/components/ui/modal/mixins/ModalMixin'

import Patient from '@/modules/patient/models/Patient'

/**
 * Modale fullscreen de création de patient
 */
export default {
  name: 'AppModalPatientForm',
  components: {
    PatientFormView,
    AppDialog,
  },
  mixins: [ModalMixin],
  props: {
    /**
     * Permet d'éditer un patient
     * @default null
     */
    patient: {
      type: Patient,
      required: false,
      default: null,
    },
  },
}
</script>