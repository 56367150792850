import Vue from 'vue'
import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI'
import Pathology from '@/modules/patient/models/riskFactors/Pathology'
import { postToAPI, deleteFromAPI, putToAPI } from '@/services/api'

export const state = () => ({
  pathologies: {},
  hasLoadedPatientPathologies: {},
  isLoadingPathologies: false,
})

export const getters = {
  getPatientPathologies: state => patientUuid => Object.values(state.pathologies[patientUuid] || {}),
  getHasLoadedPathologiesByPatientUuid: state => patientUuid => state.hasLoadedPatientPathologies[patientUuid],
  isLoadingPathologies: state => state.isLoadingPathologies,
}

export const mutations = {
  SET_PATHOLOGY (state, pathology) {
    const patientUuid = getUUIDFromIRI(pathology.patient)
    const pathologyUuid = getUUIDFromIRI(pathology['@id'])

    if (state.pathologies[patientUuid] === undefined) {
      Vue.set(state.pathologies, patientUuid, {})
    }
    Vue.set(state.pathologies[patientUuid], pathologyUuid, new Pathology(pathology))
  },
  SET_HAS_LOADED_PATHOLOGIES (state, { patientUuid, hasLoadedPathologies }) {
    Vue.set(state.hasLoadedPatientPathologies, patientUuid, hasLoadedPathologies)
  },
  SET_IS_LOADING_PATHOLOGIES (state, isLoadingPathologies) {
    state.isLoadingPathologies = isLoadingPathologies
  },
  DELETE_PATHOLOGY (state, pathology) {
    const patientUuid = getUUIDFromIRI(pathology.patient)
    Vue.delete(state.pathologies[patientUuid], pathology.getUuid())
  },
}

export const actions = {
  async fetchPatientPathologies ({ state, commit, dispatch }, patientUuid) {
    commit('SET_IS_LOADING_PATHOLOGIES', true)
    try {
      const pathologies = await dispatch('fetchPatientRiskFactor', {
        route: `api/patients/${patientUuid}/pathologies`,
        patientUuid,
        riskFactorName: 'pathologies',
      })
      pathologies.forEach(pathology => commit('SET_PATHOLOGY', pathology))
    } finally {
      commit('SET_IS_LOADING_PATHOLOGIES', false)

      if (! state.hasLoadedPatientPathologies[patientUuid]) {
        commit('SET_HAS_LOADED_PATHOLOGIES', {
          patientUuid,
          hasLoadedPathologies: true,
        })
      }
    }
  },
  async insertPatientPathology ({ commit }, pathology) {
    const { data } = await postToAPI('api/patient_pathologies', pathology)
    commit('SET_PATHOLOGY', data)
  },
  async updatePatientPathology ({ commit }, pathology) {
    const { data } = await putToAPI(pathology['@id'], { data: pathology })
    commit('SET_PATHOLOGY', data)
  },
  async deletePatientPathology ({ commit }, pathology) {
    await deleteFromAPI(pathology.getIri())
    commit('DELETE_PATHOLOGY', pathology)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}