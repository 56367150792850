<template>
  <app-mobile-subheader class="patient-file-sub-header" :back-route-name="backRouteName" @click:menu="$emit('click:menu')">
    <patient-details
      data-test="patient-details"
      :patient="patient"
      :show-anonymized-identifier="false"
      :show-keywords="false"
      x-small
    />
  </app-mobile-subheader>
</template>

<script>
import AppMobileSubheader from '@/components/ui/mobileSubheader/AppMobileSubheader.vue'
import PatientDetails from '@/modules/patient/components/PatientDetails.vue'

import Patient from '@/modules/patient/models/Patient'

export default {
  name: 'PatientFileSubHeader',
  components: {
    AppMobileSubheader,
    PatientDetails,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
    backRouteName: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.patient-file-sub-header {
  z-index: 1;
}
</style>