<template>
  <n-list class="app-contact-point-groups" :items="Object.keys(groups)">
    <template #item="{ item }">
      <div v-test="`${item}-contact-points`" class="app-contact-point-groups__group">
        <app-icon
          color="secondary"
          :icon="getMediaIcon(item)"
          class="app-contact-point-groups__group__icon"
        />
        <div class="app-contact-point-groups__group__contact-points">
          <app-contact-point
            v-for="(contactPoint, index) in getContactPointsFromMediaName(item)"
            :key="index"
            :contact-point="contactPoint"
          />
        </div>
      </div>
    </template>
  </n-list>
</template>

<script>
import AppContactPoint from '@/components/ui/contactPoint/AppContactPoint.vue'

import ContactPoint from '@/models/ContactPoint'

import { groupBy } from 'lodash'

import { MEDIA_TYPES, CONTACT_POINT_LABELS } from '@/constants'

export default {
  name: 'AppContactPointList',
  components: { AppContactPoint },
  props: {
    contactPoints: {
      type: Array,
      required: true,
      validator: contactPoints => contactPoints.every(contactPoint => contactPoint instanceof ContactPoint),
    },
  },
  computed: {
    groups () {
      return groupBy(this.contactPoints, 'media')
    },
  },
  methods: {
    getMediaIcon (mediaName) {
      return MEDIA_TYPES[mediaName.toUpperCase()].icon
    },
    getContactPointsFromMediaName (mediaName) {
      // Temporaire, à refactorer lorsque les labels seront traités côté application comme des champ libres
      const unknownLabelFromMedia = {
        [MEDIA_TYPES.TELEPHONE.value]: CONTACT_POINT_LABELS.OTHER.value,
        [MEDIA_TYPES.EMAIL.value]: 'Email',
      }
      return this.groups[mediaName]
        .map(contactPoint => new ContactPoint({
          ...contactPoint,
          label: contactPoint.label || unknownLabelFromMedia[mediaName],
        }))
    },
  },
}
</script>
<style lang="scss" scoped>
.app-contact-point-groups {

  &__group {
    display: flex;
    align-items: flex-start;

    &__icon {
      margin-right: map-get($spacers, 2);
    }

    &__contact-points {
      width: 100%;
    }
  }
}
</style>