export const BiO_EXAM_RELEVANT_CLINICAL_TYPES = Object.freeze({
  DEFAULT: {
    icon: 'dna',
    color: 'info',
    text: 'Ce document contient des résultats d\'examens biologiques avec un code d\'interprétation ou un commentaire',
  },
  CRITICAL: {
    icon: 'dna',
    color: 'error',
    text: 'Ce document contient des résultats d\'examens biologiques critiques',
  },
  DISCREPANCY: {
    icon: 'dna',
    color: 'warning',
    text: 'Ce document contient des résultats d\'examens avec des écarts',
  },
})