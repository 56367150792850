<template>
  <n-dialog
    divided
    :is-open.sync="localIsOpen"
    :actions="modalActions"
    title="Rattacher le document à un patient"
    content-class="document-attach-modal"
    width="720"
  >
    <p class="secondary--text mb-2">
      Patient concerné
    </p>
    <app-row>
      <app-col cols="6" class="d-flex align-center">
        <patient-details
          v-test="'concerned-patient'"
          x-small
          :patient="relatedPatient"
          :show-anonymized-identifier="false"
          :show-pictograms="false"
          show-birth-informations
        />
      </app-col>
      <app-col cols="6">
        <div v-if="needVerification" class="d-flex align-center">
          <patient-identity-verification-button
            v-if="needCheckPatientInsIdentity"
            v-test="'ins-verification'"
            :patient="relatedPatient"
            @check-ins="getInsCheckStatus"
          />
          <div v-else class="flex-list-2 align-center">
            <app-icon
              :icon="isRelatedPatientIdentityValid ? 'check-circle' : 'close-alt'"
              :color="isRelatedPatientIdentityValid ? 'success' : 'error'"
              size="24"
            />
            <div>
              <p class="subtitle-3">
                {{ isRelatedPatientIdentityValid ? 'Identité vérifiée par l\'INS' : 'L\'appel du service de vérification de l\'INS n\'a renvoyé aucune identité.' }}
              </p>
              <p class="secondary--text">
                {{ isRelatedPatientIdentityValid ? 'Le document peut être rattaché' : 'Le document ne peut pas être rattaché' }}
              </p>
            </div>
          </div>
        </div>
        <div v-else class="flex-list-2 align-center">
          <app-icon
            icon="check-circle"
            color="success"
            size="24"
          />
          <div>
            <p class="subtitle-3">
              Vérification INS non nécessaire en raison d'un contrat de confiance actif avec l'émetteur
            </p>
            <p class="secondary--text">
              Le document peut être rattaché
            </p>
          </div>
        </div>
      </app-col>
      <app-col cols="12">
        <patient-strict-traits-dataset-grid class="flex-grow-1" :patient="relatedPatient" />
      </app-col>
    </app-row>
    <n-divider :spacing="6" class="mx-n6" />
    <div class="document-attach-modal__results">
      <app-circular-loader v-if="isFetchingRelatedOrganisationPatients" label="Recherche de correspondances" />
      <template v-else>
        <div>
          <h3 class="subtitle-3 mb-1">
            {{ heading.title }}
          </h3>
          <p v-if="heading.text" class="secondary--text">
            {{ heading.text }}
          </p>
        </div>
        <patient-pending-document-matches-list
          v-if="organisationPatientMatches.length"
          v-model="selectedMatchingPatientIri"
          :patient-matches.sync="organisationPatientMatches"
          :related-patient="relatedPatient"
          :disabled="! isRelatedPatientIdentityValid"
          class="mt-6"
          @document-attach-failed="$emit('document-attach-failed', document)"
        />
      </template>
    </div>
  </n-dialog>
</template>

<script>
import AppCircularLoader from '@/components/ui/loaders/AppCircularLoader.vue'
import PatientDetails from '@/modules/patient/components/PatientDetails.vue'
import PatientIdentityVerificationButton from '@/modules/patient/components/PatientIdentityVerificationButton.vue'
import PatientStrictTraitsDatasetGrid from '@/modules/patient/components/PatientStrictTraitsDatasetGrid.vue'
import PatientPendingDocumentMatchesList from './PatientPendingDocumentMatchesList.vue'

import { getFromAPI, postToAPI } from '@/services/api'
import { pluralize } from '@/utils/functions/words'

import Patient from '@/modules/patient/models/Patient'
import PendingMssDocument from '@/modules/secureMessaging/modules/documents/models/PendingMssDocument'
import ButtonAction from '@novalys/src/models/ButtonAction'

import { INSEE_UNKNOWN_REF, INS_IDENTITY_STATUS, ROUTE_NAMES as PATIENT_ROUTE_NAMES } from '@/modules/patient/constants'
import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'PatientMssPendingDocumentAttachModal',
  components: {
    AppCircularLoader,
    PatientDetails,
    PatientIdentityVerificationButton,
    PatientPendingDocumentMatchesList,
    PatientStrictTraitsDatasetGrid,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    document: {
      type: PendingMssDocument,
      required: true,
    },
    /**
     * Necessite une vérification de l'identité INS du patient du document avant le rattachement
     */
    needVerification: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      organisationPatientMatches: [],
      selectedMatchingPatientIri: null,
      isFetchingRelatedOrganisationPatients: false,
      needCheckPatientInsIdentity: false,
      isRelatedPatientIdentityValid: false,
    }
  },
  computed: {
    localIsOpen: {
      get () {
        return this.isOpen
      },
      set (isOpen) {
        this.$emit('update:is-open', isOpen)
      },
    },
    isSubmitDisabled () {
      if (this.organisationPatientMatches.length > 0) {
        if (this.isRelatedPatientIdentityValid) {
          return ! this.selectedMatchingPatient?.insIdentity.isValidated()
        }
        return true
      }
      return this.isFetchingRelatedOrganisationPatients
    },
    selectedMatchingPatient () {
      return this.organisationPatientMatches.find?.(patientMatch => patientMatch['@id'] === this.selectedMatchingPatientIri)
    },
    relatedPatient () {
      return this.document.metaDataset.getPatient()
    },
    submitText () {
      if (this.organisationPatientMatches.length === 0) {
        return this.isRelatedPatientIdentityValid ? 'Créer le patient et rattacher le document' : 'Créer le patient sans rattacher le document'
      }
      return 'Rattacher'
    },
    modalActions () {
      return [
        new ButtonAction('Annuler', () => this.$emit('update:is-open', false)),
        new ButtonAction(this.submitText, this.handleSubmit, 'primary', { disabled: this.isSubmitDisabled }),
      ]
    },
    heading () {
      const matchesCount = this.organisationPatientMatches.length
      if (matchesCount > 0) {
        let text
        if (this.isRelatedPatientIdentityValid) {
          text = matchesCount > 1 ? 'Sélectionnez le patient auquel rattacher le document' : 'Le document sera rattaché à ce patient de l\'organisation'
        }
        return {
          title: `${matchesCount} ${pluralize('correspondance', matchesCount)} ${pluralize('trouvée', matchesCount)}`,
          text,
        }
      }
      return {
        title: 'Aucune correspondance n\'a été trouvée dans l\'organisation',
        text: this.isRelatedPatientIdentityValid
          ? 'Souhaitez ajouter le patient concerné à l\'organisation et lui rattacher le document ?'
          : 'Souhaitez vous ajouter le patient concerné à l\'organisation sans rattacher le document ?',
      }
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      async handler (isOpen) {
        if (isOpen) {
          if (this.needVerification) {
            this.needCheckPatientInsIdentity = true
          } else {
            this.isRelatedPatientIdentityValid = true
          }
          await this.getRelatedOrganisationPatients()
          if (this.organisationPatientMatches.length === 1 && this.organisationPatientMatches[0].insIdentity.isQualified()) {
            NovaTools.notify.info('Rattachement automatique du document.', { title: 'Document correspondant a une identité qualifiée' })
            this.attachDocumentToPatient(this.organisationPatientMatches[0])
          }
          return
        }
        this.isRelatedPatientIdentityValid = false
      },
    },
  },
  methods: {
    getPatientFileRedirectionAction (patient) {
      return new ButtonAction('Accéder au dossier du patient', () => {
        this.$router.push({
          name: PATIENT_ROUTE_NAMES.PATIENT_FILE,
          params: { uuid: patient.getUuid() },
        })
      })
    },
    async getRelatedOrganisationPatients () {
      try {
        this.isFetchingRelatedOrganisationPatients = true
        const { data } = await getFromAPI(`/api/search_by_pending_mss_document/${this.document.getUuid()}/patients`)
        this.organisationPatientMatches = data['hydra:member'].map(patient => new Patient(patient))
        if (this.organisationPatientMatches.length === 1) {
          this.selectedMatchingPatientIri = this.organisationPatientMatches[0]['@id']
        }
      } finally {
        this.isFetchingRelatedOrganisationPatients = false
      }
    },
    getInsCheckStatus (isInsValid) {
      if (isInsValid) {
        NovaTools.notify.success('L\'INS a été vérifié avec succès')
        this.isRelatedPatientIdentityValid = true
        this.$emit('document-ready-to-attach', this.document)
      } else {
        NovaTools.notify.warning('L\'INS n\'a pas pu être vérifié')
        this.$emit('document-attach-failed', this.document)
      }
      this.needCheckPatientInsIdentity = false
    },
    async attachDocumentToPatient (patient) {
      const { data } = await postToAPI(this.document.getIri() + '/turn_into_patient_imported_document', {
        patient: patient['@id'],
        title: this.document.title,
        mssFileName: 'IHE_XDM.ZIP',
      })
      if (data.update) {
        NovaTools.notify.info(`À été mis à jour vers la version ${this.document.metaDataset.versionNumber}`, { title: 'Document déjà existant' })
        return
      }
      NovaTools.notify.success(`Le document a été rattaché au dossier de ${patient.getCivilState()}`, {
        timeout: - 1,
        actions: [this.getPatientFileRedirectionAction(patient)],
      })
      this.$emit('document-attached')
    },
    async handleSubmit () {
      if (this.organisationPatientMatches.length === 0) {
        const createdPatient = await Patient.insert(
          new Patient({
            ...this.relatedPatient,
            birthPlaceCode: this.relatedPatient.birthPlaceCode || INSEE_UNKNOWN_REF.value,
            insIdentity: this.isRelatedPatientIdentityValid ? {
              ...this.relatedPatient.insIdentity,
              status: INS_IDENTITY_STATUS.FETCHED.value,
            } : null,
          }),
        )
        NovaTools.notify.success(`${createdPatient.getCivilState()} fait désormais partit de l'organisation`, { actions: [this.getPatientFileRedirectionAction(createdPatient)] })
        if (this.isRelatedPatientIdentityValid) {
          await this.attachDocumentToPatient(createdPatient)
        }
        return
      }
      await this.attachDocumentToPatient(this.selectedMatchingPatient)
    },
  },
}
</script>

<style lang="scss">
.document-attach-modal {
  .n-card__content {
    overflow: visible;
    display: flex;
    flex-direction: column;
  }

  &__results {
    overflow: auto;
    padding: map-get($spacers, 6);
    margin: -#{map-get($spacers, 6)};
  }
}
</style>