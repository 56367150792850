<template>
  <div class="patient-medical-infos">
    <div v-if="patient && medicalDataset" class="pa-4" data-test="patient-medical-dataset">
      <app-hoverable-actions-wrapper :actions="getActions()" class="d-flex">
        <app-dataset-item label="Groupe sanguin" :value="medicalDataset.bloodType" />
      </app-hoverable-actions-wrapper>
      <app-modal-form
        v-if="isEditingBloodType"
        v-model="medicalDataset"
        width="380"
        title="Groupe sanguin"
        :form="MedicalDatasetForm"
        data-test="edit-form"
        :medical-dataset="medicalDataset"
        :scrollable="false"
        :patient="patient"
        @cancel="isEditingBloodType = false"
        @submitSuccess="isEditingBloodType = false"
      />
    </div>
    <app-skeleton-loader
      v-else
      type="list-item-three-line"
      data-test="patient-medical-dataset-skeleton"
    />
  </div>
</template>

<script>
import AppModalForm from '@/components/ui/modal/AppModalForm'
import AppHoverableActionsWrapper from '@/components/ui/hoverableActionsWrapper/AppHoverableActionsWrapper.vue'
import MedicalDatasetForm from './MedicalDatasetForm.vue'
import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue'
import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue'

import { mapActions, mapGetters } from 'vuex'

import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'
import Patient from '@/modules/patient/models/Patient'

export default {
  name: 'PatientMedicalDataset',
  components: {
    AppHoverableActionsWrapper,
    AppModalForm,
    AppSkeletonLoader,
    AppDatasetItem,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      isEditingBloodType: false,
      MedicalDatasetForm,
    }
  },
  computed: {
    ...mapGetters('patient', ['getPatientMedicalDataset']),
    medicalDataset () {
      return this.getPatientMedicalDataset(this.patient.getUuid())
    },
  },
  watch: {
    patient: {
      immediate: true,
      async handler (patient) {
        if (patient && patient.medicalDataset) {
          await this.fetchPatientMedicalDataset(patient)
        }
      },
    },
  },
  methods: {
    ...mapActions('patient', ['fetchPatientMedicalDataset']),
    getActions () {
      return [new ActionMenuItem(
        'edit',
        null,
        () => this.isEditingBloodType = true,
      )]
    },
  },
}
</script>

<style lang="scss" scoped>
.patient-medical-infos {
  overflow: hidden;
  &__item,
  .item {
    display: flex;
    align-self: normal;
    align-items: center;

    &__content {
      flex: 1;
      font-size: 13px;
    }
  }
}
</style>