<template>
  <n-portal to="overlays">
    <v-dialog
      :value="isVisible"
      :scrollable="true"
      :fullscreen="isMobile"
      class="app-modal-tabbed-forms"
      content-class="rounded-lg"
      overlay-color="text"
      :width="width"
      :transition="modalTransition"
      :persistent="persistent"
      @click:outside="!persistent ? $emit('cancel') : null"
      @keydown="handleKey"
    >
      <slot />
      <v-card>
        <v-tabs :value="value" grow @change="newValue => $emit('input', newValue)">
          <v-tab
            v-for="(tab, index) in tabs"
            :key="index"
          >
            {{ tab.title }}
          </v-tab>
        </v-tabs>
        <v-tabs-items :value="value">
          <v-tab-item
            v-for="(item, index) in tabs"
            :key="index"
          >
            <app-form-wrapper
              :ref="`formWrapper-${index}`"
              :wrapper="VCardWrapper"
              :wrapper-props="{
                color: 'blue-grey lighten-2'
              }"
              v-bind="item"
              title=""
            >
              <template #default="{ localValue, formValidation, isSubmitting, formId, validationObserver }">
                <v-card-text class="app-modal-tabbed-forms__content">
                  <component
                    :is="item.form"
                    :id="`${formId}-${index}`"
                    :ref="`form-${index}`"
                    :data-test="`form-${index}`"
                    :value="localValue"
                    :validation-observer="validationObserver"
                    @submitForm="(submitMethod) => submitForm($refs[`formWrapper-${index}`][0], submitMethod)"
                  />
                </v-card-text>

                <v-card-actions class="pa-4 px-md-8 py-md-5">
                  <v-spacer />
                  <!-- @slot Slot pour inserer les actions possibles sur la modal -->
                  <slot name="actions">
                    <!--
                    Clic sur le bouton d'annulation de la modale
                    @event cancel
                  -->
                    <app-button text :color="item.cancelColor || cancelColor" @click="$emit('cancel')">
                      {{ item.cancelText || cancelText }}
                    </app-button>
                    <!--
                    Clic sur le bouton de confirmation de la modale
                    @event submit
                  -->
                    <app-button
                      :color="item.submitColor || submitColor "
                      :loading="isSubmitting"
                      :disabled="!formValidation.valid"
                      type="submit"
                      :form="`${formId}-${index}`"
                      @click="$emit('submit')"
                    >
                      {{ item.submitText || submitText }}
                    </app-button>
                  </slot>
                </v-card-actions>
              </template>

              <v-divider />
            </app-form-wrapper>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </n-portal>
</template>

<script>
import AppFormWrapper from '@/components/ui/form/AppFormWrapper.vue'
import ModalFormMixin from './mixins/ModalFormMixin'

export default {
  name: 'AppTabbedModalForms',
  components: { AppFormWrapper },
  mixins: [ModalFormMixin],
  props: {
    /**
     * Tab affichée par défaut
     */
    value: {
      type: Number,
      default: 0,
    },
    /**
     * Contient les tabs
     * Chaque tab est un objet avec les même props
     * que les tabs classiques
     */
    tabs: {
      type: Array,
      required: true,
    },
    /**
     * Indique si la modale est visible ou pas
     */
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.app-modal-tabbed-forms {
  &__content {
    background: white;
    padding: map-get($spacers, 6);
    align-self: flex-start;
  }
}

::v-deep {
  .v-tabs {
    flex: 0;
  }

  .v-dialog {

    .v-window,
    .v-window .v-window__container,
    .v-window .v-window__container .v-window-item,
    .v-window .v-window__container .v-window-item .v-card {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .v-window {
      &__container {
        overflow: hidden;

        &--is-active {
          .v-window-item,
          .v-window-item .v-card {
            transition: none !important;
          }
        }

        .v-window-item {
          overflow: hidden;

          .v-card {
            justify-content: space-between;
            overflow: hidden;

            &__text {
              flex: 1;
              overflow: auto;
            }
          }
        }
      }
    }
  }
}
</style>