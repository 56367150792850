/**
 * Plugin permettant la synchronisation des timings de déconnexion
 * @param {Store} store
 */
const tokenExpirationPlugin = async store => {
  /**
   * Si une instance de l'app est actuellement en cours et qu'une autre instance est ouverte,
   * alors elle sera automatiquement synchronisée sur cette première instance
   */
  const authPayload = JSON.parse(localStorage.getItem('authPayload'))
  if (authPayload) {
    await store.dispatch('auth/refreshTokenStorage', authPayload)
  }

  /**
   * Si le token a changé, alors les autres instances de l'app sont aussi notifiées
   */
  window.onstorage = async ({ key, newValue: authPayload }) => {
    if (key === 'authPayload' && authPayload) {
      await store.dispatch('auth/refreshTokenStorage', JSON.parse(authPayload))
    }
  }
}

export default tokenExpirationPlugin