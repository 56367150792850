<template>
  <div>
    <n-button
      v-for="(action, index) in actions"
      :key="index"
      :disabled="isActionDisabled(index)"
      :loading="isActionLoading(index)"
      :label="action.label"
      :bg-color="action.bgColor"
      @click="handleClickAction(index)"
    />
  </div>
</template>

<script>
import NButton from '@/nova-ui/NButton/NButton.vue'

import ButtonAction from '@novalys/src/models/ButtonAction'

/**
 * Permet d'afficher un groupe d'actions sous la forme de boutons
 */
export default {
  name: 'NButtonActions',
  components: { NButton },
  props: {
    /**
     * tableau des actions à afficher
     */
    actions: {
      type: Array,
      default: null,
      validator: actions => actions.every(action => action instanceof ButtonAction),
    },
  },
  data () {
    return { loadingActionIndex: null }
  },
  methods: {
    isActionDisabled (actionIndex) {
      if (this.actions[actionIndex].disabled) {
        return true
      }
      return !! this.loadingActionIndex && (actionIndex !== this.loadingActionIndex)
    },
    isActionLoading (actionIndex) {
      return this.loadingActionIndex === actionIndex
    },
    async handleClickAction (actionIndex) {
      this.loadingActionIndex = actionIndex
      try {
        await this.actions[actionIndex].onClick()
        this.$emit('confirm')
      } catch (e) {
        this.$emit('error', e)
      } finally {
        this.loadingActionIndex = null
      }
    },
  },
}
</script>