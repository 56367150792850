import SentryService from '@/nova-tools/traces/sentry'

export class Traces {
  initialise () {
    SentryService.initialise()
  }
  addMessageError (message) {
    SentryService.addMessageError(message)
  }
}

export default new Traces()