<template>
  <v-row :dense="dense">
    <slot />
  </v-row>
</template>

<script>
/**
 * Ligne de la grille de l'application
 * @see {@link https://vuetifyjs.com/en/components/grids/}
 */
export default {
  name: 'AppRow',
  props: {
    /**
     * Réduit la taille des marges du composant
     * @default false
     */
    dense: {
      type: Boolean,
      default: false,
    },
  },
}
</script>