<template>
  <div>
    <app-actions-menu :actions="actions" :max-actions-visible="0" data-test="waiting-room-actions-menu">
      <template #activator="{ on }">
        <div class="d-flex align-center">
          <app-circular-loader v-if="isCancelingAppointment" data-test="waiting-room-loader" class="mr-2" />
          <span class="app-action-menu__label" v-on="on">Rendez-vous non honoré</span>
        </div>
      </template>
    </app-actions-menu>
  </div>
</template>

<script>
import AppActionsMenu from '@/components/ui/actionsMenu/AppActionsMenu.vue'
import AppCircularLoader from '@/components/ui/loaders/AppCircularLoader.vue'

import Appointment from '@/modules/agenda/models/events/Appointment'

import { APPOINTMENT_STATES } from '@/modules/agenda/constants'
import { mapActions } from 'vuex'

export default {
  name: 'AppointmentWaitingRoomActionsMenu',
  components: {
    AppActionsMenu,
    AppCircularLoader,
  },
  props: {
    appointment: {
      type: Appointment,
      required: true,
    },
  },
  data () {
    return {
      actions: {
        cancelWithExcuse: {
          icon: 'userTag',
          text: 'Patient excusé',
          callback: () => this.cancelAppointment(true),
        },
        cancelWithoutExcuse: {
          icon: 'ban',
          text: 'Patient non excusé',
          callback: () => this.cancelAppointment(false),
        },
      },
      isCancelingAppointment: false,
    }
  },
  methods: {
    ...mapActions('agenda', ['updateAppointment']),
    async cancelAppointment (isExcused) {
      const newAppointmentState = isExcused
        ? APPOINTMENT_STATES.EXCUSED_PATIENT
        : APPOINTMENT_STATES.NOT_EXCUSED_PATIENT

      try {
        this.isCancelingAppointment = true
        await this.updateAppointment({
          ...this.appointment,
          state: newAppointmentState.value,
        })
      } catch (error) {}
      this.isCancelingAppointment = false
    },
  },
}
</script>

<style lang="scss" scoped>
.app-action-menu {
  &__label {
    cursor: pointer;
    text-decoration: underline;
    color: var(--v-secondary-base);
    font-size: 13px;
    font-weight: 500;
  }
}
</style>