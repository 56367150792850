import ContactPoint from '@/models/ContactPoint'

import { cloneDeep } from 'lodash'

/**
 * Sérialise un patient de façon à pouvoir être insérer ou modifié via l'API
 * @param {Patient} patient
 * @returns {Patient}
 */
export const getApiSerializedPatient = (patient) => {
  const clonedPatient = cloneDeep(patient)
  clonedPatient.contactPoints = ContactPoint.removeEmptyContactPoints(clonedPatient.contactPoints)

  if (clonedPatient.insIdentity?.identityDevice) {
    clonedPatient.insIdentity.identityDevice = clonedPatient.insIdentity?.identityDevice['@id']
  }

  const referringPhysician = clonedPatient.referringPhysician?.['@id']
  clonedPatient.referringPhysician = referringPhysician ? referringPhysician : null

  if (clonedPatient.billDataset) {
    const grandRegimeIri = clonedPatient.billDataset.grandRegime?.['@id']
    clonedPatient.billDataset.grandRegime = grandRegimeIri ? grandRegimeIri : null
  }

  clonedPatient.keywords = patient.keywords.map((keyword) => {
    return keyword['@id']
  })

  return clonedPatient
}