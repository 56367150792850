<template>
  <v-img
    v-if="isImage"
    :src="src"
    :alt="alt"
    data-test="app-img-file"
    class="app-file-preview__image"
  >
    <template #placeholder>
      <app-file-preview-placeholder :icon-size="iconSize" type="image" class="app-file-preview__placeholder" />
    </template>
  </v-img>
  <app-file-preview-placeholder
    v-else
    data-test="app-other-file"
    class="app-file-preview__placeholder"
    :type="type"
    :icon-size="iconSize"
  />
</template>

<script>
import AppFilePreviewPlaceholder from '@/components/ui/filePreview/AppFilePreviewPlaceholder.vue'

/**
 * Le composant de prévisualisation de fichier de l'application
 */
export default {
  name: 'AppFilePreview',
  components: { AppFilePreviewPlaceholder },
  props: {
    /**
     * La source du preview du fichier
     */
    src: {
      type: String,
      required: true,
    },
    /**
     * Le texte alternatif du preview du fichier
     */
    alt: {
      type: String,
      default: null,
    },
    /**
     * Le type du preview du fichier
     */
    type: {
      type: String,
      default: null,
    },
    iconSize: {
      type: String,
      default: '16',
    },
  },
  computed: {
    isImage () {
      return this.type?.startsWith('image')
    },
  },
}
</script>

<style lang="scss" scoped>
.app-file-preview {
  position: relative;
  border-radius: 6px;

  &__image,
  &__placeholder {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}
</style>