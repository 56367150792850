import SelectFilter from './SelectFilter'

/**
 * Structure définissant les options de configuration d'un filtre de date
 * @typedef {import('@/utils/classes/httpRequestParameters/filters/SelectFilter').SelectFilterOptions} SelectFilterOptions
 */

import { format } from '@/utils/functions/dates'

/**
 * Classe représentant un filtre de date
 * Elle se différencie du filtre Select par l'automatisation de la gestion de la valeur
 * et de la gestion du label (concaténation du label + valeur)
 */
export default class DateFilter extends SelectFilter {
  /**
   * Permet de créer un nouveau filtre de date
   * @param {SelectFilter} options les options de filtre de date
   */
  constructor (options = {}) {
    super({
      ...options,
      debounce: false,
      valueGetter: options.valueFormat
        ? (componentValue) => format(componentValue, options.valueFormat)
        : (componentValue) => componentValue,
      labelGetter: componentValue => `${options.label} ${format(componentValue, 'dd/MM/yyyy')}`,
    })
  }
}