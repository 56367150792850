<template>
  <synapse-autocomplete-wrapper
    ref="field"
    :synapse-input-widget="SynapsePathologyAutocompleteWidget"
    :value="value"
    :item-text="getItemText"
    :item-value="getItemValue"
    :label="label"
    :rules="rules"
    :append-icon="appendIcon"
    :multiple="multiple"
    @input="onItemSelected"
  />
</template>

<script>
import SynapseAutocompleteWrapper from '@/components/lap/SynapseAutocompleteWrapper.vue'
import SynapsePathologyAutocompleteWidget from '@/components/lap/synapse-widgets/PathologyAutocomplete.vue'

import Cim10ApiEntity from '@/models/Cim10ApiEntity'

export default {
  name: 'Cim10EntityAutocomplete',
  components: { SynapseAutocompleteWrapper },
  props: {
    value: {
      type: [Cim10ApiEntity, Array],
      default: null,
    },
    appendIcon: {
      type: String,
      default: null,
    },
    rules: {
      type: [String, Object],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Pathologie',
    },
  },
  data () {
    return { SynapsePathologyAutocompleteWidget }
  },
  methods: {
    onItemSelected (entity) {
      if (this.multiple) {
        this.$emit('input', entity.map(this.normalizeCim10Entity))
        return
      }
      let emittedEntity = null
      if (entity) {
        emittedEntity = this.normalizeCim10Entity(entity)
      }
      this.$emit('input', emittedEntity)
    },
    normalizeCim10Entity (entity) {
      return new Cim10ApiEntity({
        icd10Code: entity.code,
        label: entity.label,
        theriaqueLink: entity.theriaque_link,
      })
    },
    getItemText (cim10Entity) {
      return cim10Entity.getLabel()
    },
    getItemValue (entity) {
      if (entity instanceof Cim10ApiEntity) {
        return entity.icd10Code
      }
      return entity.code
    },
    resetValidation () {
      this.$refs.field.resetValidation()
    },
  },
}
</script>

<style lang="scss" scoped>

::v-deep {
  .synapse-autocomplete-wrapper__field__input {
    .options {
      // Permet au menu de se positionner de manière fixe au dessus de n'importe quel élément de l'application
      position: fixed !important;
      top: auto !important;
      left: auto !important;
      margin-top: map-get($spacers, 3);
      max-height: 300px;
    }
  }
}
</style>