import ApiEntity from '@/models/ApiEntity'

export default class Observation extends ApiEntity {
  constructor (options = {}) {
    super(options)
    this.motive = options.motive || null
    this.clinicalExamination = options.clinicalExamination || null
    this.conductToTake = options.conductToTake || null
    this.createdAt = options.createdAt || null
    this.updatedAt = options.updatedAt || null
  }
}