<template>
  <v-list dense class="mx-n4 py-0">
    <v-list-item-group
      :value="getView"
      mandatory
      color="primary"
      @change="setView"
    >
      <v-list-item
        v-for="type in types"
        :key="type['@id']"
        :value="type.value"
        data-test="list-item"
      >
        <template #default="{ active }">
          <v-list-item-action>
            <v-icon :color="active ? 'primary' : 'text'">
              {{ type.icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="type.text" />
          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { CALENDAR_VIEWS } from '@/modules/agenda/constants'

export default {
  name: 'MobileViewsSelect',
  data () {
    return { types: Object.values(CALENDAR_VIEWS).filter(({ value }) => value !== CALENDAR_VIEWS.WEEK.value) }
  },
  computed: { ...mapGetters('agenda', ['getView']) },
  methods: {
    ...mapMutations('agenda', ['SET_VIEW', 'SET_MOBILE_SIDEBAR']),
    setView (view) {
      this.SET_VIEW(view)
      this.SET_MOBILE_SIDEBAR(false)
    },
  },
}
</script>