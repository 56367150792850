<template>
  <app-form :submit-method="submit" class="free-appointment-form">
    <v-row no-gutter>
      <v-col cols="12">
        <app-select
          ref="appointmentSchedule"
          v-model="localValue.schedule"
          v-test="'schedule-input'"
          prepend-icon="schedule"
          :items="getSchedules"
          label="Agenda"
          item-text="name"
          item-value="@id"
          :loading="isLoading"
          rules="required"
          name="schedule"
          hide-errors="auto"
        />
        <app-select
          v-model="localValue.motive"
          v-test="'motives-input'"
          :disabled="!localValue.schedule"
          :items="selectedScheduleMotives"
          label="Motif"
          item-text="name"
          item-value="@id"
          rules="required"
          name="motive"
        >
          <template #item="{item}">
            <motive-label :motive="item" small />
          </template>
          <template #selection="{item}">
            <motive-label :motive="item" small />
          </template>
        </app-select>
        <app-date-picker
          v-model="localValue.startDateTime"
          v-test="'start-date-input'"
          label="Date"
          name="date"
          rules="required"
          :min="new Date()"
          icon="clock"
          @input="updateDate"
        />
      </v-col>
    </v-row>
  </app-form>
</template>

<script>
import AppDatePicker from '@/components/ui/form/AppDatePicker.vue'
import AppForm from '@/components/ui/form/AppForm.vue'
import AppSelect from '@/components/ui/form/AppSelect.vue'
import MotiveLabel from '@/modules/agenda/components/motives/MotiveLabel.vue'

import { mapGetters, mapActions } from 'vuex'

import FreeAppointmentSlot from '@/modules/patient/models/medicalHistory/FreeAppointmentSlot'
import Patient from '@/modules/patient/models/Patient'

export default {
  components: {
    AppDatePicker,
    AppForm,
    AppSelect,
    MotiveLabel,
  },
  props: {
    value: {
      type: FreeAppointmentSlot,
      required: true,
    },
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      isLoading: false,
      localValue: null,
    }
  },
  computed: {
    ...mapGetters('agenda', ['getSchedules', 'getScheduleFromIri', 'getFutureAvailabilitiesMotivesBySchedule']),
    selectedScheduleMotives () {
      if (this.localValue.schedule === null) {
        return []
      }
      return this.getFutureAvailabilitiesMotivesBySchedule({ '@id': this.localValue.schedule })
    },
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.localValue = this.value
      },
    },
    'localValue.schedule' (scheduleIri) {
      const { defaultAppointmentMotive } = this.getScheduleFromIri(scheduleIri)
      const availablelScheduleDefaultMotive = this.selectedScheduleMotives.find(motive => motive['@id'] === defaultAppointmentMotive['@id'])
      this.localValue.motive = availablelScheduleDefaultMotive?.['@id'] || null
    },
  },
  async created () {
    this.isLoading = true
    await this.fetchSchedules()
    await this.fetchAppointmentMotives()
    this.isLoading = false
    await this.fetchAvailabilitiesSettings()
  },
  methods: {
    ...mapActions('agenda', ['fetchSchedules', 'fetchAppointmentMotives', 'fetchScheduleFreeAppointmentSlots', 'fetchAvailabilitiesSettings']),
    async submit () {
      return await this.fetchScheduleFreeAppointmentSlots({
        schedule: this.localValue.schedule,
        motive: this.localValue.motive,
        startDate: this.localValue.startDateTime,
      })
    },
    updateDate (selectedDate) {
      this.$emit('setSelectedDate', selectedDate)
    },
  },
}
</script>