import Event from './Event'
import AvailabilitySettings from './AvailabilitySettings'
import { arrayToSentence } from '@/utils/functions/joiner'

export default class Availability extends Event {

  #startAsMinute
  #endAsMinute

  constructor (options = {}) {
    super(options)

    this.setting = new AvailabilitySettings(options.setting)

    // Mise en cache privée des valeurs numériques
    const [startHour, startMinute] = options.startTime.split(':')
    this.#startAsMinute = parseInt(startHour) * 60 + parseInt(startMinute)

    const [endHour, endMinute] = options.endTime.split(':')
    this.#endAsMinute = parseInt(endHour) * 60 + parseInt(endMinute)
  }

  getStartAsMinutes () {
    return this.#startAsMinute
  }

  getEndAsMinutes () {
    return this.#endAsMinute
  }

  toString () {
    const dayNames = {
      SU: 'dimanche',
      MO: 'lundi',
      TU: 'mardi',
      WE: 'mercredi',
      TH: 'jeudi',
      FR: 'vendredi',
      SA: 'samedi',
    }

    const settingInterval = this.setting.intervals[0]
    const recurrencePattern = this.setting.recurrencePattern

    const days = arrayToSentence(recurrencePattern.BYDAY.map(day => dayNames[day]))
    const interval = settingInterval && `de ${settingInterval.start} à ${settingInterval.end}`
    const end = recurrencePattern.UNTIL ? `- jusqu'au ${new Date(recurrencePattern.UNTIL).toLocaleDateString()}` : ''
    const freq = recurrencePattern.INTERVAL > 1 ? `toutes les ${recurrencePattern.INTERVAL} semaines` : 'chaque semaine'

    return `Tous les ${[days, interval, freq, end].filter(item => item).join(' ')}`
  }
}