import ActivityPlacePractitionerLink from '@/models/ActivityPlacePractitionerLink'
import ApiEntity from '@/models/ApiEntity'

import Motive from '@/modules/agenda/models/Motive'

class Schedule extends ApiEntity {
  constructor (options = {}) {
    super(options)
    this.name = options.name || null
    this.appointments = options.appointments || {}
    this.activityPlacePractitionerLink = options.activityPlacePractitionerLink
      ? new ActivityPlacePractitionerLink(options.activityPlacePractitionerLink)
      : null
    this.defaultAppointmentMotive = options.defaultAppointmentMotive
      ? new Motive(options.defaultAppointmentMotive)
      : null
    this.appointmentMotives = options.appointmentMotives
      ? options.appointmentMotives.map(appointmentMotive => new Motive(appointmentMotive))
      : []
    this.source = options.source || null
    this.timezone = options.timezone
  }

  getPractitioner () {
    return this.activityPlacePractitionerLink?.practitioner || null
  }

  getActivityPlace () {
    return this.activityPlacePractitionerLink?.activityPlace || null
  }
}

export default Schedule