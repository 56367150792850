<template>
  <div class="female-patient-medical-infos pa-4 pt-0" data-test="female-patient-medical-dataset">
    <app-row>
      <app-col cols="6">
        <app-switch
          v-model="localValue.pregnant"
          v-test="'pregnant-switch'"
          label="Grossesse"
          :disabled="isLoading"
          dense
          @input="save"
        />
      </app-col>
      <app-col cols="6">
        <app-switch
          v-model="localValue.breastfeeding"
          label="Allaitement"
          :disabled="isLoading"
          dense
          @input="save"
        />
      </app-col>
    </app-row>
    <template v-if="localValue.pregnant">
      <app-row class="date-content">
        <span class="blue-grey--text mr-1">Date des dernières règles : </span>
        <span class="font-weight-semi-bold">{{ localValue.lastPeriodDate ? format(localValue.lastPeriodDate,'dd/MM/yyyy') : '-' }}</span>
        <v-spacer />
        <app-date-picker
          v-model="localValue.lastPeriodDate"
          data-test="last-period-date-button"
          display="button"
          :max="subDays(new Date(), 1)"
        />
      </app-row>
      <app-row class="date-content">
        <span class="blue-grey--text mr-1">Date de conception : </span>
        <span class="font-weight-semi-bold">{{ localValue.conceptionDate ? format(localValue.conceptionDate,'dd/MM/yyyy') : '-' }}</span>
        <app-icon
          v-if="isDefaultConceptionDate"
          tooltip="Calculée à partir de la date des dernières régles (+ 14 jours)"
          class="hint-icon"
          icon="info"
        />
        <v-spacer />
        <app-date-picker
          v-model="localValue.conceptionDate"
          data-test="conception-date-button"
          display="button"
          :max="subDays(new Date(), 1)"
        />
      </app-row>
      <app-row class="date-content">
        <span class="blue-grey--text mr-1">Date d'accouchement : </span>
        <span class="font-weight-semi-bold">{{ localValue.deliveryDate ? format(localValue.deliveryDate,'dd/MM/yyyy') : '-' }}</span>
        <app-icon
          v-if="isDefaultDeliveryDate"
          tooltip="Calculée à partir de la date de conception (+ 275 jours)"
          class="hint-icon"
          icon="info"
        />
        <v-spacer />
        <app-date-picker
          v-model="localValue.deliveryDate"
          data-test="delivery-date-button"
          display="button"
          @input="save"
        />
      </app-row>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import AppSwitch from '@/components/ui/form/switch/AppSwitch.vue'
import PatientFemaleMedicalDataset from '@/modules/patient/components/patientFile/femaleMedicalDataset/models/PatientFemaleMedicalDataset'
import { format, subDays } from '@/utils/functions/dates'
import AppDatePicker from '@/components/ui/form/AppDatePicker.vue'
import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'FemaleMedicalDataset',
  components: {
    AppDatePicker,
    AppSwitch,
  },
  props: {
    /**
     * Le FemaleMedicalDataset à gérer
     */
    femaleMedicalDataset: {
      type: PatientFemaleMedicalDataset,
      required: true,
    },
  },
  data () {
    return {
      localValue: null,
      isLoading: false,
    }
  },
  computed: {
    isDefaultConceptionDate () {
      return this.localValue.conceptionDate && this.localValue.conceptionDate === this.localValue.getDefaultConceptionDate()
    },
    isDefaultDeliveryDate () {
      return this.localValue.deliveryDate && this.localValue.deliveryDate === this.localValue.getDefaultDeliveryDate()
    },
  },
  watch: {
    femaleMedicalDataset: {
      immediate: true,
      deep: true,
      handler () {
        this.localValue = new PatientFemaleMedicalDataset(this.femaleMedicalDataset)
      },
    },
    'localValue.lastPeriodDate' () {
      this.localValue = new PatientFemaleMedicalDataset({
        ...this.localValue,
        conceptionDate: null, // reset de la valeur -> trigger conceptionDate watcher
      })
    },
    'localValue.conceptionDate' () {
      this.localValue = new PatientFemaleMedicalDataset({
        ...this.localValue,
        deliveryDate: null, // reset de la valeur
      })
      this.save()
    },
  },
  methods: {
    ...mapActions('patient', ['updateFemaleMedicalDataset']),
    subDays,
    format,
    async save () {
      this.isLoading = true
      if (! this.localValue.pregnant) {
        this.localValue.lastPeriodDate = null
        this.localValue.conceptionDate = null
        this.localValue.deliveryDate = null
      }
      try {
        await this.updateFemaleMedicalDataset({ femaleMedicalDataset: new PatientFemaleMedicalDataset(this.localValue) })
        NovaTools.notify.success('L\'état de la patiente a été modifié avec succès')
      } catch {
        this.localValue = new PatientFemaleMedicalDataset(this.femaleMedicalDataset)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
<style scoped lang="scss">
  .date-content {
    display: flex;
    padding-left: 10px;
    align-items: center;
    font-size: 13px;

    .hint-icon {
      cursor: help;
      margin-left: map-get($spacers, 2);
    }

    ::v-deep {
      .app-button-date-picker-button {
        visibility: hidden;
      }
    }

    &:hover {
      ::v-deep {
        .app-button-date-picker-button {
          visibility: visible;
        }
      }
    }
  }
</style>