import axios from 'axios'
import debounce from 'lodash/debounce'

/**
 * Classe permettant de gérer un jeton JWT et son rafraichissement
 */
export default class JwtTokenService {
  /**
   * Instance d'axios a utiliser
   */
  axiosInstance = null

  /**
   * Liste des routes sur lesquelles ne pas tenter de rafraichir le token
   */
  refreshTokenBlacklist = null

  /**
   * Instancie JwtTokenService
   * @param {Object} axiosInstance instance d'axios à utiliser pour les requêtes
   * @param {String} tokenRefreshEndpoint URL qui sera contacter pour rafraichir le token
   * @param {Object} options options utilisé pour récupérer des éléments ou notifier le client
   */
  constructor (axiosInstance, tokenRefreshEndpoint, {
    getToken,
    getRefreshToken,
    onTokenRefreshed,
    onTokenExpired,
    isTokenExpiredError,
    refreshToken,
    refreshTokenBlacklist = [],
  } = {}) {
    this.axiosInstance = axiosInstance
    this.tokenRefreshEndpoint = tokenRefreshEndpoint

    this.getToken = getToken
    this.getRefreshToken = getRefreshToken
    this.onTokenExpired = onTokenExpired
    this.isTokenExpiredError = isTokenExpiredError
    this.refreshToken = refreshToken

    this.isRefreshing = false

    this.onRefreshListeners = onTokenRefreshed ? [onTokenRefreshed] : []

    this.refreshTokenBlacklist = refreshTokenBlacklist

    this.refreshTokenAxiosInstance = axios.create()

    // Vérifications des éléments requis
    if (! this.axiosInstance) {
      throw new Error('Une instance d\'axios doit être fournie')
    }

    if (! this.tokenRefreshEndpoint) {
      throw new Error('L\'url vers la route de refresh token doit être fournie')
    }

    if (! this.getToken) {
      throw new Error('Le callback de récupération du token doit être fourni')
    }

    if (! this.getRefreshToken) {
      throw new Error('Le callback de récupération du refresh_token doit être fourni')
    }

    this.axiosInstance.interceptors.request.use(
      async options => {
        const token = await this.getTokenAsync()
        if (token) {
          options.headers['Authorization'] = `Bearer ${token}`
        }
        return options
      },
      error => error,
    )

    this.axiosInstance.interceptors.response.use(
      response => response,
      async error => {
        if (error?.message !== 'canceled') {
          const errorResponse = error.response
          if (await this.isTokenExpiredErrorAsync(errorResponse)) {
            await this.onTokenExpiredAsync()
          }

          if (error.response.status === 401) {
            return
          }
          return Promise.reject(error)
        }
      },
    )

    const debouncedRefreshToken = debounce(this.refreshTokenAsync, 1000, {
      leading: true,
      trailing: false,
      maxWait: 1000,
    })

    this.axiosInstance.interceptors.request.use(
      async config => {
        const { url } = config
        if (! this.refreshTokenBlacklist.find(blacklistedUrl => url.startsWith(blacklistedUrl))) {
          debouncedRefreshToken()
        }
        return config
      },
      error => error,
    )
  }

  /**
   * Appelle le callback de récupération de token de manière asynchrone
   */
  getTokenAsync = async () => {
    if (this.getToken) {
      return await this.getToken()
    }
  }

  /**
   * Appelle le callback de récupération de refresh token de manière asynchrone
   */
  getRefreshTokenAsync = async () => {
    if (this.getRefreshToken) {
      return await this.getRefreshToken()
    }
  }

  /**
   * Appelle le callback de token rafraichit de manière asynchrone
   * @param {Object} authPayload object contenant les données d'authentification
   */
  onTokenRefreshedAsync = async (authPayload) => {
    if (this.onRefreshListeners.length > 0) {
      await Promise.all(this.onRefreshListeners.map(callback => callback(authPayload)))
    }
  }

  /**
   * Appelle le callback de token expiré de manière asynchrone
   */
  onTokenExpiredAsync = async () => {
    if (this.onTokenExpired) {
      await this.onTokenExpired()
    }
  }

  /**
   * Appelle le callback de vérification d'erreur indiquant l'expiration du token de manière asynchrone
   * @param {Error} error
   * @returns {Boolean} si l'erreur est bien une erreur de token expiré
   */
  isTokenExpiredErrorAsync = async (error) => {
    if (this.isTokenExpiredError) {
      return await this.isTokenExpiredError(error)
    }

    return error.status === 498
  }

  /**
   * Définie une méthode à appeler lors du refresh du token
   * @param {Function} callback
   */
  onRefresh (callback) {
    this.onRefreshListeners.push(callback)
  }

  /**
   * Rafraichit le token
   * @returns {Promise} La promise à retourner par l'intercepteur
   */
  refreshTokenAsync = async () => {
    let tokenFetchPromise = null
    this.isRefreshing = true
    if (this.refreshToken) {
      tokenFetchPromise = this.refreshToken(this.refreshTokenAxiosInstance, this.tokenRefreshEndpoint)
    } else {
      tokenFetchPromise = this.refreshTokenAxiosInstance.post(
        this.tokenRefreshEndpoint,
        { refresh_token: await this.getRefreshTokenAsync() },
        { errorHandle: false },
      )
    }

    tokenFetchPromise
      .then(async response => {
        if (! response.data) {
          await this.onTokenExpiredAsync()
        }

        await this.onTokenRefreshedAsync(response.data)
        this.isRefreshing = false
      })
      .catch(async error => {
        return Promise.reject(error)
      })
  }
}