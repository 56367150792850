<template>
  <app-autocomplete
    v-model="selectedRelation"
    v-test="'antecedent-relation-field'"
    :name="'medicalAntecedents[' + index + '].familyRelationship'"
    :items="orderedFamilyRelations"
    :rules="rules"
    label="Lien familial"
    item-text="label"
    item-value="@id"
    :attach="false"
    @input="$emit('input', $event)"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import AppAutocomplete from '@/components/ui/form/AppAutocomplete.vue'

export default {
  name: 'PatientAntecedentFamilyRelation',
  components: { AppAutocomplete },
  props: {
    index: {
      type: Number,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
  },
  data () {
    return { selectedRelation: null }
  },
  computed: {
    ...mapGetters('app', ['getFamilyRelations']),
    orderedFamilyRelations () {
      const familyRelationsCopy = JSON.parse(JSON.stringify(this.getFamilyRelations))
      return familyRelationsCopy.sort((relationA, relationB) => relationA.label.localeCompare(relationB.label))
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.selectedRelation = value
      },
    },
  },
}
</script>