import { isNil } from 'lodash'
import { uniqBy as _uniqBy } from 'lodash'

/**
 * Permet de retirer des éléments non définies dans un tableau.
 * Converve le nombre 0 si il est présent
 * @param {Array} arr Le tableau source
 * @returns {Array} Le tableau sans ses éléments non définies
 */
export const compact = (arr) => {
  return arr.filter(item => (! (typeof item === 'number' && isNaN(item))) && (! isNil(item)) && (item !== ''))
}

/**
 * Indique si des éléments définis sont présents dans un tableau
 * @param {Array} arr Le tableau source
 * @returns {Array} Si le tableau est vide ou pas
 */
export const isArrayEmpty = (arr) => compact(arr).length === 0

/**
 * Permet de retirer les doublons dans un tableau.
 * @param {Array} arr Le tableau source
 * @param {String} key La clé sur laquelle tester l'unicité
 * @returns {Array} Le tableau sans les doublons
 */
export const uniqBy = (arr, key) => {
  return _uniqBy(arr, key)
}

/**
 * Permet de filtrer de manière récursive un tableau
 * @param {Array} array
 * @param {Function} callback
 * @returns {Array}
 */
export const filterDeep = (array, callback) => {
  return compact(array.map(item => {
    if (Array.isArray(item)) {
      const result = filterDeep(item, callback)
      return result.length ? result : null
    }
    return callback(item) ? item : null
  }))
}