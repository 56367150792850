import { tokenService } from '@/services/synapse'
import { debounce } from 'lodash'

const MIN_CHAR_QUERY = 2
const DEBOUNCE_DELAY = 300

/**
 * Mixin d'autocomplétion d'une entité synapse
 */
export default {
  props: {
    rules: {
      type: [String, Object],
      default: null,
    },
    value: {
      type: [String, Array],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      results: [],
      query: '',
      isFetchingItems: false,
    }
  },
  computed: {
    defaultAutocompleteProps () {
      return {
        value: this.value,
        searchInput: this.query,
        items: this.results,
        rules: this.rules,
        loading: this.isFetchingItems,
      }
    },
    defaultAutocompleteEvents () {
      return {
        change: this.onItemSelected,
        'update:search-input': query => this.query = query,
      }
    },
  },
  watch: {
    query () {
      if (this.query && this.query.length >= MIN_CHAR_QUERY) {
        this.getItems(this.query)
      }
    },
  },
  created () {
    tokenService.refreshTokenAsync()
  },
  methods: {
    getItems: debounce(async function (query) {
      this.isFetchingItems = true
      try {
        if (! this.fetchMethod) {
          throw new Error('Une méthode de récupération "fetchMethod" est attendue')
        }
        // Une méthode "fetchMethod" doit être spécifiée.
        // Celle ci servira à récupérer puis retourner la liste d'entité
        const results = await this.fetchMethod({ query })
        if (this.multiple && this.value?.length) {
          results.push(...this.value) // Pour ajouter les valeurs pré-selectionnées qui seront absentes de la liste de résultats
        }
        this.results = results
      } catch(error) {
        throw new Error('Une erreur est survenue lors de la récupération des résultats de Synapse', error)
      } finally {
        this.isFetchingItems = false
      }
    }, DEBOUNCE_DELAY),
    onItemSelected (item) {
      if (item) {
        this.$emit('input', item)
      }
    },
  },
}