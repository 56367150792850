<template>
  <app-form :submit-method="submit">
    <validation-provider vid="antecedentList" tag="section">
      <patient-antecedent-fieldset
        v-for="(antecedent, index) in antecedentList"
        :key="index"
        :patient-iri="patient['@id']"
        :index="index"
        :antecedent.sync="antecedentList[index]"
      >
        <template v-if="! isEditing" #prepend>
          <app-button
            v-test="'delete-antecedent-button'"
            :disabled="antecedentList.length === 1"
            :label="antecedentList.length === 1 ? null : 'Supprimer l\'antécédent'"
            icon="close-alt"
            small
            color="content"
            @click="removeAntecedentLine(index)"
          />
        </template>
      </patient-antecedent-fieldset>
    </validation-provider>
    <n-button
      v-if="! isEditing"
      v-test="'add-antecedent-button'"
      outlined
      bg-color="primary"
      icon="add"
      class="add-antecedent"
      title="Ajouter un antécédent"
      @click="addAntecedentLine"
    />
  </app-form>
</template>

<script>
import AppForm from '@/components/ui/form/AppForm.vue'
import PatientAntecedentFieldset from '@/modules/patient/components/patientFile/riskFactors/antecedents/PatientAntecedentFieldset.vue'

import { putToAPI } from '@/services/api'
import { ValidationProvider, extend, validate } from 'vee-validate'

import Patient from '@/modules/patient/models/Patient'
import PatientAntecedent from '@/modules/patient/models/PatientAntecedent'
import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'PatientAntecedentsForm',
  components: {
    AppForm,
    PatientAntecedentFieldset,
    ValidationProvider,
  },
  props: {
    value: {
      type: Array,
      default: () => ([new PatientAntecedent()]),
      validator: antecedents => antecedents.every(antecedent => antecedent instanceof PatientAntecedent),
    },
    patient: {
      type: Patient,
      required: true,
    },
    validationObserver: {
      type: Object,
      default: null,
    },
  },
  data () {
    return { antecedentList: this.value }
  },
  computed: {
    isEditing () {
      return !! this.antecedentList[0]['@id']
    },
    antecedentsToCreate () {
      return this.antecedentList.filter(antecedent => ! antecedent['@id'])
    },
    antecedentToEdit () {
      return this.antecedentList.find(antecedent => antecedent['@id'])
    },
    antecedentListLabels () {
      return this.antecedentList.map(antecedent => antecedent.label)
    },
  },
  watch: {
    antecedentListLabels: {
      immediate: true,
      handler () {
        this.validateAntecedentList()
      },
    },
    value (antecedentList) {
      this.antecedentList = antecedentList
    },
  },
  methods: {
    async validateAntecedentList () {
      const { errors } = await validate(this.antecedentList, 'is_antecedent_list_valid')
      this.validationObserver?.setErrors({ antecedentList: errors })
    },
    addAntecedentLine () {
      const newAntecedentType = this.antecedentList[this.antecedentList.length - 1].type
      this.antecedentList.push(new PatientAntecedent({ type: newAntecedentType }))
    },
    removeAntecedentLine (antecedentIndex) {
      this.$delete(this.antecedentList, antecedentIndex)
    },
    submit () {
      if (this.isEditing) {
        return this.updateAntecedent(this.antecedentToEdit)
      }
      return this.createAntecedents(this.antecedentsToCreate.filter(antecedent => !! antecedent.label))
    },
    async createAntecedents (antecedents) {
      try {
        await putToAPI(this.patient.getIri(), {
          data: {
            newMedicalAntecedentList: antecedents.map(antecedent => ({
              type: antecedent.type,
              label: antecedent.label,
              icd10Code: antecedent.icd10Code,
              theriaqueLink: antecedent.theriaqueLink,
              familyRelationship: antecedent.familyRelationship,
              note: antecedent.note,
            })),
          },
        })
        NovaTools.notify.success('Les antécédents ont été ajoutés avec succès')
        return antecedents
      } catch {
        return Promise.reject()
      }
    },
    async updateAntecedent (antecedent) {
      try {
        await putToAPI(antecedent.getIri(), { data: { ...antecedent } })
        NovaTools.notify.success('L\'antécédent a été modifié avec succès')
        return [antecedent]
      } catch {
        return Promise.reject()
      }
    },
  },
}

extend('is_antecedent_list_valid', { validate: antecedents => ! antecedents.every(antecedent => antecedent.label === null) })
</script>

<style scoped lang="scss">
.add-antecedent {
  margin: map-get($spacers, 4) auto 0;
  display: block;
}
</style>