import ApiEntity from '@/models/ApiEntity'
import Patient from '@/modules/patient/models/Patient'

export default class PatientInsDemotion extends ApiEntity {
  constructor (options = {}) {
    super(options)
    this.patient = options.patient
      ? new Patient(options.patient)
      : null
  }

  get patientCivilState () {
    return this.patient.getCivilState(false, true)
  }
}