<template>
  <n-dialog
    :is-open="true"
    :title="title"
    width="80%"
    @close="$emit('close')"
  >
    <component :is="getViewer()" class="document-viewer" :content-url="document.contentUrl" />
  </n-dialog>
</template>

<script>
import PdfViewer from '@/components/ui/viewer/PdfViewer.vue'
import NDialog from '@/nova-ui/NDialog/NDialog.vue'
import ImageViewer from '@/components/ui/viewer/ImageViewer.vue'

export default {
  name: 'DocumentViewerModal',
  components: {
    ImageViewer,
    NDialog,
    PdfViewer,
  },
  props: {
    document: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  methods: {
    getViewer () {
      const mimeType = {
        'application/pdf': PdfViewer,
        'image/png': ImageViewer,
        'image/jpeg': ImageViewer,
        'image/bmp': ImageViewer,
      }
      return mimeType[this.document.mimeType]
    },
  },
}
</script>

<style scoped>

  .document-viewer {
    height: 75vh;
    width: 100%;
  }

</style>