<template>
  <div>
    <app-alert
      v-show="localValue.hasIns()"
      type="success"
      :message="insAlertMessage"
      class="my-4"
    />
    <app-row class="align-center mt-2 mb-n8">
      <app-col md="6">
        <span class="civil-state-fieldset__subtitle">Sécurisation de l’identité</span>
      </app-col>
      <app-col v-if="localValue.canGetIns()" md="6" class="d-flex justify-end">
        <patient-identity-verification-button
          v-if="localValue.hasIns()"
          :patient="patient"
          @check-ins="getInsCheckStatus"
        />
        <patient-identity-qualification-button
          v-else
          :patient="patient"
          :loading="isLoggingInsCall"
          :disabled="! isInsButtonEnabled"
          outlined
          @success="onInsFetchSuccess"
          @error="onInsFetchError"
          @fetch-ins="$emit('fetch-ins', $event)"
        />
      </app-col>
    </app-row>
    <app-row class="mb-2">
      <identity-device-field
        v-model="localValue.identityDevice"
        :auto-select-status="autoSelectStatus"
        :can-change-device="localValue.canChangeDevice()"
        :ins-identity-devices="insIdentityDevices"
      />
      <identity-status-field
        v-model="localValue.status"
        :has-ins="localValue.hasIns()"
        :auto-select-status="autoSelectStatus"
        :is-ins-status-field-disabled="isInsStatusFieldDisabled"
        :current-identity-device="localValue.identityDevice"
      />
      <identity-attribute-field v-model="localValue.attribute" />
    </app-row>
    <app-row class="mb-2">
      <identity-nir-field v-model="localValue.ins" />
      <identity-oid-field v-model="localValue.oid" />
    </app-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin'
import NovaTools from '@/nova-tools/NovaTools'
import AppAlert from '@/components/ui/alert/AppAlert.vue'
import PatientIdentityQualificationButton from '@/modules/patient/components/PatientIdentityQualificationButton.vue'
import PatientIdentityVerificationButton from '@/modules/patient/components/PatientIdentityVerificationButton.vue'
import IdentityDeviceField from '@/modules/patient/components/patientForm/fieldsets/identityFieldset/identitySecurityFieldsets/IdentityDeviceField.vue'
import IdentityStatusField from '@/modules/patient/components/patientForm/fieldsets/identityFieldset/identitySecurityFieldsets/IdentityStatusField.vue'
import IdentityAttributeField from '@/modules/patient/components/patientForm/fieldsets/identityFieldset/identitySecurityFieldsets/IdentityAttributeField.vue'
import IdentityNirField from '@/modules/patient/components/patientForm/fieldsets/identityFieldset/identitySecurityFieldsets/IdentityNirField.vue'
import IdentityOidField from '@/modules/patient/components/patientForm/fieldsets/identityFieldset/identitySecurityFieldsets/IdentityOidField.vue'
import Patient from '@/modules/patient/models/Patient'
import PatientInsIdentity from '@/modules/patient/models/PatientInsIdentity'
import { getInsStatusFromPatient } from '@/modules/patient/utils/ins'
import { INS_IDENTITY_STATUS } from '@/modules/patient/constants'
import { INS_IDENTITY_ATTRIBUTE } from '@/modules/patient/constants/insIdentityAttribute'
import InsIdentityDevice from '@/modules/patient/models/InsIdentityDevice'

export default {
  name: 'IdentitySecurityFieldset',
  components: {
    AppAlert,
    IdentityOidField,
    IdentityNirField,
    IdentityAttributeField,
    IdentityStatusField,
    IdentityDeviceField,
    PatientIdentityQualificationButton,
    PatientIdentityVerificationButton,
  },
  mixins: [localCopyMixin()],
  props: {
    value: {
      type: PatientInsIdentity,
      default: null,
    },
    patient: {
      type: Patient,
      default: null,
    },
    isLoggingInsCall: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return { insIdentityDevices: [] }
  },
  computed: {
    ...mapGetters('patient', ['getSettings']),
    autoSelectStatus () {
      return this.getSettings?.automaticStatusSetting
    },
    isInsButtonEnabled () {
      return ! ['birthDate', 'birthName', 'birthPlaceCode', 'firstName', 'firstNames', 'gender'].filter(fieldName => ! this.localValue[fieldName]?.length).length
    },
    isInsStatusFieldDisabled () {
      return ! this.localValue.identityDevice || ! this.localValue.getCurrentSelectedDevice(this.insIdentityDevices)?.strongTrustLevel
    },
    canEditInsStrictFeatures () {
      return NovaTools.security.permissions.hasPermission('update_ins_strict_features')
    },
    insAlertMessage () {
      return this.canEditInsStrictFeatures
        ? 'Les champs d\'identification sont synchronisés avec l\'Identité Nationale de Santé. Toute modification nécessitera une nouvelle récupération de l\'Identité Nationale de Santé.'
        : 'Les champs d\'identification sont en lecture seule car ils sont synchronisés avec l\'Identité Nationale de Santé'
    },
  },
  watch: {
    'localValue.identityDevice' () {
      if (this.insIdentityDevices.length && this.autoSelectStatus) {
        this.updateInsStatus()
      }
    },
    'localValue.status' (status) {
      if (! this.autoSelectStatus) {
        return
      }
      const isUnsafeCurrentStatus = [INS_IDENTITY_STATUS.TEMPORARY.value, INS_IDENTITY_STATUS.FETCHED.value].includes(status)
      // Retrait du dispositif en cas d'un changement vers un statut "Identité Provisoire" ou "Identité Récupérée"
      if (status && isUnsafeCurrentStatus && this.localValue.getCurrentSelectedDevice(this.insIdentityDevices)?.strongTrustLevel) {
        if (this.autoSelectStatus) {
          this.localValue.identityDevice = null
        }
      }
    },
    // retrait de l'INS et du dispositif en cas de passage de l'attribut à fictive ou douteuse
    'localValue.attribute' (attribute) {
      if (attribute === INS_IDENTITY_ATTRIBUTE.SUSPICIOUS.value || attribute === INS_IDENTITY_ATTRIBUTE.FICTITIOUS.value) {
        this.resetIns()
      }
    },
  },
  async created () {
    await this.fetchSettings()
    this.insIdentityDevices = await InsIdentityDevice.fetchAll()
    if (this.autoSelectStatus) {
      this.updateInsStatus()
    }
  },
  methods: {
    ...mapActions('patient', ['fetchSettings']),
    onInsFetchSuccess (newPatient) {
      this.$emit('ins-fetch-success', new Patient({
        ...newPatient,
        insIdentity: {
          ...newPatient.insIdentity,
          status: getInsStatusFromPatient(newPatient, { disableAutomaticStatusSelect: ! this.autoSelectStatus }),
        },
      }))
      if (this.autoSelectStatus) {
        this.updateInsStatus()
      }
    },
    onInsFetchError () {
      this.resetIns()
      this.localValue.identityDevice = null
    },
    resetIns () {
      this.localValue.reset()
    },
    /**
     * Met à jour le statut sélectionné en fonction de
     * l'état de récupération de l'INS et de la sélection d'un dispositif
     */
    updateInsStatus () {
      const identityDevice = this.localValue.getCurrentSelectedDevice(this.insIdentityDevices)
      const oldStatus = this.localValue.status
      const nextStatus = getInsStatusFromPatient(new Patient({
        ...this.patient,
        insIdentity: {
          ...this.localValue,
          identityDevice,
        },
      }))
      this.localValue.status = nextStatus
      if ((oldStatus !== nextStatus) && identityDevice?.strongTrustLevel === false) {
        const messageDescription = oldStatus === INS_IDENTITY_STATUS.TEMPORARY.value ? 'Le statut de l\'identité ne peut être validé' : 'Dégradation du statut de l\'identité'
        NovaTools.notify.warning(messageDescription, { title: 'Dispositif à faible niveau de confiance' })
      }
    },
    getInsCheckStatus (isInsValid) {
      if (isInsValid) {
        NovaTools.notify.success('L\'INS a été vérifié avec succès')
      } else {
        NovaTools.notify.warning('L\'INS n\'a pas pu être vérifié')
        this.resetIns()
      }
    },
  },
}
</script>
<style scoped lang="scss">
.civil-state-fieldset {
  &__subtitle {
    font-weight: 600;
    font-size: 13px;
    color: var(--v-secondary-lighten4);
  }
}
</style>