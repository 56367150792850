<template>
  <v-timeline
    class="timeline mb-n3"
    :class="{ 'timeline--timed': timeKey }"
    dense
    align-top
  >
    <template v-if="loading">
      <v-timeline-item
        v-for="i in DEFAULT_SKELETONS_COUNT"
        :key="`skeleton-${i}`"
        v-test="'timeline-skeleton-loaders'"
        small
        color="transparent"
      >
        <slot name="item-skeleton">
          <app-skeleton-loader type="text" width="100" />
          <app-skeleton-loader type="sentences" width="75%" />
          <template v-if="timeKey" #opposite>
            <app-skeleton-loader type="text" />
          </template>
        </slot>
      </v-timeline-item>
    </template>

    <div v-for="item in items" :key="item[itemKey]" class="timeline-item__wrapper">
      <!-- Permet une transition douce -->
      <v-timeline-item
        small
        color="transparent"
      >
        <!--
            @slot Le rendu de chaque item
            @binding {object} item l'item affiché
          -->
        <slot :item="item" />

        <template v-if="timeKey" #opposite>
          <span v-test="'timeline-time'">{{ getItemTime(item) }}</span>
        </template>
      </v-timeline-item>
    </div>
  </v-timeline>
</template>

<script>
import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue'

import NovaTools from '@/nova-tools/NovaTools'

const DEFAULT_SKELETONS_COUNT = 3

/**
 * Permet d'afficher une timeline de façon verticale
 * @see https://vuetifyjs.com/en/components/timelines/
 */
export default {
  name: 'AppTimeline',
  components: { AppSkeletonLoader },
  props: {
    /**
     * La liste des items à afficher
     */
    items: {
      type: Array,
      required: true,
    },
    /**
     * La clef d'index à utiliser pour la boucle v-for sur items
     */
    itemKey: {
      type: String,
      default: '@id',
    },
    /**
     * La clé à cibler permettant d'afficher l'heure de l'élément
     * @default null
     */
    timeKey: {
      type: String,
      default: null,
    },
    /**
     * Affiche un loader lorsque les données sont en cours de chargement
     * @default false
     */
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return { DEFAULT_SKELETONS_COUNT }
  },
  methods: {
    getItemTime (item) {
      return NovaTools.dates.format(item[this.timeKey], 'HH:mm')
    },
  },
}
</script>

<style lang="scss" scoped::v-deep>
$opposite-width: 44px;
$opposite-font-size: 16px;
$opposite-color: var(--v-content-base);

$divider-width: 3px;
$divider-container-width: 36px;
$divider-color: var(--v-secondary-lighten4);

$dot-size: 10px;
$dot-border-width: $divider-width;
$dot-color: $divider-color;

$item-space: map-get($spacers, 1);

.timeline {
  padding: 0 !important;
  z-index: 0;

  &:before {
    height: calc(100% - #{$item-space} - 40px) !important;
    margin-top: 24px;
    width: $divider-width !important;
    background: $divider-color !important;
    left: calc(#{$divider-width * 2} - #{$divider-width / 2}) !important;
    border-radius: $divider-width;
    opacity: .5;
  }

  &.timeline--timed {
    &:before {
      left: calc((#{$opposite-width} + #{$divider-container-width / 2}) - #{$divider-width / 2}) !important;
    }

    .v-timeline-item__body {
      max-width: calc(100% - #{$divider-container-width}) !important;
      margin-left: 0;
    }

    .v-timeline-item__divider {
      min-width: $divider-container-width !important;
    }
  }

  .v-timeline-item {
    flex-direction: row-reverse !important;
    padding: map-get($spacers, 3) 0 !important;

    @include media-md {
      padding: map-get($spacers, 5) 0 !important;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &__body {
      max-width: 100% !important;
      margin-left: map-get($spacers, 3);
    }

    &__divider {
      flex: 0 0 $dot-size + $dot-border-width;
      min-width: 0 !important;
      margin-top: $item-space;
    }

    &__opposite {
      width: $opposite-width;
      max-width: $opposite-width;
      display: block !important;
      font-size: $opposite-font-size;
      font-weight: 600;
      color: $opposite-color;
      align-self: flex-start;
      margin-top: $opposite-font-size - ($dot-size + $dot-border-width);
      line-height: $opposite-font-size;
      text-align: center;
    }
  }

  .v-timeline-item__dot {
    width: $dot-size + $dot-border-width !important;
    height: $dot-size + $dot-border-width !important;
    box-shadow: none;
  }

  .v-timeline-item__inner-dot {
    margin: 0 !important;
    border: solid $dot-color !important;
    border-width: $dot-border-width !important;
    width: 100% !important;
    height: 100% !important;
  }

  .timeline-item__wrapper {
    &:first-child {
      .v-timeline-item {
        margin-top: - #{3 * $item-space};
      }
    }
  }
}
</style>