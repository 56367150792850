<template>
  <app-modal
    :is-open="isOpen"
    :title="title"
    persistent
    width="980"
    class="consult-prescription-modal"
  >
    <div class="consult-prescription-modal__content">
      <div>Dernière modification : <span v-if="ePrescription && ePrescription.modifiedAt">{{ format(ePrescription.modifiedAt) }}</span></div>
      <app-data-table
        :headers="tableHeaders"
        :items="prescriptionLines"
        :loading="isFetching"
        class="consult-prescription-modal__content__list"
        style="height: 420px; overflow: auto"
        :group-by="tableGroupBy"
        expandable-rows
      >
        <template #expanded-row="{ item }">
          <div class="pa-4 pl-16">
            <div>{{ item.motive }}<span v-if="item.sub_motive && item.sub_motive.length !== 0"> - {{ item.sub_motive }}</span></div>
            <template v-if="item.status !== 0">
              <div v-for="(row, index) in item.execution" :key="index" class="d-flex pa-1">
                <div>
                  <div>{{ row.label }}</div>
                  <div>{{ row.description }}</div>
                </div>
              </div>
            </template>
          </div>
        </template>
        <template #[`item.original`]="{ item }">
          <div class="consult-prescription-modal__content__list__item-original">
            <div v-for="(label, index) in getLabel(item.label)" :key="'label'+index" style="color: #37467A">
              {{ label }}
            </div>
            <div v-for="(description, index) in getDescription(item.description)" :key="'description'+index" style="color: #8C94B2">
              {{ description }}
            </div>
          </div>
        </template>
        <template #[`item.status`]="{ item }">
          <div v-if="item.status === 0" class="consult-prescription-modal__content__list__item-status-waiting-execution">
            <app-icon color="#C3CBE6" icon="time-left" />
          </div>
          <div v-if="item.status === 1" class="consult-prescription-modal__content__list__item-status-identical-execution">
            <app-icon color="success" icon="check" />
          </div>
          <div v-if="item.status === 2" class="consult-prescription-modal__content__list__item-status-different-execution">
            <app-icon color="error" icon="error" />
          </div>
        </template>
      </app-data-table>
    </div>
    <template #actions>
      <app-button
        color="primary"
        @click="close"
      >
        Fermer
      </app-button>
    </template>
  </app-modal>
</template>

<script>
import ModalMixin from '@/components/ui/modal/mixins/ModalMixin'
import AppModal from '@/components/ui/modal/AppModal'
import AppDataTable from '@/components/ui/tables/AppDataTable'
import { Buffer } from 'buffer'
import { postToAPI } from '@/services/api'
import { getFromAPI } from '@/services/api'
import iCanopeeCardReaderInstance from '@/services/vendors/icanopee/classes/ICanopeeCardReader'
import { mapGetters } from 'vuex'
import { format } from '@/utils/functions/dates'

export default {
  name: 'ConsultPrescriptionModal',
  components: {
    AppDataTable,
    AppModal,
  },
  mixins: [ModalMixin],
  props: {
    amId: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      isFetching: false,
      ePrescription: null,
      tableHeaders: [
        {
          text: 'Prescription originale',
          value: 'original',
          width: '90%',
        },
        {
          text: 'Statut',
          value: 'status',
          width: '10%',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('cardReaders', ['getPincode']),
    title () {
      return 'e-Prescription ' + this.amId
    },
    prescriptionLines () {
      return this.ePrescription?.lines || []
    },
  },
  watch: {
    amId: {
      immediate: true,
      handler (amId) {
        this.fetchPrescription(amId)
      },
    },
  },
  methods: {
    format,
    async fetchPrescription (amId) {
      this.isFetching = true
      const consultationTemplate = await this.getTemplateCps('consulterEPrescription')

      const { data } = await postToAPI('/api/eprescription/preview', {
        consultationTemplate: consultationTemplate,
        amId,
      },
      )
      this.ePrescription = data
      this.isFetching = false
    },
    async getTemplateCps (operation) {
      const { data: softwareInformations } = await getFromAPI('/api/software_information')
      const template = await iCanopeeCardReaderInstance.getEPrescriptionTemplate(
        softwareInformations,
        operation,
      )
      return template ? Buffer.from(template).toString('base64') : null
    },
    close () {
      this.$emit('input', null)
      this.ePrescription = null
      this.handleCancel()
    },
    tableGroupBy (item) {
      return item.title
    },
    getLabel (value) {
      return value.split('\\r')
    },
    getDescription (value) {
      return value.split('\\r')
    },
  },
}
</script>
<style scoped lang="scss">
  .consult-prescription-modal {
    &__content {
      height: 450px;

      &__list {
        margin-top: map-get($spacers, 4);

        &__item-original {
          font-size: 13px;
          margin-top: map-get($spacers, 1);
          margin-bottom: map-get($spacers, 1);
        }

        &__item-status-waiting-execution {
          display: flex;
          gap: map-get($spacers, 2);
          color: #C3CBE6;
        }

        &__item-status-identical-execution {
          display: flex;
          gap: map-get($spacers, 2);
          color: #00C28D;
        }

        &__item-status-different-execution {
          display: flex;
          gap: map-get($spacers, 2);
          color: #E4AA38;
        }
      }
    }
  }
</style>