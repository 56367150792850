<template>
  <v-dialog
    v-model="localIsOpen"
    :width="width"
    :persistent="persistent"
    scrollable
    overlay-color="content"
    :content-class="contentClass"
    :eager="eager"
  >
    <n-card
      ref="card"
      :title="title"
      :actions="actions"
      :separate-header="divided || scrollable"
      :separate-footer="divided || scrollable"
      @confirm="localIsOpen = false"
    >
      <template v-if="! actions" #append-title>
        <v-btn
          v-test="'close-action'"
          icon
          class="my-n2"
          @click="close"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </template>
      <slot>
        <p v-test="'dialog-message'">
          {{ message }}
        </p>
      </slot>
      <template v-if="$scopedSlots.actions" #actions>
        <!-- @slot Remplace les actions par défaut contenu dans le footer de la modale -->
        <slot name="actions" />
      </template>
    </n-card>
  </v-dialog>
</template>
<script>
import NCard from '@/nova-ui/NCard/NCard.vue'
import ButtonAction from '@novalys/src/models/ButtonAction'

import localCopyMixin from '@novalys/src/mixins/local-copy-mixin'

/**
 * Permet d'afficher une boite de dialogue
 */
export default {
  name: 'NDialog',
  components: { NCard },
  mixins: [
    localCopyMixin({
      propertyName: 'isOpen',
      copyPropertyName: 'localIsOpen',
    }),
  ],
  props: {
    /**
     * Contrôle si le composant est visible ou masqué.
     */
    isOpen: {
      type: Boolean,
      default: false,
    },
    /**
     * Le titre de la boite de dialogue
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * Le message à afficher dans la boite de dialogue
     */
    message: {
      type: String,
      default: null,
    },
    /**
     * Classe CSS permettant de cibler l'élément racine (ne fonctionne pas en CSS scoped)
     */
    contentClass: {
      type: String,
      default: null,
    },
    /**
     * Défini la largeur de la boite de dialogue
     */
    width: {
      type: String,
      default: '600px',
    },
    /**
     * Cliquer en dehors de l'élément ou appuyer sur échap ne fermera pas la modale
     */
    persistent: {
      type: Boolean,
      default: false,
    },
    /**
     * tableau des actions à afficher dans la boite de dialogue
     */
    actions: {
      type: Array,
      default: null,
      validator: actions => actions.every(action => action instanceof ButtonAction),
    },
    /**
     * Permet de forcer la visibilité des séparateurs
     */
    divided: {
      type: Boolean,
      default: false,
    },
    /**
     * Permet de forcer le chargement du composant à l'initialisation
     */
    eager: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      scrollable: false,
      scrollableZoneResizeObserver: new ResizeObserver(([scrollableZoneResizeEvent]) => {
        const scrollableZone = scrollableZoneResizeEvent.target
        this.scrollable = scrollableZone.scrollHeight > scrollableZone.clientHeight
      }),
    }
  },
  watch: {
    isOpen (isOpen) {
      this.$nextTick(function () {
        const panel = this.$refs.card
        const scrollableZone = panel.$el.querySelector('.n-card__content')

        isOpen
          ? this.scrollableZoneResizeObserver.observe(scrollableZone)
          : this.scrollableZoneResizeObserver.unobserve(scrollableZone)
      })
    },
    localIsOpen (isOpen) {
      if (! isOpen) {
        this.$emit('close')
      }
      this.$emit('update:is-open', isOpen)
    },
  },
  methods: {
    close () {
      this.$emit('update:is-open', false)
      this.$emit('close', false)
    },
  },
}
</script>