import { MEASURES } from '@/modules/patient/constants/measures.js'
import { HEALTH_BOOK_AGE_RANGES } from '@/modules/patient/constants/healthBookAgeRanges.js'

export const HEALTH_BOOK_CATEGORIES = {
  SIZE: {
    label: MEASURES.SIZE.label,
    unit: MEASURES.SIZE.unit,
    name: MEASURES.SIZE.name,
    range: {
      [HEALTH_BOOK_AGE_RANGES.YOUNG_CHILD.categoryName]: {
        value: [40, 110],
        stepSize: 5,
        secondaryStepSize: 1,
      },
      [HEALTH_BOOK_AGE_RANGES.MINOR_CHILD.categoryName]: {
        value: [60, 210],
        stepSize: 10,
        secondaryStepSize: 2,
      },
    },
  },
  WEIGHT: {
    label: MEASURES.WEIGHT.label,
    unit: MEASURES.WEIGHT.unit,
    name: MEASURES.WEIGHT.name,
    range: {
      [HEALTH_BOOK_AGE_RANGES.YOUNG_CHILD.categoryName]: {
        value: [0, 22],
        stepSize: 1,
        secondaryStepSize: 0.5,
      },
      [HEALTH_BOOK_AGE_RANGES.MINOR_CHILD.categoryName]: {
        value: [0, 120],
        stepSize: 10,
        secondaryStepSize: 2,
      },
    },
  },
  HEADCIRC: {
    label: MEASURES.HEADCIRC.label,
    unit: MEASURES.HEADCIRC.unit,
    name: MEASURES.HEADCIRC.name,
    range: {
      [HEALTH_BOOK_AGE_RANGES.YOUNG_CHILD_EXTENDED.categoryName]: {
        value: [30, 60],
        stepSize: 5,
        secondaryStepSize: 1,
      },
    },
  },
}