export const INS_IDENTITY_ATTRIBUTE = Object.freeze({
  HOMONYM: {
    value: 'homonym',
    label: 'Identité homonyme',
  },
  SUSPICIOUS: {
    value: 'suspicious',
    label: 'Identité douteuse',
  },
  FICTITIOUS: {
    value: 'fictitious',
    label: 'Identité fictive',
  },
})