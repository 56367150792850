export const CONTACT_POINT_LABELS = Object.freeze({
  MOBILE: {
    value: 'mobile',
    label: 'Mobile',
  },
  OTHER: {
    value: 'other',
    label: 'Autre téléphone',
  },
  EMAIL: {
    value: 'email',
    label: 'Email',
  },
  MSS: {
    value: 'mss',
    label: 'Email mss',
  },
  MSS_REPLY_TO: {
    value: 'mss-reply-to',
    label: 'Adresse de retour',
  },
  SECRETARIAT: {
    value: 'secretariat',
    label: 'Secrétariat',
  },
  PERSONAL: {
    value: 'personal',
    label: 'Personnel',
  },
  BUSINESS: {
    value: 'business',
    label: 'Professionnel',
  },
})