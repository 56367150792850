import axios from 'axios'

const API_URL = import.meta.env.VUE_APP_ROOT_API
axios.defaults.baseURL = API_URL

/**
 * Permet de récupérer des données depuis l'API
 * @param {String} endpoint - le nom du endpoint. Commence par /api/
 * @param {Object} params - les paramètres à envoyer à l'API
 * @param {Object} config - Une config axios @see {@link https://axios-http.com/docs/req_config}
 */
export const getFromAPI = function (endpoint, params = {}, config = {}) {
  return axios.get(endpoint, {
    params,
    ...config,
  })
}

/**
 * Permet de poster des données à l'API
 * @param {String} endpoint - le nom du endpoint. Commence par /api/
 * @param {Object} [params] - les paramètres à envoyer à l'API
 * @param {Object} [config] - une config axios
 */
export const postToAPI = function (endpoint, params, config) {
  return axios.post(endpoint, { ...params }, { ...config })
}

/**
 * Permet de récupérer un fichier depuis l'API
 * @param {String} endpoint - le nom du endpoint. Commence par /api/
 * @param {Object} [params] - les paramètres à envoyer à l'API
 */
export const getFileFromAPI = function (endpoint, params) {
  return axios.get(endpoint, {
    ...params,
    responseType: 'blob',
  })
}

/**
 * Permet de poster un fichier vers l'API
 * @param {String} endpoint - le nom du endpoint. Commence par /api/
 * @param {FormData} [formData] - le fichier à envoyer
 */
export const postFileToAPI = function (endpoint, formData) {
  return axios.post(endpoint, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
}

/**
 * Permet de modifier des données avec l'API
 * @param {String} endpoint - le nom du endpoint. Commence par /api/
 * @param {Object} options Object permettant de configurer l'appel api
 * @param {Object} options.params Paramètres de l'appel
 * @param {Object} options.data Jeu de données envoyé à l'api
 */
export const patchToAPI = function (endpoint, { params, data }) {
  return axios.patch(endpoint, data, {
    headers: { 'Content-Type': 'application/merge-patch+json' },
    ...params,
  })
}

/**
 * Permet de modifier des données avec l'API
 * @param {String} endpoint - le nom du endpoint. Commence par /api/
 * @param {Object} options Object permettant de configurer l'appel api
 * @param {Object} options.params Paramètres de l'appel
 * @param {Object} options.data Jeu de données envoyé à l'api
 */
export const putToAPI = function (endpoint, { params, data }) {
  return axios.put(endpoint, data, {
    headers: { 'Content-Type': 'application/ld+json' },
    ...params,
  })
}

/**
 * Permet de supprimer une ressource depuis l'API
 * @param {String} endpoint - le nom du endpoint. Commence par /api/
 * @param {Object} [params] - les paramètres à envoyer à l'API
 */
export const deleteFromAPI = function (endpoint, params) {
  return axios.delete(endpoint, { ...params })
}