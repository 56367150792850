<template>
  <div class="d-flex align-center">
    <v-progress-circular
      indeterminate
      data-test="progress-circular"
      :color="color"
      :size="size"
      :width="width"
    />
    <p v-if="label" data-test="circular-loader-label" class="ml-2">
      {{ label }}
    </p>
  </div>
</template>

<script>
/**
 * La barre de chargement circulaire de l\'application
 * @see {@link https://vuetifyjs.com/en/components/progress-circular/}
 */
export default {
  name: 'AppCircularLoader',
  props: {
    /**
     * Le label de la barre de progression
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * La taille de la barre de progression
     */
    size: {
      type: [String, Number],
      default: '20',
    },
    /**
     * L'épaisseur de la barre de progression
     */
    width: {
      type: [String, Number],
      default: '3',
    },
    /**
     * La couleur de la barre de progression
     */
    color: {
      type: String,
      default: 'primary',
    },
  },
}
</script>