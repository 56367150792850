<template>
  <div>
    <app-switch
      v-test="'switch-is-arrived'"
      :value="switchValue"
      dense
      :label="switchLabel"
      :disabled="isUpdatingAppointment"
      @change="setSwitchValue"
    />
  </div>
</template>

<script>
import AppSwitch from '@/components/ui/form/switch/AppSwitch.vue'

import Appointment from '@/modules/agenda/models/events/Appointment'

import { APPOINTMENT_STATES } from '@/modules/agenda/constants'
import { format } from '@/utils/functions/dates'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'AppointmentWaitingRoomSwitch',
  components: { AppSwitch },
  props: {
    appointment: {
      type: Appointment,
      required: true,
    },
  },
  data () {
    return {
      localAppointment: this.appointment,
      switchValue: this.appointment.arrivalDateTime !== null,
      isUpdatingAppointment: false,
    }
  },
  computed: {
    switchLabel () {
      const { arrivalDateTime } = this.localAppointment
      const time = format(arrivalDateTime ? arrivalDateTime : new Date(), 'HH:mm')
      return `${this.switchValue ? 'En salle d\'attente depuis ' + time : 'Non présent en salle d\'attente'}`
    },
  },
  watch: {
    appointment (newAppointment) {
      this.localAppointment = newAppointment
      this.switchValue = newAppointment.arrivalDateTime !== null
    },
  },
  methods: {
    ...mapActions('agenda', ['updateAppointment']),
    ...mapMutations('app', ['SET_SNACK']),
    async updatePatientArrivalTime (isInWaitingRoom) {
      this.isUpdatingAppointment = true
      const appointmentState = isInWaitingRoom ? APPOINTMENT_STATES.IN_WAITING_ROOM : APPOINTMENT_STATES.CONFIRMED
      try {
        await this.updateAppointment(new Appointment({
          ...this.appointment,
          arrivalDateTime: isInWaitingRoom ? new Date().toISOString() : null,
          state: appointmentState.value,
        }))
      } catch (error) {
        setTimeout(() => {
          this.setSwitchValue(! this.switchValue, false)
        }, 200)
        this.SET_SNACK({
          message: 'Impossible de modifier l\'état de la salle d\'attente',
          color: 'error',
        })
      }
      this.isUpdatingAppointment = false
    },
    setSwitchValue (switchValue, updatePatient = true) {
      this.switchValue = switchValue
      this.$emit('change', switchValue)

      if (updatePatient) {
        this.updatePatientArrivalTime(switchValue)
      }
    },
  },
}
</script>

<style scoped>

</style>