<template>
  <v-menu
    v-model="visible"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-if="display==='input'" #activator="{ on, attrs }">
      <app-text-field
        ref="input"
        :value="dateFormatted"
        :label="label"
        readonly
        :prepend-icon="icon"
        data-test="app-date-picker-text"
        class="app-date-picker__field"
        :class="{ 'app-date-picker__field--filled': !! dateFormatted }"
        v-bind="{...$attrs, ...$props, ...attrs}"
        :clearable="clearable"
        @click:clear="handleClickClear"
        @input="handleInput"
        v-on="on"
      />
    </template>
    <template v-else #activator="{ on }">
      <slot name="activator" :on="on">
        <app-button class="app-button-date-picker-button" :icon="icon" @click.native="on.click" />
      </slot>
    </template>
    <validation-provider
      v-slot="{ errors }"
      slim
      :rules="rules"
      :name="label"
      :vid="name"
    >
      <v-date-picker
        :value="value"
        :error="errors.length > 0"
        :error-messages="errors"
        :hide-details="hideErrors"
        :type="precision"
        :rules="[]"
        :min="minDate"
        :max="maxDate"
        data-test="app-date-picker"
        locale-first-day-of-year="4"
        first-day-of-week="1"
        @input="inputDate"
      />
    </validation-provider>
  </v-menu>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import fieldMixin from '@/mixins/fields.js'

import AppTextField from './AppTextField.vue'
import NovaTools from '@/nova-tools/NovaTools'

/**
 * Champ AppDatePicker de l'application
 */
export default {
  name: 'AppDatePicker',
  components: {
    ValidationProvider,
    AppTextField,
  },
  mixins: [{
    ...fieldMixin,
    // Reset les watch pour éviter les event renvoyés par une mise à jour de la valeur localValue
    // Nous n'allons pas utiliser localValue ici
    watch: {},
  }],
  props: {
    /**
     * Texte à afficher dans le champ de texte de la date
     * Si rien n'est fourni le champ affichera la date formatée
     */
    dateFormat: {
      type: String,
      default: 'dd/MM/yyyy',
    },
    /**
     * Date minimale sélectionnable dans le calendrier
     */
    min: {
      type: Date,
      default: null,
    },
    /**
     * Date maximale sélectionnable dans le calendrier
     */
    max: {
      type: Date,
      default: null,
    },
    /**
     * Icone à afficher
     * @default schedule
     */
    icon: {
      type: String,
      default: 'schedule',
    },
    /**
     * L'affichage du DatePicker. Par défaut un champ texte avec la valeur
     * button affiche seulement un button
     */
    display: {
      type: String,
      default: 'input',
      validator: display => ['input', 'button'].includes(display),
    },
    /**
     * La précision du picker de la date. 2 choix possibles
     * date pour selectionner un jour
     * month pour seulement selectionner un mois
     */
    precision: {
      type: String,
      default: 'date',
      validator: precision => ['date', 'month'].includes(precision),
    },
  },
  data () {
    return { visible: false }
  },
  computed: {
    dateFormatted () {
      if(this.value) {
        return NovaTools.dates.format(this.value, this.dateFormat)
      }
      return null
    },
    minDate () {
      return this.min ? this.min.toISOString().substr(0, 10) : null
    },
    maxDate () {
      return this.max ? this.max.toISOString().substr(0, 10) : null
    },
  },
  methods: {
    handleFocus () {
      this.visible = true
    },
    handleInput (newVal) {
      if (newVal !== this.dateFormatted) {
        this.$emit('input', newVal)
      }
    },
    inputDate (date) {
      this.$emit('input', date)
      this.visible = false
    },
    async handleClickClear () {
      this.$emit('click:clear')
      await this.$nextTick()
      this.$refs.input.blur()
    },
  },
}
</script>

<style lang="scss" scoped>
.app-date-picker {
  &__field {
    cursor: default;

    &:not(.app-date-picker__field--filled) {
      ::v-deep {
        .v-input__append-inner {
          display: none;
        }
      }
    }

    ::v-deep {
      .v-label {
        pointer-events: none;
      }
    }
  }
}
</style>