<template>
  <app-form :submit-method="saveReaders">
    <div class="card-reader-select-form">
      <section>
        <app-alert
          message="Pour simplifier l'identification de vos lecteurs PC/SC, vous pouvez insérer vos cartes CPS et Vitale, et rafraîchir."
          type="info"
        />
      </section>
      <h3 class="d-flex align-center mt-4 mb-n2">
        Assignation des lecteurs
        <v-spacer />
        <app-button :loading="getIsCardReaderListUpdating" icon="rotate" @click="refreshCardReaders" />
      </h3>
      <section class="mt-4">
        <!-- Select Vitale-->
        <app-select
          v-model="selectedVitaleReader"
          :disabled="getIsCardReaderListUpdating"
          :items="getCardReaders"
          :item-text="(item) => item.s_name"
          return-object
          label="Lecteur de carte vitale"
        >
          <template #item="{item}">
            <card-reader-list-item :item="item" :disabled="getIsCardReaderListUpdating" />
          </template>
          <template #selection="{item}">
            <card-reader-list-item :item="item" :disabled="getIsCardReaderListUpdating" />
          </template>
        </app-select>

        <!-- Select CPx-->
        <app-select
          v-model="selectedCpxReader"
          :disabled="getIsCardReaderListUpdating"
          :items="getCardReaders"
          return-object
          :item-text="(item) => item.s_name"
          label="Lecteur de carte CPx"
        >
          <template #item="{item}">
            <card-reader-list-item :item="item" :disabled="getIsCardReaderListUpdating" />
          </template>
          <template #selection="{item}">
            <card-reader-list-item :item="item" :disabled="getIsCardReaderListUpdating" />
          </template>
        </app-select>
      </section>
    </div>
  </app-form>
</template>

<script>
import AppSelect from '@/components/ui/form/AppSelect.vue'
import AppForm from '@/components/ui/form/AppForm.vue'
import AppButton from '@/components/ui/buttons/AppButton.vue'
import CardReaderListItem from '@/components/ui/cardReader/CardReaderListItem.vue'

import iCanopeeCardReaderInstance from '@/services/vendors/icanopee/classes/ICanopeeCardReader'
import { mapGetters, mapMutations } from 'vuex'

import { CARD_TYPES } from '@/services/card-reader/constants/cardTypes'
import AppAlert from '@/components/ui/alert/AppAlert.vue'
import { ICANOPEE_CARD_READER_EVENTS } from '@/services/vendors/icanopee/constants/cardReaderEvents'

export default {
  name: 'CardReaderSelectForm',
  components: {
    AppSelect,
    AppForm,
    AppButton,
    CardReaderListItem,
    AppAlert,
  },
  data () {
    return {
      selectedVitaleReader: null,
      selectedCpxReader: null,
    }
  },
  computed: {
    ...mapGetters('cardReaders', ['getCardReaders', 'getCardReader', 'getIsCardReaderListUpdating']),
    vitaleCardReader () {
      return this.getCardReader(CARD_TYPES.VITALE)
    },
    cpxCardReader () {
      return this.getCardReader(CARD_TYPES.CPX)
    },
  },
  watch: {
    selectedVitaleReader (selectedVitaleReader, previousSelectedVitaleReader) {
      if (selectedVitaleReader === this.selectedCpxReader) {
        this.selectedCpxReader = previousSelectedVitaleReader
      }
    },
    selectedCpxReader (selectedCpxReader, previousSelectedCpxReader) {
      if (selectedCpxReader === this.selectedVitaleReader) {
        this.selectedVitaleReader = previousSelectedCpxReader
      }
    },
    getCardReaders: {
      immediate: true,
      deep: true,
      async handler () {
        try {
          const savedCards = JSON.parse(localStorage.getItem('cardReaders'))
          if (savedCards === null) {
            this.SET_CARD_READERS_LIST_UPDATING(false)
            await iCanopeeCardReaderInstance.getCardReaders()
          }
          if (savedCards[CARD_TYPES.VITALE]) {
            this.selectedVitaleReader = this.getCardReaders.find(reader => reader.s_name === savedCards[CARD_TYPES.VITALE])
          }

          if (savedCards[CARD_TYPES.CPX]) {
            this.selectedCpxReader = this.getCardReaders.find(reader => reader.s_name === savedCards[CARD_TYPES.CPX])
          }
        } catch (error) {
        }
      },
    },
    vitaleCardReader (vitaleCardReader) {
      this.selectedVitaleReader = vitaleCardReader
    },
    cpxCardReader (cpxCardReader) {
      this.selectedCpxReader = cpxCardReader
    },
  },
  mounted () {
    iCanopeeCardReaderInstance.addEventListener(ICANOPEE_CARD_READER_EVENTS.STATE_CHANGED, this.refreshCardReaders)
  },
  destroyed () {
    iCanopeeCardReaderInstance.removeEventListener(ICANOPEE_CARD_READER_EVENTS.STATE_CHANGED, this.refreshCardReaders)
  },
  methods: {
    ...mapMutations('cardReaders', ['SET_CARD_READER', 'SET_CPX_CARD_READER', 'SET_CARD_READERS_LIST_UPDATING']),
    saveReaders () {
      this.SET_CARD_READER({
        cardReaderType: CARD_TYPES.VITALE,
        cardReader: this.selectedVitaleReader,
      })
      this.SET_CARD_READER({
        cardReaderType: CARD_TYPES.CPX,
        cardReader: this.selectedCpxReader,
      })
    },
    async refreshCardReaders () {
      await iCanopeeCardReaderInstance.getCardReaders()
      this.getCardReaders
        .filter(cardReader => cardReader.i_slotType === 2 || cardReader.i_slotType === 3)
        .forEach(cardReader => {
          if (cardReader.i_slotType === 2) {
            this.selectedVitaleReader = cardReader
          } else {
            this.selectedCpxReader = cardReader
          }
        })
    },
  },
}
</script>