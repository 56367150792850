<template>
  <div v-if="isNotEmptyAddress(contactActivityPlacesWithoutMss)" class="ml-3">
    <n-expandable
      v-test="'correspondent-wrapper'"
      title="Lieux d'exercice"
      :title-style="{ color: 'var(--v-secondary-base)', fontWeight: 'bolder' }"
    >
      <div v-for="activityPlace in contactActivityPlacesWithoutMss" :key="activityPlace['@id']">
        <div v-test="'correspondent-activity-place'" class="contact-activity-places__container">
          <app-person-address class="mb-2" :address="activityPlace" color-icon="var(--v-secondary-lighten4)">
            <template #append>
              <div
                v-for="(cp, index) in notMssContactPoints(activityPlace)"
                :key="index"
              >
                <app-contact-point
                  v-test="'not-mss-contact-point'"
                  :contact-point="{ value: cp }"
                  :formatted-contact-point="false"
                  :show-label="false"
                  compact
                />
              </div>
            </template>
          </app-person-address>
        </div>
      </div>
    </n-expandable>
  </div>
</template>

<script>
import NExpandable from '@/nova-ui/NExpandable/NExpandable'
import AppContactPoint from '@/components/ui/contactPoint/AppContactPoint'
import AppPersonAddress from '@/components/ui/personAddress/AppPersonAddress'
import Contact from '@/models/user/Contact'

export default {
  name: 'CorrespondentsPanelItemContactActivityPlaces',
  components: {
    NExpandable,
    AppContactPoint,
    AppPersonAddress,
  },
  props: {
    correspondentContact: {
      type: Contact,
      required: true,
    },
  },
  computed: {
    /**
     * Retourner les lieux d'activités sans les informations de contact de type mss
     * @returns {*[]}
     */
    contactActivityPlacesWithoutMss () {
      return this.correspondentContact.contactActivityPlaces.filter(item => item.mssAddress === false)
    },
  },
  methods: {
    isNotEmptyAddress (notMssContactActivityPlaces) {
      if (0 === notMssContactActivityPlaces.length) {
        return false
      }

      return !! (
        notMssContactActivityPlaces[0].name
        || notMssContactActivityPlaces[0].line1
        || notMssContactActivityPlaces[0].line2
        || notMssContactActivityPlaces[0].postalCode
        || notMssContactActivityPlaces[0].city
      )
    },
    hasPostalCodeOrCity (activityPlace) {
      return !! (activityPlace.postalCode || activityPlace.city)
    },
    notMssContactPoints (contactActivityPlace) {
      return this.correspondentContact.getNotMssContactPointsForContactActivityPlace(contactActivityPlace)
    },
  },
}
</script>