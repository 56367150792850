export default {
  props: {
    /**
     * Le statut ouvert/fermé de la fenêtre modale
     * @model
     * Peut être utilisé avec le mot-clef `.sync`
     * @example <app-modal :is-open.sync="show">
     */
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    opened: {
      get () {
        return this.isOpen
      },
      set (value) {
        this.$emit('update:isOpen', value)
        if (! value) {
          this.$emit('cancel')
        }
      },
    },
  },
  methods: {
    handleCancel () {
      this.$emit('update:isOpen', false)
      this.$emit('cancel')
    },
  },
}