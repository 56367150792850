import '@fortawesome/fontawesome-free/css/all.css'
import { COLORS } from '@/constants/colors'


export default {
  theme: {
    light: {
      ...COLORS,

      // Anciennes couleurs (à supprimer progressivement)
      text: {
        base: COLORS.content.base,
        lighten3: '#8C94b2',
        lighten4: '#e5e7ef',
        lighten5: '#e8edf2',
      },
      border: COLORS.secondary.lighten4,
      'blue-grey': {
        base: COLORS.secondary.base,
        lighten1: COLORS.secondary.lighten4,
        lighten2: COLORS.secondary.lighten5,
      },
    },
  },
}