import ApiEntity from '@/models/ApiEntity'
import Patient from '@/modules/patient/models/Patient'

import { getDocumentDisplayNameByCode } from '@/modules/secureMessaging/modules/documents/utils/documentTypes'
import { getIsoDate } from '@/utils/functions/dates'

import { BiO_EXAM_RELEVANT_CLINICAL_TYPES } from '@/modules/secureMessaging/modules/documents/constants'

const REPORT_OF_BIOLOGICAL_EXAMINATIONS_CODE = '11502-2'

export default class MssDocumentMetaDataset extends ApiEntity {
  constructor (options = {}) {
    super(options)
    this.acts = options.acts || []
    this.authors = options.authors || []
    this.bioExamRelevantClinicalElem = options.bioExamRelevantClinicalElem || false
    this.bioExamRelevantClinicalElemCritical = options.bioExamRelevantClinicalElemCritical || false
    this.bioResults = options.bioResults || []
    this.care = options.care || null
    this.code = options.code || null
    this.versionNumber = options.versionNumber || null
    this.setId = options.setId || null
    this.patientBirthDate = options.patientBirthDate || null
    this.patientBirthName = options.patientBirthName || null
    this.patientBirthPlaceCode = options.patientBirthPlaceCode || null
    this.patientFirstNames = options.patientFirstNames || null
    this.patientGender = options.patientGender || null
    this.patientIns = options.patientIns || null
    this.patientOid = options.patientOid || null
  }

  getPatient () {
    return new Patient({
      firstName: this.patientFirstNames,
      firstNames: this.patientFirstNames,
      birthName: this.patientBirthName,
      gender: this.patientGender,
      birthDate: getIsoDate(this.patientBirthDate),
      birthPlaceCode: this.patientBirthPlaceCode,
      insIdentity: {
        ins: this.patientIns,
        oid: this.patientOid,
      },
    })
  }

  getCodeDisplayName () {
    return getDocumentDisplayNameByCode(this.code)
  }

  isReportOfBiologicalExaminations () {
    return this.code === REPORT_OF_BIOLOGICAL_EXAMINATIONS_CODE
  }

  getBioIconPropsFromMetaDataset () {
    if (this.bioExamRelevantClinicalElemCritical) {
      return {
        icon: BiO_EXAM_RELEVANT_CLINICAL_TYPES.CRITICAL.icon,
        color: BiO_EXAM_RELEVANT_CLINICAL_TYPES.CRITICAL.color,
        tooltip: BiO_EXAM_RELEVANT_CLINICAL_TYPES.CRITICAL.text,
      }
    }
    return {
      icon: BiO_EXAM_RELEVANT_CLINICAL_TYPES.DEFAULT.icon,
      color: BiO_EXAM_RELEVANT_CLINICAL_TYPES.DEFAULT.color,
      tooltip: BiO_EXAM_RELEVANT_CLINICAL_TYPES.DEFAULT.text,
    }
  }
}