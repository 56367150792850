/**
 * Les options par défaut pour la méthode formatAmount
 */
const defaultOptions = { showPositiveSign: false }

/**
 *
 * @param {Number} amount Le montant à formater
 * @param {Object} options Les options à appliquer
 * @returns {String} Le montant formaté
 */
export const formatAmount = (amount, options = defaultOptions) => {
  let strNumber = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    signDisplay: options.showPositiveSign ? 'always' : 'auto',
  }).format(amount)

  // Ajoute un espace entre le signe et la valeur
  strNumber = strNumber.replace(/^([+-])/, '$1 ')
  return strNumber
}