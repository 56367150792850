import TlsiNir from '@/modules/patient/utils/classes/TlsiNir'

import NovaTools from '@/nova-tools/NovaTools'

export default class TlsiBeneficiary {
  /**
   * @param {InsIdentityDeviceType} options
   */
  constructor (options = {}) {
    this.nir = options.Nir ? new TlsiNir(options.Nir) : null
    this.nirOd = options.NirOD ? new TlsiNir(options.NirOD) : null
    this.birthdate = options.birthdate || null
    this.birthrank = options.birthrank || null
    this.grandRegime = options.grandRegime || null
    this.desk = options.desk || null
    this.center = options.center || null
    this.fullName = options.fullName || null
  }

  getFormattedBirthDate () {
    return NovaTools.dates.format(this.birthdate, 'dd/MM/yyyy')
  }
}