<template>
  <v-dialog
    v-model="opened"
    v-bind="$attrs"
    :scrollable="scrollable"
    :fullscreen="isMobile"
    content-class="app-modal"
    overlay-color="text"
    :width="$attrs && $attrs.width ? $attrs.width : '600px'"
    :transition="modalTransition"
    :eager="eager"
    @input="val => $emit('update:isOpen', val)"
  >
    <template #activator="{ on, attrs }">
      <!-- @slot Slot pour inserer l'élément qui permet d'afficher la modal -->
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>
    <v-card color="app-modal__content">
      <v-card-title class="pt-6 px-6 pb-0" :class="{ 'pb-6': scrollable }">
        <h2 class="app-modal__headline title-3">
          {{ title }}
        </h2>
        <app-button
          v-if="hideFooter"
          icon="close"
          class="ml-auto"
          data-test="close-button"
          label="Fermer"
          @click="handleCancel"
        />
      </v-card-title>
      <n-divider v-if="scrollable" :spacing="0" />
      <v-card-text
        class="pa-6 app-modal__body"
        :class="{'py-4 py-md-5': scrollable }"
      >
        <slot />
      </v-card-text>
      <v-divider v-if="scrollable" />
      <v-card-actions v-if="!hideFooter" class="pa-4 px-md-8 py-md-5" data-test="modalFooter">
        <v-spacer />
        <!-- @slot Slot pour inserer les actions possibles sur la modal -->
        <slot name="actions" :cancel="handleCancel">
          <!--
            Clic sur le bouton d'annulation de la modale
            @event cancel
          -->
          <app-button
            text
            :color="cancelColor"
            data-test="cancel"
            @click="handleCancel"
          >
            {{ cancelText }}
          </app-button>
          <!--
            Clic sur le bouton de confirmation de la modale
            @event submit
          -->
          <app-button
            :color="submitColor"
            :loading="isSubmitting"
            :disabled="submitIsDisabled"
            :form="formId"
            type="submit"
            @click="$emit('submit')"
          >
            {{ submitText }}
          </app-button>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import ModalMixin from './mixins/ModalMixin'
/**
 * Affiche une fenêtre modale
 */
export default {
  name: 'AppModal',
  mixins: [ModalMixin],
  props: {
    /**
     * Le status de chargement d'envoi des données de la fenêtre modale.
     */
    isSubmitting: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Le status activé/désactivé de la fenêtre modale
     */
    submitIsDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Le type de fenêtre modale (scrollable)
     */
    scrollable: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Affiche ou non le footer de la fenêtre modale
     */
    hideFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Le titre de la modale. Affiché en haut de celle-ci
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * Le texte du bouton de confirmation de la modale
     * Utilisé seulement si le slot actions n'est pas remplacé
     * @default "Enregistrer"
     */
    submitText: {
      type: String,
      required: false,
      default: 'Enregistrer',
    },
    /**
     * La couleur du bouton de confirmation de la modale
     * Utilisé seulement si le slot actions n'est pas remplacé
     * @default "Enregistrer"
     */
    submitColor: {
      type: String,
      required: false,
      default: 'primary',
    },
    /**
     * Le texte du bouton d'annulation de la modale
     * Utilisé seulement si le slot actions n'est pas remplacé
     * @default "Annuler"
     */
    cancelText: {
      type: String,
      required: false,
      default: 'Annuler',
    },
    /**
     * La couleur du bouton d'annulation de la modale
     * Utilisé seulement si le slot actions n'est pas remplacé
     * @default "Annuler"
     */
    cancelColor: {
      type: String,
      required: false,
      default: 'blue-grey',
    },
    /**
     * L'id (html) du formulaire qui sera utilisé au clic sur le bouton de submit
     */
    formId: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Force le chargement dans le DOM au mount du composant parent
     */
    eager: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState('app', ['isMobile']),
    modalTransition () {
      return this.isMobile ? 'dialog-bottom-transition' : 'dialog-transition'
    },
  },
}
</script>

<style lang="scss">
.app-modal {
  border-radius: 12px !important;

  &__content {
    border-radius: 12px !important;
    display: flex;
    flex-direction: column;
  }

  &__headline {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px;
    font-weight: 300;
    flex: 1 0 0;
  }

  &__body {
    flex: 1 0 auto;
    font-size: 13px;
  }
}
</style>