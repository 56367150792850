<template>
  <app-panel title="Organismes de rattachement">
    <app-text-field
      v-model="localValue.birthRank"
      v-test="'birth-rank-field'"
      label="Rang de naissance"
      rules="numeric|max:1"
      hide-errors="auto"
      class="mb-4"
      autocomplete="off"
    />
    <app-autocomplete
      v-model="localValue.quality"
      v-test="'quality-field'"
      label="Qualité du bénéficiaire"
      hide-errors="auto"
      class="mb-4"
      autocomplete="off"
      item-text="label"
      clearable
      :items="beneficiaryQualityFieldItems"
    />
    <app-autocomplete
      v-model="localValue.grandRegime"
      v-test="'grand-regime-field'"
      :items="getGrandRegimes"
      item-text="libelle"
      item-value="@id"
      label="Grand régime"
      hide-errors="auto"
      class="mb-4"
      clearable
      return-object
    />
    <app-text-field
      v-model="localValue.fund"
      v-test="'fund-field'"
      label="Caisse"
      hide-errors="auto"
      class="mb-4"
      rules="numeric|max:3"
      autocomplete="off"
    />
    <app-text-field
      v-model="localValue.center"
      v-test="'center-field'"
      label="Centre"
      hide-errors="auto"
      rules="numeric|max:4"
      autocomplete="off"
    />
  </app-panel>
</template>

<script>
import AppPanel from '@/components/ui/panel/AppPanel.vue'
import AppTextField from '@/components/ui/form/AppTextField.vue'
import AppAutocomplete from '@/components/ui/form/AppAutocomplete.vue'

import PatientBillDataset from '@/modules/patient/models/datasets/PatientBillDataset'

import { BENEFICIARIES_QUALITY_CODES } from '@/modules/patient/constants'

import { mapGetters } from 'vuex'

export default {
  name: 'BillDatasetFieldset',
  components: {
    AppPanel,
    AppTextField,
    AppAutocomplete,
  },
  props: {
    value: {
      type: PatientBillDataset,
      default: null,
    },
  },
  data () {
    return {
      localValue: new PatientBillDataset(this.value || {}),
      beneficiaryQualityFieldItems: Object.values(BENEFICIARIES_QUALITY_CODES).map(quality => ({
        label: quality.label,
        value: quality.value,
      })),
    }
  },
  computed: { ...mapGetters('app', ['getGrandRegimes']) },
  watch: {
    value (newVal) {
      this.localValue = newVal
    },
    localValue: {
      deep: true,
      handler (newVal) {
        this.$emit('input', newVal)
      },
    },
  },
}
</script>