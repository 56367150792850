<template>
  <div class="login">
    <authentication-form-wrapper
      :title="FORM_HEADINGS.CONNECTION.title"
      :text="FORM_HEADINGS.CONNECTION.text"
      :form="LoginForm"
      submit-text="Se connecter"
    />
    <choice-divider />
    <psc-button />
  </div>
</template>

<script>
import AuthenticationFormWrapper from '@/modules/authentication/components/AuthenticationFormWrapper.vue'
import LoginForm from '@/modules/authentication/forms/LoginForm.vue'
import ChoiceDivider from '@/components/ui/divider/ChoiceDivider.vue'

import { FORM_HEADINGS } from '@/modules/authentication/constants'
import PscButton from '@/modules/authentication/components/PscButton'
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  components: {
    PscButton,
    ChoiceDivider,
    AuthenticationFormWrapper,
  },
  async beforeRouteEnter (to, from, next) {
    next(async vm => {
      if (vm.isPscAuthentication(to)) {
        const pscLocal = vm.getPscFromLocalStorage()
        if (vm.checkStateCsrf(pscLocal.state)) {
          await vm.authenticatePSC(to.query)
          await vm.$router.push('/')
        }
      }
    })
  },
  data () {
    return {
      FORM_HEADINGS,
      LoginForm,
    }
  },
  methods: {
    ...mapActions('auth', ['authenticatePSC']),
    isPscAuthentication (to) {
      return (to.path === '/' && to.query?.code)
    },
    getPscFromLocalStorage () {
      return JSON.parse(localStorage.getItem('psc'))
    },
    checkStateCsrf (state) {
      return (state === this.getPscFromLocalStorage().state)
    },
  },
}
</script>
<style lang="scss" scoped>
  .login {
    width: 100%;
    display: flex;
    flex-direction:column;
    align-items: center;
  }
</style>