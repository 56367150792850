import BaseFile from './BaseFile'
import { getFileFromAPI } from '@/services/api.js'

export default class ZipFile extends BaseFile {

  constructor (fetchUrl) {
    super(fetchUrl)
  }

  async fileGetter () {
    const response = await getFileFromAPI(this._fetchUrl)
    const name = response.headers['content-disposition'].split('filename=')[1]
    const blob = new Blob([response.data], { type: 'application/zip' })
    return {
      name,
      blob,
    }
  }
}