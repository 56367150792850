export const ALERT_TYPES = Object.freeze({
  SUCCESS: {
    icon: 'fa fa-check-circle',
    name: 'success',
  },
  ERROR: {
    icon: 'fa fa-exclamation-circle',
    name: 'error',
  },
  INFO: {
    icon: 'fas fa-info-circle',
    name: 'info',
  },
  WARNING: {
    icon: 'fa fa-exclamation-triangle',
    name: 'warning',
  },
})