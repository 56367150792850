import Vue from 'vue'
import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI'
import PatientNonDrugAllergy from '@/modules/patient/models/riskFactors/PatientNonDrugAllergy'
import { postToAPI, deleteFromAPI, putToAPI } from '@/services/api'

export const state = () => ({
  nonDrugAllergies: {},
  hasLoadedPatientNonDrugAllergies: {},
  isLoadingNonDrugAllergies: false,
})

export const getters = {
  getPatientNonDrugAllergies: state => patientUuid => Object.values(state.nonDrugAllergies[patientUuid] || {}),
  getHasLoadedNonDrugAllergiesByPatientUuid: state => patientUuid => state.hasLoadedPatientNonDrugAllergies[patientUuid],
  isLoadingNonDrugAllergies: state => state.isLoadingNonDrugAllergies,
}

export const mutations = {
  SET_NON_DRUG_ALLERGY (state, nonDrugAllergy) {
    const patientUuid = getUUIDFromIRI(nonDrugAllergy.patient)
    const nonDrugAllergyUuid = getUUIDFromIRI(nonDrugAllergy['@id'])
    if (state.nonDrugAllergies[patientUuid] === undefined) {
      Vue.set(state.nonDrugAllergies, patientUuid, {})
    }
    Vue.set(state.nonDrugAllergies[patientUuid], nonDrugAllergyUuid, new PatientNonDrugAllergy(nonDrugAllergy))
  },
  DELETE_NON_DRUG_ALLERGY (state, nonDrugAllergy) {
    const patientUuid = getUUIDFromIRI(nonDrugAllergy.patient)
    Vue.delete(state.nonDrugAllergies[patientUuid], nonDrugAllergy.getUuid())
  },
  SET_HAS_LOADED_PATIENT_NON_DRUG_ALLERGIES (state, { patientUuid, hasLoadedNonDrugAllergies }) {
    state.hasLoadedPatientNonDrugAllergies[patientUuid] = hasLoadedNonDrugAllergies
  },
  SET_IS_LOADING_NON_DRUG_ALLERGIES (state, isLoadingNonDrugAllergies) {
    state.isLoadingNonDrugAllergies = isLoadingNonDrugAllergies
  },
}

export const actions = {
  async fetchPatientNonDrugAllergies ({ state, commit, dispatch }, patientUuid) {
    commit('SET_IS_LOADING_NON_DRUG_ALLERGIES', true)
    try {
      const patientNonDrugAllergies = await dispatch('fetchPatientRiskFactor', {
        route: `api/patients/${patientUuid}/non_drug_allergies`,
        patientUuid,
        riskFactorName: 'nonDrugAllergies',
      })
      patientNonDrugAllergies.forEach(nonDrugAllergy => commit('SET_NON_DRUG_ALLERGY', nonDrugAllergy))
    } finally {
      commit('SET_IS_LOADING_NON_DRUG_ALLERGIES', false)

      if (! state.hasLoadedPatientNonDrugAllergies[patientUuid]) {
        commit('SET_HAS_LOADED_PATIENT_NON_DRUG_ALLERGIES', {
          patientUuid,
          hasLoadedNonDrugAllergies: true,
        })
      }
    }
  },
  async insertPatientNonDrugAllergy ({ commit }, nonDrugAllergy) {
    const { data } = await postToAPI('api/patient_non_drug_allergies', nonDrugAllergy)
    commit('SET_NON_DRUG_ALLERGY', data)
  },
  async updatePatientNonDrugAllergy ({ commit }, nonDrugAllergy) {
    const { data } = await putToAPI(nonDrugAllergy.getIri(), { data: nonDrugAllergy })
    commit('SET_NON_DRUG_ALLERGY', data)
  },
  async deletePatientNonDrugAllergy ({ commit }, nonDrugAllergy) {
    await deleteFromAPI(nonDrugAllergy.getIri())
    commit('DELETE_NON_DRUG_ALLERGY', nonDrugAllergy)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}