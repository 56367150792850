import PrescriptionLppLine from '@/modules/patient/models/prescriptionLine/prescriptionHealthProductLine/prescriptionLppLine/PrescriptionLppLine'
import LppProduct from '@/modules/patient/models/LppProduct'

export default class PrescriptionCodifiedLppLine extends PrescriptionLppLine {
  constructor (options = {}) {
    super(options)

    this.product = new LppProduct(options.product || {})
    this.exceptionDrug = options.exceptionDrug || false
  }

  getLabel () {
    return this.product.codeLabel
  }
}