import { isObject, omitBy, isNil } from 'lodash'

/**
 * Génère un objet nesté avec les sous-clés fournies dans path si elles n'existent pas
 * @param {Object} obj - Objet source
 * @param {Array} keys - Clés intermédiaires
 * @param {any} val - Valeur a appliquer sur la dernière clé
 */

export const objectSetNested = (obj, keys, val) => {

  const lastKey = keys.pop()
  const lastObj = keys.reduce((obj, key) =>
    obj[key] = obj[key] || {},
  obj)
  lastObj[lastKey] = val
}

/**
 * Fusionne 2 objets en profondeur
 * @param {Object} objA - Objet source
 * @param {Object} objB - Objet à fusionner
 * @param {Boolean} options.ignoreNullish - Si une valeur de l'objet à fusionner est nulle ou inconnue, alors elle sera ignorée
 * @returns {Object}
 */
export const mergeObjectsDeep = (objA, objB, options = {}) => {
  const onReplace = (a, b) => {
    if (isObject(a) || isObject(b)) {
      const ObjModel = a?.constructor || b?.constructor
      if (options.ignoreNullish) {
        a = omitBy(a, isNil)
        b = omitBy(b, isNil)
      }
      return Object.assign(new ObjModel(), a, b)
    }
    if (options.ignoreNullish) {
      return isNil(b) ? a : b
    }
    return b
  }
  const mergedObjects = [...new Set([...Object.keys(objA), ...Object.keys(objB)])].reduce(
    (acc, key) => ({
      ...acc,
      [key]: onReplace(objA[key], objB[key]),
    }),
    {},
  )
  const ObjModel = objA?.constructor
  if (ObjModel !== Object) {
    return new ObjModel(mergedObjects)
  }
  return mergedObjects
}