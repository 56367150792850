import ApiEntity from '@/models/ApiEntity'
import Consultation from '@/modules/patient/models/Consultation'
import MedicalHistoryNote from '@/modules/patient/models/medicalHistory/MedicalHistoryNote'

export default class MedicalHistoryItem extends ApiEntity {
  constructor (options = {}) {
    super(options)
    this.type = options.type || null
    this.item = options.item ? this._initItem(options.item) : null
  }

  _initItem (item) {
    if (this.isConsultation()) {
      return new Consultation(item)
    }
    if (this.isNote()) {
      return new MedicalHistoryNote(item)
    }
  }

  isConsultation () {
    return (this.type === 'consultation')
  }

  isNote () {
    return (this.type === 'note')
  }
}