import SendMessageModalForm from '@/modules/secureMessaging/modules/messaging/forms/SendMessageModalForm.vue'

import { getMssMessageForDocument } from '@/modules/secureMessaging/modules/messaging/utils/getMssMessageForDocument'

import AuthenticationServiceInstance from '@/modules/secureMessaging/modules/authentication/services/AuthenticationService'
import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'
import NovaTools from '@/nova-tools/NovaTools'

/**
 * Ouvre une modale d'envoie de message avec en pièce jointe un fichier importer, un document de consultation ou une ordonnance
 * @param document un document de consultation (PatientDocument, ConsultationDocument ou Prescription)
 * @param {object} options les options de l'ActionMenuItem (@see /components/ui/actionsMenu/classes/ActionMenuItem.js)
 */
export default class DocumentMessagingSendingAction extends ActionMenuItem {
  constructor (document, options) {
    if (! document) {
      throw 'Un document est requis pour cette action'
    }
    super('messaging-transfert', 'Envoyer par messagerie', async () => {
      if (! AuthenticationServiceInstance.isAuthenticated) {
        NovaTools.notify.warning('Veuillez vous connecter au module de messagerie')
        return
      }
      const mssMessage = await getMssMessageForDocument(document)
      const { id } = NovaTools.modal.append(SendMessageModalForm, {
        title: 'Envoyer le document',
        message: mssMessage,
        isOpen: true,
        patient: document.patient,
      }, {
        cancel: () => NovaTools.modal.destroy(id),
        submitSuccess: () => NovaTools.modal.destroy(id),
        'update:is-open': () => NovaTools.modal.destroy(id),
      })
    }, { ...options })
  }

}