<template>
  <app-form :submit-method="submit">
    <app-row>
      <app-col cols="12" md="8">
        <app-row>
          <app-col cols="12">
            <identity-fieldset
              v-model="localValue"
              v-test="'civil-state-fieldset'"
              :is-logging-ins-call="isLoggingInsCall"
              :auto-select-civility="! isEditing"
              @fetch-ins="payload => saveInsiLog(payload)"
            />
          </app-col>
          <app-col cols="12">
            <contact-info-fieldset :contact-points.sync="localValue.contactPoints" :address.sync="localValue.address" />
          </app-col>
        </app-row>
      </app-col>
      <app-col cols="12" md="4">
        <app-row>
          <app-col cols="12">
            <consents-fieldset
              :mss-document-consent.sync="localValue.mssDocumentConsent"
            />
          </app-col>
          <app-col cols="12">
            <other-informations-fieldset
              :note.sync="localValue.note"
              :profession.sync="localValue.profession"
              :family-situation.sync="localValue.familySituation"
            />
          </app-col>
          <app-col cols="12">
            <death-fieldset
              :is-deceased.sync="localValue.isDeceased"
              :death-date.sync="localValue.deathDate"
            />
          </app-col>
          <app-col cols="12">
            <bill-dataset-fieldset v-model="localValue.billDataset" />
          </app-col>
        </app-row>
      </app-col>
    </app-row>
  </app-form>
</template>

<script>
import AppForm from '@/components/ui/form/AppForm.vue'

import IdentityFieldset from './fieldsets/IdentityFieldset.vue'
import ContactInfoFieldset from './fieldsets/contactInfo/ContactInfoFieldset.vue'
import ConsentsFieldset from './fieldsets/ConsentsFieldset.vue'
import BillDatasetFieldset from './fieldsets/BillDatasetFieldset.vue'
import OtherInformationsFieldset from './fieldsets/OtherInformationsFieldset.vue'
import DeathFieldset from './fieldsets/DeathFieldset.vue'

import violationsMixin from '@/mixins/violations'

import { mapActions, mapMutations } from 'vuex'

import Patient from '@/modules/patient/models/Patient'

export default {
  name: 'PatientForm',
  components: {
    AppForm,
    IdentityFieldset,
    ConsentsFieldset,
    BillDatasetFieldset,
    ContactInfoFieldset,
    OtherInformationsFieldset,
    DeathFieldset,
  },
  mixins: [violationsMixin],
  props: {
    /**
     * Modèle du patient
     * @model
     */
    value: {
      type: Patient,
      default: () => new Patient(),
    },
  },
  data () {
    return {
      isLoggingInsCall: false,
      pendingInsiLogs: [],
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      },
    },
    isEditing () {
      return !! this.value?.['@id']
    },
  },
  methods: {
    ...mapMutations('app', ['SET_SNACK']),
    ...mapActions('patient', ['insertPatient', 'updatePatient']),
    async submit () {
      const action = this.isEditing ? 'updatePatient' : 'insertPatient'
      const patient = await this[action](new Patient(this.localValue))

      // Une fois le patient créé, on met à jour les logs en fournissant l'@id du patient
      for await (const pendingLog of this.pendingInsiLogs) {
        await this.localValue.insIdentity.associatePatientIriToLog(pendingLog['@id'], patient['@id'])
      }
      this.pendingInsiLogs = []

      this.SET_SNACK({
        message: this.isEditing ?
          'Le patient a été modifié avec succès' :
          'Le patient a été ajouté avec succès',
      })
      return patient
    },
    async saveInsiLog ({ data, callType }) {
      try {
        this.isLoggingInsCall = true
        const callLogsData = await this.localValue.insIdentity.logCall(callType, {
          patient: this.localValue['@id'],
          trace: data,
        })

        // On met de côté le log généré tant que le patient n'a pas d'@id
        if (! this.isEditing) {
          this.pendingInsiLogs.push(callLogsData)
        }
      } finally {

        this.isLoggingInsCall = false
      }
    },
  },
}
</script>