/**
 * Mixin permettant l'écoute d'évènement de la saisie dans l'input de recherche
 * d'un widget de type autocomplete de Synapse
 */
export default {
  mounted () {
    this.getSearchInput()?.addEventListener('input', this.handleInput)
  },
  beforeDestroy () {
    this.getSearchInput()?.removeEventListener('input', this.handleInput)
  },
  methods: {
    handleInput (event) {
      this.$emit('update:search-input', event.target.value)
    },
    getSearchInput () {
      return this.$refs.widget.querySelector('input')
    },
    focus () {
      this.getSearchInput()?.focus()
    },
  },
}