import { logger } from '@/services/logger.js'

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js', { scope: '/' })
      .then(registration => {
        registration.onupdatefound = () => {
          const installingWorker = registration.installing
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed'
              && navigator.serviceWorker.controller) {
              notifyUserOfUpdate(installingWorker)
            }
          }
        }
        // Vérification manuelle des mises à jour
        setInterval(() => {
          registration.update().catch()
        }, 600000) // 10 * 60 * 1000 Vérifier toutes 10 min
      }).catch(error => {
        logger.error(error)
      })
  })
}

function notifyUserOfUpdate (worker) {
  const updateNotification = document.createElement('div')
  updateNotification.innerText = 'Une mise à jour est disponible. Cliquez ici pour mettre à jour.'
  updateNotification.style.backgroundColor = '#37467a'
  updateNotification.style.borderRadius = '6px'
  updateNotification.style.bottom = '20px'
  updateNotification.style.boxShadow = '0 3px 10px rgb(55 70 122 / 12%)'
  updateNotification.style.color = '#fff'
  updateNotification.style.cursor = 'pointer'
  updateNotification.style.fontFamily = 'Poppins, sans-serif'
  updateNotification.style.padding = '12px'
  updateNotification.style.position = 'fixed'
  updateNotification.style.right = '20px'
  updateNotification.style.zIndex = '100'

  updateNotification.addEventListener('click', () => {
    worker.postMessage({ type: 'SKIP_WAITING' })
    updateNotification.remove()
    window.location.reload()
  })

  document.body.appendChild(updateNotification)
}