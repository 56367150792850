import referringPhysiciansStore from './referringPhysicians'

export const state = () => ({ ...referringPhysiciansStore.state() })

export const getters = { ...referringPhysiciansStore.getters }

export const mutations = { ...referringPhysiciansStore.mutations }

export const actions = { ...referringPhysiciansStore.actions }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}