import Vue from 'vue'

import { getFromAPI, postToAPI, patchToAPI, deleteFromAPI } from '@/services/api.js'

import Motive from '@/modules/agenda/models/Motive'
import { uniqBy } from '@/utils/functions/arrays'
import NovaTools from '@/nova-tools/NovaTools'

const state = () => ({ appointmentMotives: {} })

const getters = {
  getAppointmentMotives: state => Object.values(state.appointmentMotives),
  getAppointmentMotiveFromIri: state => (appointmentMotiveIri) => state.appointmentMotives[appointmentMotiveIri],
  getScheduleAppointmentMotives: (state, getters) => (schedule) => {
    return getters.getAppointmentMotives.filter(appointmentMotive => {
      return appointmentMotive.practitionerSchedules
        .find(motiveSchedule => motiveSchedule['@id'] === schedule['@id'])
    })
  },
  getFutureAvailabilitiesMotivesBySchedule: (state, getters) => (schedule) => {
    const availabilitiesSettings = getters.getAvailabilitiesSettingsByScheduleIri(schedule['@id'])
    availabilitiesSettings.filter((availabilitiesSetting) => {
      const today = new Date(NovaTools.dates.format(new Date(), 'yyyy-MM-dd'))
      const settingDateStart = new Date(availabilitiesSetting.date)
      const settingDateEnd = availabilitiesSetting.recurrencePattern?.UNTIL ? new Date(availabilitiesSetting.recurrencePattern?.UNTIL) : today

      const activeRecurringAvailabilitiesPast = settingDateStart <= today && availabilitiesSetting.recurrencePattern && settingDateEnd >= today
      const activeNoRecurringAvailabilitiesFuture = settingDateStart >= today && ! availabilitiesSetting.recurrencePattern
      const punctualAvailabilityOfTheDay = settingDateStart === today && availabilitiesSetting.intervals[0].end > NovaTools.dates.format(new Date(), 'HH:mm')

      return activeRecurringAvailabilitiesPast || activeNoRecurringAvailabilitiesFuture || punctualAvailabilityOfTheDay
    })
    const motives = availabilitiesSettings.reduce((availabilitiesMotives, availabilitySettings) => {
      return availabilitiesMotives.concat(availabilitySettings.appointmentMotives)
    }, [])
    return uniqBy(motives, 'name')
  },
}

const mutations = {
  SET_MOTIVE (state, motive) {
    Vue.set(state.appointmentMotives, motive['@id'], new Motive(motive))
  },
  DELETE_MOTIVE (state, motive) {
    Vue.delete(state.appointmentMotives, motive['@id'])
  },
}

const actions = {
  async fetchAppointmentMotives ({ commit }) {
    const response = await getFromAPI('/api/appointment_motives')
    const rawMotives = response.data['hydra:member']
    rawMotives.forEach(motive => commit('SET_MOTIVE', motive))
  },
  async insertMotive ({ commit }, motive) {
    const serializedMotive = {
      ...motive,
      practitionerSchedules: motive.practitionerSchedules.map(pSchedule => pSchedule['@id']),
    }
    const { data } = await postToAPI('/api/appointment_motives', serializedMotive)
    commit('SET_MOTIVE', data)
    return data
  },
  async updateMotive ({ commit }, motive) {
    const { data } = await patchToAPI(motive['@id'], {
      data: {
        ...motive,
        practitionerSchedules: motive.practitionerSchedules.map(pSchedule => pSchedule['@id']),
      },
    })
    commit('SET_MOTIVE', data)
    return data
  },
  async deleteMotive ({ commit }, motive) {
    await deleteFromAPI(motive['@id'])
    commit('DELETE_MOTIVE', motive)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}