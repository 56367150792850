import Vue from 'vue'

import { getFromAPI, postToAPI, putToAPI } from '@/services/api.js'
import Template from '@/modules/documents/models/Template.js'
import TemplateFolder from '@/modules/documents/models/TemplateFolder.js'

export const state = () => ({
  templates: {},
  templateFolders: {},
  isLoadingTemplates: false,
  draggingTemplate: null,
})

export const getters = {
  getTemplates: state => Object.values(state.templates),
  getTemplateFolders: state => Object.values(state.templateFolders),
  getTemplateFromUuid: state => templateUuid => state.templates['/api/document_templates/' + templateUuid ],
  getDraggingTemplate: state => state.draggingTemplate,
}

export const mutations = {
  SET_TEMPLATE (state, template) {
    Vue.set(state.templates, template[ '@id' ], new Template(template))
  },
  SET_TEMPLATE_FOLDER (state, templateFolder) {
    const newTemplateFolder = new TemplateFolder(templateFolder)
    Vue.set(state.templateFolders, templateFolder['@id'], newTemplateFolder)

    // Afin de permettre de conserver une version à jour des dossiers
    // Il est nécessaire de modifier également leurs modèles associés
    Object.values(state.templates)
      .filter(template => template.folder && (template.folder['@id'] === newTemplateFolder['@id']))
      .forEach(template => Vue.set(template, 'folder', newTemplateFolder))
  },
  SET_IS_LOADING_TEMPLATE (state, isLoadingTemplates) {
    state.isLoadingTemplates = isLoadingTemplates
  },
  START_DRAG_TEMPLATE (state, template) {
    state.draggingTemplate = template
  },
  END_DRAG_TEMPLATE (state) {
    state.draggingTemplate = null
  },
  RESET_TEMPLATE (state) {
    state.templates = {}
  },
  RESET_TEMPLATE_FOLDER (state) {
    state.templateFolders = {}
  },
}

export const actions = {
  async fetchTemplates ({ commit }) {
    commit('RESET_TEMPLATE')

    try {
      commit('SET_IS_LOADING_TEMPLATE', true)
      const { data } = await getFromAPI('/api/document_templates', { enabled: true })
      data[ 'hydra:member' ].forEach(template => commit('SET_TEMPLATE', template))
    } catch (error) {
    }

    commit('SET_IS_LOADING_TEMPLATE', false)
  },
  async fetchTemplateFolders ({ commit }) {
    commit('RESET_TEMPLATE_FOLDER')

    try {
      commit('SET_IS_LOADING_TEMPLATE', true)
      const { data } = await getFromAPI('/api/document_template_folders')
      data[ 'hydra:member' ].forEach(template => commit('SET_TEMPLATE_FOLDER', template))
    } catch (error) {
    }

    commit('SET_IS_LOADING_TEMPLATE', false)
  },
  async fetchOneTemplate ({ commit }, templateId) {
    const { data } = await getFromAPI('/api/document_templates/' + templateId)
    commit('SET_TEMPLATE', data)
  },
  async insertTemplate ({ commit }, template) {
    const templateFolderIri = template.folder?.['@id'] || null
    const { data } = await postToAPI('/api/document_templates', {
      ...template,
      folder: templateFolderIri,
    })
    commit('SET_TEMPLATE', data)
    return new Template(data)
  },
  async updateTemplate ({ commit }, template) {
    const templateFolderIRI = template.folder ? template.folder['@id'] : null
    const { data } = await putToAPI(template['@id'], {
      data: {
        ...template,
        folder: templateFolderIRI,
      },
    })
    commit('SET_TEMPLATE', data)
  },
  async updateTemplateFolder ({ commit }, folder) {
    const { data } = await putToAPI(folder['@id'], { data: folder })
    commit('SET_TEMPLATE_FOLDER', data)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}