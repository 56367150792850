import ApiEntity from '@/models/ApiEntity'
import MssDocumentMetaDataset from '@/modules/secureMessaging/modules/documents/models/MssDocumentMetaDataset'

import { getFromAPI } from '@/services/api'

import { LIFE_CYCLE_REPORTS_EVENTS } from '@/modules/secureMessaging/modules/documents/constants'

const ENTITY_URL = '/api/mss_document_life_cycle_reports'

export default class MssDocumentLifeCycleReport extends ApiEntity {
  constructor (options = {}) {
    super(options)
    this.title = options.title || null
    this.event = options.event || LIFE_CYCLE_REPORTS_EVENTS.UNDETERMINED.value
    this.metaDataset = options.metaDataset ? new MssDocumentMetaDataset(options.metaDataset) : null
    this.effectiveTime = options.effectiveTime || null
  }

  /**
   * Permet de récupérer l'ensemble des logs de documents de la messagerie depuis l'API
   * @returns {MssDocumentLifeCycleReport[]}
   */
  static async fetchAll () {
    const { data: documents } = await getFromAPI(ENTITY_URL)
    return documents['hydra:member'].map(document => new MssDocumentLifeCycleReport(document))
  }

  /**
   * Retourne le texte, l'icône ainsi que la couleur associée à un type d'évènement
   * @returns {{text, icon, color}}
   */
  getEventMetaData () {
    const getMetaData = (text, icon, color) => ({
      text,
      icon,
      color,
    })
    const metaDataFromEvent = {
      [LIFE_CYCLE_REPORTS_EVENTS.CREATE.value]: getMetaData('Document créé', 'file', 'green'),
      [LIFE_CYCLE_REPORTS_EVENTS.DELETE.value]: getMetaData('Document supprimé', 'delete', 'error'),
      [LIFE_CYCLE_REPORTS_EVENTS.UPDATE.value]: getMetaData(`Mise à jour vers la version ${this.metaDataset?.versionNumber}`, 'edit', 'blue'),
      [LIFE_CYCLE_REPORTS_EVENTS.UNDETERMINED.value]: getMetaData('Non déterminé', 'unknown', 'grey'),
    }
    return metaDataFromEvent[this.event]
  }
}