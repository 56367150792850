import ApiEntity from '@/models/ApiEntity'
import Contact from './Contact.js'
import RoleRef from './RoleRef.js'
import PatientMeasureUserSetting from './PatientMeasureUserSetting.js'

export default class User extends ApiEntity {
  constructor (options = {}) {
    super(options)
    this.isAdmin = options.isAdmin || null
    this.username = options.username || null
    this.roleRefs = options.roleRefs
      ? options.roleRefs.map(roleRef => new RoleRef(roleRef))
      : []
    this.patientMeasureUserSettings = options.patientMeasureUserSettings
      ? options.patientMeasureUserSettings.map(
        patientMeasureUserSetting => new PatientMeasureUserSetting(patientMeasureUserSetting),
      ) : []
    this.contact = options.contact ? new Contact(options.contact) : null
  }
}