/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity'
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField'

const keywordEntity = new DocumentEntity('Keyword', 'keywords.xml', 'Contient les mots-clés')

keywordEntity.addField(new DocumentEntityField('label', 'Le label'))
keywordEntity.addField(new DocumentEntityField('color', 'La couleur au format hexadécimal'))
keywordEntity.addField(new DocumentEntityField('description', 'La description'))

export default keywordEntity