<template>
  <section>
    <app-modal-form
      v-if="localIsOpen"
      :is-opened.sync="localIsOpen"
      :letter="consultationLetterDocument"
      :consultation-iri="consultationLetterDocument.consultation"
      :patient-iri="patientIri"
      :patient-ins="patientIns"
      :title="modalTitle"
      :submit-text="modalSubmit"
      :form="form"
      width="98%"
      @submitSuccess="handleLetterDocumentSubmitSuccess"
      @cancel="closeModal"
    >
      <template #actions-left>
        <send-by-mss-option v-if="isDischargeLetter" v-model="sendDocumentBySecureMessaging" :patient="patient" />
      </template>
    </app-modal-form>
    <send-message-modal-form v-if="!! mssMessage" :message="mssMessage" :is-open.sync="isMessagingModalFormOpened" />
  </section>
</template>

<script>
import AppModalForm from '@/components/ui/modal/AppModalForm.vue'
import DischargeLetterForm from '@/modules/patient/forms/staticTemplates/DischargeLetterForm.vue'
import FollowUpLetterForm from '@/modules/patient/forms/staticTemplates/FollowUpLetterForm.vue'
import SendByMssOption from '@/modules/secureMessaging/modules/messaging/components/sendByMssOption/SendByMssOption.vue'
import SendMessageModalForm from '@/modules/secureMessaging/modules/messaging/forms/SendMessageModalForm.vue'

import { getMssMessageForDocument } from '@/modules/secureMessaging/modules/messaging/utils/getMssMessageForDocument'

import ConsultationLettersDocument from '@/modules/patient/models/ConsultationLettersDocument'
import Patient from '@/modules/patient/models/Patient'
import ConsultationDischargeLetterDocument from '@/modules/patient/models/ConsultationDischargeLetterDocument'

export default {
  name: 'ConsultationLettersDocumentModalForm',
  components: {
    AppModalForm,
    SendByMssOption,
    SendMessageModalForm,
  },
  props: {
    consultationLetterDocument: {
      type: ConsultationLettersDocument,
      required: true,
    },
    patient: {
      type: Patient,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isGeneratingDocument: false,
      sendDocumentBySecureMessaging: false,
      isMessagingModalFormOpened: false,
      mssMessage: null,
    }
  },
  computed: {
    patientIri () {
      return this.patient.getIri()
    },
    patientIns () {
      return this.patient.insIdentity
    },
    isEditing () {
      return !! this.consultationLetterDocument['@id']
    },
    isDischargeLetter () {
      return this.consultationLetterDocument instanceof ConsultationDischargeLetterDocument
    },
    localIsOpen: {
      get () {
        return this.isOpen
      },
      set (isOpen) {
        this.$emit('update:is-open', isOpen)
      },
    },
    form () {
      if (this.isDischargeLetter) {
        return DischargeLetterForm
      }
      return FollowUpLetterForm
    },
    modalTitle () {
      const letterLabel = this.consultationLetterDocument.getLabel().toLowerCase()
      if (this.isEditing) {
        return `Modifier une ${letterLabel}`
      }
      return `Nouvelle ${letterLabel}`
    },
    modalSubmit () {
      let baseLabel = 'Enregistrer'
      if (this.isEditing) {
        baseLabel = 'Modifier'
      }
      return this.sendDocumentBySecureMessaging ? baseLabel + ' et envoyer' : baseLabel
    },
  },
  watch: {
    isOpen (isOpen) {
      if (! isOpen) {
        this.sendDocumentBySecureMessaging = false
      }
    },
    isMessagingModalFormOpened (isOpen) {
      // Pour éviter que TinyMCE ne se greffe au mauvais textarea puisque si la modale n'est pas fermée
      // elle contient toujours le WYSIWYG, on ferme la modale
      if (! isOpen) {
        this.closeModal()
      }
    },
  },
  methods: {
    closeModal () {
      this.$emit('update:consultation-letter-document', null)
    },
    closeLetterForm () {
      this.$emit('update:is-open', false)
    },
    async handleLetterDocumentSubmitSuccess (createdLetterDocument) {
      // On enregistre la valeur de "sendDocumentBySecureMessaging" pour éviter qu'elle soit remise à "false" dans le cas ou
      // l'event "health-summary-generated" provoquerait la modification de "isOpen" et donc le reset de "sendDocumentBySecureMessaging"
      const canOpenSendMessageModalForm = this.sendDocumentBySecureMessaging
      this.$emit('submit-success', createdLetterDocument)
      this.closeLetterForm()
      if (canOpenSendMessageModalForm) {
        this.isMessagingModalFormOpened = true
        this.mssMessage = await getMssMessageForDocument(createdLetterDocument, this.patient)
      } else {
        this.closeModal()
      }
    },
  },
}
</script>