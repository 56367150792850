<template>
  <authentication-module-layout :illustration-name="layoutIllustrationName">
    <v-slide-x-transition mode="out-in">
      <router-view class="mx-4" />
    </v-slide-x-transition>
  </authentication-module-layout>
</template>

<script>
import AuthenticationModuleLayout from '@/modules/authentication/layouts/AuthenticationModuleLayout.vue'
import { ROUTE_NAMES } from '@/modules/authentication/constants'

export default {
  name: 'AuthenticationModule',
  components: { AuthenticationModuleLayout },
  computed: {
    layoutIllustrationName () {
      if (this.$route.name === ROUTE_NAMES.RESET_PASSWORD) {
        return 'icon-illustration-reset-password'
      }
      return 'icon-illustration-doctors'
    },
  },
}
</script>