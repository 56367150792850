<template>
  <div>
    <app-modal-form
      :title="tlsiModalMixin_modalFormTitle"
      submit-text="Transmettre"
      :form="ImtiForm"
      :value="patient"
      width="720"
      :is-opened.sync="tlsiModalMixin_isOpen"
      @cancel="tlsiModalMixin_closeModalForm"
      @submitError="tlsiModalMixin_closeModalForm"
      @submitSuccess="handleImtiRequestSuccess"
    />
    <n-dialog
      v-if="shouldResponseModalExists"
      v-test="'imti-result-modal'"
      width="720"
      :title="responsePayload.message.label"
      :is-open.sync="isResponseModalOpen"
    >
      <imti-result-summary :message="responsePayload.message" :practitioner="responsePayload.imt" :beneficiary="responsePayload.beneficiary" />
    </n-dialog>
  </div>
</template>

<script>
import AppModalForm from '@/components/ui/modal/AppModalForm.vue'
import ImtiForm from '@/modules/patient/components/sidebar/tlsi/imti/ImtiForm.vue'
import ImtiResultSummary from '@/modules/patient/components/sidebar/tlsi/imti/ImtiResultSummary.vue'
import NovaTools from '@/nova-tools/NovaTools'

import tlsiModalMixin from '@/modules/patient/components/sidebar/tlsi/tlsiModalMixin'

import Patient from '@/modules/patient/models/Patient'
import TlsiBeneficiary from '@/modules/patient/utils/classes/TlsiBeneficiary'

import { TLSI_TYPES } from '@/modules/patient/constants'
import { getFromAPI } from '@/services/api'
import { mapActions } from 'vuex'

export default {
  name: 'ImtiModalForm',
  components: {
    AppModalForm,
    ImtiResultSummary,
  },
  mixins: [tlsiModalMixin(TLSI_TYPES.IMTI)],
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      ImtiForm,
      isResponseModalOpen: false,
      responsePayload: {
        message: null,
        imt: null,
        beneficiary: null,
      },
      isReferringPhysicianUpdated: false,
    }
  },
  computed: {
    /**
     * Permet de ne pas rendre la modale avec certaines de ces props required à null
     */
    shouldResponseModalExists () {
      return Object.values(this.responsePayload).every(value => !! value)
    },
  },
  methods: {
    ...mapActions('contact', ['setReferringPhysicianByContact']),
    async handleImtiRequestSuccess ({ message, imt, beneficiary, beneficiaryFullName }) {
      this.tlsiModalMixin_closeModalForm()
      if (message.code === '10') {
        NovaTools.notify.error('Code de retour : ' + message.code, { title: message.label })
        return
      }
      if (message.referringPhysicianUpdated) {
        const { data } = await getFromAPI(this.patient['@id'])
        if (data.referringPhysician?.correspondent) {
          await this.setReferringPhysicianByContact({
            patientId: this.patient['@id'],
            referringPhysician: data.referringPhysician,
          })
          this.isReferringPhysicianUpdated = true
        } else {
          this.isReferringPhysicianUpdated = false
        }
      } else {
        this.isReferringPhysicianUpdated = false
      }
      this.isResponseModalOpen = true
      this.responsePayload.message = message
      this.responsePayload.imt = imt
      this.responsePayload.beneficiary = new TlsiBeneficiary({
        ...beneficiary,
        fullName: beneficiaryFullName,
      })
      if (this.isReferringPhysicianUpdated) {
        NovaTools.notify.success('Le médecin traitant a été rattaché au patient avec succès')
      } else {
        NovaTools.notify.error('Le médecin traitant n\'a pas pu être rattaché au patient')
      }
    },
  },
}
</script>