<template>
  <div>
    <n-button
      v-if="canCheckIns"
      v-test="'verif-ins-button'"
      bg-color="primary"
      size="small"
      :disabled="! getHasCpxCard"
      outlined
      label="Vérifier l'INS"
      @click="openIcanopeeModal()"
    />
    <div v-else v-test="'impossible-verif-message'" class="flex-list-2 align-center">
      <app-icon
        icon="close-alt"
        color="error"
        size="24"
      />
      <div>
        <p class="subtitle-3">
          L'identité ne peut pas être vérifiée.
        </p>
        <p>Certains traits stricts sont manquants.</p>
      </div>
    </div>
    <i-canopee-modal
      v-test="'icanopee-modal'"
      :patient="patient"
      :call-type="insCallType"
      :is-open.sync="iCanopeeModalVisible"
      @fetch-result="logAccess"
      @success="onInsCheckSuccess"
      @error="onInsCheckError"
    />
  </div>
</template>

<script>
import ICanopeeModal from '@/modules/patient/components/patientForm/ICanopeeModal.vue'

import Patient from '@/modules/patient/models/Patient'

import { INS_FETCH_METHOD } from '@/modules/patient/constants'
import { mapGetters } from 'vuex'

import NovaTools from '@/nova-tools/NovaTools'

/**
 * Bouton qui permet de lancer une vérification de l'INS d'un patient
 * Ne peut donc être utilisé qu'avec un patient au statut INS qualifié ou récupéré
 */
export default {
  name: 'PatientIdentityVerificationButton',
  components: { ICanopeeModal },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      insCallType: INS_FETCH_METHOD.IDENTITY_CHECK,
      iCanopeeModalVisible: false,
      canCheckIns: true,
    }
  },
  computed: { ...mapGetters('cardReaders', ['getHasCpxCard']) },
  watch: {
    patient: {
      immediate: true,
      deep: true,
      handler () {
        this.canCheckIns = this.patient.haveAllStrictTraits() && this.patient.hasIns()
      },
    },
  },
  methods: {
    openIcanopeeModal () {
      this.iCanopeeModalVisible = true
    },
    closeIcanopeeModal () {
      this.iCanopeeModalVisible = false
    },
    onInsCheckSuccess (event) {
      this.closeIcanopeeModal()
      this.$emit('check-ins', event.identityIsValid)
    },
    onInsCheckError (data) {
      this.closeIcanopeeModal()
      NovaTools.notify.error(data.error, { title: 'Impossible de valider l\'INS' })
      this.$emit('error')
    },
    async logAccess ({ data }) {
      await this.patient.insIdentity.logCall(this.insCallType, {
        patient: this.patient['@id'],
        trace: { request: data },
      })
    },
  },
}
</script>