<template>
  <div>
    <portal-target v-for="portalId in portals" :key="portalId" :name="portalId" />
  </div>
</template>

<script>
export default {
  name: 'NPortalTarget',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    portals () {
      return this.$portals.getPortals(this.name)
    },
  },
}
</script>