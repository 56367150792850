<template>
  <app-form :submit-method="submit">
    <letters-form-fields
      :patient-iri="patientIri"
      :patient-ins="patientIns"
      :recipients.sync="dischargeLetterDocument.recipients"
      :content.sync="dischargeLetterDocument.content"
      :generate-preview-content="generatePreviewContent"
    />
  </app-form>
</template>

<script>
import AppForm from '@/components/ui/form/AppForm.vue'
import LettersFormFields from '@/modules/patient/forms/staticTemplates/LettersFormFields.vue'

import { mapActions } from 'vuex'
import { postToAPI } from '@/services/api'

import ConsultationDischargeLetterDocument from '@/modules/patient/models/ConsultationDischargeLetterDocument'
import PatientInsIdentity from '@/modules/patient/models/PatientInsIdentity'
import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'DischargeLetterForm',
  components: {
    AppForm,
    LettersFormFields,
  },
  props: {
    letter: {
      type: ConsultationDischargeLetterDocument,
      required: true,
    },
    patientIri: {
      type: String,
      required: true,
    },
    patientIns: {
      type: PatientInsIdentity,
      required: true,
    },
  },
  data () {
    return { dischargeLetterDocument: null }
  },
  computed: {
    isEditing () {
      return !! this.letter['@id']
    },
  },
  watch: {
    letter: {
      immediate: true,
      handler () {
        this.dischargeLetterDocument = new ConsultationDischargeLetterDocument({ ...this.letter })
      },
    },
  },
  methods: {
    ...mapActions('patient', ['insertConsultationDischargeLetter', 'updateConsultationDischargeLetter']),
    async submit () {
      let successMessage
      let letterUpdated
      const newLetter = new ConsultationDischargeLetterDocument({
        ...this.dischargeLetterDocument,
        recipients: this.dischargeLetterDocument.recipients.map(correspondent => correspondent['@id']),
      })
      if (this.isEditing) {
        letterUpdated = await this.updateConsultationDischargeLetter(newLetter)
        successMessage = 'La lettre de liaison a été modifiée avec succès'
      } else {
        letterUpdated = await this.insertConsultationDischargeLetter(newLetter)
        successMessage = 'La lettre de liaison a été générée avec succès'
      }
      NovaTools.notify.success(successMessage)
      return letterUpdated
    },
    async generatePreviewContent () {
      const { data: consultationDischargeLetterPreview } = await postToAPI(
        '/api/consultation_discharge_letter_previews',
        {
          ...this.dischargeLetterDocument,
          recipients: this.dischargeLetterDocument.recipients.map(correspondent => correspondent['@id']),
        })
      return consultationDischargeLetterPreview.dischargeLetterBase64FileContent ?? null
    },
  },
}
</script>