import StorePlugin from '@novalys/src/classes/StorePlugin'

const REFRESH_MESSAGES_DELAY_MINUTES = 2

export default class RefresherService extends StorePlugin {
  #refresherInterval

  constructor (store) {
    super(store)
  }

  async refreshMessages () {
    await this.getStore().dispatch('secureMessaging/refresh')
  }

  async start () {
    await this.refreshMessages()
    this.#refresherInterval = setInterval(
      this.refreshMessages.bind(this),
      60_000 * REFRESH_MESSAGES_DELAY_MINUTES,
    )
  }

  async stop () {
    clearInterval(this.#refresherInterval)
  }
}