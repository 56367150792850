<template>
  <article class="dashboard-events-timeline-appointment">
    <div v-if="isMobile" class="dashboard-events-timeline-appointment__mobile-headline" data-test="mobile-headline">
      <span>{{ appointment.getStartTime() }}</span>
      <start-consultation-button
        :show-label="false"
        :patient="appointment.patient"
        back-route="homePage"
        data-test="start-consultation-button"
      />
    </div>
    <patient-details
      class="dashboard-events-timeline-appointment__patient"
      data-test="patient-details"
      :patient="appointment.patient"
      :align-content-top="! isMobile"
      redirect-to-patient-file
      :show-anonymized-identifier="false"
      :small="isMobile"
    >
      <template v-if="! isMobile" #sub-content>
        <dashboard-events-timeline-appointment-details :appointment="appointment" class="mt-1" data-test="appointment-sub-content" />
      </template>
    </patient-details>
    <dashboard-events-timeline-appointment-details v-if="isMobile" :appointment="appointment" data-test="appointment-sub-content" />
    <start-consultation-button
      v-if="! isMobile"
      :show-label="false"
      :patient="appointment.patient"
      back-route="homePage"
      class="dashboard-events-timeline-appointment__start-consultation-button"
      data-test="start-consultation-button"
    />
  </article>
</template>

<script>
import DashboardEventsTimelineAppointmentDetails from '@/modules/users/components/dashboard/events/timeline/appointment/DashboardEventsTimelineAppointmentDetails.vue'
import PatientDetails from '@/modules/patient/components/PatientDetails.vue'
import StartConsultationButton from '@/modules/patient/components/patientFile/StartConsultationButton.vue'

import { mapGetters } from 'vuex'

import Appointment from '@/modules/agenda/models/events/Appointment'

export default {
  name: 'DashboardEventsTimelineAppointment',
  components: {
    StartConsultationButton,
    PatientDetails,
    DashboardEventsTimelineAppointmentDetails,
  },
  props: {
    appointment: {
      type: Appointment,
      required: true,
    },
  },
  computed: { ...mapGetters('app', ['isMobile']) },
}
</script>

<style lang="scss" scoped>
.dashboard-events-timeline-appointment {
  display: flex;
  flex-direction: column;

  @include media-md {
    flex-direction: row;
  }

  &__patient {
    flex-grow: 1;
    margin-bottom: map-get($spacers, 3);

    @include media-md {
      margin-bottom: 0;
    }
  }

  &__mobile-headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: map-get($spacers, 2);
    font-size: 16px;
    font-weight: 600;
    color: var(--v-content-base);
    align-self: flex-start;
  }
}
</style>