<template>
  <div class="allergy-autocomplete">
    <section ref="widget" />
  </div>
</template>

<script>
import widgetMixin from '@/components/lap/synapse-widgets/mixins/widget'
import autocompleteMixin from '@/components/lap/synapse-widgets/mixins/autocomplete'

export default {
  name: 'AllergyAutocomplete',
  mixins: [widgetMixin, autocompleteMixin],
  async mounted () {
    await this.initWidget()
    this.widget.setCallback('onSelectAllergy', (entity) => {
      this.$emit('input', entity)
    })
    this.focus()
  },
}
</script>