/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity'
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField'

const lppProductEntity = new DocumentEntity('LppProduct', 'lppProducts.xml', 'Contient les produits et prestations')

lppProductEntity.addField(new DocumentEntityField('code', 'Le code'))
lppProductEntity.addField(new DocumentEntityField('codeLabel', 'Le label'))

export default lppProductEntity