<template>
  <div class="app-file-preview-placeholder">
    <app-file-icon :type="type" :size="iconSize" />
  </div>
</template>

<script>
import AppFileIcon from '@/components/ui/icon/AppFileIcon.vue'
/**
 * Le plaecholder du composant de prévisualisation de fichier de l'application
 */
export default {
  name: 'AppFilePreviewPlaceholder',
  components: { AppFileIcon },
  props: {
    /**
     * Le type du placeholder
     */
    type: {
      type: String,
      default: null,
    },
    iconSize: {
      type: String,
      default: '16',
    },
  },
}
</script>

<style scoped>
  .app-file-preview-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--v-secondary-lighten5);
  }
</style>