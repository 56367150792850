<template>
  <fieldset class="patient-antecedent-fieldset">
    <app-row dense class="patient-antecedent-fieldset__common">
      <app-col cols="12" md="5">
        <app-select
          v-model="type"
          v-test="'antecedent-type-field'"
          :items="availableAntecedentTypes"
          label="Type"
          hide-errors="auto"
        />
        <patient-antecedent-family-relation
          v-if="isFamilyAntecedent"
          v-model="familyRelationship"
          :rules="!! cim10Entity ? 'required' : null"
          :index="index"
        />
      </app-col>
      <app-col cols="12" md="7">
        <div class="d-flex flex-grow-1 pt-3">
          <cim10-entity-autocomplete
            v-model="cim10Entity"
            v-test="'antecedent-content-field'"
            label="Sélectionnez un antécédent"
            class="flex-grow-1"
          />
          <slot name="prepend" />
        </div>
      </app-col>
    </app-row>
    <app-row dense class="patient-antecedent-fieldset__common">
      <app-textarea-field
        v-model="note"
        v-test="'pathology-note'"
        label="Commentaire (optionnel)"
        class="mt-0"
        hide-errors="auto"
        rows="1"
        auto-grow
      />
    </app-row>
  </fieldset>
</template>

<script>
import AppSelect from '@/components/ui/form/AppSelect.vue'
import Cim10EntityAutocomplete from '@/components/ui/form/Cim10EntityAutocomplete.vue'
import PatientAntecedentFamilyRelation from '@/modules/patient/components/patientFile/riskFactors/antecedents/PatientAntecedentFamilyRelation.vue'

import { ANTECEDENT_TYPES } from '@/modules/patient/constants'

import AppTextareaField from '@/components/ui/form/AppTextareaField.vue'
import Cim10ApiEntity from '@/models/Cim10ApiEntity'
import PatientAntecedent from '@/modules/patient/models/PatientAntecedent'
import PatientFamilyAntecedent from '@/modules/patient/models/PatientFamilyAntecedent'

export default {
  name: 'PatientAntecedentFieldset',
  components: {
    AppTextareaField,
    AppSelect,
    Cim10EntityAutocomplete,
    PatientAntecedentFamilyRelation,
  },
  props: {
    antecedent: {
      type: PatientAntecedent,
      required: true,
    },
    patientIri: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      type: null,
      familyRelationship: null,
      cim10Entity: new Cim10ApiEntity(),
      note: null,
    }
  },
  computed: {
    availableAntecedentTypes () {
      return Object.values(ANTECEDENT_TYPES).filter(type => type.value !== ANTECEDENT_TYPES.NOT_CODIFIED.value)
    },
    isFamilyAntecedent () {
      return this.type === ANTECEDENT_TYPES.FAMILY.value
    },
  },
  watch: {
    antecedent: {
      immediate: true,
      handler (antecedent) {
        this.type = antecedent.type
        this.familyRelationship = antecedent.familyRelationship
        this.cim10Entity = this.cim10Entity ?? new Cim10ApiEntity()
        this.cim10Entity.icd10Code = antecedent.icd10Code
        this.cim10Entity.label = antecedent.label
        this.cim10Entity.theriaqueLink = antecedent.theriaqueLink,
        this.note = antecedent.note
      },
    },
    type () {
      this.onFieldChange()
    },
    familyRelationship () {
      this.onFieldChange()
    },
    'cim10Entity.icd10Code' () {
      this.onFieldChange()
    },
    note () {
      this.onFieldChange()
    },
  },
  methods: {
    onFieldChange () {
      this.$emit('update:antecedent', this.getSerializedAntecedent())
    },
    getSerializedAntecedent () {
      const Model = this.isFamilyAntecedent ? PatientFamilyAntecedent : PatientAntecedent
      return new Model({
        ...this.antecedent,
        icd10Code: this.cim10Entity?.icd10Code,
        label: this.cim10Entity?.label,
        theriaqueLink: this.cim10Entity?.theriaqueLink,
        type: this.type,
        familyRelationship: this.familyRelationship,
        patient: this.patientIri,
        note: this.note,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.patient-antecedent-fieldset {
  &:not(:last-child) {
    padding-bottom: map-get($spacers, 4);
  }

  &__common {
    align-items: end;
  }
}

::v-deep {
  .synapse-autocomplete-wrapper__field__input {
    .options {
      width: 281px !important;
    }
  }
  .synapse-autocomplete-wrapper__field__input--mobile {
    .options {
      width: 80% !important;
    }
  }
}
</style>