import PrescriptionLine from '@/modules/patient/models/prescriptionLine/PrescriptionLine'

export default class PrescriptionHealthProductLine extends PrescriptionLine {
  constructor (options = {}) {
    super(options)

    this.comment = options.comment || null
    this.packageCount = options.packageCount || null
  }

  getComment () {
    return this.comment
  }
}