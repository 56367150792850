import { RESET_MUTATION } from '@/utils/functions/store'

import { getFromAPI } from '@/services/api'

import { ALERT_TYPES } from '@novalys/src/constants/alertTypes'

import GrandRegime from '@/models/GrandRegime'
import NovaTools from '@/nova-tools/NovaTools'

export const state = () =>({
  isMobile: false,
  specialities: [],
  grandRegimes: [],
  appDatasStatus: null,
  appDmpConnectJsStatus: null,
  isCpxCardReaderIconVisible: true,
  pincodeVisible: false,
})

export const getters = {
  isMobile: state => state.isMobile,
  getGrandRegimes: state => state.grandRegimes,
  getSpecialities: state => state.specialities,
  getIsCpxCardReaderIconVisible: state => state.isCpxCardReaderIconVisible,
  getFamilyRelations: state => state.familyRelations,
}

export const mutations = {
  /**
   * @deprecated Utiliser le plugin notify de novalys
   */
  SET_SNACK (state, { color, message, timeout }) {
    const type = Object.values(ALERT_TYPES).map(alert => alert.name).indexOf(color) >= 0 ? color : ALERT_TYPES.SUCCESS.name
    if (timeout) {
      NovaTools.notify[type](message, { timeout })
      return
    }
    NovaTools.notify[type](message)
  },
  SET_MOBILE (state, value) {
    state.isMobile = value
  },
  SET_SPECIALITIES (state, specialities) {
    state.specialities = specialities
  },
  SET_GRAND_REGIMES (state, grandRegimes) {
    state.grandRegimes = grandRegimes.map(grandRegime => new GrandRegime(grandRegime))
  },
  SET_FAMILY_RELATIONS (state, familyRelations) {
    state.familyRelations = familyRelations
  },
  SET_APP_DATAS_STATUS (state, status) {
    state.appDatasStatus = status
  },
  SET_CPX_CARD_READER_ICON_VISIBLE (state, isVisible) {
    state.isCpxCardReaderIconVisible = isVisible
  },
  [RESET_MUTATION] (state) {
    state.appDatasStatus = null
  },
}
export const actions = {
  async fetchMedicalSpecialityRefs ({ commit }) {
    const { data } = await getFromAPI('/api/medical_specialty_refs')
    commit('SET_SPECIALITIES', data['hydra:member'])
  },

  async fetchGrandRegimeRefs ({ commit }) {
    const { data } = await getFromAPI('/api/grand_regime_refs')
    commit('SET_GRAND_REGIMES', data['hydra:member'])
  },

  async fetchFamilyRelationsRefs ({ commit }) {
    const { data } = await getFromAPI('/api/family_relationship_refs') // TO RENAME
    commit('SET_FAMILY_RELATIONS', data['hydra:member'])
  },

  async getAppDatas ({ dispatch, commit, state, rootGetters }) {
    if (! rootGetters['auth/isLogged'] || state.appDatasStatus === 'success') {
      return
    }

    Promise.all([
      dispatch('fetchMedicalSpecialityRefs'),
      dispatch('fetchGrandRegimeRefs'),
      dispatch('fetchFamilyRelationsRefs'),
      dispatch('users/fetchUsers',
        null,
        { root: true }),
    ]).then(() => {
      commit('SET_APP_DATAS_STATUS', 'success')
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}