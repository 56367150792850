<template>
  <validation-provider ref="validator" slim>
    <div v-if="!isLoading">
      <barcode-reader
        v-test="'qr-code-reader'"
        :barcode-format="BarcodeFormat.QR_CODE"
        @scan-success="authenticateViaApCv"
      />
      <app-form v-if="showFakeCodeField" class="ap-cv-form__fake-code-form">
        <app-text-field v-model="fakeCode" />
        <n-button
          type="submit"
          label="Fake Scan"
          color="primary"
          @click="authenticateViaApCv()"
        />
      </app-form>
    </div>
    <div v-else class="ap-cv-form--loading">
      <app-circular-loader :size="50" :width="5" class="ap-cv-form--loading__loader" />
      <p class="ap-cv-form--loading__label">
        Chargement...
      </p>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import BarcodeReader from '@/components/ui/barcodeReader/BarcodeReader.vue'
import AppForm from '@/components/ui/form/AppForm.vue'
import AppTextField from '@/components/ui/form/AppTextField.vue'
import AppCircularLoader from '@/components/ui/loaders/AppCircularLoader.vue'

import { BarcodeFormat } from '@zxing/library'
import { mapGetters } from 'vuex'

import Patient from '@/modules/patient/models/Patient'

import { CARD_TYPES } from '@/services/card-reader/constants/cardTypes'
import ApCv from './models/ApCv'

import NovaTools from '@/nova-tools/NovaTools'
import { getEnv } from '@/utils/functions/env.js'

export default {
  name: 'ApCvForm',
  components: {
    ValidationProvider,
    AppForm,
    BarcodeReader,
    AppTextField,
    AppCircularLoader,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      showFakeCodeField: getEnv('NODE_ENV') === 'development',
      apCvInstance: null,
      BarcodeFormat,
      fakeCode: null,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('cardReaders', ['getCardContent', 'getPincode']),
    practitioner () {
      return this.getCardContent(CARD_TYPES.CPX)
    },
    pincode () {
      return this.getPincode
    },
  },
  mounted () {
    this.apCvInstance = new ApCv({
      patient: this.patient,
      practitioner: this.practitioner,
    })
  },
  async destroyed () {
    await this.apCvInstance.destroy()
  },
  methods: {
    async authenticateViaApCv (apCvProfile) {
      try {
        if (! apCvProfile && this.showFakeCodeField) {
          apCvProfile = this.fakeCode
        }

        if (! apCvProfile) {
          throw new Error('Aucun QR code scanné')
        }

        this.isLoading = true
        this.$refs.validator.applyResult({
          valid: false,
          errors: ['Veuillez patienter'],
        })
        const { insureds, data } = await this.apCvInstance.authenticate(apCvProfile, this.pincode)

        this.$emit('submit-success', {
          insureds,
          data,
        })

      } catch (error) {
        NovaTools.notify.error(`Une erreur est survenue pendant l'authentification apCV : ${error.message}`)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.ap-cv-form {
  &__fake-code-form {
    display: flex;
    align-items: baseline;
  }

  &--loading {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: map-get($spacers, 6);
    height: 100%;

    &__loader {
      min-height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__label {
      font-size: 16px;
      font-weight: 600;
      max-width: 400px;
    }
  }
}

</style>