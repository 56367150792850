<template>
  <div class="flex-list-6 flex-column">
    <div v-test="'legislation-text'" class="flex-list-2 flex-column secondary--text">
      <p>
        Conformément à l'article L 162-5-3 du code de la sécurité sociale, l'assuré(e) de 16 ans et plus ou pour les ayants droit âgés de moins de seize ans, l'un au moins des deux parents ou le titulaire de l'autorité parentale indique à son organisme gestionnaire de régime de l'assurance maladie le nom du médecin traitant qu'il a choisi avec l'accord de celui-ci.
      </p>
      <p>
        La loi 78.17 modifiée relative à l'informatique, aux fichiers et aux libertés s'applique aux réponses faites sur ce formulaire. Elle garantit un droit d'accès et de rectification pour les données vous concernant ainsi que pour celles de l'assuré(e).
      </p>
      <p>
        La présente déclaration de choix de médecin traitant annule et remplace une éventuelle déclaration réalisée antérieurement.
      </p>
    </div>
    <n-divider :spacing="0" />
    <beneficiary-selector
      :patient="patient"
      :insureds="insureds"
      :selected-beneficiary="selectedBeneficiary"
      @update:selected-beneficiary="selectedBeneficiary => $emit('update:selected-beneficiary', selectedBeneficiary)"
    />
    <app-row>
      <app-col cols="12" md="6">
        <h2 class="subtitle-3 mb-1">
          Médecin traitant
        </h2>
        <div class="flex-column flex-list-1">
          <app-dataset-item v-test="'practitioner-full-name'" label="Nom complet" :value="practitionerFullName" />
          <app-dataset-item v-test="'practitioner-rpps'" label="RPPS" :value="practitionerRpps" />
        </div>
      </app-col>
    </app-row>
    <n-divider :spacing="0" />
    <div class="flex-column flex-list-2">
      <app-checkbox
        v-model="patientConsent"
        v-test="'patient-consent-checkbox'"
        rules="checked"
        label="J'ai informé mon patient de la réalisation de la présente déclaration par mes soins et il est averti qu'il peut modifier son choix à tout moment"
      />
      <app-checkbox
        v-model="practitionerApproval"
        v-test="'practitioner-approval-checkbox'"
        rules="checked"
        label="J'accepte d'être le médecin traitant du bénéficiaire indiqué ci-dessus et mon patient ou le titulaire de l'autorité parentale accepte que j'indique son choix en transmettant cette déclaration à la CPAM pour son compte."
      />
    </div>
  </div>
</template>

<script>
import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue'
import AppCheckbox from '@/components/ui/form/AppCheckbox.vue'

import BeneficiarySelector from '@/modules/patient/components/sidebar/tlsi/BeneficiarySelector.vue'

import Patient from '@/modules/patient/models/Patient'
import { getCivilState } from '@/utils/functions/persons'

/**
 * Permet à partir d'une liste d'assuré d'en choisir un
 */
export default {
  name: 'DmtiBeneficiarySelector',
  components: {
    AppCheckbox,
    BeneficiarySelector,
    AppDatasetItem,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
    practitioner: {
      type: Object,
      required: true,
    },
    insureds: {
      type: Array,
      required: true,
    },
    selectedBeneficiary: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      patientConsent: false,
      practitionerApproval: false,
    }
  },
  computed: {
    practitionerRpps () {
      return this.practitioner.s_internalId
    },
    practitionerFullName () {
      return getCivilState({
        firstNames: this.practitioner?.s_given,
        familyName: this.practitioner?.s_name,
      })
    },
  },
  watch: {
    selectedBeneficiary () {
      this.patientConsent = false
      this.practitionerApproval = false
    },
  },
}
</script>