<template>
  <section>
    <div
      v-if="patient.archived"
      v-test="'archived-patient-tag'"
      class="error lighten-5 error--text flex-list-2 px-5 py-3 rounded-lg"
      :class="{'mb-2': patient.isDeceased, 'mb-5': !patient.isDeceased }"
    >
      <app-icon icon="archive" color="error" />
      <span class="subtitle-3">Patient archivé</span>
    </div>
    <div v-if="patient.isDeceased" v-test="'deceased-patient-tag'" class="secondary lighten-4 secondary--text flex-list-2 mb-5 px-5 py-3 rounded-lg">
      <app-icon icon="warning" color="secondary" />
      <span class="subtitle-3">Patient décédé</span>
    </div>
    <administrative-resume
      v-test="'administrative-resume'"
      :patient="patient"
      class="pb-4"
    />
    <app-sidebar-panel title="Remarques" is-open>
      <medical-note ref="medicalNote" v-test="'medical-note'" :patient="patient" />
    </app-sidebar-panel>
    <app-sidebar-panel title="Pathologies" is-open @add="$refs.pathology.openForm()">
      <pathology-listing ref="pathology" v-test="'pathology-listing'" :patient="patient" />
    </app-sidebar-panel>
    <app-sidebar-panel title="Traitements en cours" is-open>
      <ongoing-medication-listing v-test="'ongoing-medication-listing'" :patient="patient" />
    </app-sidebar-panel>
    <app-sidebar-panel title="Données de suivi" is-open>
      <biometric-measures v-test="'biometric-measures-listing'" :patient="patient" />
    </app-sidebar-panel>
    <app-sidebar-panel title="Facteurs de risques" is-open>
      <drug-allergies-listing v-test="'drug-allergies-listing'" :patient="patient" />
      <n-divider :spacing="0" />
      <non-drug-allergies-listing v-test="'non-drug-allergies-listing'" :patient="patient" />
      <n-divider :spacing="0" />
      <patient-antecedents-listing v-test="'antecedents-listing'" :patient="patient" />
      <n-divider :spacing="0" />
      <habitus-risk-factors-listing v-test="'habitus-risk-factors-listing'" :patient="patient" />
      <n-divider :spacing="0" />
      <professional-risk-factors-listing v-test="'professional-risk-factors-listing'" :patient="patient" />
    </app-sidebar-panel>
    <app-sidebar-panel title="Informations médicales" is-open>
      <patient-medical-dataset v-test="'patient-medical-dataset'" :patient="patient" />
      <female-medical-dataset
        v-if="femaleMedicalDataset"
        v-test="'female-patient-medical-dataset'"
        :female-medical-dataset="femaleMedicalDataset"
      />
    </app-sidebar-panel>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AppSidebarPanel from '@/components/ui/sidebarPanel/AppSidebarPanel.vue'
import AdministrativeResume from '@/modules/patient/components/patientFile/administrativeResume/AdministrativeResume.vue'
import BiometricMeasures from '@/modules/patient/components/patientFile/measures/BiometricMeasures.vue'
import DrugAllergiesListing from '@/modules/patient/components/patientFile/riskFactors/drugAllergies/DrugAllergiesListing.vue'
import FemaleMedicalDataset from '@/modules/patient/components/patientFile/femaleMedicalDataset/FemaleMedicalDataset.vue'
import OngoingMedicationListing from '@/modules/patient/components/patientFile/ongoingMedications/OngoingMedicationListing.vue'
import PatientMedicalDataset from '@/modules/patient/components/patientFile/medicalDataset/PatientMedicalDataset.vue'
import PatientAntecedentsListing from '@/modules/patient/components/patientFile/riskFactors/antecedents/PatientAntecedentsListing.vue'
import PathologyListing from '@/modules/patient/components/patientFile/pathologies/PathologyListing.vue'
import NonDrugAllergiesListing from '@/modules/patient/components/patientFile/riskFactors/nonDrugAllergies/NonDrugAllergiesListing.vue'
import MedicalNote from '@/modules/patient/components/patientFile/medicalNote/MedicalNote'

import Patient from '@/modules/patient/models/Patient'
import HabitusRiskFactorsListing from '@/modules/patient/components/patientFile/riskFactors/habitus/HabitusRiskFactorsListing'
import ProfessionalRiskFactorsListing from '@/modules/patient/components/patientFile/riskFactors/professional/ProfessionalRiskFactorsListing'

export default {
  name: 'PatientSidebar',
  components: {
    HabitusRiskFactorsListing,
    AppSidebarPanel,
    OngoingMedicationListing,
    NonDrugAllergiesListing,
    DrugAllergiesListing,
    PatientMedicalDataset,
    PatientAntecedentsListing,
    PathologyListing,
    BiometricMeasures,
    AdministrativeResume,
    FemaleMedicalDataset,
    MedicalNote,
    ProfessionalRiskFactorsListing,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  computed: {
    ...mapGetters('patient', ['getFemaleMedicalDataset']),
    femaleMedicalDataset () {
      return this.getFemaleMedicalDataset(this.patient.getUuid())
    },
  },
  watch: {
    'patient.femaleMedicalDataset': {
      immediate: true,
      async handler () {
        if (this.patient.femaleMedicalDataset) {
          this.fetchFemaleMedicalDataset(this.patient)
        }
      },
    },
  },
  methods: { ...mapActions('patient', ['fetchFemaleMedicalDataset']) },
}
</script>