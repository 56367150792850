<template>
  <app-panel title="Dégradations d'identité" :quantity="items.length > 0 ? items.length : null" :actions="actions">
    <div class="mx-n4">
      <app-data-table
        :headers="headers"
        :items="items"
        :loading="isLoading"
        pageable
      >
        <template #[`item.patientCivilState`]="{ item }">
          <patient-details
            class="py-2"
            :patient="item.patient"
            :show-pictograms="false"
            redirect-to-patient-file
            :show-anonymized-identifier="false"
            :show-keywords="false"
            show-birth-informations
          />
        </template>
        <template #[`item.createdAt`]="{ item }">
          {{ getFormattedCreatedAt(item.createdAt) }}
        </template>
      </app-data-table>
    </div>
  </app-panel>
</template>

<script>
import AppDataTable from '@/components/ui/tables/AppDataTable.vue'
import AppPanel from '@/components/ui/panel/AppPanel.vue'
import PatientDetails from '@/modules/patient/components/PatientDetails.vue'

import { format } from '@/utils/functions/dates'
import { getFromAPI } from '@/services/api'

import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'
import PatientInsDemotion from '@/modules/patient/models/PatientInsDemotion'

const MAX_DISPLAYABLE_ITEMS_COUNT = 4

export default {
  name: 'InsDemotionPanel',
  components: {
    AppPanel,
    AppDataTable,
    PatientDetails,
  },
  data () {
    return {
      MAX_DISPLAYABLE_ITEMS_COUNT,
      headers: [
        {
          text: 'Patient',
          value: 'patientCivilState',
        },
        {
          text: 'Date de modification',
          value: 'createdAt',
          cellClass: 'secondary--text',
        },
      ],
      items: [],
      isLoading: false,
    }
  },
  computed: {
    actions () {
      return {
        refresh: new ActionMenuItem('undo', 'Rafraîchir la liste',
          async () => {
            if (this.isLoading) {
              return
            }
            try {
              this.isLoading = true
              await this.fetchInsDemotion()
            } finally {
              this.isLoading = false
            }
          },
          { loading: this.isLoading },
        ),
      }
    },
  },
  created () {
    this.initInsDemotion()
  },
  methods: {
    async fetchInsDemotion () {
      const { data } = await getFromAPI('/api/patient_ins_demotions')
      this.items = data['hydra:member'].map(patientInsDemotion => new PatientInsDemotion(patientInsDemotion))
    },
    async initInsDemotion () {
      this.isLoading = true
      try {
        await this.fetchInsDemotion()
      } finally {
        this.isLoading = false
      }
    },
    getFormattedCreatedAt (createdAt) {
      return format(createdAt, 'dd MMM yyyy à HH:mm')
    },
    getFormattedBirthDate (birthDate) {
      return format(birthDate, 'dd/MM/yyyy')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .patient-details {
    &__civil-state-link {
      font-size: 13px;
    }
  }
}
</style>