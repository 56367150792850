<template>
  <div data-test="app-link" @click="click">
    <n-tooltip v-slot="{ on }" :disabled="!tooltip" :text="tooltip">
      <div
        v-bind="buttonAttrs"
        data-test="app-link-div"
        v-on="on"
      >
        <app-icon
          v-if="icon"
          :color="iconColor ? iconColor : color"
          data-test="app-link-icon"
          :icon="icon"
        />
        <slot />
      </div>
    </n-tooltip>
  </div>
</template>

<script>
/**
 * Composant de Lien cliquable de l'application
 */
export default {
  name: 'AppLink',
  props: {
    /**
     * Un icone à afficher en début de lien
     */
    icon: {
      type: String,
      default: null,
    },
    /**
     * La route vers laquelle rediriger
     * En savoir plus sur le formatage des routes @see {@link https://router.vuejs.org/api/#to}
     */
    to: {
      type: Object,
      default: null,
    },
    /**
     * La couleur du lien
     * @default null
     */
    color: {
      type: String,
      default: 'primary',
    },
    /**
     * La couleur de l\'icône du lien
     * @default null
     */
    iconColor: {
      type: String,
      default: 'primary',
    },
    /**
     * Permet l'affichage d'un tooltip
     * @default null
     */
    tooltip: {
      type: String,
      default: null,
    },
    /**
     * Permet de rendre le composant inactif
     * @default false
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonAttrs () {
      return {
        class: `app-link ${this.color}--text ${this.isDisabled ? 'app-link--disabled' : ''}`,
        role: 'link',
      }
    },
  },
  methods: {
    click () {
      if(! this.isDisabled) {
        this.$emit('click')
        if (this.to) {
          this.$router.push(this.to)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .app-link {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    color: var(--v-primary-base);
    text-decoration: none;
    user-select: none;

    &--disabled {
      cursor: default;
      font-weight: normal;
      opacity: 0.5;
    }

    &::v-deep {
      .v-icon {
        font-size: inherit;
        margin-right: 8px;
      }
    }
  }
</style>