<template>
  <validation-observer v-slot="formValidation" ref="validationObserver" slim>
    <component :is="wrapper" v-bind="wrapperProps">
      <slot v-bind="{ formValidation, isSubmitting, localValue, formId, validationObserver: $refs.validationObserver }" />
    </component>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import violationsMixin from '@/mixins/violations'

import { cloneDeep } from 'lodash'

let GLOBAL_CURRENT_FORM_COUNT = 1

export default {
  name: 'AppFormWrapper',
  components: { ValidationObserver },
  mixins: [violationsMixin],
  props: {
    /**
     * Composant ou balise qui wrap le formulaire
     * @default "div"
     */
    wrapper: {
      type: [Function, String],
      default: 'div',
    },
    /**
     * Propriétés ($props) à transmettre au composant wrapper
     */
    wrapperProps: {
      type: Object,
      default: () => {},
    },
    /**
     * le formulaire du wrapper
     */
    form: {
      type: [Object, Function],
      default: null,
    },
    /**
     * Modèle ou groupe d'entité utilisé pour remplir le formulaire
     */
    value: {
      type: [Object, Array],
      default: null,
    },
    /**
     * Défini s'il faut créer une copie locale de la valeur.
     * Cela permet la sécurisation des champs originaux qui ne seront alors pas directement édités.
     * @default true
     */
    useLocalCopy: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      localValue: null,
      isSubmitting: false,
      currentFormId: 0,
    }
  },
  computed: {
    formId () {
      return `form-${this.currentFormId}`
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (this.form?.props?.value?.type === Array) {
          this.localValue = value ? value : this.form.props.value.default?.()
        } else {
          let Constructor
          if (value) {
            Constructor = value?.constructor
          }
          if ((!! Constructor || Constructor !== 'Object') && this.form?.props?.value) {
            Constructor = new this.form.props.value.type().constructor
          }
          const isValidConstructor = Constructor && (Constructor.name !== 'Object')
          this.localValue = this.useLocalCopy && isValidConstructor ? new Constructor(cloneDeep(value || {})) : value
        }

        this.$nextTick(() => {
          this.$refs.validationObserver?.reset()
        })
      },
    },
  },
  created () {
    this.currentFormId = GLOBAL_CURRENT_FORM_COUNT
    GLOBAL_CURRENT_FORM_COUNT ++
  },
  methods: {
    async submitForm (submitMethod) {
      this.isSubmitting = true

      try {
        const submitted = await submitMethod()
        return Promise.resolve(submitted)
      } catch (error) {
        this.setViolations(error, this.$refs.validationObserver)
        return Promise.reject(error)
      } finally {
        this.isSubmitting = false
      }
    },
  },
}
</script>