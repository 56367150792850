<template>
  <app-form :submit-method="submit" class="referring-physician-form">
    <correspondent-input
      :disabled="disabledInput"
      label="Recherchez dans l'annuaire santé"
      url="api/new_health_professionals"
      @change="updateReferringPhysician"
    />
    <correspondent-form-item
      v-if="correspondent"
      v-test="'user-referring-correspondent-item'"
      :correspondent="correspondent"
      class="mt-5"
      @remove="removeUpdatedReferringPhysician"
    />
    <app-switch
      v-model="isUserReferring"
      label="Vous êtes le médecin traitant"
      dense
      :disabled="disabledSwitch"
      class="mt-5"
    />
  </app-form>
</template>

<script>
import AppForm from '@/components/ui/form/AppForm.vue'
import CorrespondentInput
  from '@/modules/patient/modules/patientFile/modules/correspondents/components/correspondentsPanel/forms/CorrespondentInput.vue'
import CorrespondentFormItem
  from '@/modules/patient/modules/patientFile/modules/correspondents/components/correspondentsPanel/forms/CorrespondentFormItem.vue'
import NovaTools from '@/nova-tools/NovaTools'
import AppSwitch from '@/components/ui/form/switch/AppSwitch.vue'

import Patient from '@/modules/patient/models/Patient'
import Correspondent from '@/modules/contact/models/NewHealthProfessional'

import { putToAPI } from '@/services/api'
import { mapGetters } from 'vuex'

export default {
  name: 'ReferringPhysicianForm',
  components: {
    AppSwitch,
    CorrespondentFormItem,
    CorrespondentInput,
    AppForm,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
    value: {
      type: Correspondent,
      required: true,
    },
  },
  data () {
    return {
      correspondent: null,
      rppsSelected: null,
      isUserReferring: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUser']),
    userContact () {
      return this.getCurrentUser.contact
    },
    disabledInput () {
      return !! this.rppsSelected
    },
    disabledSwitch () {
      return !! this.correspondent
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler () {
        this.isUserReferring = this.patient.referringPhysician?.['@id'] === this.userContact?.['@id']
        if (this.isUserReferring || ! this.value || ! this.value.contact) {
          this.correspondent = null
        } else {
          this.correspondent = new Correspondent(this.value)
        }
      },
    },
    isUserReferring: {
      deep: true,
      immediate: true,
      handler (isUserReferring) {
        this.rppsSelected = (isUserReferring) ? this.userContact['@id'] : null
      },
    },
  },
  methods: {
    async submit () {
      let patientData
      if (this.correspondent) {
        patientData = { referringPhysicianNewHealthProfessional: new Correspondent(this.correspondent) }
      } else {
        patientData = { referringPhysician: this.rppsSelected }
      }

      const updatedPatient = await putToAPI(this.patient.getIri(), { data: patientData })
      NovaTools.notify.success('Le médecin référent a été ajouté avec succès')

      return updatedPatient
    },
    updateReferringPhysician (correspondent) {
      if (correspondent) {
        this.correspondent = new Correspondent(correspondent)
      }
    },
    removeUpdatedReferringPhysician () {
      this.correspondent = null
      this.rppsSelected = null
    },
  },
}
</script>

<style lang="scss" scoped>
.referring-physician-form {
  display: flex;
  flex-direction: column;
}
.correspondent {
  list-style: none;
  margin-bottom: 5px;
}
</style>