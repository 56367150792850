<template>
  <div class="app-badge">
    <v-badge
      left
      inline
      :dot="!icon && !content"
      :content="content"
      :color="color"
      :icon="icon"
      class="app-badge"
      :class="{
        'app-badge--icon': icon,
        'app-badge--content': content
      }"
      data-test="badge"
    >
      <template v-if="icon" #badge>
        <app-icon :icon="icon" :color="color" data-test="badge-icon" />
      </template>
      <slot />
    </v-badge>
  </div>
</template>

<script>
/**
 * Composant de badge de l'application
 * @see {@link https://vuetifyjs.com/en/components/badges/}
 */
export default {
  name: 'AppBadge',
  props: {
    /**
     * La couleur du badge à afficher
     * @default null
     */
    color: {
      type: String,
      default: null,
    },
    /**
     * L\'icône du badge
     * @default null
     */
    icon: {
      type: String,
      default: null,
    },
    /**
     * Contenu interne du badge
     */
    content: {
      type: [String, Number],
      default: null,
    },
  },
}
</script>

<style lang="scss">
  $dot-size: 12px;
  $icon-size: 16px;

  .app-badge {
    display: inline-block;

    &--content {
      .v-badge__badge {
        width: 16px !important;
        height: 16px !important;
        min-width: inherit;
        font-size: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .app-badge.v-badge {
    display: flex;

    &--inline {
      align-items: flex-start;
    }

    &--icon {
      .v-badge__wrapper {
        margin-right: 8px;
        margin-left: 0;

        span {
          background-color: transparent !important;
        }
      }
      .v-badge__badge {
        border: none;
        width: auto !important;
        height: auto !important;
        color: inherit;
        padding: 0 !important;
        min-width: $icon-size;

        .v-icon {
          font-size: $icon-size;
        }
      }
    }

    .v-badge__wrapper {
      display: flex;
      height: auto;
      margin-right: 8px;
      margin-left: 0;
      width: $icon-size;
    }
    .v-badge__badge {
      width: $dot-size;
      height: $dot-size;
      border-radius: $dot-size;
    }
  }
</style>