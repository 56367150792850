<template>
  <section
    class="page-layout"
    :class="{
      'page-layout--mobile': isMobile,
      'page-layout--sidebar-on-right': sidebarOnRight,
      'page-layout--fab-visible': isFabVisible,
      'page-layout--has-mobile-sub-header': $scopedSlots['mobile-sub-header'] !== undefined,
    }"
  >
    <component
      :is="sidebarWrapper"
      v-if="hasSidebar"
      v-bind="sidebarAttrs"
      class="page-layout__sidebar elevation-5"
      data-test="sidebar"
      v-on="sidebarEvents"
    >
      <slot name="sidebar" />
    </component>
    <main
      ref="content"
      data-test="page-layout-content"
      class="page-layout__content"
      @scroll="scroll"
    >
      <app-button
        v-if="isFabVisible"
        color="primary"
        fab
        class="page-layout__fab "
        :style="{ bottom: `${fabBottomSpacing}px` }"
        data-test="fab"
        @click="handleFabClick"
      >
        <app-icon size="20" :icon="mobileFabIcon" data-test="fab-icon" />
      </app-button>

      <header v-if="$scopedSlots['mobile-sub-header'] && isMobile" class="page-layout__mobile-sub-header" data-test="mobile-sub-header">
        <slot name="mobile-sub-header" :toggle-sidebar="toggleSidebar" />
      </header>
      <slot />
    </main>
  </section>
</template>

<script>
import AppNavigationDrawer from '@/components/ui/drawer/AppNavigationDrawer.vue'

import pageLayoutMixin from '@/layout/mixins/pageLayoutMixin'
import { mapGetters } from 'vuex'

const MOBILE_BOTTOM_NAVIGATION_HEIGHT = 56

export default {
  name: 'AppPageLayout',
  mixins: [pageLayoutMixin],
  props: {
    mobileFabMethod: {
      type: Function,
      default: null,
    },
  },
  data () {
    return {
      isSidebarOpen: false,
      positionY: - 1,
      fabBottomSpacing: MOBILE_BOTTOM_NAVIGATION_HEIGHT,
    }
  },
  computed: {
    ...mapGetters('app', ['isMobile']),
    sidebarWrapper () {
      return this.isMobile ? AppNavigationDrawer : 'aside'
    },
    sidebarAttrs () {
      if (this.isMobile) {
        return {
          value: this.isSidebarOpen,
          title: this.sidebarTitle,
          fromLeft: ! this.sidebarOnRight,
          scrollable: true,
        }
      }
      return { style: { flex: `0 0 ${this.sidebarWidth}px` } }
    },
    sidebarEvents () {
      if (this.isMobile) {
        return { input: isOpen => this.isSidebarOpen = isOpen }
      }
      return {}
    },
    hasSidebar () {
      return this.$scopedSlots.sidebar?.() !== undefined
    },
    isFabVisible () {
      return this.hasSidebar && this.isMobile && ! this.hideMobileFab
    },
  },
  watch: {
    isMobile (isMobile) {
      if (! isMobile) {
        this.isSidebarOpen = false
      }
    },
    $route (to, from) {
      if(to !== from && this.positionY > - 1) {
        this.$refs.content.scrollTo(0, this.positionY)
      }
    },
  },
  created () {
    this.initFabBottomSpacing()
  },
  methods: {
    initFabBottomSpacing () {
      const routeMeta = this.$route.meta
      if (! routeMeta) {
        return
      }
      const isMobileBottomNavigationHidden = routeMeta.hideMobileBottomNavigation || (routeMeta.layout === 'empty')
      this.fabBottomSpacing = isMobileBottomNavigationHidden ? 0 : MOBILE_BOTTOM_NAVIGATION_HEIGHT
    },
    scroll (event) {
      this.positionY = event.target.scrollTop
    },
    toggleSidebar () {
      this.isSidebarOpen = ! this.isSidebarOpen
    },
    handleFabClick () {
      if (this.mobileFabMethod) {
        this.mobileFabMethod()
        return
      }
      this.toggleSidebar()
    },
  },
}
</script>

<style lang="scss" scoped>
$white-space-desktop: map-get($spacers, 5);
$white-space-mobile: map-get($spacers, 4);

$scrollbar-width: 16px;

  .page-layout {
    display: flex;
    height: 100%;
    width: 100vw;
    overflow: hidden;

    &--mobile &__sidebar {
      padding: 0;
    }

    &--mobile &__content {
      padding: $white-space-mobile;
    }

    &--mobile.page-layout--has-mobile-sub-header &__content {
       padding-top: 0;
    }

    &--fab-visible &__content {
      padding-bottom: calc(56px + #{$white-space-mobile * 2});
    }

    &--sidebar-on-right &__sidebar {
      order: 3;
    }

    &__fab {
      position: fixed;
      padding: map-get($spacers, 4);
      z-index: 5;
      right: 0;
    }

    &__sidebar,
    &__content {
      padding: $white-space-desktop;
      overflow: auto;
    }

    &__sidebar {
      background-color: #fff;
      order: 1;
    }

    &__mobile-sub-header {
      position: sticky;
      top: 0;
      z-index: 6;
      margin: 0 -#{$white-space-mobile} $white-space-mobile -#{$white-space-mobile};
    }

    &__content {
      flex-grow: 1;
      order: 2;
      background-color: var(--v-blue-grey-lighten2);
    }
  }
</style>