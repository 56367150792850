import ButtonAction from '@novalys/src/models/ButtonAction'
import NDialog from '@/nova-ui/NDialog/NDialog.vue'
import NovaTools from '@/nova-tools/NovaTools'

/**
 * @typedef {Object} InfoPromptOptions Les options de personnalisation de la modale d'information
 * @property {String} acceptLabel Label à afficher sur le bouton de confirmation
 * @property {Function} onAccept Action à exécuter en cas de clic sur le bouton de confirmation
 * @property {Boolean} persistent Indique si la modale doit rester visible en cas de clic en dehors de celle-ci, ou de pression de la touche échap
 */

/**
 * Affiche une modale d'information personnalisable
 * @param {String} title Le titre de la modale
 * @param {String} message Le message de la modale
 * @param {InfoPromptOptions} options Les options de personnalisation de la modale d'information
 */
function info (title, message, options = {}) {
  const DEFAULT_INFOS_OPTIONS = {
    acceptLabel: 'OK',
    onAccept: () => {},
    persistent: false,
  }

  const { acceptLabel, onAccept, persistent } = {
    ...DEFAULT_INFOS_OPTIONS,
    ...options,
  }

  prompt({
    title,
    message,
    actions: [
      new ButtonAction(acceptLabel, onAccept, 'primary'),
    ],
    otherNDialogProps: { persistent },
  })
}

/**
 * Affiche une modale de confirmation personnalisable
 * @param {String} title Le titre de la modale
 * @param {String} message Le message de la modale
 * @param {ButtonAction} onAccept ButtonAction à utiliser pour l'action de confirmation
 * @param {ButtonAction} onCancel ButtonAction à utiliser pour l'action d'annulation
 */
function confirm (title, message, onAccept = () => {}, onCancel = () => {}) {
  prompt({
    title,
    message,
    actions: [
      onCancel instanceof ButtonAction ? onCancel : new ButtonAction('Annuler', onCancel),
      onAccept instanceof ButtonAction ? onAccept : new ButtonAction('Confirmer', onAccept, 'error'),
    ],
    otherNDialogProps: { persistent: true },
  })
}

/**
 * Méthode générique d'affichage de modales s'appuyant sur NDialog
 */
function prompt ({ title, message, width, actions = [], otherNDialogProps = {} }) {
  const DEFAULT_DIALOG_PROPS = { persistent: false }
  const nDialogProps = {
    ...DEFAULT_DIALOG_PROPS,
    ...otherNDialogProps,
  }
  const { id } = NovaTools.modal.append(NDialog, {
    isOpen: true,
    title,
    message,
    width,
    actions,
    ...nDialogProps,
  }, {
    close: async () => {
      // permet de voir l'animation de fermeture de la boite de dialogue
      await new Promise(r => setTimeout(r, 1500))
      NovaTools.modal.destroy(id)
    },
  })
}

export default {
  confirm,
  info,
  prompt,
}