import { Buffer } from 'buffer'
import { postToAPI } from '@/services/api'
import iCanopeeCardReaderInstance from '@/services/vendors/icanopee/classes/ICanopeeCardReader'
import Tlsi from '@/modules/patient/components/sidebar/tlsi/models/Tlsi'
import TlsiBeneficiary from '@/modules/patient/utils/classes/TlsiBeneficiary'

/**
 * @typedef {Object} AldiType
 * @property {Patient} patient
 */
export default class Aldi {

  /**
   * @param {AldiType} options
   */
  constructor (options = {}) {
    if (! options.patient) {
      throw new Error('Un patient est nécessaire')
    }
    this.patient = options.patient
  }

  async getAldiTemplate (beneficiaryVitaleCardIndex, byApCv) {
    const softwareInformations = await Tlsi.fetchSoftwareInformations()

    const template = await iCanopeeCardReaderInstance.getAldiTemplate(softwareInformations, beneficiaryVitaleCardIndex, byApCv)
    return Buffer.from(template).toString('base64')
  }

  async sendAldiRequest (requestParams) {
    const { data } = await postToAPI('/api/tlsi/aldi', {
      patient: this.patient['@id'],
      ...requestParams,
    }, { errorHandle: false })
    return data
  }

  formatAldiResult (responsePayload, beneficiaryFullName = null) {
    responsePayload.beneficiary = new TlsiBeneficiary({
      ...responsePayload.beneficiary,
      fullName: beneficiaryFullName,
    })
    responsePayload.aldList = responsePayload.ald
    return responsePayload
  }
}