<template>
  <v-card class="n-card">
    <v-card-title
      v-if="title"
      v-test="'n-card-title'"
      class="n-card__title ma-6 mb-0"
    >
      <span>{{ title }}</span>
      <v-spacer />
      <slot name="append-title" />
    </v-card-title>
    <div class="n-card__separator">
      <v-expand-transition>
        <div v-show="separateHeader">
          <n-divider :spacing="0" class="mt-6 ma-0 pa-0" />
        </div>
      </v-expand-transition>
    </div>
    <v-card-text class="n-card__content pa-6">
      <!-- @slot Slot pour insérer le contenu de la carte -->
      <slot />
    </v-card-text>
    <template v-if="actions || $scopedSlots.actions">
      <div class="n-card__separator">
        <v-expand-transition>
          <div v-show="separateFooter">
            <n-divider :spacing="0" class="mb-6" />
          </div>
        </v-expand-transition>
      </div>
      <v-card-actions
        v-test="'n-card-actions'"
        class="n-card__actions pa-0 mb-6 ml-6 mr-6"
      >
        <!-- @slot Remplace les actions par défaut contenu dans le footer de la carte -->
        <slot name="actions">
          <v-spacer />
          <!--
         Est déclenché lorsque le bouton est cliqué
         @event click
       -->
          <n-button-actions :actions="actions" @confirm="$emit('confirm')" />
        </slot>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import NButtonActions from '@/nova-ui/NButtonActions/NButtonActions.vue'

import ButtonAction from '@novalys/src/models/ButtonAction'

/**
 * Permet d'afficher une carte
 */
export default {
  name: 'NCard',
  components: { NButtonActions },
  props: {
    /**
     * Le titre de la carte
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * tableau des actions à afficher dans la carte
     */
    actions: {
      type: Array,
      default: null,
      validator: actions => actions.every(action => action instanceof ButtonAction),
    },
    /**
     * Ajoute un séparateur pour le header
     */
    separateHeader: {
      type: Boolean,
      default: false,
    },
    /**
     * Ajoute un séparateur pour le footer
     */
    separateFooter: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
  .n-card {
    &.v-sheet.v-card {
      border-radius: 12px;
    }

    &__title {
      padding: 0 !important;
      color: #37467a;
      font-size: 20px !important;
      font-weight: 600 !important;
      word-break: break-word;
    }

    &__content {
      color: var(--v-content-base) !important;
      font-size: 13px;
    }

    &__actions {
      button.primary{
        box-shadow: 0 5px 12px -5px rgba(77, 124, 255, 0.3), 0px 1px 2px rgba(55, 70, 122, 0.06) !important;
      }
    }
  }
</style>