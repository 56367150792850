<template>
  <v-row no-gutters>
    <v-col
      md="11"
      lg="9"
      xl="7"
    >
      <v-row class="accounting-list-item">
        <v-col cols="6" md="3">
          <div class="accounting-list-item__date">
            <app-icon left icon="schedule" color="secondary lighten-4" />
            <span class="accounting-list-item__label">
              <slot name="date" />
            </span>
          </div>
          <div v-if="$slots['total-amount']" class="accounting-list-item__amount mt-2 mb-1">
            <slot name="total-amount" />
          </div>
          <div v-if="hasTransaction" class="accounting-list-item__summary">
            <slot name="paid-amount-label" />
            <div class="value">
              <slot name="paid-amount-value" />
            </div>
            <slot name="difference-amount-label" />
            <div class="value">
              <slot name="difference-amount-value" />
            </div>
          </div>
          <div class="accounting-list-item__status mt-2">
            <slot name="billing-status" />
          </div>
        </v-col>
        <v-col cols="6" md="4">
          <div class="accounting-list-item__place first-line">
            <app-icon
              left
              icon="map-marker"
              color="secondary lighten-4"
            />
            <slot name="place" />
          </div>
          <div class="accounting-list-item__patient-name">
            <app-icon
              left
              icon="user"
              color="secondary lighten-4"
            />
            <slot name="patient-name" />
          </div>
        </v-col>
        <v-col cols="12" md="5">
          <v-row no-gutters class="accounting-list-item__payment-list payment-list">
            <slot name="payment-list" />
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import AppIcon from '@/components/ui/icon/AppIcon.vue'
export default {
  name: 'AccountingListItemLayout',
  components: { AppIcon },
  computed: {
    hasTransaction () {
      return this.$slots['paid-amount-label']
        || this.$slots['paid-amount-value']
        || this.$slots['difference-amount-label']
        || this.$slots['difference-amount-value']
    },
  },
}
</script>

<style lang="scss" scoped>
.accounting-list-item {
  &__date,
  &__place,
  &__patient-name {
    display: flex;
    align-items: flex-end;
    font-size: 13px;

    .app-icon {
      text-align: center;
      width: 24px;
    }
  }

  &__label {
    font-size: 13px;
  }

  &__amount {
    font-size: 20px;
    font-weight: 700;
  }

  &__summary {
    font-size: 12px;
    color: var(--v-secondary-base);
    display: inline-grid;
    grid-template-columns: auto minmax(0, 1fr);
    grid-column-gap: 8px;

    .value {
      text-align: right;
    }
  }

  &__place,
  &__patient-name {
    font-size: 13px;
    font-weight: 500;
  }

  &__payments-title {
    font-size: 13px;
    color: var(--v-secondary-base);
  }

  .payment-list {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    max-width: 350px;
  }

  .first-line {
    min-height: 25px;
    align-items: flex-start;
    display: flex;
  }

  .app-tag {
    max-height: 20px;
    font-size: 10px;
    color: white !important;
    font-weight: 500;
  }
}
</style>