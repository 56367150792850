<template>
  <div v-if="hasAdditionalInformations" v-test="'additional-informations'" class="ml-3">
    <div class="additional-informations__container">
      <p class="additional-informations__subtitle">
        Informations additionnelles :
      </p>
      <div v-if="correspondent.note" class="additional-informations__sub-container">
        <app-icon icon="note" color="var(--v-secondary-lighten4)" class="mr-2 ml-3" />
        <p v-test="'additional-informations-note'">
          {{ correspondent.note }}
        </p>
      </div>
      <div v-if="0 !== getFilteredContactPoints(mediaType.TELEPHONE.value).length" class="additional-informations__sub-container">
        <app-icon icon="phone" color="var(--v-secondary-lighten4)" class="mr-2 ml-3" />
        <div>
          <app-contact-point
            v-for="contactPoint in getFilteredContactPoints(mediaType.TELEPHONE.value)"
            :key="contactPoint['@id']"
            v-test="'contact-point-phone'"
            :contact-point="contactPoint"
            :formatted-contact-point="false"
            :show-label="false"
            compact
          />
        </div>
      </div>
      <div v-if="0 !== getFilteredContactPoints(mediaType.FAX.value).length" class="additional-informations__sub-container">
        <app-icon icon="fax" color="var(--v-secondary-lighten4)" class="mr-2 ml-3" />
        <div>
          <app-contact-point
            v-for="contactPoint in getFilteredContactPoints(mediaType.FAX.value)"
            :key="contactPoint['@id']"
            v-test="'contact-point-fax'"
            :contact-point="contactPoint"
            :formatted-contact-point="false"
            :show-label="false"
            compact
          />
        </div>
      </div>
      <div v-if="0 !== getFilteredContactPoints(mediaType.EMAIL.value).length" class="additional-informations__sub-container">
        <app-icon icon="email" color="var(--v-secondary-lighten4)" class="mr-2 ml-3" />
        <div>
          <app-contact-point
            v-for="contactPoint in getFilteredContactPoints(mediaType.EMAIL.value)"
            :key="contactPoint['@id']"
            v-test="'contact-point-email'"
            :contact-point="contactPoint"
            :formatted-contact-point="false"
            :show-label="false"
            compact
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppContactPoint from '@/components/ui/contactPoint/AppContactPoint'
import PatientCorrespondent from '@/modules/patient/models/PatientCorrespondent'

import { MEDIA_TYPES } from '@/constants'

export default {
  name: 'CorrespondentsPanelItemAdditionalInformations',
  components: { AppContactPoint },
  props: {
    correspondent: {
      type: PatientCorrespondent,
      required: true,
    },
  },
  data () {
    return { mediaType: MEDIA_TYPES }
  },
  computed: {
    correspondentContactPoints () {
      return this.correspondent.contact?.contactPoints?.filter(item => item.value)
    },
    hasAdditionalInformations () {
      return (this.correspondentContactPoints && (0 !== this.correspondentContactPoints.length)) || this.correspondent.note
    },
  },
  methods: {
    getFilteredContactPoints (mediaType) {
      return this.correspondentContactPoints.filter(item => item.media === mediaType)
    },
  },
}
</script>

<style lang="scss" scoped>
.additional-informations {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__subtitle {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: var(--v-secondary-base);
  }

  &__sub-container {
    display: flex;
    gap: 10px;
  }
}
</style>