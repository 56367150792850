export const ROUTE_NAMES = Object.freeze({
  FOOTER_SETTINGS: 'ShowFooters',
  CREATE_FOOTER: 'AddFooter',
  EDIT_FOOTER: 'EditFooter',
  HEADER_SETTINGS: 'settings.headers',
  CREATE_HEADER: 'settings.headers.new',
  EDIT_HEADER: 'settings.headers.edit',
  TEMPLATE_SETTINGS: 'settings.models',
  CREATE_TEMPLATE: 'settings.models.new',
  EDIT_TEMPLATE: 'settings.models.edit',
  PRESCRIPTION_SETTINGS: 'settings.prescription',
  PRESCRIPTION_HEADER_SETTINGS: 'settings.prescription.headers',
})