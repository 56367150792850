<template>
  <app-date-field
    ref="birthDateInput"
    v-model="localValue"
    label="Date de naissance"
    name="birthDate"
    :rules="fieldRules"
    :disabled="disabled"
  />
</template>

<script>
import AppDateField from '@/components/ui/form/AppDateField.vue'

import localCopyMixin from '@novalys/src/mixins/local-copy-mixin'

import { extend, normalizeRules } from 'vee-validate'
import { intervalToDuration, parse } from 'date-fns'

const DEFAULT_RULES = 'before_now|patient_age_valid'

export default {
  name: 'PatientBirthDateField',
  components: { AppDateField },
  mixins: [localCopyMixin()],
  props: {
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [String, Object],
      default: null,
    },
  },
  computed: {
    fieldRules () {
      const normalizedDefaultRules = normalizeRules(DEFAULT_RULES)
      if (this.rules) {
        return {
          ...normalizeRules(this.rules),
          ...normalizedDefaultRules,
        }
      }
      return normalizedDefaultRules
    },
  },
}

extend('patient_age_valid', date => {
  const PATIENT_MAX_AGE = 120
  return intervalToDuration({
    start: new Date(),
    end: parse(date, 'dd/MM/yyyy', new Date()),
  }).years <= PATIENT_MAX_AGE || `L'âge du patient ne peut pas dépasser ${PATIENT_MAX_AGE} ans`
})
</script>