import { softInclude } from '@/utils/functions/words'
import { objectSetNested, mergeObjectsDeep } from '@/utils/functions/object'

import { isObject } from 'lodash'

export default class ReplacementItem {
  label = null
  before = null
  after = null
  key = null

  /**
   *
   * @param {String} label Le label à afficher
   * @param {String} before La valeur actuelle
   * @param {String} after La valeur à venir
   * @param {String} key Une clé permettant d'identifier l'original
   * @param {Function} options.valueLabelGetter Resolver permettant de récupérer la valeur littérale
   */
  constructor (label, before, after, key, options = {}) {
    if (! label) {
      throw new Error('Le label est obligatoire')
    }
    if (before === undefined) {
      throw new Error('La valeur actuelle est obligatoire pour "' + label + '"')
    }
    if (! after === undefined) {
      throw new Error('La valeur à venir est obligatoire pour "' + label + '"')
    }

    this.label = label
    this.before = before
    this.after = after
    this.key = key

    this.valueLabelGetter = options.valueLabelGetter || null
  }

  /**
   * Retourne les labels des propriétés "before" et "after"
   * @returns {Object}
   */
  getValueLabel () {
    const UNKNOWN_LABEL = '-'
    if (this.valueLabelGetter) {
      return {
        before: this.valueLabelGetter(this.before) || UNKNOWN_LABEL,
        after: this.valueLabelGetter(this.after) || UNKNOWN_LABEL,
      }
    }
    return {
      before: this.before || UNKNOWN_LABEL,
      after: this.after || UNKNOWN_LABEL,
    }
  }

  /**
   * @typedef {Object} ReplacementFieldsResult
   * @property {Array} replaceable
   * @property {Array} conflicts
   */

  /**
   * @typedef {Object} ReplacementFieldsCompareOptions
   * @property {String} labels Un objet permettant de renseigner les labels en français selon leurs propriétés
   * @property {Object} LabelGetterMap Permet de spécifier les labels en français des valeurs de certaines clés spécifiques
   * @property {Object} conflictsIgnoreMap Permet de forcer pour un couple clé/valeur l'ignorance d'un conflits (par exemple considérer le genre "inconnue" comme valeur nulle)
   * @property {Object} forceMergeObjectsMap Force la comparaison d'un objet complet sans rentrer dans ce dernier, en se basant sur une clé et son resolver (utile pour les tables de refs)
   */

  /**
   * Comparer deux objets et retourne l'ensemble des valeurs en conflits et directement remplaçables
   * @param {Object} objA
   * @param {Object} objB
   * @param {ReplacementFieldsCompareOptions} options
   * @param {ReplacementFieldsResult} payload.previousResult
   * @param {String} payload.scope
   * @returns {ReplacementFieldsResult}
   */
  static getReplacementFieldsFromObjects (objA, objB, options = {}, payload = {}) {
    const { excludeResolver, LabelGetterMap, forceMergeObjectsMap, conflictsIgnoreMap } = options
    const { previousResult, scope } = payload
    const labels = options.labels || {}
    const result = previousResult || {
      replaceable: [],
      conflicts: [],
    }

    const hydrateResult = (valueA, valueB, key, path, compareMethod) => {
      const valueLabelGetter = LabelGetterMap?.[path]
      const replacementItem = new ReplacementItem(labels[path] || key, valueA, valueB, path, { valueLabelGetter })
      if ((! valueA && valueB) || conflictsIgnoreMap?.[path]?.(valueA, valueB)) {
        result.replaceable.push(replacementItem)
        return
      }
      if (compareMethod ? compareMethod() : softInclude(valueB, valueA)) {
        return
      }
      result.conflicts.push(replacementItem)
    }

    Object.keys((Object.keys(objA).length > Object.keys(objB).length) ? objA : objB)
      .forEach((key) => {
        let valueA = objA[key] || null
        let valueB = objB[key] || null
        const path = scope ? [scope, key].join('.') : key
        if (excludeResolver?.(key, valueA, valueB, path)) {
          return
        }
        if (forceMergeObjectsMap && Object.keys(forceMergeObjectsMap).includes(path)) {
          hydrateResult(valueA, valueB, key, path, () => forceMergeObjectsMap[path](valueA, valueB))
          return
        }
        if ((isObject(valueA) && ! Array.isArray(valueA)) || (isObject(valueB) && ! Array.isArray(valueB))) {
          const Model = valueA?.constructor || valueB?.constructor
          if (Model) {
            valueA = new Model(valueA || {})
            valueB = new Model(valueB || {})
          }
          return this.getReplacementFieldsFromObjects(valueA || {}, valueB || {}, {
            labels,
            excludeResolver,
            LabelGetterMap,
            forceMergeObjectsMap,
          }, {
            previousResult: result,
            scope: path,
          })
        }
        hydrateResult(valueA, valueB, key, path)
      })
    return result
  }

  /**
   * Permet de remplacer les valeurs d'un objet à partir de différents ReplacementItems.
   * @param {Object} obj
   * @param {Array<ReplacementItem>} fields
   */
  static replace (obj, fields) {
    const payload = {}
    fields.forEach(field => objectSetNested(payload, field.key.split('.'), field.after))
    obj = mergeObjectsDeep(obj, payload, { ignoreNullish: true })
    return obj
  }
}