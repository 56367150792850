import { formatInTimeZone as formatDateFns, getTimezoneOffset } from 'date-fns-tz'
import { fr } from 'date-fns/locale'

export class Dates {
  newDate (date) {
    if (date.length === 10) {
      const newDate = new Date(this.dateToDatetime(date))
      if (this.getTimeZone() !== 'UTC') {
        newDate.setMinutes(newDate.getMinutes() - getTimezoneOffset(this.getTimeZone(), newDate) / 60000)
      }
      return newDate
    }
    return new Date(date)
  }

  /**
     * Formater une date
     * @param {Date|String} date
     * @param {String} [formatStr='dd MMM yyyy'] le format voulu de la date ex dd/MM/yyyy
     * @see https://date-fns.org/docs/format#description pour les formats acceptés
     */
  format (date, formatStr = 'dd MMM yyyy') {
    let dateToFormat
    if ((date instanceof String) || (typeof date === 'string')) {
      dateToFormat = this.newDate(date)
    } else {
      dateToFormat = date
    }
    return formatDateFns(
      dateToFormat,
      this.getTimeZone(),
      formatStr,
      { locale: fr },
    )
  }
  dateToDatetime (date) {
    return date + 'T00:00:00.000Z'
  }
  isValidDate (date) {
    return date instanceof Date && ! isNaN(date)
  }
  getTimeZone () {
    return Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'Europe/Paris'
  }
}

export default new Dates()