<template>
  <app-form
    :submit-method="submitAbsence"
    class="absence-form mt-4"
  >
    <fieldset class="fieldset">
      <v-row dense>
        <v-col cols="12">
          <app-text-field
            v-model="value.title"
            label="Titre"
            prepend-icon="fa-pen"
            name="title"
            data-test="title"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            slim
            vid="schedule"
          >
            <v-select
              ref="absenceSchedule"
              v-model="value.schedule"
              prepend-icon="fa-calendar"
              :items="getVisibleSchedules"
              label="Agenda"
              item-text="name"
              item-value="@id"
              :error-messages="errors"
              :hide-details="!errors.length"
              return-object
              data-test="schedule-input"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="4">
          <app-date-picker
            v-model="value.date"
            label="Date"
            data-test="date-input"
            name="date"
            rules="required"
            icon="fa-clock"
            hide-errors="auto"
          />
        </v-col>
        <v-fade-transition>
          <v-col v-if="!value.isWholeDay()" cols="8">
            <v-row dense>
              <v-col cols="6">
                <app-text-field
                  v-model="value.intervals[0].start"
                  label="Heure de début"
                  type="time"
                  name="intervals[0].start"
                  rules="required"
                  data-test="time-input"
                  hide-errors="auto"
                />
              </v-col>
              <v-col cols="6">
                <app-text-field
                  ref="endTime"
                  v-model="value.intervals[0].end"
                  label="Heure de fin"
                  type="time"
                  name="intervals[0].end"
                  :rules="'required|time_after:' + value.intervals[0].start"
                  data-test="endtime-input"
                  hide-errors="auto"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-fade-transition>
      </v-row>
      <v-row dense>
        <v-col cols="4">
          <app-checkbox
            class="pa-0 ma-0"
            :input-value="value.isWholeDay()"
            label="Journée entière"
            data-test="whole-day-input"
            hide-details="true"
            @change="value.setIsWholeDay(!value.isWholeDay())"
          />
        </v-col>
      </v-row>
      <app-alert
        v-show="isPastAbsence"
        ref="pastAbsenceAlert"
        type="warning"
        message="Attention, votre absence est dans le passé"
        data-test="past-absence-alert"
        class="my-4"
      />
    </fieldset>
    <fieldset class="fieldset">
      <event-recurrence
        v-model="value.recurrencePattern"
        :start-date="value.date"
      />
    </fieldset>
  </app-form>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'

import AbsenceSettings from '@/modules/agenda/models/events/AbsenceSettings'

import { ValidationProvider } from 'vee-validate'

import AppAlert from '@/components/ui/alert/AppAlert.vue'
import AppForm from '@/components/ui/form/AppForm.vue'
import EventRecurrence from '../../EventRecurrence.vue'
import AppTextField from '@/components/ui/form/AppTextField.vue'
import AppCheckbox from '@/components/ui/form/AppCheckbox.vue'
import AppDatePicker from '@/components/ui/form/AppDatePicker.vue'

export default {
  name: 'AbsenceForm',
  components: {
    ValidationProvider,
    AppAlert,
    AppForm,
    EventRecurrence,
    AppTextField,
    AppCheckbox,
    AppDatePicker,
  },
  props: {
    value: {
      type: AbsenceSettings,
      default: () => new AbsenceSettings(),
    },
  },
  data () {
    return {
      absenceDateMenuVisible: false,

      /**
       * Permet de s'assurer de ne pas scroller dès la création de l'alerte
       */
      canScrollOnAlert: false,
    }
  },
  computed: {
    ...mapGetters('agenda', ['getVisibleSchedules', 'getAbsenceMotives']),
    isEditing () {
      return !! this.value['@id']
    },
    date: {
      get () {
        if(! this.value) {
          return
        }
        return this.value.date
      },
      /** Met à jour startDateTime en conservant l'horaire du rdv */
      set (date) {
        this.value.date = date
      },
    },
    isPastAbsence () {
      const time = this.value.intervals[0].start
      if (! this.date || ! time) {
        return false
      }
      const now = new Date()
      const eventDate = new Date(this.date + 'T' + time)

      return now > eventDate
    },
  },
  watch: {
    isPastAbsence (isPastAbsence) {
      if (isPastAbsence) {
        this.$nextTick(() => {
          if (this.canScrollOnAlert) {
            this.$refs.pastAbsenceAlert.$el.scrollIntoView({
              block: 'nearest',
              behavior: 'smooth',
              inline: 'nearest',
            })
          }

          this.canScrollOnAlert = true
        })
      }
    },
    'value.intervals.0.start' () {
      if ((! this.value.isWholeDay()) && this.value.intervals[0].start && this.value.intervals[0].end) {
        this.$nextTick(() => {
          this.$refs.endTime.validate()
        })
      }
    },
  },
  methods: {
    ...mapActions('agenda', ['insertAbsenceSetting', 'updateAbsenceSetting']),
    ...mapMutations('app', { setSnack: 'SET_SNACK' }),

    async submitAbsence () {
      this.isEditing ?
        await this.updateAbsenceSetting(this.value) :
        await this.insertAbsenceSetting(this.value)
      this.setSnack({
        message: this.isEditing ?
          'L\'absence a été modifiée avec succès' :
          'L\'absence a été ajoutée avec succès',
      })
    },

    setPatient (patient) {
      this.value.patient = patient
      this.$refs.absenceSchedule.focus()
    },
  },
}
</script>

<style lang="scss" scoped>
.section-label {
  font-weight: 600;
}

label {
  text-transform: lowercase;
}
</style>