<template>
  <patient-sidebar-panel-listing
    title="Antécédents"
    scrollable-modal
    create-label="Ajouter un ou plusieurs antécédents"
    edit-label="Modifier un antécédent"
    no-data-text="Aucune donnée"
    :items="sortedPatientAntecedents"
    :item-model-class="PatientAntecedent"
    :loading="isFetchingAntecedents"
    :patient="patient"
    group-by="type"
    :form="PatientAntecedentsForm"
    :value-getter="modalValueGetter"
    @submit-success="getAntecedents"
  >
    <template #category-title="{ category }">
      <span v-test="'antecedent-type'">{{ getAntecedentFormattedType(category) }}</span>
    </template>
    <template #item="{ item, edit }">
      <app-hoverable-actions-wrapper tag="article" :inset-x="4" :actions="getActions(item, edit)">
        <p>
          <app-tag
            v-if="item.icd10Code "
            v-test="'antecedent-code'"
            x-small
            color="content"
            class="px-2 mr-2"
          >
            {{ item.icd10Code }}
          </app-tag>
          <span v-test="'antecedent-label'">
            {{ getAntecedentLabel(item) }}
            <n-truncable-text
              v-if="item.note"
            >
              {{ item.note }}
            </n-truncable-text>
          </span>
        </p>
      </app-hoverable-actions-wrapper>
    </template>
  </patient-sidebar-panel-listing>
</template>

<script>
import AppTag from '@/components/ui/tag/AppTag.vue'
import PatientSidebarPanelListing from '@/modules/patient/components/patientFile/PatientSidebarPanelListing.vue'
import PatientAntecedentsForm from '@/modules/patient/components/patientFile/riskFactors/antecedents/PatientAntecedentsForm.vue'
import AppHoverableActionsWrapper from '@/components/ui/hoverableActionsWrapper/AppHoverableActionsWrapper.vue'

import { mapGetters } from 'vuex'
import { deleteFromAPI, getFromAPI } from '@/services/api'
import { pluralize } from '@/utils/functions/words'

import { ANTECEDENT_TYPES } from '@/modules/patient/constants'
import DeleteActionMenuItem from '@/components/ui/actionsMenu/classes/DeleteActionMenuItem'
import EditActionMenuItem from '@/components/ui/actionsMenu/classes/EditActionMenuItem'

import Patient from '@/modules/patient/models/Patient'
import PatientAntecedent from '@/modules/patient/models/PatientAntecedent'
import PatientFamilyAntecedent from '@/modules/patient/models/PatientFamilyAntecedent'
import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'PatientAntecedentsListing',
  components: {
    AppHoverableActionsWrapper,
    PatientSidebarPanelListing,
    AppTag,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      PatientAntecedent,
      PatientAntecedentsForm,
      patientAntecedents: [],
      isFetchingAntecedents: false,
    }
  },
  computed: {
    ...mapGetters('app', { FAMILY_RELATIONS: 'getFamilyRelations' }),
    sortedPatientAntecedents () {
      const antecedentTypeValues = Object.values(ANTECEDENT_TYPES).map(type => type.value)
      return [...this.patientAntecedents].sort((a, b) => antecedentTypeValues.indexOf(a.type) - antecedentTypeValues.indexOf(b.type))
    },
  },
  created () {
    this.initAntecedents()
  },
  methods: {
    async getAntecedents () {
      const { data } = await getFromAPI(`${this.patient.getIri()}/medical_antecedents?exists[habitus]=false`)
      this.patientAntecedents = data['hydra:member'].map((antecedent) => {
        if (antecedent.type === 'family') {
          return new PatientFamilyAntecedent({
            ...antecedent,
            familyRelationship: antecedent.familyRelationship['@id'],
          })
        }
        return new PatientAntecedent(antecedent)

      })
    },
    async initAntecedents () {
      this.isFetchingAntecedents = true
      try {
        await this.getAntecedents()
      } finally {
        this.isFetchingAntecedents = false
      }
    },
    getAntecedentFormattedType (antecedentType) {
      if (antecedentType === ANTECEDENT_TYPES.NOT_CODIFIED.value) {
        return 'Non codifiés CIM10'
      }
      return pluralize(ANTECEDENT_TYPES[antecedentType.toUpperCase()]?.text || ANTECEDENT_TYPES.EMPTY.text)
    },
    getAntecedentLabel (antecedent) {
      if (antecedent.type === ANTECEDENT_TYPES.FAMILY.value) {
        const familyRelation = this.FAMILY_RELATIONS.find(relation => relation['@id'] === antecedent.familyRelationship)
        return `${antecedent.label} (${familyRelation.label.toLowerCase()})`
      }
      return antecedent.label
    },
    modalValueGetter (antecedent) {
      if (antecedent) {
        return [antecedent]
      }
      return [new PatientAntecedent({ type: ANTECEDENT_TYPES.MEDICAL.value })]
    },
    getActions (item, editMethod) {
      return [
        new EditActionMenuItem({ callback: () => editMethod(item) }),
        new DeleteActionMenuItem({
          callback: () => {
            NovaTools.dialog.confirm(
              'Supprimer l\'antécédent',
              'Êtes-vous sûr(e) de vouloir supprimer l\'antécédent ?',
              async () => {
                await deleteFromAPI(item['@id'])
                this.removeAntecedentValue(item)
                NovaTools.notify.success('L\'antécédent a été supprimé avec succès')
              })
          },
        })]
    },
    removeAntecedentValue (value) {
      const index = this.patientAntecedents.indexOf(value)
      if (index !== - 1) {
        this.patientAntecedents.splice(index, 1)
      }
    },
  },
}
</script>