<template>
  <v-switch
    v-model="localValue"
    class="app-switch mt-0 pt-0"
    inset
    :label="label"
    :hide-details="hideErrors"
    :dense="dense"
    :disabled="disabled"
    :hint="hint"
    persistent-hint
    @input="$emit('input', localValue)"
    @change="$emit('change', localValue)"
  />
</template>

<script>
import fieldMixin from '@/mixins/fields'

/**
 * Composant de switch de l\'application
 */
export default {
  name: 'AppSwitch',
  mixins: [fieldMixin],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    /**
     * Permet de désactiver le switch
     * @default "false"
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped::v-deep>
  .app-switch {
    .v-messages {
      &__message {
        line-height: 14px;
      }
    }

    &.v-input--is-label-active .v-label {
      color: var(--v-primary-base);
    }
    .v-label {
      color: var(--v-secondary-base);
      font-size: 13px;
    }
    &.v-input--dense {
      margin-left: 3px;

      .v-input {
        &--selection-controls__input {
          width: 32px;
          height: auto;
        }
        &--switch__track {
          width: 34px !important;
          height: 16px !important;
          top: calc(50% - 8px) !important;
        }
        &--switch__thumb {
          width: 12px !important;
          height: 12px !important;
          top: calc(50% - 7px) !important;
        }
        &--selection-controls__ripple {
          height: 22px !important;
          width: 22px !important;
          top: calc(50% - 19px) !important;
        }
      }
      .v-input__control > .v-input__slot > div > div.v-input--switch__thumb.theme--light.primary--text{
        transform: translate(17px, 0) !important;
      }
      .v-input__control > .v-input__slot > div > div.v-input--selection-controls__ripple.primary--text{
        transform: translate(17px, 0) !important;
      }
    }
  }
</style>