import axios from 'axios'
import router from '@/router/instance'
import { ROUTE_NAMES } from '@/modules/authentication/constants'
import { globalErrorHandler, displayError } from '@/services/errorManager'
import jwtDecode from 'jwt-decode'

const ONE_MINUTE_AS_MILLISECONDS = 60000

/**
 * Plugin permettant de préparer axios à l'utilisation de l'api easy-care
 */
export default {
  isInitialized: false,
  axiosInstance: null,
  unprotectedUrl: [
    '/api/authentication_token',
    '/api/token/refresh',
    '/users/password/reset-link-email',
    '/users/password/reset',
    '/users/password/check-token',
  ],
  intervalDuration: ONE_MINUTE_AS_MILLISECONDS,

  install () {
    this.axiosInstance = axios
    this.setDefaultContentType('application/json')
    this.setDefaultBaseUrl(import.meta.env.VUE_APP_ROOT_API)

    this.getAxiosApiInstance().interceptors.request.use(async (request) => {
      return await this.onResolveRequestInterceptorProcess(request)
    }, async (err) => {
      return await this.onRejectRequestInterceptorProcess(err)
    })
    this.getAxiosApiInstance().interceptors.response.use((response) => {
      return this.onResolveResponseInterceptorProcess(response)
    }, async (err) => {
      return await this.onRejectResponseInterceptorProcess(err)
    })

    setInterval(this.checkToken.bind(this), this.intervalDuration)
  },

  async onResolveRequestInterceptorProcess (request) {
    const controller = new AbortController()
    if (! this.unprotectedUrl.includes(request.url)) {
      if (this.getToken()) {
        request.headers['Authorization'] = 'Bearer ' + this.getToken()
        if (this.isInitialized) {
          return request
        }
        await this.refreshTokens(request)
        return request
      }

      const requestAborted = {
        ...request,
        signal: controller.signal,
      }
      controller.abort()
      return requestAborted
    }
    return request
  },

  async onRejectRequestInterceptorProcess (err) {
    return err
  },

  onResolveResponseInterceptorProcess (response) {
    switch (response.config.url) {
    case '/api/authentication_token':
    case '/api/token/refresh':
      this.setTokens(response.data.token, response.data.refresh_token, response.data.refresh_token_expiration)
      this.setAuthPayload(response.data)
      break
    case '/api/logout':
      this.resetTokens()
      break
    }
    return response
  },

  async onRejectResponseInterceptorProcess (err) {
    if (err?.code !== 'ERR_CANCELED') {
      const currentPath = err.response.config.url

      switch (currentPath) {
      case '/api/authentication_token':
        this.showNotification(err.response.data.message)
        await this.redirectToAuthentication()
        break
      case '/api/logout':
        await this.redirectToAuthentication()
        break
      case '/api/token/refresh':
        this.showNotification('Votre session a expiré')
        await this.redirectToAuthentication()
        break
      default:
        switch (err.response.status) {
        case 401:
          if (! this.unprotectedUrl.includes(currentPath)) {
            return await this.refreshTokens(err.config)
          }
          break
        default:
          await globalErrorHandler(err)
        }
      }
      return err
    }
  },

  async refreshTokens (config) {
    const resultRefresh = await this.renewToken()
    if (resultRefresh?.status === 200) {
      if (config?.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data'
      }
      return await this.getAxiosApiInstance()(config)
    }
  },

  setDefaultBaseUrl (baseUrl) {
    this.getAxiosApiInstance().defaults.baseURL = baseUrl
  },

  setDefaultContentType (contentType) {
    this.getAxiosApiInstance().defaults.headers['Content-Type'] = contentType
  },

  setTokens (token, refresh_token, refresh_token_expiration) {
    this.isInitialized = true
    this.setToken(token)
    this.setRefreshToken(refresh_token)
    this.setRefreshTokenExpiration(refresh_token_expiration)
  },

  resetTokens () {
    this.isInitialized = false
    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('refresh_token_expiration')
    localStorage.removeItem('authPayload')
  },

  setToken (token) {
    localStorage.setItem('token', token)
  },

  getToken () {
    return localStorage.getItem('token') ?? null
  },

  setRefreshToken (refresh_token) {
    localStorage.setItem('refresh_token', refresh_token)
  },

  getRefreshToken () {
    return localStorage.getItem('refresh_token')
  },

  setRefreshTokenExpiration (refreshTokenExpiration) {
    localStorage.setItem('refresh_token_expiration', refreshTokenExpiration)
  },

  getRefreshTokenExpiration () {
    return localStorage.getItem('refresh_token_expiration')
  },

  setAuthPayload (authPayload) {
    localStorage.setItem('authPayload', JSON.stringify(authPayload))
  },

  getAuthPayload () {
    return JSON.parse(localStorage.getItem('authPayload'))
  },
  getCurrentUser () {
    return this.getAuthPayload().user
  },
  getAxiosApiInstance () {
    return this.axiosInstance
  },

  showNotification (message) {
    displayError(message)
  },

  async checkToken () {
    if (this.getToken()) {
      if (! this.isRefreshTokenValid()) {
        this.showNotification('Votre session a expiré')
        await this.redirectToAuthentication()
      }
    } else {
      await this.redirectToAuthentication()
    }
  },

  isRefreshTokenValid () {
    let isExpired = true
    const refreshTokenExpiration = this.getRefreshTokenExpiration()
    if (refreshTokenExpiration) {
      const expiration = refreshTokenExpiration * 1000
      const currentTime = new Date().getTime()
      isExpired = (expiration < currentTime)
    }
    return ! isExpired
  },

  async redirectToAuthentication () {
    if (router.currentRoute.name !== ROUTE_NAMES.CONNECTION) {
      await router.push({ name: ROUTE_NAMES.CONNECTION })
    }
    this.resetTokens()
  },

  renewToken () {
    return this.getAxiosApiInstance().get('/api/token/refresh', { params: { refresh_token: this.getRefreshToken() } })
  },

  getTokenExpire () {
    const token = this.getToken()
    return token ? jwtDecode(token).exp * 1000 : null
  },
}