import DocumentViewerModal from '@/components/ui/viewer/DocumentViewerModal.vue'
import axios from 'axios'
import NovaTools from '@/nova-tools/NovaTools'

export class Files {
  openDocumentViewer (document) {
    NovaTools.modal.appendDestroyOnClose(DocumentViewerModal, {
      document,
      title: document.title,
      isOpened: true,
    })
  }

  async getBase64Content (contentUrl) {
    const { data } = await axios.create().get(contentUrl, { responseType: 'blob' })
    return await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(data)
      reader.onload = () => resolve(reader.result.split(',')[1])
      reader.onerror = error => reject(error)
    })
  }

}

export default new Files()