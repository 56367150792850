import SettingsIndex from '@/components/settings/SettingsIndex.vue'

import { ROUTE_NAMES as SCHEDULE_ROUTE_NAMES } from '@/modules/agenda/constants'
import { ROUTE_NAMES as SECURE_MESSAGING_ROUTE_NAMES } from '@/modules/secureMessaging/constants'
import { ROUTE_NAMES as DOCUMENT_ROUTE_NAMES } from '@/modules/documents/constants'
import { ROUTE_NAMES as PATIENT_ROUTE_NAMES } from '@/modules/patient/constants'

export default {
  path: '/parametres',
  name: 'settings',
  component: SettingsIndex,
  meta: {
    /**
     * Masque le header uniquement sur mobile et uniquement pour les routes enfant
     */
    hideHeader: {
      childrenOnly: true,
      mobileOnly: true,
    },
  },
  children: [
    {
      path: 'general',
      name: 'settings.account',
      component: () => import('@/components/settings/views/General.vue'),
    },
    {
      path: 'pro-sante-connect',
      name: 'settings.psc',
      component: () => import('@/components/settings/views/account/PscAssociationSettings'),
    },
    {
      path: 'patient-general',
      name: PATIENT_ROUTE_NAMES.PATIENT_IDENTITY_GENERAL,
      component: () => import('@/components/settings/views/patient/PatientGeneralSettings.vue'),
    },
    {
      path: 'patient-rgpd',
      name: PATIENT_ROUTE_NAMES.PATIENT_RGPD,
      component: () => import('@/components/settings/views/patient/PatientRgpdSettings.vue'),
    },
    {
      path: 'prescription-parametres',
      name: DOCUMENT_ROUTE_NAMES.PRESCRIPTION_SETTINGS,
      component: () => import('@/components/settings/views/prescription/PrescriptionSettings.vue'),
    },
    {
      path: 'prescription-entetes',
      name: DOCUMENT_ROUTE_NAMES.PRESCRIPTION_HEADER_SETTINGS,
      component: () => import('@/components/settings/views/prescription/PrescriptionHeadersSettings.vue'),
    },
    {
      path: 'dispositifs-d-identite',
      name: PATIENT_ROUTE_NAMES.PATIENT_IDENTITY_DEVICE_SETTINGS,
      component: () => import('@/components/settings/views/patient/PatientDeviceSettings.vue'),
    },
    {
      path: 'alertes-vsm',
      name: PATIENT_ROUTE_NAMES.PATIENT_VSM_ALERT_SETTINGS,
      component: () => import('@/components/settings/views/patient/PatientVsmAlertSettings.vue'),
    },
    {
      path: 'mots-clef',
      name: PATIENT_ROUTE_NAMES.PATIENT_KEYWORDS_SETTINGS,
      component: () => import('@/modules/patient/components/keywords/settings/PatientKeywordsSettings.vue'),
    },
    {
      path: 'metriques',
      name: PATIENT_ROUTE_NAMES.CONSULTATION_METRIC_SETTINGS,
      component: () => import('@/components/settings/views/ConsultationMetricSettings.vue'),
    },
    {
      path: 'portabilite-des-documents',
      name: PATIENT_ROUTE_NAMES.PATIENT_DOCUMENTS_EXPORT,
      component: () => import('@/components/settings/views/patient/DocumentsExportSetting.vue'),
    },
    {
      path: 'agendas',
      name: SCHEDULE_ROUTE_NAMES.AGENDA_LIST,
      component: () =>
        import('@/components/settings/views/agenda/schedules/SchedulesList.vue'),
    },
    {
      path: 'motifs',
      name: SCHEDULE_ROUTE_NAMES.ADENDA_MOTIVES_LIST,
      component: () =>
        import('@/components/settings/views/agenda/appointmentMotives/AppointmentMotives.vue'),
    },
    {
      path: 'disponibilites',
      name: SCHEDULE_ROUTE_NAMES.ADENDA_AVAILABILITIES_LIST,
      component: () =>
        import('@/components/settings/views/agenda/availabilities/Availabilities.vue'),
    },
    {
      path: 'messagerie-securisee',
      name: SECURE_MESSAGING_ROUTE_NAMES.CONFIGURATION_SETTINGS,
      component: () => import('@/components/settings/views/secureMessaging/configuration/SecureMessagingConfiguration.vue'),
    },
    {
      path: 'contrats-de-confiance',
      name: SECURE_MESSAGING_ROUTE_NAMES.TRUSTED_SENDERS,
      component: () => import('@/components/settings/views/secureMessaging/configuration/trustedSenders/TrustedSendersConfiguration.vue'),
    },
    {
      path: 'modeles',
      name: DOCUMENT_ROUTE_NAMES.TEMPLATE_SETTINGS,
      component: () =>
        import('@/components/settings/views/documents/Templates.vue'),
    },
    {
      path: 'modeles/nouveau',
      name: DOCUMENT_ROUTE_NAMES.CREATE_TEMPLATE,
      component: () =>
        import('@/modules/documents/views/TemplateView.vue'),
      meta: { layout: 'empty' },
    },
    {
      path: 'modeles/edition/:templateId',
      name: DOCUMENT_ROUTE_NAMES.EDIT_TEMPLATE,
      component: () =>
        import('@/modules/documents/views/TemplateView.vue'),
      meta: { layout: 'empty' },
    },
    {
      path: 'entetes',
      name: DOCUMENT_ROUTE_NAMES.HEADER_SETTINGS,
      component: () =>
        import('@/components/settings/views/documents/Headers.vue'),
    },
    {
      path: 'entetes/nouveau',
      name: DOCUMENT_ROUTE_NAMES.CREATE_HEADER,
      component: () =>
        import('@/modules/documents/views/HeaderView.vue'),
      meta: { layout: 'empty' },
    },
    {
      path: 'entetes/edition/:headerId',
      name: DOCUMENT_ROUTE_NAMES.EDIT_HEADER,
      component: () =>
        import('@/modules/documents/views/HeaderView.vue'),
      meta: { layout: 'empty' },
    },
    {
      path: 'pieds-de-page',
      name: DOCUMENT_ROUTE_NAMES.FOOTER_SETTINGS,
      component: () =>
        import('@/components/settings/views/documents/Footers.vue'),
    },
    {
      path: 'pieds-de-page/nouveau',
      name: DOCUMENT_ROUTE_NAMES.CREATE_FOOTER,
      component: () =>
        import('@/modules/documents/views/FooterView.vue'),
      meta: { layout: 'empty' },
    },
    {
      path: 'pieds-de-page/edition/:footerId',
      name: DOCUMENT_ROUTE_NAMES.EDIT_FOOTER,
      component: () =>
        import('@/modules/documents/views/FooterView.vue'),
      meta: { layout: 'empty' },
    },
    {
      path: 'espace-patient',
      name: 'settings.patientspace',
      component: () => import('@/components/settings/views/patientSpace/PatientSpaceSettings.vue'),
    },
  ],
}