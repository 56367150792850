<template>
  <app-button
    v-test="'start-consultation-button'"
    color="primary"
    :icon="! showLabel && 'consultation'"
    class="start-consultation-button"
    label="Démarrer la consultation"
    :loading="isLoading"
    @click="startConsultation"
  >
    <app-icon icon="consultation" :class="{'mr-2': showLabel}" />
    <span v-if="showLabel" v-test="'start-consultation-button-label'" class="start-consultation-button__text">Démarrer la consultation</span>
  </app-button>
</template>

<script>
import { ROUTE_NAMES } from '@/modules/patient/constants'
import StartConsultationForm from '@/modules/patient/components/patientFile/StartConsultationForm.vue'
import Consultation from '@/modules/patient/models/Consultation'
import NovaTools from '@/nova-tools/NovaTools'
import { mapGetters, mapActions } from 'vuex'
import Patient from '@/modules/patient/models/Patient'

/**
 * Bouton de création de consultation
 */
export default {
  name: 'StartConsultationButton',
  props: {
    /**
     * Renseigne l'Uuid du patient
     * @required true
     */
    patient: {
      type: Patient,
      required: true,
    },
    /**
     * Défini si le label est visible ou pas
     * @default true
     */
    showLabel: {
      type: Boolean,
      default: true,
    },
    /**
     * Défini la route appelé en cas de retour ou submitSuccess
     * @default 'patients.patientFile'
     */
    backRoute: {
      type: String,
      default: ROUTE_NAMES.PATIENT_FILE,
    },
  },
  data () {
    return { isLoading: false }
  },
  computed: { ...mapGetters('auth', ['getCurrentUser', 'isCurrentUserSecretary']) },
  methods: {
    ...mapActions('patient', ['insertPatientConsultation']),
    async startConsultation () {
      this.isLoading = true
      const todayConsultations = await Consultation.fetchTodayPatientConsultations(this.patient.getUuid())
      const todayConsultationsFiltered = todayConsultations.filter(this.isEditableByCurrentUser)
      if (todayConsultationsFiltered.length > 0) {
        NovaTools.modal.appendModalForm({
          consultations: todayConsultationsFiltered,
          title: 'Démarrer une consultation',
          form: StartConsultationForm,
          submitText: 'Créer une nouvelle',
          width: '50%',
          isOpened: true,
          scrollable: false,
        }, () => this.openNewConsultation())
      } else {
        this.openNewConsultation()
      }
      this.isLoading = false
    },
    isEditableByCurrentUser (consultation) {
      const isAssigned = consultation.activityPlacePractitionerLink !== null
      const isAssignedToCurrentUser = consultation.activityPlacePractitionerLink?.practitioner?.['@id'] === this.getCurrentUser.contact['@id']
      return ! isAssigned || isAssignedToCurrentUser || this.isCurrentUserSecretary
    },
    async openNewConsultation () {
      const newConsultation = await this.insertPatientConsultation({
        consultation: new Consultation(),
        patient: this.patient,
      })
      await this.$router.push({
        name: ROUTE_NAMES.CREATE_CONSULTATION,
        params: {
          uuid: this.patient.getUuid(),
          consultationUUID: newConsultation.getUuid(),
          backRoute: this.backRoute,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.start-consultation-button {
  ::v-deep {
    .v-btn {
      background-color: var(--v-primary-base) !important;
      color: white !important;
      font-size: 13px;
    }
  }
}
</style>