<template>
  <v-app class="n-application">
    <!-- @slot Slot pour insérer le contenu de l'application -->
    <slot />
    <n-application-notifications-center class="n-application__notifications-center" />
    <component
      :is="component"
      v-for="({component, props, events, id}) in getAllComponents"
      :ref="`root-component-${id}`"
      :key="id"
      v-test="'root-component'"
      v-bind="props"
      v-on="events"
    />
    <n-portal-target name="overlays" />
  </v-app>
</template>

<script>
import NApplicationNotificationsCenter from './NApplicationNotificationsCenter.vue'
import NovaTools from '@/nova-tools/NovaTools'

/**
 * Composant racine permettant d'encapsuler une application
 */
export default {
  name: 'NApplication',
  components: { NApplicationNotificationsCenter },
  computed: {
    getAllComponents () {
      return NovaTools.modal.getAll()
    },
  },
  mounted () {
    NovaTools.modal.refGetter = ({ id }) => this.$refs[`root-component-${id}`]
  },
}
</script>

<style lang="scss" scoped>
$notifications-white-space: map-get($spacers, 4);

.n-application {
  position: relative;

  &__notifications-center {
    position: absolute;
    bottom: $notifications-white-space;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
    width: calc(100% - #{$notifications-white-space * 2});
    z-index: 250;

    @include media-md {
      max-width: 450px;
    }
  }
}
</style>