<template>
  <div class="app-infinite-scrolling" data-test="app-infinite-scrolling">
    <!-- @slot Slot pour insérer le contenu à wrapper -->
    <slot v-bind="sharedData" />
    <v-lazy
      :key="lazyKey"
      v-model="hasBeenIntercepted"
      class="app-infinite-scrolling__interceptor"
    />
    {{ requiredErrorMessage }}
    <slot v-if="isLoadingComponentVisible" name="loading-component" v-bind="sharedData" />
  </div>
</template>

<script>
/**
 * Composant permettant d'exploiter un Infinite Scroller d'API easy-care.
 * Il permet d'automatiser la récupération de la page suivante
 * lorsqu'on arrive en bas de page
 */

import EcApiInfiniteScroller from '@/services/classes/EcApiInfiniteScroller'

export default {
  name: 'InfiniteScrollingWrapper',
  props: {
    /**
     * URL de l'api paginée
     * Requis | gestion du requis déléguée dans la computed "infiniteScroller"
     */
    apiResourceUrl: {
      type: String,
      default: null,
    },

    /**
     * Nombre d'élément par page
     * @default 30
     */
    itemPerPage: {
      type: Number,
      default: 30,
    },

    /**
     * Classe du modèle à appliquer automatiquement
     */
    itemModelClass: {
      type: Function,
      default: null,
    },

    /**
     * Filtres à appliquer sur la requête
     */
    filters: {
      type: Array,
      default: () => ([]),
    },

    /**
     * Convertisseur custom des résultats.
     * Permet de retourner une version différente de chaque résultat selon des critères custom.
     */
    resultConverter: {
      type: Function,
      default: null,
    },

    /**
     * Instance du scroller infini
     */
    infiniteScroller: {
      type: EcApiInfiniteScroller,
      default: null,
    },

    /**
     * Permet de savoir s'il faut charger le contenu au chargement du listing
     */
    forceLoad: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    const forceIntersect = !! this.forceLoad
    return {
      localInfiniteScroller: null,
      hasBeenIntercepted: forceIntersect,
      lazyKey: 0, // Clé pour le VLazy qui permet de régénérer le composant
    }
  },
  computed: {
    sharedData () {
      const { items, hasInitializedContent, count } = this.localInfiniteScroller
      return {
        items,
        hasInitializedContent,
        count,
      }
    },
    isLoadingComponentVisible () {
      return this.localInfiniteScroller.hasInitializedContent && this.localInfiniteScroller.isFetchingNextPage
    },
    requiredErrorMessage () {
      if (! this.infiniteScroller && ! this.apiResourceUrl) {
        throw new Error('La props apiResourceUrl est manquante.')
      }
      return null
    },
  },
  watch: {
    infiniteScroller: {
      immediate: true,
      handler (infiniteScroller) {
        this.localInfiniteScroller = infiniteScroller
          ? infiniteScroller
          : new EcApiInfiniteScroller({
            apiResourceUrl: this.apiResourceUrl,
            itemPerPage: this.itemPerPage,
            filters: this.filters,
            itemModelClass: this.itemModelClass,
            resultConverter: this.resultConverter,
          })

        this.localInfiniteScroller.addFetchedPageItemsListener(this.onFetchedPageItems)
        this.localInfiniteScroller.addResetListener(this.onReset)
      },
    },
    hasBeenIntercepted: {
      immediate: true,
      async handler (newValue) {
        if (newValue) {
          this.$nextTick(async () => {
            await this.localInfiniteScroller.loadNextPage()
          })
        }
      },
    },
    async apiResourceUrl (apiResourceUrl) {
      this.localInfiniteScroller.setApiResourceUrl(apiResourceUrl)
    },
    async filters (filters) {
      this.localInfiniteScroller.setFilters(filters)
    },
    async itemModelClass (itemModelClass) {
      this.localInfiniteScroller.setItemModelClass(itemModelClass)
    },
    async itemPerPage (itemPerPage) {
      this.localInfiniteScroller.setItemPerPage(itemPerPage)
    },
    async resultConverter (resultConverter) {
      this.localInfiniteScroller.setResultConverter(resultConverter)
    },
  },
  beforeDestroy () {
    this.localInfiniteScroller.removeListeners()
  },
  methods: {
    /**
     * Permet au VLazy (Interceptor) de savoir s'il doit déclencher un chargement
     */
    resetIntersector (forceIntersection = false) {
      this.hasBeenIntercepted = false

      if (forceIntersection) {
        this.$nextTick(() => {
          this.hasBeenIntercepted = true
        })
      }
    },
    onReset () {
      this.resetIntersector(true)
    },
    onFetchedPageItems (fetchedPageItems) {
      this.resetIntersector()
      this.lazyKey ++

      this.$emit('fetch-page', fetchedPageItems)
    },
    refresh () {
      this.localInfiniteScroller.refresh()
    },
  },
}
</script>

<style lang="scss" scoped>
.app-infinite-scrolling {
  // Permet au composant v-lazy intercepter l'intersection du bas de la liste pour des skeletons avec des bordures ou des dividers
  &__interceptor {
    margin-bottom: 1px;
  }
}
</style>