import Event from './Event'

import Patient from '@/modules/patient/models/Patient'

import { APPOINTMENT_STATES } from '@/modules/agenda/constants'
import NovaTools from '@/nova-tools/NovaTools'

export default class Appointment extends Event {
  constructor (options = {}) {
    super(options)

    const date = new Date()
    this.startDateTime = options.startDateTime || date.toISOString()
    this.endDateTime = options.endDateTime || date.toISOString()
    this.arrivalDateTime = options.arrivalDateTime || null

    this.schedule = options.schedule || null
    this.patient = new Patient(options.patient)
    this.motive = options.motive || null

    this.source = options.source || 'easycare'
    
    this.state = options.state || 'confirmed'
    this.note = options.note || null

    this.consultation = options.consultation || null
  }

  /**
   * Permets de savoir si l'état du rendez-vous correspond ou non à un statut non honoré
   * @returns {Boolean}
   */
  isHonored () {
    const CANCELED_STATES = [APPOINTMENT_STATES.EXCUSED_PATIENT, APPOINTMENT_STATES.NOT_EXCUSED_PATIENT]
    return ! CANCELED_STATES.find(canceledState => canceledState.value === this.state)
  }

  /**
   * Permets de savoir si l'état du rendez-vous correspond ou non à un statut "patient vu"
   * @returns {Boolean}
   */
  isSeen () {
    return (this.state === APPOINTMENT_STATES.SEEN_PATIENT.value)
  }

  getStartDateTime () {
    return new Date(this.startDateTime)
  }

  getEndDateTime () {
    return new Date(this.endDateTime)
  }

  /**
   * Retourne l'heure de début du rendez-vous au format HH:mm
   * @returns {String}
   */
  getStartTime () {
    return NovaTools.dates.format(this.getStartDateTime(), 'HH:mm')
  }

  getLabel (inVisibleTimeRange = false) {
    const timedPrefix = inVisibleTimeRange
      ? ''
      : this.getStartDateTime().toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit',
      })

    return [
      timedPrefix,
      this.patient.civilState,
    ].join(' ')
  }

  /**
   * Retourne la date du RDV formatée avec le jour de la semaine
   * Exemple : "Mardi 05 avril 2024"
   * @returns {String}
   */
  getStartDateWithDayOfWeek () {
    const date = NovaTools.dates.format(this.getStartDateTime(), 'eeee dd MMMM yyyy')
    return date.charAt(0).toUpperCase() + date.slice(1)
  }

  /**
   * Retourne la période de temps dans un format du type "10h30 - 11h30"
   * @returns {String}
   */
  getFormattedTimePeriod () {
    const startHour = NovaTools.dates.format(this.getStartDateTime(), 'HH')
    const startMinutes = NovaTools.dates.format(this.getStartDateTime(), 'mm')
    const endHour = NovaTools.dates.format(this.getEndDateTime(), 'HH')
    const endMinutes = NovaTools.dates.format(this.getEndDateTime(), 'mm')

    return startHour + 'h' + startMinutes + ' - ' + endHour + 'h' + endMinutes
  }
}