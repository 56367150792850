/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity'
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField'

const patientPathologyEntity = new DocumentEntity('PatientPathology', 'patientPathologies.xml', 'Contient les pathologies des patients')

patientPathologyEntity.addField(new DocumentEntityField('label', 'Le label'))
patientPathologyEntity.addField(new DocumentEntityField('note', 'La note'))
patientPathologyEntity.addField(new DocumentEntityField('important', 'Est important'))

export default patientPathologyEntity