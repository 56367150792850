<template>
  <header>
    <app-list-header
      name="Évènement"
      :count="count"
      :is-loading="isLoading"
    />
    <section class="accounting-list-widget-section">
      <accounting-list-widget
        v-for="(dataset, index) in datasetSummary"
        :key="index"
        class="accounting-list-widget-section__item"
        :title="dataset.displayableName"
        :items="dataset.items"
      />
    </section>
    <section class="header-actions mt-2">
      <n-button
        v-test="'export-csv-button'"
        icon="download"
        label="Télécharger la liste au format CSV"
        bg-color="primary"
        transparent
        size="small"
        :loading="isExporting"
        @click="exportCsv"
      />
    </section>
  </header>
</template>

<script>
import AppListHeader from '@/components/ui/listing/AppListHeader.vue'
import { getFromAPI } from '@/services/api'
import { downloadCsv } from '@/utils/functions/files'
import AccountingListWidget from '@/modules/accounting/components/AccountingListWidget.vue'

export default {
  name: 'AccountingListHeader',
  components: {
    AccountingListWidget,
    AppListHeader,
  },
  props: {
    /**
     * Si le contenu est en cours de chargement
     */
    isLoading: {
      type: Boolean,
      default: true,
    },
    /**
     * Nombre d'évènements
     */
    count: {
      type: Number,
      default: null,
    },
    /**
     * Tableau des filtres
     */
    filters: {
      type: Array,
      default: () => ([]),
    },
  },
  data () {
    return {
      isExporting: false,
      datasetSummary: [],
    }
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      async handler (newValue) {
        if (newValue.length > 0) {
          await this.fetchDatasetSummary()
        }
      },
    },
  },
  methods: {
    async refresh () {
      await this.fetchDatasetSummary()
    },
    async fetchDatasetSummary () {
      const response = await getFromAPI('/api/accounting_dataset_summaries', this.getFilteredAsObject())
      this.datasetSummary = response?.data['hydra:member'] || []
    },
    async exportCsv () {
      this.isExporting = true
      const endpoint = '/api/detailed_consultations'
      try {
        const { data } = await getFromAPI(endpoint, this.getFilteredAsObject(), { headers: { Accept: 'text/csv' } })
        downloadCsv(data, { fileName: 'easy-care_gestion_export.csv' })
      } finally {
        this.isExporting = false
      }
    },
    getFilteredAsObject () {
      return this.filters.reduce((obj, filter) => {
        const { name, value } = filter.getQueryParam()
        obj[name] = value
        return obj
      }, {})
    },
  },
}
</script>
<style scoped lang="scss">
  .accounting-list-widget-section {
    display: flex;
    flex-wrap: wrap;
    gap: map-get($spacers, 4);
  }

  @media screen and (max-width: 530px) {
    .accounting-list-widget-section {
      &__item {
        ::v-deep {
          .accounting-widget {
            gap: 6px;
            .accounting-widget__item__title {
              font-size: 10px !important;
            }
            .accounting-widget__item__value {
              font-size: 16px !important;
            }
          }
        }
      }
    }
  }
</style>