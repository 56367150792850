import Vue from 'vue'
import { getFromAPI, putToAPI } from '@/services/api.js'
import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI'

import MedicalDataset from '@/modules/patient/models/MedicalDataset.js'

export const state = () => ({
  medicalDataset: {},
  isLoadingMedicalDataset: false,
})

export const getters = {
  getPatientMedicalDataset: state => (patientUuid) => {
    return state.medicalDataset?.[patientUuid]
  },
  isLoadingMedicalDataset: state => state.isLoadingMedicalDataset,
}

export const mutations = {
  SET_IS_LOADING_MEDICAL_DATASET (state, isLoadingMedicalDataset) {
    state.isLoadingMedicalDataset = isLoadingMedicalDataset
  },
  SET_MEDICAL_DATASET (state, { patientUuid, medicalDataset }) {
    Vue.set(state.medicalDataset, patientUuid, medicalDataset)
  },
}

export const actions = {
  async fetchPatientMedicalDataset ({ commit }, patient) {
    commit('SET_IS_LOADING_MEDICAL_DATASET', true)
    try {
      const { data } = await getFromAPI(patient.medicalDataset)
      commit('SET_MEDICAL_DATASET', {
        patientUuid: getUUIDFromIRI(data.patient),
        medicalDataset: new MedicalDataset(data),
      })
    } finally {
      commit('SET_IS_LOADING_MEDICAL_DATASET', false)
    }
  },
  async updatePatientMedicalDataset ({ commit }, { patient, medicalDataset }) {
    const { data } = await putToAPI(patient.medicalDataset, { data: medicalDataset })
    commit('SET_MEDICAL_DATASET', {
      patientUuid: getUUIDFromIRI(data.patient),
      medicalDataset: new MedicalDataset(data),
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}