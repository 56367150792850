import Vue from 'vue'
import { getFromAPI, postToAPI, putToAPI } from '@/services/api'
import Medication from '@/modules/patient/models/Medication'

export const state = () => ({
  ongoingMedications: [],
  isLoadingOngoingMedications: false,
})

export const getters = {
  getPatientOngoingMedications: (state) => patientUuid => {
    return (state.ongoingMedications[patientUuid] ? state.ongoingMedications[patientUuid] : [])
  },
  isLoadingOngoingMedications: state => state.isLoadingOngoingMedications,
}

export const mutations = {
  SET_ONGOING_MEDICATION (state, { patientUuid, medication }) {
    if (state.ongoingMedications[patientUuid] === undefined) {
      Vue.set(state.ongoingMedications, patientUuid, [])
    }
    state.ongoingMedications[patientUuid].push(medication)
  },
  UPDATE_ONGOING_MEDICATION (state, { patientUuid, medication }) {
    state.ongoingMedications[patientUuid].forEach(item => {
      if (item['@id'] === medication['@id']) {
        item.product = medication.product
        item.endDate = medication.endDate
        item.longTerm = medication.longTerm
      }
    })
  },
  RESET_PATIENT_ONGOING_MEDICATIONS (state, { patientUuid }) {
    if (state.ongoingMedications[patientUuid] !== undefined) {
      state.ongoingMedications[patientUuid] = []
    }
  },
  SET_IS_LOADING (state, visibility) {
    state.isLoadingOngoingMedications = visibility
  },
}

export const actions = {
  async fetchOngoingMedications ({ commit }, patientUuid) {
    commit('SET_IS_LOADING', true)
    try {
      commit('RESET_PATIENT_ONGOING_MEDICATIONS', { patientUuid })
      const { data } = await getFromAPI(`/api/patients/${patientUuid}/ongoing_medications`)
      const ongoingMedications = data['hydra:member']
      ongoingMedications.forEach((medication) => {
        commit('SET_ONGOING_MEDICATION', {
          patientUuid,
          medication: new Medication(medication),
        })
      })
    } catch (error) {}
    commit('SET_IS_LOADING', false)
  },
  async insertOngoingMedication ({ commit }, { patientUuid, medication }) {
    const { data } = await postToAPI('/api/external_medications', {
      patient: `/api/patients/${patientUuid}`,
      longTerm: medication.longTerm,
      product: new Medication(medication).product,
      endDate: new Medication(medication).endDate,
    })
    const newMedication = new Medication(data)
    newMedication.healthProductLine = data?.healthProductLine || data?.['@id']
    newMedication.healthProductLine = newMedication.getIri()
    commit('SET_ONGOING_MEDICATION', {
      patientUuid,
      medication: newMedication,
    })
  },
  async updateOngoingMedication ({ commit }, { patientUuid, medication }) {
    await putToAPI(medication.healthProductLine, {
      data: {
        patient: `/api/patients/${patientUuid}`,
        longTerm: medication.longTerm,
        product: new Medication(medication).product,
        endDate: new Medication(medication).endDate,
      },
    })
    commit('UPDATE_ONGOING_MEDICATION', {
      patientUuid,
      medication: new Medication(medication),
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}