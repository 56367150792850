import ApiEntity from '@/models/ApiEntity'
export default class CheckDataset extends ApiEntity {
  /**
   * @param { {bankName: String, holderName: String, checkNumber: number} } options
   */
  constructor (options = {}) {
    super(options)
    this.bankName = options.bankName || null
    this.holderName = options.holderName || null
    this.checkNumber = options.checkNumber || null
  }
}