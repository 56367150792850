import ApiEntity from '@/models/ApiEntity'

export default class Measure extends ApiEntity {
  constructor (options = {}) {
    super(options)
    this.name = options.name
    this.value = options.value || null
    this.patient = options.patient || null //IRI
    this.measuredAt = options.measuredAt || null
    this.consultation = options.consultation || null //IRI
    this.consultationCreatedAt = options.consultationCreatedAt || null
    this.sortIndex = options.sortIndex || null
    this.editable = options.editable ?? true
    this.unit = options.unit || null
    this.label = options.label || null
  }
}