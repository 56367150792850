<template>
  <v-alert
    class="n-alert"
    :class="{ 'n-alert--transparent': transparent }"
    :type="type"
    :text="transparent"
    @mouseenter="stopProgressBar"
    @mouseleave="hasTimeout && resumeProgressBar()"
  >
    <section v-if="isBadgeVisible" v-test="'badge-container'" class="n-alert__badge-container">
      <div class="n-alert__badge-container__badge" :style="badgeStyle">
        {{ badgeCount }}
      </div>
    </section>

    <h3 v-if="title" v-test="'alert-title'" class="n-alert__title">
      {{ title }}
    </h3>
    <p v-test="'alert-message'" class="n-alert__message">
      {{ message }}
    </p>
    <n-button-actions v-if="actions" :actions="actions" class="mt-1 mx-n4" />
    <div
      v-if="hasTimeout"
      ref="timeoutProgress"
      v-test="'timeout-progress'"
      class="n-alert__timeout-progress"
      :style="{ width: progressBarWidth }"
    />
    <template #prepend>
      <v-icon size="16" class="n-alert__icon">
        {{ alertIcon }}
      </v-icon>
    </template>
    <template v-if="closable" #append>
      <!--
        Permet d'intercepter le clic sur le bouton de fermeture de l'alerte
        @event click:close
       -->
      <v-btn
        v-test="'alert-close-icon'"
        icon
        class="n-alert__close-icon"
        small
        @click="$emit('click:close')"
      >
        <v-icon>
          fas fa-times
        </v-icon>
      </v-btn>
    </template>
  </v-alert>
</template>

<script>
import NButtonActions from '@/nova-ui/NButtonActions/NButtonActions.vue'

import { ALERT_TYPES } from '@novalys/src/constants/alertTypes'

/**
 * Transmet des informations importantes à l'utilisateur, contextualisées via un type et un message
 */
export default {
  name: 'NAlert',
  components: { NButtonActions },
  props: {
    /**
     * Le type de l'alerte
     * @values error, info, success, warning
     */
    type: {
      type: String,
      default: ALERT_TYPES.INFO.name,
      validator: type => Object.values(ALERT_TYPES).map(alertType => alertType.name).includes(type),
    },
    /**
     * Dans le cas ou l'alerte aurait besoin de plus de contexte, un titre peut être spécifié
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * Le message de l'alerte
     */
    message: {
      type: String,
      required: true,
    },
    /**
     * Affiche une ou plusieurs actions sous la forme de boutons
     */
    actions: {
      type: Array,
      default: null,
    },
    /**
     * Affiche un badge en haut à gauche de la notification avec la valeur renseignée,
     * dés lors que celle-ci est supérieure à 1.
     */
    badgeCount: {
      type: Number,
      default: null,
    },
    /**
     * Indique à l'utilisateur le temps restant avant la suppression de l'alerte
     */
    timeout: {
      type: Number,
      default: null,
    },
    /**
     * Permet d'appliquer un style transparent à l'alerte
     */
    transparent: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      progressBarAnimationFrame: null,
      currentProgressTimeout: 0,
      clock: null,
      progressTimeStart: null,
      badgeStyle: null,
    }
  },
  computed: {
    hasTimeout () {
      return this.timeout > 0
    },
    alertIcon () {
      return ALERT_TYPES[this.type.toUpperCase()].icon
    },
    closable () {
      return this.$listeners['click:close']
    },
    progressBarWidth () {
      return `${Math.min(1, this.currentProgressTimeout / this.timeout) * 100}%`
    },
    isBadgeVisible () {
      return this.badgeCount && this.badgeCount > 1
    },
  },
  watch: {
    badgeCount () {
      if (this.isBadgeVisible) {
        this.shakeBadge()
      }
    },
  },
  mounted () {
    if (this.hasTimeout) {
      this.resumeProgressBar()
    }
  },
  methods: {
    restartTimeout () {
      this.currentProgressTimeout = 0
      this.progressTimeStart = this.clock
    },
    resumeProgressBar () {
      requestAnimationFrame(this.animateProgressBar)
    },
    stopProgressBar () {
      this.progressTimeStart = null
      cancelAnimationFrame(this.progressBarAnimationFrame)
    },
    animateProgressBar (clock) {
      this.clock = clock
      if (this.progressTimeStart === null) {
        this.progressTimeStart = this.currentProgressTimeout ? (this.clock - this.currentProgressTimeout) : this.clock
      }
      this.currentProgressTimeout = this.clock - this.progressTimeStart
      if (this.currentProgressTimeout < this.timeout) {
        this.progressBarAnimationFrame = requestAnimationFrame(this.animateProgressBar, this.currentProgressTimeout)
        return
      }
      this.$emit('timeout-end')
    },
    async shakeBadge () {
      this.badgeStyle = null
      await this.$nextTick()
      this.badgeStyle = {
        animationName: 'badge-shake',
        animationDuration: '.42s',
      }
    },
  },
}
</script>

<style lang="scss">
@keyframes badge-shake {
  15% { transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg) }
  30% { transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg) }
  45% { transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg) }
  60% { transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg) }
  75% { transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg) }
}
</style>

<style lang="scss" scoped>
$alert-border-radius: 6px;
$alert-white-space: map-get($spacers, 3);
$alert-badge-size: 20px;

.n-alert {
  padding: $alert-white-space;
  margin-bottom: 0;
  border-radius: $alert-border-radius;

  &--transparent {
    ::v-deep {
      .n-button {
        color: currentColor;
      }
    }
  }

  ::v-deep {
    .n-button {
      height: 30px;

      .v-btn__content {
        font-size: 13px;
      }
    }
  }

  &__icon,
  &__close-icon {
    margin-top: 2px;
    margin-bottom: auto;
    color: currentColor !important;
  }

  &__icon {
    margin-right: $alert-white-space;
  }

  &__close-icon {
    margin-left: $alert-white-space;
    margin-top: -#{map-get($spacers, 2)};
    margin-right: -#{map-get($spacers, 2)};
  }

  &__title {
    font-size: 13px;
    font-weight: 500;
  }

  &__message {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0;
  }

  &__badge-container {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-25%, -25%);

    &__badge {
      padding: 0 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: $alert-badge-size;
      height: $alert-badge-size;
      background-color: var(--v-blueGrey-darken4);
      color: #fff;
      border-radius: $alert-badge-size / 2;
      font-size: 13px;
      font-weight: 500;
    }
  }

  &__timeout-progress {
    position: absolute;
    bottom: 0;
    left: $alert-border-radius;
    background-color: white;
    opacity: .4;
    height: 4px;
    max-width: calc(100% - #{$alert-border-radius * 2});
  }
}
</style>