import ApiEntity from '@/models/ApiEntity'
import { MEDIA_TYPES } from '@/constants'

export default class ContactPoint extends ApiEntity {
  constructor (options = {}) {
    super(options)
    this.value = options.value || null
    this.media = options.media || null
    this.source = options.source || 'easycare'
    this.label = options.label || null
  }

  /**
   * Formate la valeur de contact
   * Ajoute des espaces aux numéros de téléphone
  */
  get formattedValue () {
    if (this.media === MEDIA_TYPES.TELEPHONE.value) {
      if (this.value && ! (this.value.startsWith('+'))) {
        return this.value.replaceAll(' ', '').replace(/.{2}/g, '$&' + ' ').trim()
      }
    }
    return this.value
  }

  /**
   * Retire les ContactPoints sans valeur et retire la clé label si elle n'existe pas
   * @param {Array<ContactPoint>} contactPointsArray
   * @returns {Array<ContactPoint>}
   */
  static removeEmptyContactPoints (contactPointsArray) {
    return contactPointsArray
      .filter(contactPoint => contactPoint.value)
      .map(contactPoint => {
        if (! contactPoint.label) {
          delete contactPoint['label']
        }
        return contactPoint
      })
  }
}