<template>
  <app-tabs
    outlined
    transparent
    :spacing="6"
    :spacing-x="0"
  >
    <app-tabs-item label="Planning du jour">
      <app-row>
        <app-col cols="12" md="8">
          <dashboard-events-panel v-test="'dashboard-events-panel'" :events="getDailyAppointmentsDashboard(getAppointmentsByDay())" :loading="isFetchingDailyEvents" />
        </app-col>
        <app-col class="dashboard__right-column" cols="12" md="4">
          <dashboard-events-secondary-panel
            v-test="'dashboard-events-not-honored-panel'"
            :events="getDailyAppointmentsNotHonored(getAppointmentsByDay())"
            :loading="isFetchingDailyEvents"
            :title="'Rendez-vous non honorés'"
          />
          <dashboard-events-secondary-panel
            v-test="'dashboard-events-seen-panel'"
            :events="getDailyAppointmentsSeen(getAppointmentsByDay())"
            :loading="isFetchingDailyEvents"
            :title="'Patients vus'"
            prepend-time-field="startDateTime"
          />
        </app-col>
      </app-row>
    </app-tabs-item>
    <app-tabs-item
      v-if="isCurrentUserPractitioner"
      v-test="'dashboard-documents-tab'"
      label="Documents"
      badge-color="error"
      :badge-content="pendingMssDocuments.length"
    >
      <app-row>
        <app-col cols="12">
          <dashboard-documents-panel
            :pending-documents.sync="pendingMssDocuments"
            :pending-documents-loading="isFetchingPendingMssDocuments"
            @document-attach-failed="handleDocumentAttachFail"
            @document-ready-to-attach="handleDocumentReadyToAttach"
            @refresh="refreshPendingMssDocuments"
          />
        </app-col>
      </app-row>
    </app-tabs-item>
    <app-tabs-item
      v-if="isCurrentUserPractitioner"
      v-test="'dashboard-vsm-alert-tab'"
      badge-color="error"
      :badge-content="healthSummaryReminders.length"
      label="Alertes VSM"
    >
      <app-row>
        <app-col cols="12">
          <health-summary-reminders-panel
            :health-summary-reminders="healthSummaryReminders"
            :loading="isFetchingHealthSummaryReminders"
            :keywords-filter.sync="healthSummaryRemindersKeywords"
            :pathologies-filter.sync="healthSummaryRemindersPathologies"
          />
        </app-col>
      </app-row>
    </app-tabs-item>
    <app-tabs-item v-if="isCurrentUserPractitioner" v-test="'dashboard-ins-tab'" label="Retours INS">
      <app-row>
        <app-col v-if="isCurrentUserAdmin" cols="12">
          <ins-call-logs-panel />
        </app-col>
        <app-col cols="12">
          <ins-demotion-panel />
        </app-col>
      </app-row>
    </app-tabs-item>
    <app-tabs-item v-if="isCurrentUserPractitioner" v-test="'dashboard-analytics-tab'" label="Statistiques">
      <analytics-section />
    </app-tabs-item>
  </app-tabs>
</template>

<script>
import AnalyticsSection from '@/modules/users/components/dashboard/analytics/AnalyticsSection.vue'
import DashboardEventsPanel from '@/modules/users/components/dashboard/events/DashboardEventsPanel.vue'
import DashboardEventsSecondaryPanel from '@/modules/users/components/dashboard/events/DashboardEventsSecondaryPanel.vue'
import DashboardDocumentsPanel from '@/modules/users/components/dashboard/documents/DashboardDocumentsPanel.vue'
import InsDemotionPanel from '@/modules/users/components/dashboard/ins/InsDemotionPanel.vue'
import InsCallLogsPanel from '@/modules/users/components/dashboard/ins/InsCallLogsPanel.vue'
import HealthSummaryRemindersPanel from '@/modules/users/components/dashboard/healthSummary/HealthSummaryRemindersPanel.vue'

import { fetchPendingMssDocuments, refreshPendingMssDocuments } from '@/modules/secureMessaging/modules/documents/utils/pendingDocuments'
import { getFromAPI, putToAPI } from '@/services/api'
import { mapActions, mapGetters } from 'vuex'

import PendingMssDocument from '@/modules/secureMessaging/modules/documents/models/PendingMssDocument'

import Appointment from '@/modules/agenda/models/events/Appointment'
import HealthSummaryReminder from '@/modules/patient/components/vsm/models/HealthSummaryReminder'

export default {
  name: 'UserDashboard',
  components: {
    AnalyticsSection,
    DashboardEventsSecondaryPanel,
    DashboardEventsPanel,
    DashboardDocumentsPanel,
    InsDemotionPanel,
    InsCallLogsPanel,
    HealthSummaryRemindersPanel,
  },
  data () {
    return {
      isFetchingDailyEvents: false,
      isFetchingHealthSummaryReminders: false,
      isFetchingPendingMssDocuments: false,
      healthSummaryRemindersKeywords: [],
      healthSummaryRemindersPathologies: [],
      healthSummaryReminders: [],
      pendingMssDocuments: [],
    }
  },
  computed: {
    ...mapGetters('agenda', ['getAppointmentsByDay']),
    ...mapGetters('auth', ['getCurrentUser', 'isCurrentUserPractitioner']),
    ...mapGetters('patient', ['isHealthSummaryRemindersUpdated']),
    isCurrentUserAdmin () {
      return this.getCurrentUser.isAdmin
    },
  },
  watch: {
    healthSummaryRemindersKeywords () {
      this.fetchHealthSummaryReminders()
    },
    healthSummaryRemindersPathologies () {
      this.fetchHealthSummaryReminders()
    },
    isHealthSummaryRemindersUpdated () {
      if (this.isHealthSummaryRemindersUpdated === true) {
        this.setIsHealthSummaryRemindersUpdated(false)
        this.fetchHealthSummaryReminders()
      }
    },
  },
  created () {
    this.fetchDashboardDailyEvents()
    if (this.isCurrentUserPractitioner) {
      this.fetchHealthSummaryReminders()
      this.fetchPendingMssDocuments()
    }
  },
  methods: {
    ...mapActions('agenda', ['fetchAppointments', 'fetchAgendaDatas']),
    ...mapActions('patient', ['setIsHealthSummaryRemindersUpdated']),
    handleDocumentAttachStateChange (document, documentAttachmentFailedAt) {
      const documentIndex = this.pendingMssDocuments.findIndex(_document => _document['@id'] === document['@id'])
      const newDocument = new PendingMssDocument({
        ...document,
        documentAttachmentFailedAt,
      })
      this.$set(this.pendingMssDocuments, documentIndex, newDocument)
      putToAPI(document['@id'], { data: newDocument })
    },
    handleDocumentAttachFail (document) {
      this.handleDocumentAttachStateChange(document, new Date())
    },
    handleDocumentReadyToAttach (document) {
      this.handleDocumentAttachStateChange(document, null)
    },
    async fetchDashboardDailyEvents () {
      this.isFetchingDailyEvents = true
      await Promise.all([
        this.fetchAgendaDatas(),
        this.fetchAppointments({
          fromDate: new Date(),
          toDate: new Date(),
        }),
      ])
      this.isFetchingDailyEvents = false
    },
    async fetchHealthSummaryReminders () {
      this.isFetchingHealthSummaryReminders = true
      try {
        const { data } = await getFromAPI('/api/health_summary_reminders', {
          patient_keywords: this.healthSummaryRemindersKeywords.map(keyword => keyword['@id']),
          patient_pathologies: this.healthSummaryRemindersPathologies.map(pathology => pathology.icd10Code),
        })
        this.healthSummaryReminders = data['hydra:member'].map(healthSummaryReminder => new HealthSummaryReminder(healthSummaryReminder))
      } finally {
        this.isFetchingHealthSummaryReminders = false
      }
    },
    async updatePendingMssDocuments (documentsGetter) {
      this.isFetchingPendingMssDocuments = true
      try {
        this.pendingMssDocuments = await documentsGetter()
      } finally {
        this.isFetchingPendingMssDocuments = false
      }
    },
    async fetchPendingMssDocuments () {
      await this.updatePendingMssDocuments(fetchPendingMssDocuments)
    },
    async refreshPendingMssDocuments () {
      await this.updatePendingMssDocuments(refreshPendingMssDocuments)
    },
    getDailyAppointmentsDashboard (appointments) {
      return this.sortAppointmentsListFromStartDate(appointments.filter(appointment => (new Appointment(appointment).isHonored() && ! new Appointment(appointment).isSeen())))
    },
    getDailyAppointmentsNotHonored (appointments) {
      return this.sortAppointmentsListFromStartDate(appointments.filter(appointment => ! new Appointment(appointment).isHonored()))
    },
    getDailyAppointmentsSeen (appointments) {
      return this.sortAppointmentsListFromStartDate(appointments.filter(appointment => new Appointment(appointment).isSeen()))
    },
    sortAppointmentsListFromStartDate (appointments) {
      return appointments.sort((a, b) => new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime())
    },
  },
}
</script>
<style scoped lang="scss">
.dashboard {
  &__right-column {
    display: flex;
    flex-direction: column;
    gap: map-get($spacers, 6);
  }
}
</style>