<template>
  <v-skeleton-loader
    :width="width"
    :height="height"
    :type="type"
    class="app-skeleton-loader"
  />
</template>

<script>
/**
 * Le squelette de chargement de l'application
 */
export default {
  name: 'AppSkeletonLoaders',
  props: {
    /**
     * Indique le type de squelette à afficher
     * Plus d'information sur les valeurs possibles @see {@link https://vuetifyjs.com/en/api/v-skeleton-loader/#props-type}
     */
    type: {
      type: String,
      required: true,
    },
    /**
     * Indique la largeur du squelette de chargement
     * @default '100%'
     */
    width: {
      type: [String, Number],
      default: '100%',
    },
    /**
     * Indique la hauteur du squelette de chargement
     * @default null
     */
    height: {
      type: [String, Number],
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.app-skeleton-loader {
  ::v-deep {
    .v-skeleton-loader{
      &__image {
        height: 100%;
      }
    }
  }
}
</style>