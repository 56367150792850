<template>
  <app-form :submit-method="linkKeywords">
    <app-text-field
      v-model="search"
      label="Rechercher un mot-clef"
      prepend-icon="search"
      hide-errors="auto"
      clearable
      data-test="keywords_field"
      autofocus
    />

    <n-list
      class="keywords-list"
      :items="filteredKeywords"
      empty-text="Aucun mot-clef disponible"
      :loading="isFetchingKeywords"
      :skeleton-count="3"
    >
      <template #item="{ item }">
        <article
          class="keyword"
          :class="{'selected' : isSelected(item) }"
          @click="toggleKeywordSelection(item)"
        >
          <app-color-dot :color="item.color" :size="24" :icon="isSelected(item) ? 'check' : ''" />
          <p class="keyword__infos">
            <span class="keyword__infos__label">{{ item.label }}</span>
            <span class="keyword__infos__description secondary--text">{{ item.description }}</span>
          </p>
        </article>
      </template>
      <template #skeleton>
        <app-skeleton-loader type="list-item-avatar-two-line" height="60" />
      </template>
    </n-list>
  </app-form>
</template>

<script>
import { mapMutations } from 'vuex'
import { getFromAPI, putToAPI } from '@/services/api'

import AppForm from '@/components/ui/form/AppForm.vue'
import AppTextField from '@/components/ui/form/AppTextField.vue'
import AppColorDot from '@/components/ui/form/color/AppColorDot.vue'
import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue'

import Patient from '@/modules/patient/models/Patient'
import PatientKeyword from '@/modules/patient/models/PatientKeyword'
import { cloneDeep } from 'lodash'

export default {
  name: 'PatientKeywordsLinkForm',
  components: {
    AppForm,
    AppTextField,
    AppColorDot,
    AppSkeletonLoader,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      isFetchingKeywords: false,
      search: '',
      keywords: [],
      selectedKeywords: [],
    }
  },
  computed: {
    filteredKeywords () {
      if (this.search) {
        return this.keywords.filter((keyword) => {
          return [keyword.label, keyword.description].join(' ').toLowerCase().includes(this.search.toLowerCase())
        })
      }
      return this.keywords
    },
  },
  watch: {
    patient () {
      this.selectedKeywords = cloneDeep(this.patient.keywords)
    },
  },
  async mounted () {
    this.fetchKeywords()
    this.selectedKeywords = cloneDeep(this.patient.keywords)
  },
  methods: {
    ...mapMutations('app', { setSnack: 'SET_SNACK' }),
    async fetchKeywords () {
      this.isFetchingKeywords = true
      try {
        const { data } = await getFromAPI('/api/patient_keywords')
        this.keywords = data['hydra:member'].map(keyword => new PatientKeyword(keyword))
      } finally {
        this.isFetchingKeywords = false
      }
    },
    isSelected (keyword) {
      return this.selectedKeywords.some((selectedKeyword) => {
        return selectedKeyword.getIri() === keyword.getIri()
      })
    },
    toggleKeywordSelection (keyword) {
      if (this.isSelected(keyword)) {
        this.selectedKeywords = this.selectedKeywords.filter((selectedKeyword) => {
          return selectedKeyword.getIri() !== keyword.getIri()
        })
      } else {
        this.selectedKeywords.push(keyword)
      }
    },
    async linkKeywords () {
      const selectedKeywordsIris = this.selectedKeywords.map((keyword) => {
        return keyword.getIri()
      })
      await putToAPI(this.patient.getIri(), { data: { keywords: selectedKeywordsIris } })
      this.setSnack({ message: 'Les mots-clefs ont été rajoutés au patient avec succès' })
      return this.selectedKeywords
    },
  },
}
</script>

<style lang="scss" scoped>
  .keywords-list {
    margin-top: 10px;
    padding-left: 0;
    height: 200px;
    overflow: auto;
  }

  .keyword {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color .3s ease-out;

    &__infos {
      &__label,
      &__description {
        display: block;
        margin-left: 10px;
      }

      &__label {
        color: var(--v-content-base);
        font-size: 13px;
        font-weight: 500;
      }
      &__description {
        font-size: 12px;
        color: var(--v-secondary);
      }
    }

    &:hover {
      background-color: var(--v-secondary-lighten5);
    }

    &.selected {
      .keyword__infos {
        &__label {
          font-weight: 700;
        }
      }
    }
  }
</style>