<template>
  <app-form :submit-method="submit" class="correspondent-form">
    <template v-if="contact">
      <h1>
        {{ correspondentCivilState }}
      </h1>
      <p class="mb-2">
        {{ correspondentProfession }}
      </p>
    </template>
    <app-textarea-field v-model="localNote" label="Commentaire" :rows="4" />
    <edit-correspondent-form-part
      v-model="localContactPointsPhones"
      v-test="'phone-form'"
      media="telephone"
      title="Téléphones"
    />
    <edit-correspondent-form-part
      v-model="localContactPointsMails"
      v-test="'email-form'"
      media="email"
      title="Emails"
    />
  </app-form>
</template>

<script>
import NovaTools from '@/nova-tools/NovaTools'

import AppForm from '@/components/ui/form/AppForm.vue'
import AppTextareaField from '@/components/ui/form/AppTextareaField'
import EditCorrespondentFormPart
  from '@/modules/patient/modules/patientFile/modules/correspondents/components/correspondentsPanel/forms/EditCorrespondentFormPart'
import { putToAPI } from '@/services/api'
import Contact from '@/models/user/Contact'

export default {
  name: 'EditCorrespondentForm',
  components: {
    EditCorrespondentFormPart,
    AppTextareaField,
    AppForm,
  },
  props: {
    /**
     * Patient avec lequel le correspondent sera lié
     */
    value: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      localContactPointsPhones: [],
      localContactPointsMails: [],
      localNote: null,
      contact: null,
    }
  },
  computed: {
    correspondentCivilState () {
      return this.contact.getCivilState()
    },
    correspondentProfession () {
      return this.contact?.profession
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (newValue) {
        if (newValue) {
          this.localContactPointsPhones = JSON.parse(JSON.stringify(newValue.contact.contactPoints.filter(item => (item.value && item.media === 'telephone' && item.source !== 'health_directory'))))
          this.localContactPointsMails = JSON.parse(JSON.stringify(newValue.contact.contactPoints.filter(item => (item.value && item.media === 'email' && item.source !== 'health_directory'))))
          this.localNote = newValue.note
          this.contact = new Contact(newValue.contact)
        }
      },
    },
  },
  methods: {
    async submit () {
      try {
        await putToAPI(this.value['@id'], {
          data: {
            note: this.localNote,
            contact: {
              '@id': this.value.contact['@id'],
              contactPoints: [
                ...this.localContactPointsPhones,
                ...this.localContactPointsMails,
                ...this.value.contact.contactPoints.filter(item => (item.value && item.source === 'health_directory')),
              ],
            },
          },
        })
        NovaTools.notify.success('Le correspondant a été modifié avec succès')
      } catch (e) {
        NovaTools.notify.error(e.response.data.violations[0].message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.correspondent-form {
  display: flex;
  flex-direction: column;

  &__title {
    font-weight: 600;
    font-size: 16px;
    color: var(--v-content-base) !important;
    margin-top: map-get($spacers, 6);
  }

}
</style>