/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity'
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField'
import DocumentEntityRelation from '@/modules/dataPortability/classes/DocumentEntityRelation'

const patientRelativeEntity = new DocumentEntity('PatientRelative', 'patientRelatives.xml', 'Contient les données des proches des patient')

patientRelativeEntity.addField(new DocumentEntityField('firstNames', 'Les informations de contact', new DocumentEntityRelation('Contact', 'id')))
patientRelativeEntity.addField(new DocumentEntityField('familyName', 'Le nom de famille'))
patientRelativeEntity.addField(new DocumentEntityField('birthName', 'Le nom de naissance'))
patientRelativeEntity.addField(new DocumentEntityField('note', 'La note associée'))
patientRelativeEntity.addField(new DocumentEntityField('civility', 'La civilité'))
patientRelativeEntity.addField(new DocumentEntityField('link', 'Le type de lien (spouse | father | mother | brother | sister | son | daughter | friend | curator | tutor | granddaughter | grandson | son_in_law | daughter_in_law | neighbor | other)'))
patientRelativeEntity.addField(new DocumentEntityField('address', 'L\'adresse', new DocumentEntityRelation('Address', 'id')))
patientRelativeEntity.addField(new DocumentEntityField('contactPoints', 'Les points de contact', new DocumentEntityRelation('ContactPoint', 'id')))

export default patientRelativeEntity