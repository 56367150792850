<template>
  <div class="pending-mss-document-meta-dataset-modal__content">
    <h3 class="subtitle-3 mb-4">
      Prise en charge
    </h3>
    <app-row>
      <app-col cols="12">
        <app-dataset-item label="Responsable" vertical>
          <article v-if="responsibleParty && responsibleParty.name" class="d-flex">
            <h3 class="subtitle-3 mr-2">
              {{ responsibleParty.name }}
            </h3>
            <p class="secondary--text">
              {{ responsibleParty.organisation }}
            </p>
          </article>
          <span v-else class="secondary--text">-</span>
        </app-dataset-item>
      </app-col>
    </app-row>
    <app-row>
      <app-col cols="12" md="6">
        <app-dataset-item label="Lieu" :value="documentMetaDataset.care.location || null" vertical />
      </app-col>
      <app-col cols="12" md="6">
        <app-dataset-item label="Type de sortie" :value="documentMetaDataset.care.dischargeDisposition" vertical />
      </app-col>
    </app-row>
    <app-row>
      <app-col cols="12">
        <app-dataset-item vertical label="Personnes impliquées">
          <n-list
            :items="documentMetaDataset.care.participants"
            empty-text="-"
            inline
            :spacing="4"
          >
            <template #item="{ item }">
              <article v-if="responsibleParty">
                <h3 class="subtitle-3 mr-2">
                  {{ item.name }}
                </h3>
                <p class="secondary--text">
                  {{ item.organisation }}
                </p>
              </article>
            </template>
          </n-list>
        </app-dataset-item>
      </app-col>
    </app-row>
  </div>
</template>

<script>
import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue'

export default {
  name: 'DocumentSupportedSection',
  components: { AppDatasetItem },
  props: {
    documentMetaDataset: {
      type: Object,
      required: true,
    },
  },
  computed: {
    responsibleParty () {
      if (! this.documentMetaDataset.care.responsibleParty) {
        return null
      }
      if (typeof this.documentMetaDataset.care.responsibleParty === 'string') {
        return { name: this.documentMetaDataset.care.responsibleParty }
      }
      return this.documentMetaDataset.care.responsibleParty
    },
  },
}
</script>

<style lang="scss" scoped>
.document-meta-dataset-section {
  &__content {
    color: var(--v-content-base);
    font-weight: 400;
    font-size: 13px;
    line-height: initial;
    letter-spacing: initial;
  }
}
</style>