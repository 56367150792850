<template>
  <div class="authentication-form-wrapper">
    <div class="text-center mb-4">
      <h1 v-test="'title'" class="authentication-form-wrapper__title">
        {{ title }}
      </h1>
      <p v-test="'text'" class="authentication-form-wrapper__text">
        {{ text }}
      </p>
    </div>
    <app-simple-form
      v-test="'form'"
      :form="form"
      :show-divider="false"
      @submitSuccess="$emit('submit-success')"
    >
      <template #actions="{ isValid, isSubmitting, formId }">
        <app-button
          v-test="'submit-button'"
          color="primary"
          class="authentication-form-wrapper__submit-button"
          :disabled="! isValid"
          :loading="isSubmitting"
          :form="formId"
          type="submit"
        >
          {{ submitText }}
        </app-button>
      </template>
    </app-simple-form>
  </div>
</template>

<script>
import AppSimpleForm from '@/components/ui/form/AppSimpleForm.vue'

export default {
  name: 'AuthenticationFormWrapper',
  components: { AppSimpleForm },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    submitText: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.authentication-form-wrapper {
  max-width: 450px;
  width: 100%;

  &__title {
    font-size: 20px;
    font-weight: 700;
  }

  &__text {
    font-size: 13px;
    color: var(--v-secondary-base);
    margin-bottom: 0;
  }

  &__submit-button {
    width: 100%;
  }
}
</style>