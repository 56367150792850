<template>
  <div class="settings-left-menu">
    <div class="mb-5 d-flex align-center">
      <v-icon class="mr-2">
        fas fa-cog
      </v-icon>
      <h2 class="settings-left-menu__title">
        Paramètres
      </h2>
    </div>
    <v-expansion-panels
      v-model="localValue"
      accordion
      :multiple="multiple"
      :mandatory="mandatory"
      :readonly="readonly"
    >
      <app-menu-panel
        v-for="(panel, itemIndex) in items"
        :key="itemIndex"
        :title="panel.title"
        :icon="panel.icon"
        class="settings-left-menu__panel mb-2"
        :hide-actions="readonly"
        :ripple="!readonly"
        :readonly="readonly"
      >
        <div class="d-flex flex-column pa-4">
          <component
            :is="link.disabled ? AppLink : AppMenuPanelLink"
            v-for="(link, linkIndex) in panel.links"
            :key="linkIndex"
            :to="link.to"
            v-bind="link.disabled ? { isDisabled: link.disabled, color: 'text',tooltip: 'Bientôt disponible' } : {}"
            class="settings-left-menu__panel-link mb-2 text--text"
          >
            {{ link.text }}
          </component>
        </div>
      </app-menu-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import AppMenuPanel from '@/components/ui/menuPanel/AppMenuPanel.vue'
import AppMenuPanelLink from '@/components/ui/menuPanel/AppMenuPanelLink.vue'
import AppLink from '@/components/ui/typography/link/AppLink.vue'

/**
 * Représente le menu de gauche de la partie paramètres
 */
export default {
  name: 'SettingsLeftMenu',
  components: {
    AppMenuPanel,
    AppMenuPanelLink,
  },
  props: {
    /**
     * Éléments du menu
     */
    items: {
      type: Array,
      required: true,
    },
    /**
     * Si vrai, aucune intéraction n'est possible avec les panneaux
     */
    readonly: {
      type: Boolean,
      required: false,
    },
    /**
     * Si vrai, plusieurs panneaux peuvent être dépliés
     */
    multiple: {
      type: Boolean,
      required: false,
    },
    /**
     * Si vrai, plusieurs panneaux peuvent être dépliés
     */
    mandatory: {
      type: Boolean,
      required: false,
    },
    /**
     * Valeur du menu
     */
    value: {
      type: [Number, Array],
      required: false,
      default: 0,
    },
  },
  data () {
    return {
      AppLink,
      AppMenuPanelLink,
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value || 0
      },
      set (newVal) {
        this.$emit('input', newVal)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.settings-left-menu {
  &__title {
    font-size: 16px;
    font-weight: 700;
  }

  &__panel,
  &__panel-link {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}
</style>