<template>
  <app-form
    v-test="'availability-form'"
    class="availability-form"
    :submit-method="submit"
  >
    <v-row>
      <v-col>
        <app-text-field
          v-model="value.title"
          v-test="'title-input'"
          label="Titre (optionnel)"
          name="title"
          hide-errors="auto"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <app-autocomplete
          v-model="value.schedule"
          :items="getSchedules"
          label="Agenda"
          item-text="name"
          name="schedule"
          rules="required"
          prepend-icon="schedule"
          return-object
          hide-errors="auto"
          @change="clearMotives"
        />
      </v-col>
    </v-row>
    <v-row v-if="value.schedule">
      <v-col>
        <app-selectable-list
          v-model="value.appointmentMotives"
          label="Motifs associés"
          rules="required"
          :items="selectedAgendaAppointmentMotives"
          @changed="toggleMotiveSelection"
        >
          <template #empty>
            Aucun motif disponible
          </template>
        </app-selectable-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <app-date-picker
          v-model="value.date"
          v-test="'date-input'"
          label="Date"
          name="date"
          rules="required"
          :min="new Date()"
          icon="fa-clock"
          hide-errors="auto"
        />
      </v-col>
      <v-col>
        <app-text-field
          v-model="value.intervals[0].start"
          v-test="'start-input'"
          label="Heure de début"
          name="intervals[0].start"
          rules="required"
          type="time"
          hide-errors="auto"
        />
      </v-col>
      <v-col>
        <app-text-field
          v-model="value.intervals[0].end"
          v-test="'end-input'"
          label="Heure de fin"
          name="intervals[0].end"
          rules="required|hourIsAfter:@intervals[0].start"
          type="time"
          hide-errors="auto"
        />
      </v-col>
    </v-row>
    <event-recurrence
      v-model="value.recurrencePattern"
      :start-date="value.date"
    />
  </app-form>
</template>

<script>
import AppAutocomplete from '@/components/ui/form/AppAutocomplete'
import AppDatePicker from '@/components/ui/form/AppDatePicker.vue'
import AppForm from '@/components/ui/form/AppForm.vue'
import AppSelectableList from '@/components/ui/selectableList/AppSelectableList.vue'
import AppTextField from '@/components/ui/form/AppTextField.vue'
import EventRecurrence from '@/modules/agenda/components/EventRecurrence.vue'

import AvailabilitySettings from '@/modules/agenda/models/events/AvailabilitySettings'

import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AvailabilityForm',
  components: {
    EventRecurrence,
    AppForm,
    AppTextField,
    AppAutocomplete,
    AppSelectableList,
    AppDatePicker,
  },
  props: {
    value: {
      type: AvailabilitySettings,
      default: () => new AvailabilitySettings(),
    },
    validationObserver: {
      type: Object,
      default: null,
    },
  },
  data () {
    return { datePicker: false }
  },
  computed: {
    ...mapGetters('agenda', ['getSchedules', 'getScheduleAppointmentMotives']),
    isEditing () {
      return !! this.value?.['@id']
    },
    selectedAgendaAppointmentMotives () {
      if (this.value.schedule) {
        return this.getScheduleAppointmentMotives(this.value.schedule)
      }
      return []
    },
  },
  watch: {
    selectedAgendaAppointmentMotives: {
      immediate: true,
      handler (appointmentMotives) {
        if (appointmentMotives.length === 1) {
          this.selectMotive(appointmentMotives[0])
        }
        // On s'assure de ne pas laisser de motif sélectionné dans les champs de l'observer
        this.$nextTick(this.validationObserver?.validate)
      },
    },
  },
  async created () {
    await this.fetchAppointmentMotives()
    await this.fetchAvailabilitiesSettings()
  },
  methods: {
    ...mapMutations('app', ['SET_SNACK']),
    ...mapActions('agenda', [
      'saveAvailabilitySettings',
      'updateAvailabilitySettings',
      'fetchAppointmentMotives',
      'fetchAvailabilitiesSettings',
    ]),
    async submit () {
      return new Promise((resolve, reject) => {
        const action = this[this.isEditing ? 'updateAvailabilitySettings' : 'saveAvailabilitySettings']
        action(this.value)
          .then((response) => {
            this.SET_SNACK({ message: `La disponibilité a été ${this.isEditing ? 'modifiée' : 'ajoutée'} avec succès` })
            resolve(response)
          })
          .catch((error) => {
            const intervalsErrorMessage = error.response?.data?.violations.find(violation => violation.propertyPath === 'intervals')?.message
            if (intervalsErrorMessage) {
              this.SET_SNACK({
                color: 'warning',
                message: intervalsErrorMessage,
              })
            }
            reject(error)
          })
      })
    },
    selectMotive (motive) {
      if (! this.value.appointmentMotives.includes(motive)) {
        this.value.appointmentMotives.push(motive)
      }
    },
    unselectMotive (motive) {
      const motiveIndex = this.value.appointmentMotives.findIndex(_motive => _motive['@id'] === motive['@id'])
      if (motiveIndex > - 1) {
        this.value.appointmentMotives.splice(motiveIndex, 1)
      }
    },
    toggleMotiveSelection ({ item, isChecked }) {
      const toggleMethod = isChecked
        ? this.selectMotive
        : this.unselectMotive

      toggleMethod(item)
    },
    clearMotives () {
      this.value.appointmentMotives = []
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  input {
    &:disabled {
      cursor: default !important;
    }
  }
}
</style>