export class Permissions {
  permissions = null
  getPermissions () {
    return this.permissions
  }

  hasPermission (permission) {
    if (! this.getPermissions()) {
      return false
    }
    return this.permissions.indexOf(permission) > - 1
  }

  setPermissions (permissions) {
    this.permissions = permissions
  }

}

export default new Permissions()