import Vue from 'vue'

import { getFromAPI } from '@/services/api.js'

import MssFolder from '@/modules/secureMessaging/modules/messaging/models/MssFolder'
import MssMessage from '@/modules/secureMessaging/modules/messaging/models/MssMessage'

import { DEFAULT_FOLDERS } from '@/modules/secureMessaging/modules/messaging/constants'

import EcApiInfiniteScroller, { createVuexStoreProxy, mapInfiniteScrollerMutations } from '@/services/classes/EcApiInfiniteScroller'
import BaseFilter from '@/utils/classes/httpRequestParameters/filters/BaseFilter'

export const state = () => ({
  folders: {},
  foldersScroller: {},
  isFetchingFolders: false,
  isRefreshing: false,
  filters: { search: null },
  isFilterRefresh: false,
})

export const getters = {
  getFolderByName: state => folderName => Object.values(state.folders).find(folder => folder.name === folderName),
  getFolders: state => Object.values(state.folders),
  isFetchingFolders: state => state.isFetchingFolders,
  getMessagesFromFolder: state => folder => {
    return state.folderMessages?.[folder.getIri()] || []
  },
  getActiveFolderScroller: state => folderIri => state.foldersScroller?.[folderIri],
  isRefreshing: state => state.isRefreshing,
  getFilters: state => state.filters,
  isFilterRefresh: state => state.isFilterRefresh,
}

export const mutations = {
  ...mapInfiniteScrollerMutations(),
  SET_FOLDER (state, { folder, store }) {
    const newFolder = new MssFolder(folder)
    Vue.set(state.folders, newFolder.getIri(), newFolder)

    const infiniteScroller = state.foldersScroller?.[newFolder.getIri()]

    if (infiniteScroller) {
      infiniteScroller.reset()
    }

    // On génère l'instance du scroller infini pour chaque dossier
    if (! infiniteScroller || state.isFilterRefresh) {
      const filters = Object.keys(state.filters).map((key) => {
        return new BaseFilter({
          name: key,
          value: state.filters[key],
        })
      })
      const folderInfiniteScroller = new EcApiInfiniteScroller({
        apiResourceUrl: `${newFolder.getIri()}/messages`,
        itemModelClass: MssMessage,
        filters,
      })

      // Permet de forcer l'usage d'une mutation générique
      const folderInfiniteScrollerProxy = createVuexStoreProxy(store, folderInfiniteScroller)

      Vue.set(
        state.foldersScroller,
        newFolder.getIri(),
        folderInfiniteScrollerProxy,
      )
    }
  },
  SET_IS_FETCHING_FOLDERS (state, isFetchingFolder) {
    state.isFetchingFolders = isFetchingFolder
  },
  SET_IS_REFRESHING (state, isRefreshing) {
    state.isRefreshing = isRefreshing
  },
  SET_FILTERS (state, filters) {
    state.filters = { ...filters }
    state.isFilterRefresh = true
  },
  SET_IS_FILTER_REFRESH (state, isFilterRefresh) {
    state.isFilterRefresh = isFilterRefresh
  },
}

export const actions = {
  async setFilters ({ dispatch, commit }, { search }) {
    commit('SET_FILTERS', search)
    await dispatch('refresh')
  },
  async fetchFolders (store) {
    const { commit } = store
    commit('SET_IS_FETCHING_FOLDERS', true)
    try {
      const { data } = await getFromAPI('/api/mss_folders')
      data['hydra:member'].
        filter(f => Object.values(DEFAULT_FOLDERS).map(_f => _f.value).includes(f.name)).
        forEach(folder => commit('SET_FOLDER', {
          folder,
          store,
        }))
    } finally {
      commit('SET_IS_FETCHING_FOLDERS', false)
      commit('SET_IS_FILTER_REFRESH', false)
    }
  },
  async loadNextPage ({ getters }) {
    await getters.getActiveFolderScroller.loadNextPage()
  },
  async refresh ({ dispatch, commit }) {
    commit('SET_IS_REFRESHING', true)
    try {
      await dispatch('fetchFolders')
    } finally {
      commit('SET_IS_REFRESHING', false)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}