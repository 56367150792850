/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity'
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField'

const consultationDocumentEntity = new DocumentEntity('ConsultationDocument', 'consultationDocuments.xml', 'Contient les documents des consultations')

consultationDocumentEntity.addField(new DocumentEntityField('title', 'Le titre'))
consultationDocumentEntity.addField(new DocumentEntityField('content', 'Le contenu'))

export default consultationDocumentEntity