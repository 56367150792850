<template>
  <app-row>
    <app-col cols="12" md="8">
      <patient-documents-explorer :patient="patient" />
    </app-col>
    <app-col cols="12" md="4">
      <health-summaries-panel :patient="patient" />
    </app-col>
  </app-row>
</template>

<script>
import PatientDocumentsExplorer from '@/modules/patient/modules/patientFile/modules/documents/components/documentsExplorer/PatientDocumentsExplorer.vue'
import HealthSummariesPanel from '@/modules/patient/modules/patientFile/modules/documents/components/healthSummariesPanel/HealthSummariesPanel.vue'

import Patient from '@/modules/patient/models/Patient'

export default {
  name: 'PatientDocumentsModule',
  components: {
    PatientDocumentsExplorer,
    HealthSummariesPanel,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
}
</script>