import nonDrugAllergiesStore from './nonDrugAllergies'
import drugAllergiesStore from './drugAllergies'
import pathologiesStore from './pathologies'

import { getFromAPI } from '@/services/api'

import Vue from 'vue'

export const state = () => ({
  ...nonDrugAllergiesStore.state(),
  ...drugAllergiesStore.state(),
  ...pathologiesStore.state(),
})

export const getters = {
  ...nonDrugAllergiesStore.getters,
  ...drugAllergiesStore.getters,
  ...pathologiesStore.getters,
}

export const mutations = {
  CLEAR_RISK_FACTOR (state, { riskFactorName, patientUuid }) {
    Vue.set(state[riskFactorName], patientUuid, {})
  },
  ...nonDrugAllergiesStore.mutations,
  ...drugAllergiesStore.mutations,
  ...pathologiesStore.mutations,
}

export const actions = {
  async fetchPatientRiskFactor ({ commit }, { route, patientUuid, riskFactorName }) {
    try {
      const { data } = await getFromAPI(route)
      commit('CLEAR_RISK_FACTOR', {
        riskFactorName,
        patientUuid,
      })
      return data['hydra:member']
    } catch (e) {
      throw new Error(e)
    }
  },
  ...nonDrugAllergiesStore.actions,
  ...drugAllergiesStore.actions,
  ...pathologiesStore.actions,
}

export default {
  state,
  getters,
  mutations,
  actions,
}