<template>
  <v-list-item class="dropdown-menu__list__item" @click="$emit('click')">
    <v-list-item-icon>
      <app-icon :icon="icon" />
    </v-list-item-icon>
    <v-list-item-content class="dropdown-menu__list__item__content">
      <v-list-item-title class="dropdown-menu__list__item__content__title">
        {{ title }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="subtitle" class="dropdown-menu__list__item__content__subtitle">
        {{ subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'AppDropdownMenuListItem',
  props: {
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped lang="scss">
  .dropdown-menu__list__item {
    padding-top: 0;

    &__content {
      padding-top: 0;
      padding-bottom: 0;

      &__title {
        font-size: 13px;
      }

      &__subtitle {
        font-size: 12px;
      }
    }
  }
</style>