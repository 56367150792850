<template>
  <app-panel :title="panelTitle">
    <div v-if="loading">
      <app-skeleton-loader type="text" width="200" class="mb-2" />
      <app-skeleton-loader
        v-if="isCurrentUserSecretary"
        type="text"
        width="50%"
        class="mb-2"
      />
    </div>
    <organisation-practitioner-selector
      v-if="!loading && isCurrentUserSecretary"
      v-test="'dashboard-events-practitioner-filter'"
      clearable
      item-value="contact.@id"
      class="dashboard-events-panel_filters pt-0 mb-5"
      @input="practitionerFilter = $event"
    />
    <dashboard-events-timeline v-test="'dashboard-events-timeline'" :events="filteredEvents" :loading="loading" />
    <p v-if="!filteredEvents.length && ! loading" v-test="'dashboard-events-no-data'" class="secondary--text">
      Vous pouvez vous rendre sur l'agenda afin d'en ajouter.
    </p>
  </app-panel>
</template>
<script>
import AppPanel from '@/components/ui/panel/AppPanel.vue'
import DashboardEventsTimeline from './timeline/DashboardEventsTimeline.vue'
import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader'
import OrganisationPractitionerSelector from '@/components/ui/form/OrganisationPractitionerSelector.vue'

import { pluralize } from '@/utils/functions/words'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardEventsPanel',
  components: {
    AppPanel,
    DashboardEventsTimeline,
    AppSkeletonLoader,
    OrganisationPractitionerSelector,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return { practitionerFilter: null }
  },
  computed: {
    ...mapGetters('auth', ['isCurrentUserSecretary']),
    ...mapGetters('agenda', ['getScheduleFromIri']),
    panelTitle () {
      return this.loading
        ? ''
        : `${this.filteredEvents.length || 'Aucun'} ${pluralize('évènement', this.filteredEvents.length)}`
    },
    filteredEvents () {
      return this.practitionerFilter ?
        this.events.filter((event) => this.getScheduleFromIri(event.schedule)?.getPractitioner()?.['@id'] === this.practitionerFilter) :
        this.events
    },
  },
}
</script>
<style lang="scss" scoped>
.dashboard-events-panel_filters {
  width: 50%;
}
</style>