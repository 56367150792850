// App stores
import app from './app/app'
import auth from './auth/auth'
import cardReadersStore from '@/store/cardReaders'

// Modules stores
import AgendaStore from '@/modules/agenda/store'
import PatientStore from '@/modules/patient/store'
import DocumentsStore from '@/modules/documents/store'
import UsersStore from '@/modules/users/store'
import SecureMessagingStore from '@/modules/secureMessaging/store'
import ContactStore from '@/modules/contact/store'

// Plugins
import TokenExpirationPlugin from './plugins/tokenExpirationPlugin'
import MercurePlugin from './plugins/mercurePlugin'
import MssConnectionManagerPlugin from './plugins/mssConnectionManagerPlugin'

export default {
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    app,
    auth,
    cardReaders: cardReadersStore,
    agenda: AgendaStore,
    patient: PatientStore,
    documents: DocumentsStore,
    users: UsersStore,
    secureMessaging: SecureMessagingStore,
    contact: ContactStore,
  },
  plugins: [TokenExpirationPlugin, MercurePlugin, MssConnectionManagerPlugin],
}