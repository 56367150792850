import { format } from '@/utils/functions/dates'
export default class FreeAppointmentSlot {
  constructor (options = {}) {
    this.schedule = options.schedule || null
    this.motive = options.motive || null
    this.startDateTime = options.startDateTime || null
    this.endDateTime = options.endDateTime || null
  }

  /**
   * Retourne l'heure du créneau au format "HH:mm"
   * @returns {String} L'heure au format "HH:mm"
   */
  getTime () {
    if (this.startDateTime) {
      return format(this.startDateTime, 'HH:mm')
    }
    return null
  }
}