import ApiEntity from '@/models/ApiEntity'

export default class Cim10ApiEntity extends ApiEntity {
  constructor (options = {}) {
    super(options)

    this.label = options.label || null
    this.icd10Code = options.icd10Code || null // code CIM-10
    this.theriaqueLink = options.theriaqueLink || null
  }

  /**
   * Permet de récupérer le label de la pathologie (avec ou sans son code CIM-10)
   * @param {Boolean} [code=true] Demande à renvoyer le code avec le label ou non
   * @returns {String} Le label de la pathologie
   */
  getLabel ({ hideCode } = { hideCode: false }) {
    if (! hideCode && this.icd10Code) {
      return `${this.label} (${this.icd10Code})`
    }
    return this.label
  }

  /**
   * Renvoi une pathologie telle que demandée par Synapse pour la sécurisation
   * @returns Object SynapsePathology
   */
  asSynapseObject () {
    return {
      label: this.getLabel(),
      code: this.icd10Code,
      theriaque_link: this.theriaqueLink,
    }
  }
}