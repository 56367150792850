<template>
  <app-container :fluid="isFullScreen || (isMobile && !isRouteRoot)">
    <v-row :no-gutters="isFullScreen || isMobile">
      <v-col
        v-if="(isMobile && isRouteRoot) || (!isMobile && !isFullScreen)"
        :cols="isMobile ? 12 : 3"
        data-test="settings-menu"
      >
        <settings-left-menu
          :items="menuItems"
          :value="leftMenuValue"
          :mandatory="isMobile"
          :multiple="!isMobile"
          :readonly="!isMobile"
        />
      </v-col>
      <v-col
        v-if="(isMobile && !isRouteRoot) || !isMobile"
        :cols="(isFullScreen || isMobile) ? 12 : 9"
        :class="{ 'pa-0': isMobile }"
        data-test="settings-content"
      >
        <app-mobile-subheader
          v-if="(isMobile && !isRouteRoot) && ! isFullScreen"
          back-route-name="settings"
          :title="categoryTitle"
          class="mb-4"
          centered-title
        />
        <slot name="default" />
      </v-col>
    </v-row>
  </app-container>
</template>

<script>
import AppContainer from '@/components/ui/container/AppContainer.vue'
import AppMobileSubheader from '@/components/ui/mobileSubheader/AppMobileSubheader.vue'
import SettingsLeftMenu from '../leftMenu/SettingsLeftMenu.vue'
import { mapState } from 'vuex'
/**
 * Représente l'habillage sur desktop de la partie paramètres
 */
export default {
  name: 'SettingsLayout',
  components: {
    AppContainer,
    AppMobileSubheader,
    SettingsLeftMenu,
  },
  props: {
    /**
     * Éléments du menu
     */
    menuItems: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState('app', ['isMobile']),
    /**
     * Détermine si la route courante correspond à la racine ('/settings')
     * @returns {Boolean}
     */
    isRouteRoot () {
      return this.$route.name === 'settings'
    },
    leftMenuValue () {
      if (this.isMobile) {
        return this.menuItems.findIndex(item => item.title === this.categoryTitle)
      }
      // Retourne l'ensemble des index des éléments du menu de gauche de façon à déplier l'ensemble des panneaux
      return this.menuItems.map((item, index) => index)
    },
    isFullScreen () {
      return this.$route.meta.layout === 'empty'
    },
    categoryTitle () {
      return this.$route.meta.categoryTitle
    },
  },
}
</script>