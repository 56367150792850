<template>
  <div class="correspondent-form-part">
    <span v-if="title" class="correspondent-form-part__title"><app-icon :icon="media === 'telephone' ? 'phone' : 'email'" color="secondary" />{{ title }}</span>
    <div v-for="(item,index) in localValue" :key="index">
      <div class="correspondent-form-part__contact-points-fieldset">
        <component
          :is="media === 'email' ? 'app-text-field' : 'app-phone-field'"
          v-model="item.value"
          :label="media === 'email' ? 'E-mail' : 'Téléphone'"
          :name="getName(index)"
          class="correspondent-form-part__contact-points-fieldset__value-field"
          :rules="getRules(index)"
          @input.native="onChange(index)"
        />
        <app-button icon="close-alt" :disabled="isLastIndex(index)" @click="deleteItem(index)" />
      </div>
    </div>
  </div>
</template>

<script>
import AppTextField from '@/components/ui/form/AppTextField'
import AppPhoneField from '@/components/ui/form/AppPhoneField'
import ContactPoint from '@/models/ContactPoint'

export default {
  name: 'EditCorrespondentFormPart',
  components: {
    AppPhoneField,
    AppTextField,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    media: {
      type: String,
      default: null,
    },
  },
  data () {
    return { localValue: [] }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (newValue) {
        this.localValue = [...newValue, new ContactPoint({ media: this.media })]
      },
    },
  },
  methods: {
    getName (index) {
      return `${this.media}${index}`
    },
    getRules (index) {
      if(this.media === 'email') {
        return ! this.isLastIndex(index) ? 'required|email' : 'email'
      }
      return ! this.isLastIndex(index) ? 'required' : ''
    },
    onChange (index) {
      if (index === (this.localValue.length - 1)) {
        this.localValue.push(new ContactPoint({ media: this.media }))
        this.$emit('input', this.localValue.filter(item => item.value))
      }
    },
    isLastIndex (index) {
      return (index === (this.localValue.length - 1))
    },
    deleteItem (index) {
      this.localValue.splice(index, 1)
      this.$emit('input', this.localValue.filter(item => item.value))
    },
  },
}
</script>

<style scoped lang="scss">
.correspondent-form-part {
  display: flex;
  flex-direction: column;

  &__title {
     font-weight: 600;
     font-size: 13px;
     display: flex;
     gap: 10px;
     color: var(--v-content-base) !important;
     margin-top: map-get($spacers, 4);
   }

  &__contact-points-fieldset {
     display: flex;
     align-items: center;
     gap: map-get($spacers, 4);

  &__value-field {
     width: 100%;
   }
  }
}
</style>