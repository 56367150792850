<template>
  <app-form :submit-method="submitMethod">
    <app-row class="mb-4">
      <app-col
        v-for="(rules, index) in chunk(passwordRules, 3)"
        :key="index"
        cols="6"
        class="reset-password-form__rules-col"
      >
        <n-list :spacing="0" :items="rules">
          <template #item="{ item }">
            <authentication-validation-rule
              v-test="'validation-rule'"
              :rule="item.rule"
              :rule-short-label="item.ruleShortLabel"
              :label="item.label"
              :value="newPassword"
            />
          </template>
        </n-list>
      </app-col>
    </app-row>
    <app-text-field
      v-model="newPassword"
      v-test="'new-password-field'"
      :type="showClearPassword ? 'text' : 'password'"
      name="password"
      label="Nouveau mot de passe"
      rules="required"
      :append-icon="showClearPassword ? 'hide' : 'visualize'"
      class="mb-2"
      @click:append="showClearPassword = !showClearPassword"
    />
    <app-text-field
      v-test="'repeat-password-field'"
      :type="showClearRepeatedPassword ? 'text' : 'password'"
      label="Répétez le mot de passe"
      :rules="{ required: true, is: newPassword }"
      validation-mode="aggressive"
      :append-icon="showClearRepeatedPassword ? 'hide' : 'visualize'"
      class="mb-4"
      @click:append="showClearRepeatedPassword = !showClearRepeatedPassword"
    />
    <app-link
      v-if="getCurrentUser"
      v-test="'go-to-login-form-link'"
      class="d-flex justify-center"
      @click="goToLoginForm"
    >
      Se déconnecter et revenir au formulaire de connexion
    </app-link>
  </app-form>
</template>

<script>
import AppForm from '@/components/ui/form/AppForm.vue'
import AppTextField from '@/components/ui/form/AppTextField.vue'
import AuthenticationValidationRule from '@/modules/authentication/components/AuthenticationValidationRule.vue'

import { extend } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'

import { chunk } from 'lodash'

import { ROUTE_NAMES } from '@/modules/authentication/constants'
import { getFromAPI } from '@/services/api'
import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'ResetPasswordForm',
  components: {
    AppTextField,
    AppForm,
    AuthenticationValidationRule,
  },
  data () {
    return {
      ROUTE_NAMES,
      newPassword: null,
      resetToken: null,
      passwordRules: [
        {
          label: '12 Caractères',
          rule: 'min_length:12',
          ruleShortLabel: '12+',
        },
        {
          label: 'Une majuscule',
          rule: { regex: '[A-Z]' },
          ruleShortLabel: 'Aa',
        },
        {
          label: 'Une minuscule',
          rule: { regex: '[a-z]' },
          ruleShortLabel: 'aa',
        },
        {
          label: 'Un nombre',
          rule: { regex: '[0-9]' },
          ruleShortLabel: '123',
        },
        {
          label: 'Un caractère spécial',
          rule: { regex: /[!-/:-@[-`{-~]/ },
          ruleShortLabel: '#$',
        },
      ],
      showClearPassword: false,
      showClearRepeatedPassword: false,
    }
  },
  computed: { ...mapGetters('auth', ['getCurrentUser']) },
  beforeMount () {
    this.resetToken = this.$route.query.token
    if (this.resetToken) {
      this.verifyToken()
    }
  },
  methods: {
    ...mapActions('auth', ['resetUserPassword', 'updateUserPassword', 'logout']),
    goToLoginForm () {
      this.$router.push({ name: ROUTE_NAMES.CONNECTION })
      this.logout()
    },
    async verifyToken () {
      const { data } = await getFromAPI('/users/password/check-token', { token: this.resetToken })
      if (! data.validToken) {
        NovaTools.notify.error(data.reasonMessage, { timeout: - 1 })
        this.goToLoginForm()
      }
    },
    async submitMethod () {
      try {
        if (this.getCurrentUser) {
          await this.updateUserPassword({ password: this.newPassword })
          NovaTools.notify.success('Mot de passe modifié, veuillez vous reconnecter')
        } else {
          await this.resetUserPassword({
            password: this.newPassword,
            token: this.resetToken,
          })
          NovaTools.notify.success('Mot de passe réinitialisé, veuillez vous reconnecter')
        }
        await this.$router.push({ name: ROUTE_NAMES.CONNECTION })
      } catch(e) {
        throw new Error(e)
      }
    },
    chunk,
  },
}
extend('is', {
  validate () {},
  message: 'Les mots de passe ne sont pas identiques',
})
</script>

<style lang="scss" scoped>
.reset-password-form {
  &__rules-col {
    display: flex;
    justify-content: center;
    padding-bottom: 0 !important;
  }
}
</style>