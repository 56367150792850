import ApiEntity from './ApiEntity'

/**
 * @typedef {Object} AdressType
 * @property {String} name
 * @property {String} label
 * @property {String} line1
 * @property {String} line2
 * @property {String} postalCode
 * @property {String} city
 * @property {String} country
 */
export default class Address extends ApiEntity {
  /**
   * @param {AdressType} options
   */
  constructor (options = {}) {
    super(options)
    this.name = options.name || null
    this.label = options.label || null
    this.line1 = options.line1 || null
    this.line2 = options.line2 || null
    this.postalCode = options.postalCode || null
    this.city = options.city || null
    this.country = options.country || null
  }

  isValid () {
    return !! this.line1
  }
}