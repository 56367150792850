import ApiEntity from '@/models/ApiEntity'

/**
 * @typedef {Object} MedicalDataset
 * @property {Object} bloodType
 */
export default class MedicalDataset extends ApiEntity {

  /**
   * @param {MedicalDataset} options
   */
  constructor (options = {}) {
    super(options)
    this.bloodType = options.bloodType || null
  }

}