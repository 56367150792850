<template>
  <validation-provider
    ref="validator"
    v-slot="{ valid, errors }"
    slim
    :rules="`${rules}|phone_ec`"
    :name="label"
    :vid="name"
    :mode="fieldValidationMode"
  >
    <v-text-field
      ref="input"
      v-model="localValue"
      v-mask="phoneMask"
      v-bind="{ ...$props, ...$attrs }"
      type="tel"
      :rules="[]"
      :label="fieldLabel"
      :error="errors.length > 0"
      :error-messages="errors"
      :hide-details="hideErrors"
      :class="{
        'is-valid': valid,
        ...$attrs.class
      }"
      :prepend-icon="fieldIcon.prepend"
      :clearable="clearable"
      autocomplete="off"
      v-on="{...$listeners}"
    />
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import fieldMixin from '@/mixins/fields.js'
import transformableMixin from '@/components/ui/form/mixins/TransformableMixin.js'

/**
 * Champ de téléphone de l'application
 */
export default {
  name: 'AppPhoneField',
  components: { ValidationProvider },
  mixins: [fieldMixin, transformableMixin('localValue')],
  computed: {
    isInternationalPhoneNumber () {
      return this.localValue?.startsWith('+')
    },
    phoneMask () {
      if (this.isInternationalPhoneNumber) {
        return ''
      }
      return '## ## ## ## ##'
    },
  },
}
</script>