import ConsultationBaseDocument from '@/modules/patient/models/ConsultationBaseDocuments'
import { DOCUMENT_TYPES } from '@/modules/documents/constants'

export default class ConsultationCertificate extends ConsultationBaseDocument {

  constructor (options = {}) {
    super(options)
    this.content = options.content || ''
    this.recipients = options.recipients || []
  }

  getLabel () {
    return 'Certificat'
  }

  getIcon () {
    return DOCUMENT_TYPES.CERTIFICATE.icon
  }
}