<template>
  <n-list
    :items="events"
    :loading="loading"
    :spacing="2"
    :skeleton-count="DEFAULT_SKELETONS_COUNT"
    :empty-text="emptyText"
    class="app-event-patient-list"
  >
    <template #skeleton>
      <article class="app-event-patient-list__skeleton">
        <span class="app-event-patient-list__skeleton__content">
          <app-skeleton-loader v-if="$scopedSlots['prepend']" type="text" width="38" />
          <app-skeleton-loader type="avatar" class="app-event-patient-list__skeleton__avatar" />
          <app-skeleton-loader type="text" class="align-self-center" width="120" />
        </span>
        <template v-if="$scopedSlots['actions']">
          <v-spacer />
          <app-skeleton-loader type="avatar" class="app-event-patient-list__skeleton__action" />
        </template>
      </article>
    </template>
    <template #item="{ item }">
      <v-expand-transition appear>
        <section class="d-flex align-center">
          <slot name="prepend" v-bind="item" />
          <patient-details
            x-small
            redirect-to-patient-file
            :patient="item.patient"
            :show-anonymized-identifier="false"
            :show-keywords="false"
          />
          <v-spacer v-if="$scopedSlots['actions']" />
          <slot name="actions" v-bind="item" />
        </section>
      </v-expand-transition>
    </template>
  </n-list>
</template>

<script>
import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue'
import PatientDetails from '@/modules/patient/components/PatientDetails.vue'

const DEFAULT_SKELETONS_COUNT = 2
/**
 * Permet d'afficher une liste de patients avec leur état civil et avatar en lui passant un tableau d'évenements
 */
export default {
  name: 'AppEventPatientList',
  components: {
    AppSkeletonLoader,
    PatientDetails,
  },
  props: {
    /**
     * Le tableau d'évenements avec les patients
     */
    events: {
      type: Array,
      required: true,
    },
    /**
     * Affiche un loader lorsque les données sont en cours de chargement
     */
    loading: {
      type: Boolean,
      required: true,
    },
    /**
     * Le texte à afficher si aucun patient n'a été récupéré
     */
    emptyText: {
      type: String,
      default: null,
    },
  },
  data () {
    return { DEFAULT_SKELETONS_COUNT }
  },
}
</script>

<style lang="scss" scoped>
$avatar-size: 28px;
$action-size: 36px;

.app-event-patient-list {
  &__skeleton {
    display: flex;
    align-items: center;

    &__content {
      display: flex;
      align-items: center;
      gap: map-get($spacers, 2);
    }

    &__avatar {
      width: $avatar-size !important;

      ::v-deep {
        .v-skeleton-loader {
          &__avatar {
            height: $avatar-size;
            width: $avatar-size;
          }
        }
      }
    }

    &__action {
      width: $action-size !important;

      ::v-deep {
        .v-skeleton-loader {
          &__avatar {
            height: $action-size;
            width: $action-size;
          }
        }
      }
    }

    ::v-deep {
      .v-skeleton-loader {
        &__text {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>