<template>
  <div>
    <div v-if="mainInsured" class="mb-4">
      <div class="subtitle-3 mb-4">
        Assuré principal
      </div>
      <app-panel
        v-ripple
        is-outlined
        class="clickable"
        is-flat
        dense
        @click.native="handleClickInsured(mainInsured)"
      >
        <patient-details
          v-test="'main-insured'"
          x-small
          :show-anonymized-identifier="false"
          show-birth-informations
          show-initials
          :show-pictograms="false"
          :patient="mainInsured"
        />
      </app-panel>
    </div>
    <div v-if="rightholders.length">
      <div class="subtitle-3 mb-4">
        Ayant(s) droits
      </div>
      <app-panel
        v-for="(rightholder, index) in rightholders"
        :key="index"
        v-ripple
        is-outlined
        is-flat
        dense
        class="clickable mb-2"
        @click.native="handleClickInsured(rightholder)"
      >
        <patient-details
          v-test="'right-holder'"
          x-small
          :show-anonymized-identifier="false"
          show-birth-informations
          :show-pictograms="false"
          :patient="rightholder"
          show-initials
        />
      </app-panel>
    </div>
  </div>
</template>

<script>
import AppPanel from '@/components/ui/panel/AppPanel.vue'
import Patient from '@/modules/patient/models/Patient'
import PatientDetails from '@/modules/patient/components/PatientDetails.vue'

import { mapGetters } from 'vuex'

/**
 * Une liste sous la forme de cartes permettant de sélectionner un assuré
 * de la carte vitale en cours d'utilisation
 */
export default {
  name: 'VitaleCardInsuredListSelector',
  components: {
    AppPanel,
    PatientDetails,
  },
  computed: {
    ...mapGetters('cardReaders', ['getVitaleCardInsureds']),
    insureds () {
      return this.getVitaleCardInsureds
    },
    mainInsured () {
      return this.insureds?.find(insured => insured.isMainInsured)?.patient
    },
    rightholders () {
      return this.insureds
        ?.filter(insured => ! insured.isMainInsured)
        .map(rightholder => rightholder.patient)
    },
  },
  methods: {
    handleClickInsured (insured) {
      this.$emit('click:insured', new Patient(insured))
    },
  },
}
</script>