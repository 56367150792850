<template>
  <app-form :submit-method="submit">
    <letters-form-fields
      :patient-iri="patientIri"
      :patient-ins="patientIns"
      :recipients.sync="followUpLetterDocument.recipients"
      :content.sync="followUpLetterDocument.freeText"
    />
  </app-form>
</template>

<script>
import AppForm from '@/components/ui/form/AppForm.vue'
import LettersFormFields from '@/modules/patient/forms/staticTemplates/LettersFormFields.vue'

import { mapActions } from 'vuex'

import ConsultationFollowUpLetterDocument from '@/modules/patient/models/ConsultationFollowUpLetterDocument'
import InsIdentityDevice from '@/modules/patient/models/InsIdentityDevice'
import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'FollowUpLetterForm',
  components: {
    AppForm,
    LettersFormFields,
  },
  props: {
    letter: {
      type: ConsultationFollowUpLetterDocument,
      required: true,
    },
    patientIri: {
      type: String,
      required: true,
    },
    patientIns: {
      type: InsIdentityDevice,
      required: true,
    },
  },
  data () {
    return { followUpLetterDocument: null }
  },
  watch: {
    letter: {
      immediate: true,
      handler () {
        this.followUpLetterDocument = new ConsultationFollowUpLetterDocument({ ...this.letter })
      },
    },
  },
  methods: {
    ...mapActions('patient', ['insertConsultationFollowUpLetter']),
    async submit () {
      await this.insertConsultationFollowUpLetter({
        ...this.followUpLetterDocument,
        recipients: this.followUpLetterDocument.recipients.map(correspondent => correspondent['@id']),
      })
      NovaTools.notify.success('La lettre de suivi a été générée avec succès')
    },
  },
}
</script>