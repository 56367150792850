<template>
  <document-analytic-widget-layout :title="title">
    <template #total-count>
      <app-dataset-item
        v-slot="{ value }"
        vertical
        label="Total"
        :value="totalCount"
        :separator-char="''"
      >
        <strong class="title-1">{{ value }}</strong>
      </app-dataset-item>
    </template>
    <template #failed-count>
      <app-dataset-item
        v-slot="{ value }"
        vertical
        label="Échecs"
        :value="documentAnalytic.failedCount"
        :separator-char="''"
      >
        <strong class="title-1">{{ value }}</strong>
      </app-dataset-item>
    </template>
    <template #qualified-ins-count>
      <app-dataset-item label="INS qualifié" :value="documentAnalytic.qualifiedInsCount" />
    </template>
    <template #unqualified-ins-count>
      <app-dataset-item label="INS non qualifié" :value="documentAnalytic.unQualifiedInsCount" />
    </template>
  </document-analytic-widget-layout>
</template>

<script>
import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue'
import DocumentAnalyticWidgetLayout from '@/modules/users/components/dashboard/analytics/widgets/DocumentAnalyticWidgetLayout.vue'

import DocumentAnalyticDataset from '@/modules/users/models/DocumentAnalyticDataset'

export default {
  name: 'DocumentAnalyticWidget',
  components: {
    AppDatasetItem,
    DocumentAnalyticWidgetLayout,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    documentAnalytic: {
      type: DocumentAnalyticDataset,
      required: true,
    },
  },
  computed: {
    totalCount () {
      const { qualifiedInsCount, unQualifiedInsCount, unknownCount } = this.documentAnalytic
      return qualifiedInsCount + unQualifiedInsCount + unknownCount
    },
  },
}
</script>

<style lang="scss" scoped>
.document-analytic-widget {
  &__count-item {
    display: flex;
    align-items: center;
    gap: map-get($spacers, 2);

    &__count {
      font-weight: 600;
      width: 24px;
    }
  }

  &__number-item {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: map-get($spacers, 2);
  }
}
</style>