import iCanopeeCardReaderInstance from '@/services/vendors/icanopee/classes/ICanopeeCardReader'

import { Buffer } from 'buffer'

/**
 * @typedef {Object} AatiType
 */
class Aati {

  /**
     * @param {AatiType} options
     */
  constructor (options = {}) {
    this.patient = options.patient
  }

  static async getTemplate (softwareInformations, operation) {
    const template = await iCanopeeCardReaderInstance.getAatiTemplate(softwareInformations, operation)
    return Buffer.from(template).toString('base64')
  }
}

export default Aati