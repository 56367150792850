import AuthenticationServiceInstance from '@/modules/secureMessaging/modules/authentication/services/AuthenticationService'
import MessagingRefresherService from '@/modules/secureMessaging/modules/messaging/services/RefresherService'

let isApplicationReady = false

const mssConnectionManagerPlugin = store => {
  const messagingRefresherService = new MessagingRefresherService(store)

  if (store.state.auth.authPayload && ! store.getters['auth/isCurrentUserPractitioner']) {
    return
  }

  store.subscribeAction((action) => {
    if (action.type === 'app/getAppDatas') {
      isApplicationReady = true
    }

    // Utilisateur déjà connecté à la mss
    if (isApplicationReady && ! messagingRefresherService.isStarted() && AuthenticationServiceInstance.isAuthenticated) {
      AuthenticationServiceInstance.refreshConnection()
      AuthenticationServiceInstance.keepAliveConnection()

      messagingRefresherService._run()
    }
    if (action.type === 'auth/logout') {
      AuthenticationServiceInstance.killConnection()
      messagingRefresherService._kill()
    }
  })
}

export default mssConnectionManagerPlugin