import RiskFactor from '@/modules/patient/models/riskFactors/PatientRiskFactor'

import { getFromAPI, postToAPI, putToAPI, deleteFromAPI } from '@/services/api'
import NovaTools from '@/nova-tools/NovaTools'

export default class PatientProfessionalRiskFactor extends RiskFactor {
  constructor (options = {}) {
    super(options)
  }

  /**
   * Permet de récupérer les facteurs de risque professionnels depuis l'API
   * @param {String} patientIri
   * @returns {PatientProfessionalRiskFactor[]}
   */
  static async fetchAll (patientIri) {
    const { data } = await getFromAPI(`${patientIri}/professional_risk_factors`)
    return data['hydra:member'].map(professionalRiskFactor => new PatientProfessionalRiskFactor(professionalRiskFactor))
  }

  /**
   * Insère un facteur de risque professionnel vers l'API
   * @param {Object} professionalRiskFactor
   * @returns {PatientProfessionalRiskFactor}
   */
  static async insert (professionalRiskFactor) {
    const { data } = await postToAPI('/api/patient_professional_risk_factors', professionalRiskFactor)
    NovaTools.notify.success('Le facteur de risque professionnel a été créé avec succès')
    return new PatientProfessionalRiskFactor(data)
  }

  /**
   * Met à jour un facteur de risque professionnel à partir de l'API
   * @param {Object} professionalRiskFactor
   * @returns {PatientProfessionalRiskFactor}
   */
  static async update (professionalRiskFactor) {
    const { data } = await putToAPI(professionalRiskFactor['@id'], { data: professionalRiskFactor })
    NovaTools.notify.success('Le facteur de risque professionnel a été mis à jour avec succès')
    return new PatientProfessionalRiskFactor(data)
  }

  /**
   * Supprime un facteur de risque professionnel à partir de l'API
   * @param {Object} professionalRiskFactor
   */
  static async delete (professionalRiskFactor) {
    await deleteFromAPI(professionalRiskFactor['@id'])
    NovaTools.notify.success('Le facteur de risque professionnel a été supprimé avec succès')
  }
}