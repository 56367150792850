<template>
  <v-row>
    <v-col cols="8" class="dashboard-header">
      <h1 class="dashboard-header__title" data-test="header-greetings">
        Bonjour <strong class="font-weight-medium">{{ userFirstName }}</strong>
      </h1>
      <p class="dashboard-header__subtitle mb-0">
        Bienvenue dans votre espace professionnel
      </p>
    </v-col>
    <v-col cols="4" class="dashboard-header__date" data-test="header-date">
      <div class="dashboard-header__date__time">
        {{ today.time }}
      </div>
      <div class="dashboard-header__date__date">
        {{ today.date }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { format } from '@/utils/functions/dates'
import { mapGetters } from 'vuex'

const MILLISECONDS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60
const MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE

export default {
  name: 'DashboardHeader',
  data () {
    return {
      todayDate: new Date(),
      clockInterval: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUser']),
    userFirstName () {
      return this.getCurrentUser?.contact.firstNames
    },
    today () {
      return {
        date: format(this.todayDate),
        time: format(this.todayDate, 'HH:mm'),
      }
    },
  },
  mounted () {
    const nowDate = new Date()
    const ellapsedMilliseconds = nowDate.getMilliseconds() + (MILLISECONDS_IN_SECOND * nowDate.getSeconds())
    const remainingMilliseconds = MILLISECONDS_IN_MINUTE - ellapsedMilliseconds

    // Attend le passage à la minute suivante afin de se synchroniser avec la date système
    setTimeout(this.startClock, remainingMilliseconds)
  },
  destroyed () {
    if (this.clockInterval) {
      clearInterval(this.clockInterval)
    }
  },
  methods: {
    startClock () {
      this.updateTodayDate()
      this.clockInterval = setInterval(this.updateTodayDate, MILLISECONDS_IN_MINUTE)
    },
    updateTodayDate () {
      this.todayDate = new Date()
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard-header {
  &__title {
    font-size: 24px;
    font-weight: 400;
  }

  &__subtitle {
    color: var(--v-secondary-base);
    font-size: 16px;
  }

  &__date {
    &__time,
    &__date {
      color: var(--v-secondary-base);
      text-align: right;
    }

    &__time {
      font-size: 24px;
      font-weight: 500;
    }
  }
}
</style>