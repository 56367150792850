import axios from 'axios'
export class Requests {
  async get (endpoint, params = {}, config = {}) {
    return axios.get(endpoint, {
      params,
      ...config,
    })
  }
  async patch (endpoint, { params, data }) {
    return axios.patch(endpoint, data, {
      headers: { 'Content-Type': 'application/merge-patch+json' },
      ...params,
    })
  }
  async put (endpoint, { params, data }) {
    return axios.put(endpoint, data, {
      headers: { 'Content-Type': 'application/ld+json' },
      ...params,
    })
  }
}
export default new Requests()