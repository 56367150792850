<template>
  <validation-provider
    v-slot="{ errors }"
    slim
    :rules="rules"
    :vid="name"
    :name="label"
  >
    <v-text-field
      ref="input"
      :value="value"
      inputmode="decimal"
      :suffix="suffix"
      :label="fieldLabel"
      :error="errors.length > 0"
      :error-messages="errors"
      :disabled="disabled"
      hide-details="auto"
      @input="handleInput"
      @keydown="handleKeypress"
      @blur="handleBlur"
      @focus="select"
      @change="value => $emit('change', value)"
    />
  </validation-provider>
</template>

<script>
import { getFormattedFieldLabel } from '@/utils/functions/fields'
import { ValidationProvider } from 'vee-validate'

const VALID_SPECIAL_KEYS = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Enter', 'Backspace', 'Delete', 'Escape', 'Home', 'Tab']

/**
 * Champ nombre de l'application
 */
export default {
  name: 'AppNumberField',
  components: { ValidationProvider },
  props: {
    integerOnly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    rules: {
      type: [String, Object, Array],
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fieldLabel () {
      return getFormattedFieldLabel(this.label, this.rules)
    },
  },
  methods: {
    handleKeypress (event) {
      this.$emit('keypress', event)
      if (event.ctrlKey) {
        return
      }
      // Bloque la saisie de nombre avec plusieurs décimales
      if (event.target.value.includes('.') && ['.', ','].includes(event.key)) {
        event.preventDefault()
      }
      if (! VALID_SPECIAL_KEYS.includes(event.key) && ! this.isEventValidNumberKey(event)) {
        event.preventDefault()
      }
    },
    isEventValidNumberKey (event) {
      const regex = this.integerOnly ? /[0-9]/ : /[-.,0-9]/
      return regex.test(event.key)
    },
    formatValue (value) {
      if (value.startsWith('.') || value.startsWith(',')) {
        return '0.'
      }
      return parseFloat(value.toString().replaceAll(',', '.'))
    },
    handleInput (value) {
      this.$emit('input', value ? this.formatValue(value) : null)
    },
    handleBlur () {
      if (this.value) {
        this.$emit('input', parseFloat(this.value))
        this.$emit('blur', parseFloat(this.value))
      } else {
        this.$emit('blur')
      }
    },
    select () {
      this.$refs.input.$el.querySelector('input').select()
    },
    blur () {
      this.$refs.input.blur()
    },
  },
}
</script>