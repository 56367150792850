import ApiEntity from '@/models/ApiEntity'
import ContactPoint from '@/models/ContactPoint'

export default class ContactActivityPlace extends ApiEntity {
  constructor (options = {}) {
    super(options)
    this.name = options.name || null
    this.label = options.label || null
    this.line1 = options.line1 || null
    this.line2 = options.line2 || null
    this.postalCode = options.postalCode || null
    this.city = options.city || null
    this.country = options.country || null
    this.mssAddress = options.mssAddress || false
    this.contactPoints = options.contactPoints
      ? options.contactPoints?.map(contactPoint => new ContactPoint({
        ...contactPoint,
        source: 'health_directory',
      }))
      : []
  }
}