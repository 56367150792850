<template>
  <div>
    <section class="pending-mss-documents-panel__filters mt-n6 mb-6 px-4">
      <app-row>
        <app-col cols="12" md="3">
          <app-text-field
            v-model="patientSearch"
            label="Patient"
            hide-errors="auto"
          />
        </app-col>
        <app-col cols="12" md="3">
          <app-text-field
            v-model="documentTypeSearch"
            label="Type de document"
            hide-errors="auto"
          />
        </app-col>
        <app-col cols="5" md="3">
          <app-date-picker
            v-model="fromDateSearch"
            :icon="null"
            :max="maxFromDate"
            label="À partir du"
            clearable
          />
        </app-col>
        <app-col cols="7" md="3" class="flex-list-2 align-end">
          <app-date-picker
            v-model="toDateSearch"
            :icon="null"
            :min="new Date(fromDateSearch)"
            :max="new Date()"
            label="Jusqu'au"
            clearable
          />
          <n-button icon="rotate" :loading="loading" @click="$emit('refresh')" />
        </app-col>
      </app-row>
    </section>
    <app-data-table
      :headers="pendingMssDocumentHeaders"
      :custom-sort="customSort"
      :items="filteredPendingMssDocuments"
      :items-per-page="5"
      :loading="loading"
      :pageable="documents.length > 5"
      :large="!isMobile"
      :group-by="tableGroupBy"
      :class="{'pending-mss-documents-panel__sort--mobile': isMobile}"
    >
      <template #[`item.title`]="{ item }">
        <div class="d-flex align-center" :class="{ 'py-1': isMobile }">
          <article>
            <div class="pending-mss-documents-panel__title">
              <app-icon
                v-if="getBioExamIconVisibleState(item)"
                :icon="getBioExamIconProps(item).icon"
                size="13"
                :color="getBioExamIconProps(item).color"
                :tooltip="getBioExamIconProps(item).tooltip"
              />
              <n-truncable-text class="pending-mss-documents-panel__title__text subtitle-3" :class="{ 'pending-mss-documents-panel__title__text--mobile': isMobile }">
                {{ item.title }}
              </n-truncable-text>
            </div>
            <span class="secondary--text"> {{ `Version ${(item.metaDataset && item.metaDataset.versionNumber) ? item.metaDataset.versionNumber : 'non spécifiée'}` }}</span>
            <document-act-list v-if="item.metaDataset" :acts="item.metaDataset.acts" :class="{ 'd-block': isMobile }" />
          </article>
        </div>
      </template>
      <template #[`item.type`]="{ item }">
        <article>
          <n-truncable-text
            v-if="getTypeLabel(item)"
            class="pending-mss-documents-panel__type subtitle-3"
          >
            {{ getTypeLabel(item) }}
          </n-truncable-text>
          <span v-else>-</span>
        </article>
      </template>
      <template #[`item.patient`]="{ item }">
        <document-meta-dataset-related-patient-details v-if="item.metaDataset" :meta-dataset="item.metaDataset" />
        <span v-else>-</span>
      </template>
      <template #[`item.authors`]="{ item }">
        <n-list
          v-if="item.metaDataset"
          :items="item.metaDataset.authors"
          :spacing="1"
        >
          <template #item="authorListProps">
            <article class="pending-mss-documents-panel__author">
              <h1 class="subtitle-3">
                {{ authorListProps.item.name }}
                <n-truncable-text class="pending-mss-documents-panel__author__organisation">
                  {{ authorListProps.item.organisation }}
                </n-truncable-text>
              </h1>
            </article>
          </template>
        </n-list>
        <span v-else>-</span>
      </template>
      <template #[`item.effectiveTime`]="{ item }">
        {{ getFormattedEffectiveTime(item.effectiveTime) }}
      </template>
      <template #[`item.actions`]="{ item }">
        <div v-if="item.metaDataset">
          <v-badge
            v-tooltip="getDocumentAttachTooltipText(item)"
            overlap
            bordered
            icon="fas fa-exclamation"
            color="error"
            :value="!! item.documentAttachmentFailedAt"
          >
            <n-button
              v-test="'document-attach-action'"
              icon="attachment"
              :bg-color="!! item.documentAttachmentFailedAt ? 'error' : 'content'"
              :disabled="!getHasCpxCard"
              @click="handleAttachDocumentClick(item)"
            />
          </v-badge>

          <n-button
            v-test="'document-details-action'"
            v-tooltip="'Détails'"
            icon="magnifying-glass-plus"
            @click="displayDocumentMetaDataset(item)"
          />
        </div>
      </template>
    </app-data-table>
    <patient-mss-pending-document-attach-modal
      v-if="!! currentAttachingDocument"
      :document="currentAttachingDocument"
      :is-open.sync="isAttachingDocument"
      :need-verification="!iscurrentAttachingDocumentFromTrustedSender"
      @document-attached="handleMssPendingDocumentPatientAttach"
      @document-attach-failed="document => $emit('document-attach-failed', document)"
      @document-ready-to-attach="document => $emit('document-ready-to-attach', document)"
    />
    <document-meta-dataset-modal
      v-if="currentDisplayedDocument"
      v-model="isDisplayingDocumentMetaDataset"
      :title="currentDisplayedDocument.title"
      :document-meta-dataset="currentDisplayedDocument.metaDataset"
      :is-mobile="isMobile"
    />
  </div>
</template>

<script>
import AppDataTable from '@/components/ui/tables/AppDataTable.vue'
import AppTextField from '@/components/ui/form/AppTextField.vue'
import AppDatePicker from '@/components/ui/form/AppDatePicker.vue'
import PatientMssPendingDocumentAttachModal from '@/modules/users/components/dashboard/documents/PatientMssPendingDocumentAttachModal.vue'
import DocumentActList from '@/modules/secureMessaging/modules/documents/components/DocumentActList.vue'
import DocumentMetaDatasetRelatedPatientDetails from '@/modules/users/components/dashboard/documents/DocumentMetaDatasetRelatedPatientDetails.vue'

import DocumentMetaDatasetModal from '@/modules/secureMessaging/modules/documents/components/metaDatasetModal/DocumentMetaDatasetModal.vue'

import NovaTools from '@/nova-tools/NovaTools'
import { sortByMetaDatasetCodeLabel, sortByMetaDatasetPatient, getFilteredPendingMssDocuments } from '@/modules/secureMessaging/modules/documents/utils/pendingDocuments'
import { sortItems as vuetifySortItems } from 'vuetify/lib/util/helpers'
import { mapGetters } from 'vuex'

import AuthenticationServiceInstance from '@/modules/secureMessaging/modules/authentication/services/AuthenticationService'

import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'
import PendingMssDocument from '@/modules/secureMessaging/modules/documents/models/PendingMssDocument'
import TrustedSender from '@/modules/secureMessaging/models/TrustedSender'

export default {
  name: 'PendingMssDocumentsTable',
  components: {
    AppDataTable,
    PatientMssPendingDocumentAttachModal,
    AppTextField,
    AppDatePicker,
    DocumentActList,
    DocumentMetaDatasetModal,
    DocumentMetaDatasetRelatedPatientDetails,
  },
  props: {
    documents: {
      type: Array,
      default: () => [],
      validator: documents => documents.every(document => document instanceof PendingMssDocument),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      pendingMssDocumentHeaders: [
        {
          text: 'Document',
          value: 'title',
          width: 350,
        },
        {
          text: 'Auteurs',
          value: 'authors',
          sortable: false,
        },
        {
          text: 'Patient concerné',
          value: 'patient',
        },
        {
          text: 'Créé le',
          value: 'effectiveTime',
          cellClass: 'secondary--text',
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          cellClass: 'secondary--text',
        },
      ],
      currentAttachingDocument: null,
      patientSearch: '',
      documentTypeSearch: '',
      fromDateSearch: null,
      toDateSearch: null,
      isAttachingDocument: false,
      currentDisplayedDocument: null,
      isDisplayingDocumentMetaDataset: false,
      trustedSenders: [],
    }
  },
  computed: {
    ...mapGetters('cardReaders', ['getHasCpxCard']),
    panelActions () {
      return {
        refresh: new ActionMenuItem('undo',
          AuthenticationServiceInstance.isAuthenticated ? 'Rafraîchir la liste' : 'Connectez-vous à la messagerie pour rafraîchir la liste',
          () => this.$emit('refresh'),
          { disabled: this.loading || ! AuthenticationServiceInstance.isAuthenticated },
        ),
      }
    },
    maxFromDate () {
      return this.toDateSearch ? new Date(this.toDateSearch) : new Date()
    },
    filteredPendingMssDocuments () {
      return getFilteredPendingMssDocuments(this.documents, {
        patient: this.patientSearch,
        type: this.documentTypeSearch,
        fromDate: this.fromDateSearch,
        toDateSearch: this.toDateSearch,
      })
    },
    iscurrentAttachingDocumentFromTrustedSender () {
      return this.trustedSenders.filter((trustedSender) => {
        return trustedSender.email === this.currentAttachingDocument?.senderEmail
      }).length > 0
    },
  },
  created () {
    this.fetchTrustedSenders()
  },
  methods: {
    handleAttachDocumentClick (document) {
      this.isAttachingDocument = true
      this.currentAttachingDocument = new PendingMssDocument(document)
    },
    displayDocumentMetaDataset (document) {
      this.currentDisplayedDocument = document
      this.isDisplayingDocumentMetaDataset = true
    },
    tableGroupBy (item) {
      return item.metaDataset?.getCodeDisplayName() || 'Documents sans type'
    },
    getFormattedEffectiveTime (effectiveTime) {
      return NovaTools.dates.format(effectiveTime, 'dd/MM/yyyy à HH:mm')
    },
    handleMssPendingDocumentPatientAttach () {
      const deletedIndex = this.documents.findIndex(pendingMssDocument => pendingMssDocument['@id'] === this.currentAttachingDocument['@id'])
      this.$emit('update:documents', this.documents.filter((document, index) => index !== deletedIndex))
      this.isAttachingDocument = false
    },
    displayBioResults (document) {
      this.displayedBioDocument = new PendingMssDocument(document)
    },
    getSortedActs (acts) {
      return [...acts].sort((actLeft, actRight) => (actLeft.main === actRight.main) ? 0 : actLeft.main ? - 1 : 1)
    },
    customSort (items, sortBy, sortDesc, locale, customSorters) {
      if (sortBy.includes('type')) {
        return sortByMetaDatasetCodeLabel(items, { sortDesc: sortDesc?.[0] })
      }
      if (sortBy.includes('patient')) {
        return sortByMetaDatasetPatient(items, { sortDesc: sortDesc?.[0] })
      }
      return vuetifySortItems(items, sortBy, sortDesc, locale, customSorters)
    },
    getTypeLabel (pendingMssDocument) {
      if (! pendingMssDocument?.metaDataset?.code) {
        return null
      }
      return pendingMssDocument.metaDataset.getCodeDisplayName()
    },
    getBioExamIconVisibleState (item) {
      return !! item.metaDataset?.bioExamRelevantClinicalElem
    },
    getBioExamIconProps ({ metaDataset }) {
      return metaDataset.getBioIconPropsFromMetaDataset()
    },
    getDocumentAttachTooltipText (document) {
      if (document.documentAttachmentFailedAt) {
        return `Suite à une vérification d'identité KO, le rattachement de ce document a échoué le ${NovaTools.dates.format(document.documentAttachmentFailedAt, 'dd MMM yyyy à HH:mm')}`
      }
      return this.getHasCpxCard ? 'Rattacher à un patient' : 'Nécessite une carte CPS'
    },
    async fetchTrustedSenders () {
      const trustedSenders = await TrustedSender.fetchAll()
      this.trustedSenders = trustedSenders.filter(sender => sender.trusted)
    },
  },
}
</script>

<style lang="scss" scoped>
.pending-mss-documents-panel {
  &__title {
    max-width: 350px;
    display: flex;
    align-items: center;
    gap: map-get($spacers, 2);

    &__text {
      overflow: hidden;

      &--mobile {
        ::v-deep {
          .ellipsis-text {
            white-space: unset !important;
            word-break: break-word;
          }
        }
      }
    }

  }

  &__tag {
    max-width: 150px;
  }

  &__type {
    max-width: 125px;
  }

  &__author {
    max-width: 300px;
    display: flex;
    white-space: nowrap;
    gap: map-get($spacers, 2);

    &__organisation {
      overflow: hidden;
      font-size: 11px;
      color: var(--v-secondary-base);
      font-weight: normal;
    }
  }

  &__sort {
    &--mobile {
      ::v-deep {
        th {
          padding-left: 16px !important;
        }
      }
    }
  }
}

</style>