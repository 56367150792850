<template>
  <div
    v-if="showAddress"
    v-test="'address'"
    class="app-person-address"
  >
    <app-icon class="app-person-address__icon" :color="colorIcon" icon="address" />
    <div class="app-person-address__content">
      <div
        v-if="address.name"
        v-test="'address-name'"
        data-sentry-mask
        class="app-person-address__content__name"
      >
        {{ address.name }}
      </div>
      <div v-if="address.line1" v-test="'address-line1'" data-sentry-mask>
        {{ address.line1 }}
      </div>
      <div v-if="address.line2" v-test="'address-line2'" data-sentry-mask>
        {{ address.line2 }}
      </div>
      <div
        v-if="address.postalCode || address.city"
        data-sentry-mask
        v-test="'address-postalcode-city'"
      >
        {{ address.postalCode }} {{ address.city }}
      </div>
      <slot name="append" />
    </div>
  </div>
</template>

<script>

import Address from '@/models/Address'

/**
 * L'adresse d'une personne de l'application
 */
export default {
  name: 'AppPersonAddress',
  props: {
    /**
     * L'adresse à afficher
     */
    address: {
      type: Address,
      required: true,
    },
    colorIcon: {
      type: String,
      default: 'secondary',
    },
  },
  computed: {
    showAddress () {
      return Boolean(this.address.line1 || this.address.line2 || this.address.postalCode || this.address.city)
    },
  },
}
</script>

<style lang="scss" scoped>
.app-person-address {
  display: flex;
  align-items: flex-start;
  &__icon {
    width: 16px;
    margin-right: map-get($spacers, 2);
  }
  &__content {
    &__name {
      font-weight: 600;
    }
  }
}
</style>