<template>
  <div class="patient-file">
    <app-page-layout
      v-test="'patient-file-layout'"
      sidebar-title="Résumé du patient"
      :sidebar-width="PATIENT_SUMMARY_SIDEBAR_WIDTH"
      hide-mobile-fab
    >
      <template #sidebar>
        <patient-sidebar v-if="patient" :patient="patient" />
      </template>
      <template #mobile-sub-header="{ toggleSidebar }">
        <patient-file-sub-header
          v-if="patient"
          :patient="patient"
          :back-route-name="ROUTE_NAMES.LISTING"
          @click:menu="toggleSidebar"
        />
        <n-portal-target name="mobile-dashboard-tabs" />
      </template>
      <div v-if="patient" class="patient-file__tabs">
        <section class="patient-file__tabs__headers">
          <n-portal-target name="patient-file-tabs-headers" />
          <start-consultation-button :patient="patient" :show-label="! isMobile" />
        </section>
        <app-tabs header-portal-target="patient-file-tabs-headers">
          <app-tabs-item label="Vue d'ensemble" icon="search">
            <patient-file-overview-module ref="patient-medical-dashboard" :patient="patient" />
          </app-tabs-item>
          <app-tabs-item label="Documents" icon="file">
            <patient-file-documents-module :patient="patient" />
          </app-tabs-item>
          <app-tabs-item label="Correspondants et entourage" icon="users">
            <patient-file-correspondents-module :patient="patient" />
          </app-tabs-item>
        </app-tabs>
      </div>
    </app-page-layout>
    <n-dialog
      v-if="!!patient"
      v-test="'patient-identity-qualification-alert'"
      persistent
      divided
      title="Patient avec identité non vérifiée"
      :is-open.sync="isPatientIdentityQualificationAlertOpen"
    >
      <div v-if="isUpdatingPatientIdentity">
        <app-circular-loader label="Mise à jour du patient" />
      </div>
      <div v-else class="flex-list-3 flex-column">
        <p>L'identité de ce patient n'a pas été vérifiée par appel au Téléservice INSi</p>
        <p>
          Veuillez qualifier son identité afin de pouvoir utiliser des fonctionnalités telles que l'envoi de document au
          DMP ou par messagerie sécurisée
        </p>
        <div class="d-flex">
          <v-spacer />
          <patient-identity-qualification-button
            :patient="patient"
            @error="closePatientIdentityQualificationAlert"
            @success="handleIdentityQualificationSuccess"
            @fetch-ins="saveInsiLog"
          />
        </div>
      </div>
    </n-dialog>
  </div>
</template>

<script>
import AppCircularLoader from '@/components/ui/loaders/AppCircularLoader.vue'
import AppPageLayout from '@/layout/AppPageLayout.vue'
import PatientFileOverviewModule from '@/modules/patient/modules/patientFile/modules/overview/PatientOverviewModule.vue'
import PatientFileDocumentsModule from '@/modules/patient/modules/patientFile/modules/documents/PatientDocumentsModule.vue'
import PatientFileCorrespondentsModule from '@/modules/patient/modules/patientFile/modules/correspondents/PatientCorrespondentsModule.vue'
import PatientSidebar from '@/modules/patient/components/PatientSidebar.vue'
import PatientFileSubHeader from '@/modules/patient/components/patientFile/PatientFileSubHeader.vue'
import PatientIdentityQualificationButton from '@/modules/patient/components/PatientIdentityQualificationButton.vue'
import StartConsultationButton from '@/modules/patient/components/patientFile/StartConsultationButton.vue'

import { postToAPI } from '@/services/api'
import { getInsStatusFromPatient } from '@/modules/patient/utils/ins'
import { mapActions, mapGetters } from 'vuex'

import { ROUTE_NAMES } from '@/modules/patient/constants'

import Patient from '@/modules/patient/models/Patient'
import NovaTools from '@/nova-tools/NovaTools'
import NDialog from '@/nova-ui/NDialog/NDialog.vue'
import NPortalTarget from '@/nova-ui/NPortal/NPortalTarget.vue'

const PATIENT_SUMMARY_SIDEBAR_WIDTH = 420

export default {
  name: 'PatientFileModule',
  components: {
    NPortalTarget,
    NDialog,
    AppCircularLoader,
    AppPageLayout,
    PatientSidebar,
    PatientFileOverviewModule,
    PatientFileDocumentsModule,
    PatientFileCorrespondentsModule,
    PatientFileSubHeader,
    PatientIdentityQualificationButton,
    StartConsultationButton,
  },
  beforeRouteLeave (to, from, next) {
    sessionStorage.setItem('last-route-patient-uuid', to.params.uuid)
    // On réinitialise les filtres de l'historique médical uniquement si on sort du contexte du patient
    if (to.params.uuid !== from.params.uuid) {
      this.$refs['patient-medical-dashboard'].clearMedicalHistoryFilters()
    }
    next()
  },
  data () {
    return {
      ROUTE_NAMES,
      PATIENT_SUMMARY_SIDEBAR_WIDTH,
      isPatientIdentityQualificationAlertOpen: false,
      isUpdatingPatientIdentity: false,
    }
  },
  computed: {
    ...mapGetters('patient', ['getPatientByUUID', 'getSettings']),
    ...mapGetters('app', ['isMobile']),
    autoSelectStatus () {
      return this.getSettings?.automaticStatusSetting
    },
    patientUuid () {
      return this.$route.params.uuid
    },
    patient () {
      return this.getPatientByUUID(this.patientUuid)
    },
    patientIri () {
      return this.patient?.getIri()
    },
  },
  watch: {
    // On se base sur l'IRI du patient récupéré pour ne pas logger un patient inconnu
    patientIri: {
      immediate: true,
      handler (patientIri) {
        if (! patientIri) {
          return
        }
        postToAPI('/api/read_entity_logs', { objectIri: patientIri })

        if ((sessionStorage.getItem('last-route-patient-uuid') !== this.patientUuid)
          && ! this.patient.insIdentity.isValidated()
          && this.patient.insIdentity.canGetIns()) {
          this.isPatientIdentityQualificationAlertOpen = true
        }
      },
    },
  },
  methods: {
    ...mapActions('patient', ['updatePatient']),
    async handleIdentityQualificationSuccess (updatedPatient) {
      this.isUpdatingPatientIdentity = true
      try {
        await this.updatePatient(new Patient({
          ...updatedPatient,
          firstName: updatedPatient.firstNames.split(' ')[0],
          insIdentity: {
            ...updatedPatient.insIdentity,
            status: getInsStatusFromPatient(updatedPatient, { disableAutomaticStatusSelect: ! this.autoSelectStatus }),
          },
        }))
        NovaTools.notify.success('La patient a été mis à jour avec succès')
      } finally {
        this.isUpdatingPatientIdentity = false
        this.closePatientIdentityQualificationAlert()
      }
    },
    async saveInsiLog ({ data, callType }) {
      await this.patient.insIdentity.logCall(callType, {
        patient: this.patient['@id'],
        trace: data,
      })
    },
    closePatientIdentityQualificationAlert () {
      this.isPatientIdentityQualificationAlertOpen = false
    },
  },
}
</script>

<style lang="scss">
.patient-file {
  height: 100%;

  &__tabs {
    margin: 0 -#{map-get($spacers, 4)} !important;

    @include media-md {
      margin: -#{map-get($spacers, 5)} !important;
    }

    &__headers {
      align-items: center;
      background-color: white;
      border-bottom: 1px solid var(--v-divider-base);
      display: flex;
      justify-content: space-between;
      padding: 0 map-get($spacers, 4);
    }

    ::v-deep {
      .patient-dashboard__mobile-tabs {
        margin-top: -#{map-get($spacers, 4)} !important;
      }

      .app-tabs__items {

        @include media-md {
          margin-top: map-get($spacers, 1) !important;
        }
      }
    }
  }

  ::v-deep {
    .page-layout__mobile-sub-header {
      margin-bottom: 0
    }
  }

  .item-group__container {
    max-width: calc(100% - 32px);
  }

  .v-slide-group__next,
  .v-slide-group__prev {
    flex: 0 1 32px;
    justify-content: flex-start;
    min-width: 32px;
  }
}
</style>