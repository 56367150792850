<template>
  <v-snackbar
    v-model="show"
    transition="slide-y-reverse-transition"
    :timeout="timeout"
    bottom
    :color="color"
    elevation="5"
  >
    <p>{{ message }}</p>
    <template #action="{ attrs }">
      <v-btn
        color="white"
        text
        v-bind="attrs"
        @click="show = false"
      >
        Fermer
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { mapState } from 'vuex'
/**
 * Wrapper snackbar vuetify global à l'application
//  */
const defaultSnack = {
  message: null,
  color: null,
  timeout: 4000,
}
export default {
  name: 'AppSnackbar',
  data () {
    return {
      show: false,
      message: '',
      timeout: 4000,
      color: 'success',
    }
  },
  computed: { ...mapState('app', ['snack']) },
  watch: {
    snack: {
      deep: true,
      async handler (newVal) {
        if (newVal.message) {
          this.show = true
          const { color, message, timeout } = newVal
          this.message = message
          this.color = color
          this.timeout = timeout
          this.$store.commit('app/SET_SNACK', defaultSnack)
        }
      },
    },
  },
}
</script>