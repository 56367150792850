<template>
  <section v-if="showTableSkeleton" v-test="'table-skeletons'">
    <app-skeleton-loader v-test="'skeleton-headers'" class="app-data-table__skeleton-headings" :type="`heading@${headers.length}`" />
    <template v-for="(row, index) in SKELETON_ROWS_AMOUNT">
      <div :key="index">
        <n-divider :spacing="0" />
        <app-skeleton-loader v-test="'skeleton-row'" class="app-data-table__skeleton-cell-group d-flex align-center" :type="`text@${headers.length}`" />
      </div>
    </template>
    <n-divider :spacing="0" />
  </section>
  <v-data-table
    v-else
    v-test="'table'"
    class="app-data-table"
    mobile-breakpoint="960"
    :class="{ 'app-data-table--large': large, 'app-data-table--grouped': groupBy }"
    :items="items"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    :no-data-text="noDataText"
    :headers="tableHeaders"
    :custom-sort="customSort"
    :expanded="expanded"
    :item-key="itemKey"
    :loading="loading"
    :items-per-page="itemsPerPage"
    :hide-default-footer="! pageable"
    :disable-pagination="! pageable"
    v-bind="tableGroupByProps"
    fixed-header
    @click:row="rowClicked"
    @update:expanded="expanded => $emit('update:expanded', expanded)"
  >
    <template v-if="groupBy" #[`group.header`]="{ group, toggle, isOpen }">
      <h3
        v-ripple
        class="app-data-table__group-title subtitle-3"
        :class="{ 'app-data-table__group-title--expanded': isOpen }"
        @click="toggle"
      >
        <app-icon
          class="app-data-table__group-title__icon"
          icon="chevron-down"
          color="secondary"
          :size="13"
        />
        <slot name="category" :category="group">
          <p>{{ group }}</p>
        </slot>
      </h3>
    </template>

    <template v-for="header in headers" #[`item.${header.value}`]="{ item }">
      <div :key="header.value" v-test="'item-row'">
        <slot :name="`item.${header.value}`" :item="item">
          {{ getDisplayedItemValueFromHeader(item, header) }}
        </slot>
      </div>
    </template>

    <template v-if="$scopedSlots.item || expandableRows" #item="itemProps">
      <slot name="item" v-bind="itemProps">
        <n-expandable
          class="app-data-table__expandable-row"
          :content-spacing-left="0"
          :content-spacing-top="0"
          :icon-rotation-angle="[90, 180]"
        >
          <template #header="{ toggle, expandIconStyle }">
            <tr v-ripple v-test="'item-row'" @click="toggle">
              <td v-test="'item-row-expand'">
                <app-icon icon="chevron-up" size="12" :style="expandIconStyle" />
              </td>
              <td v-for="header in headers" :key="header.value" v-test="'item-cell'">
                <slot :name="`item.${header.value}`" :item="itemProps.item">
                  {{ getDisplayedItemValueFromHeader(itemProps.item, header) }}
                </slot>
              </td>
            </tr>
          </template>
          <template #content-outer="{ isExpanded }">
            <tr class="app-data-table__expanded-row">
              <td :colspan="headers.length + 1" class="px-0">
                <v-expand-transition>
                  <div v-if="isExpanded" v-test="'expandable-row-content'">
                    <slot name="expanded-row" :item="itemProps.item" />
                  </div>
                </v-expand-transition>
              </td>
            </tr>
          </template>
        </n-expandable>
      </slot>
    </template>

    <template v-if="$scopedSlots[`expanded-item`]" #expanded-item="props">
      <slot name="expanded-item" v-bind="props" />
    </template>

    <template v-if="$scopedSlots[`body.prepend`]" #[`body.prepend`]="props">
      <slot name="body.prepend" v-bind="props" />
    </template>

    <template v-if="itemActions" #[`item.actions`]="{ item }">
      <n-tooltip v-slot="{ on }" :text="disabledActionText" :disabled="(! isAllItemActionsDisabled(item) || ! disabledActionText)">
        <span v-on="on">
          <app-actions-menu
            v-test="'item-actions'"
            :disabled="isAllItemActionsDisabled(item)"
            small
            :actions="getItemActions(item)"
          />
        </span>
      </n-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import AppActionsMenu from '@/components/ui/actionsMenu/AppActionsMenu.vue'

import { sortItems } from 'vuetify/lib/util/helpers'
import { groupBy } from 'lodash'

import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'
import AppSkeletonLoader from '../loaders/AppSkeletonLoader.vue'

const SKELETON_ROWS_AMOUNT = 4
const DEFAULT_ITEMS_PER_PAGE = 10

/**
 * Le composant de tableau de donnée de l'application
 */
export default {
  name: 'AppDataTable',
  components: {
    AppActionsMenu,
    AppSkeletonLoader,
  },
  props: {
    /**
     * Les actions de la table
     */
    itemActions: {
      type: [Array, Function],
      default: null,
    },
    /**
     * Les éléments affiché sous la forme de ligne dans la table
     */
    items: {
      type: Array,
      default: () => ([]),
    },
    /**
     * Les différentes entêtes de la table permettant également de paramétrer le comportement de leurs colonne associé
     * @see {@link https://vuetifyjs.com/en/api/v-data-table/#props-headers}
     */
    headers: {
      type: Array,
      default: () => ([]),
    },
    /**
     * La liste des éléments étendue de la table
     */
    expanded: {
      type: Array,
      default: () => ([]),
    },
    /**
     * Indique le nom de la propriété qui servira à grouper les éléments de la table
     * Une fonction de regroupement permettra de de définir par élément le groupe à appliquer @see {@link https://lodash.com/docs/4.17.15#groupBy}
     */
    groupBy: {
      type: [String, Function],
      default: null,
    },
    /**
     * La propriété servant de clé aux différents éléments de la table
     */
    itemKey: {
      type: String,
      default: null,
    },
    /**
     * Indique le tri par défaut de la table
     */
    sortBy: {
      type: String,
      default: null,
    },
    /**
     * Le texte à afficher lorsqu'une donnée n'est affiché dans la table
     */
    noDataText: {
      type: String,
      default: 'Aucune donnée disponible',
    },
    /**
     * Le texte à afficher au survol d'une action désactivée
     */
    disabledActionText: {
      type: String,
      default: null,
    },
    /**
     * Permet de déplier les lignes du tableau
     */
    expandableRows: {
      type: Boolean,
      default: false,
    },
    /**
     * Modifie l'odre dans lequel le tri est effectué.
     */
    sortDesc: {
      type: Boolean,
      default: false,
    },
    /**
     * Si vrai alors on ne peut pas désactiver le tri, il basculera toujours entre croissant et décroissant
     */
    mustSort: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche la table avec une plus grande taille pour ses lignes
     */
    large: {
      type: Boolean,
      default: false,
    },
    /**
     * Indicateur de chargement permettant l'affichage d'un squelette
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Permet de paginer les éléments de la table
     */
    pageable: {
      type: Boolean,
      default: false,
    },
    /**
     * Le nom d'élément maximum affiché lors d'une pagination
     */
    itemsPerPage: {
      type: Number,
      default: DEFAULT_ITEMS_PER_PAGE,
    },
    /**
     * Applique un tri personnalisé à la liste
     */
    customSort: {
      type: Function,
      default: sortItems,
    },
    /**
     * Fonction permettant de déterminer quelles lignes de la table ne pourront pas posséder d'actions
     */
    itemActionsDisabled: {
      type: Function,
      default: null,
    },
  },
  data () {
    return { SKELETON_ROWS_AMOUNT }
  },
  computed: {
    showTableSkeleton () {
      return this.loading && (this.items.length === 0)
    },
    tableHeaders () {
      const expandHeader = {
        text: null,
        value: 'expandRow',
        sortable: false,
        width: 0,
      }
      const actionHeader = {
        text: null,
        value: 'actions',
        sortable: false,
        width: 40,
      }
      return [
        this.expandableRows ? expandHeader : null,
        ...this.headers,
        this.itemActions ? actionHeader : null,
      ].filter(header => header)
    },
    tableGroupByProps () {
      if (! this.groupBy) {
        return null
      }
      if (typeof this.groupBy === 'string') {
        return { groupBy: this.groupBy }
      }
      return {
        groupBy: 'category',
        customGroup: (items) => {
          const groupedItems = groupBy(items, this.groupBy)
          return Object.keys(groupedItems).map(itemKey => ({
            name: itemKey,
            items: groupedItems[itemKey],
          }))
        },
      }
    },
  },
  methods: {
    getDisplayedItemValueFromHeader (item, itemHeader) {
      const EMPTY_VALUE = '-'
      if (itemHeader.valueGetter) {
        return itemHeader.valueGetter?.(item) || EMPTY_VALUE
      }
      const fieldArray = itemHeader.value.toString().split('.')
      let result = item[fieldArray[0]]
      for (let i = 1; i < fieldArray.length; i ++) {
        result = result[fieldArray[i]]
      }
      return result || EMPTY_VALUE
    },
    getItemActions (item) {
      if (typeof this.itemActions === 'function') {
        return this.itemActions(item)
      }
      const actionOptionGetter = (optionValue) => {
        if(! optionValue) {
          return null
        }
        return typeof optionValue === 'function' ? optionValue(item) : optionValue
      }
      return this.itemActions.map(itemAction => new ActionMenuItem(itemAction.icon, itemAction.text, () => itemAction.callback(item), {
        important: actionOptionGetter(itemAction.important),
        loading: actionOptionGetter(itemAction.loading),
        disabled: actionOptionGetter(itemAction.disabled),
      }))
    },
    isAllItemActionsDisabled (item) {
      if (this.itemActionsDisabled) {
        return this.itemActionsDisabled(item)
      }
      return this.getItemActions(item).every(action => action.disabled)
    },
    rowClicked (item) {
      this.$emit('click:row-clicked', item)
    },
  },
}
</script>

<style lang="scss" scoped>
$rows-height: 40px;
$rows-height-large: 64px;
$row-hover-color: #eee;
$row-hover-transition: background-color .125s ease;

.app-data-table {
  ::v-deep {
    .v-data-table__wrapper {
      height: 100%;
      overflow: auto;
    }
  }

  &.app-data-table--large {
    ::v-deep {
      tr > td  {
        height: $rows-height-large !important;
      }
      td {
        padding-top: map-get($spacers, 2);
        padding-bottom: map-get($spacers, 2);
      }
    }
  }

  &.app-data-table--grouped {
    ::v-deep {
      tr > td:first-child:not(.v-data-table__mobile-row),
      tr > th:first-child:not(.v-data-table__mobile-row)  {
        padding-left: 29px;
      }
    }
  }

  ::v-deep {
    .v-data-table-header th .v-icon {
      margin-left: map-get($spacers, 1);
    }

    .v-data-table__progress {
      position: absolute;
      left: 0;
      width: 100%;

      & > .column,
      & .v-progress-linear {
        height: 3px !important;
      }
      & > .column {
        display: block;
      }
      & .v-progress-linear {
        position: relative;
      }
    }

    .v-row-group {
      &__header {
        position: relative;
        background-color: transparent;
        height: $rows-height;

        &:hover {
          background-color: transparent !important;
        }
      }
    }

    th,
    .v-data-table__mobile-row__header {
      font-size: 13px;
      font-weight: 600;
    }

    th {
      height: $rows-height !important;
    }

    th,
    td {
      border-bottom: thin solid var(--v-divider-base);
      padding: 0 map-get($spacers, 4);
      font-size: 12px;
      vertical-align: middle;
    }

    tr {
      transition: $row-hover-transition;

      &:hover {
        background-color: $row-hover-color;
      }

      & > td,
      & > td.v-data-table__mobile-row__header {
        height: $rows-height;
        min-height: $rows-height;
      }
    }
  }

  &__group-title {
    position: absolute !important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: map-get($spacers, 2);
    padding: 0 map-get($spacers, 2);
    cursor: pointer;
    background-color: var(--v-divider-base);
    transition: $row-hover-transition;

    &:hover {
      background-color: $row-hover-color;
    }

    &--expanded &__icon {
      transform: rotate(0deg);
    }

    &__icon {
      transition: transform .125s ease;
      transform: rotate(-90deg);
    }
  }

  &__expandable-row {
    display: contents;
    user-select: none;
    cursor: pointer;
  }

   &__expanded-row,
   &__expanded-row > td {
    height: 0 !important;
    min-height: 0 !important;
    cursor: auto;
    user-select: auto;
  }

  &__expandable-row > tr:first-child > td {
    border-bottom: none;
  }

  &__skeleton-headings {
    display: flex;
    justify-content: flex-start;
    height: $rows-height;
    gap: map-get($spacers, 6);

    ::v-deep {
      .v-skeleton-loader__heading {
        max-width: 80px;
        margin-right: auto;
      }
    }
  }

  &__skeleton-cell-group {
    display: flex;
    justify-content: flex-start;
    gap: map-get($spacers, 6);
    height: $rows-height;

    ::v-deep {
      .v-skeleton-loader__text {
        margin-bottom: 0;
        max-width: 120px;
        margin-right: auto;
      }
    }
  }
}
</style>