import ApiEntity from '@/models/ApiEntity'
import Patient from '@/modules/patient/models/Patient'
import Pathology from '@/modules/patient/models/riskFactors/Pathology'

export default class HealthSummaryReminder extends ApiEntity {
  constructor (options = {}) {
    super(options)
    this.patient = options.patient ? new Patient(options.patient) : null
    this.patientPathologies = options.pathologies ? options.pathologies.map(pathology => new Pathology(pathology)) : []
    this.patientAld = options.ald
    this.reminderDate = options.reminderDate || null
    this.display = options.display || null
  }
}