<template>
  <figure
    class="app-avatar"
    :class="`app-avatar--${gender}`"
    :style="avatarStyles"
  >
    <figcaption v-if="label" class="app-avatar__label" data-test="avatar-label">
      <app-tag :color="labelColor">
        {{ label }}
      </app-tag>
    </figcaption>
    <svg
      v-if="[GENDERS.FEMALE.value, GENDERS.MALE.value].includes(gender)"
      class="app-avatar__icon"
      data-test="avatar-icon"
      height="100%"
      :name="avatarIcon"
      :class="avatarIcon"
    >
      <use :xlink:href="`#${avatarIcon}`" />
    </svg>
    <app-icon v-else data-test="avatar-unknown-icon" icon="unknown" />
  </figure>
</template>

<script>
import { GENDERS, AVATARS, CONTACTS_TYPES } from '@/constants'
import AppTag from '@/components/ui/tag/AppTag.vue'

export default {
  name: 'AppAvatar',
  components: { AppTag },
  props: {
    /**
     * Couleur de fond personnalisée
     */
    color: {
      type: String,
      default: null,
    },
    /**
     * le type de contact
     * @values medical, paramedical, other
     */
    type: {
      type: String,
      default: CONTACTS_TYPES.OTHER.value,
    },
    /**
     * le genre de l'avatar
     * @values male, female, unknown
     */
    gender: {
      type: String,
      default: GENDERS.UNKNOWN.value,
    },
    /**
     * Taille de l'avatar
     * par défaut 80
     */
    size: {
      type: [Number, String],
      default: 80,
    },
    /**
     * Le label de l'avatar
     * @default null
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * La couleur de fond du label de l'avatar
     * @default 'primary'
     */
    labelColor: {
      type: String,
      default: 'primary',
    },
  },
  data () {
    return { GENDERS }
  },
  computed: {
    /**
     * Retourne l'icône à afficher
     * @returns {String}
     */
    avatarIcon () {
      return AVATARS[this.type][this.gender].icon
    },
    /**
     * Retourne le nom de l'icône à afficher
     * @returns {String}
     */
    avatarName () {
      return AVATARS[this.type][this.gender].name
    },
    /**
     * Retourne la couleur de l'avatar
     * @returns {String}
     */
    avatarColor () {
      return AVATARS[this.type][this.gender].color
    },
    /**
     * Retourne le style de l'avatar
     * @returns {Object}
     */
    avatarStyles () {
      const size = typeof this.size === 'number' ? `${this.size}px` : this.size
      return {
        backgroundColor: this.color ? this.color : this.avatarColor,
        minWidth: size,
        maxWidth: size,
        height: size,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.app-avatar {
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  line-height: normal;
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;

  &__label {
    bottom: -2px;
    position: absolute;
    text-align: center;
    width: 100%;

    &::v-deep {
      .app-tag {
        border-radius: 0;
        height: 15px;
        justify-content: center;
        width: 100%;

        .v-chip__content {
          font-size: 10px;
          font-weight: 600;
        }
      }
    }
  }

  &__icon {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
}
</style>