import Vue from 'vue'

import { getFromAPI } from '@/services/api.js'
import { mapStoreResetter } from '@/utils/functions/store'

import documentHeaders from './headers'
import documentFooters from './footers'
import documentTemplates from './templates'
import DocumentVariable from '@/modules/documents/models/DocumentVariable.js'

export const state = () => ({
  ...documentHeaders.state(),
  ...documentFooters.state(),
  ...documentTemplates.state(),
  isDocumentEditorInitialized: false,
  documentVariables: {},
})

export const getters = {
  ...documentHeaders.getters,
  ...documentFooters.getters,
  ...documentTemplates.getters,
  getDocumentVariables: state => Object.values(state.documentVariables),
}

export const mutations = {
  ...mapStoreResetter(state),
  ...documentHeaders.mutations,
  ...documentFooters.mutations,
  ...documentTemplates.mutations,
  SET_DOCUMENT_EDITOR_INITIALIZED (state, value) {
    state.isDocumentEditorInitialized = value
  },
  SET_DOCUMENT_VARIABLE (state, variable) {
    Vue.set(state.documentVariables, variable['@id'], new DocumentVariable(variable))
  },
}

export const actions = {
  ...documentHeaders.actions,
  ...documentFooters.actions,
  ...documentTemplates.actions,
  async fetchDocumentVariables ({ commit }) {
    const { data } = await getFromAPI('/api/document_variables')
    data['hydra:member'].forEach(variable => commit('SET_DOCUMENT_VARIABLE', variable))
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}