/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity'
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField'
import DocumentEntityRelation from '@/modules/dataPortability/classes/DocumentEntityRelation'

const prescriptionLppLineEntity = new DocumentEntity('PrescriptionLppLine', 'prescriptionLppLines.xml', 'Contient les lignes des prescriptions de services et prestations')

prescriptionLppLineEntity.addField(new DocumentEntityField('product', 'Le service ou la prestation', new DocumentEntityRelation('LppProduct', 'id')))
prescriptionLppLineEntity.addField(new DocumentEntityField('comment', 'Le commentaire'))

export default prescriptionLppLineEntity