import Cim10ApiEntity from '@/models/Cim10ApiEntity'

export default class PatientAntecedent extends Cim10ApiEntity {
  constructor (options = {}) {
    super(options)

    this.type = options.type || null
    this.patient = options.patient || null
    this.antecedentRelation = options.antecedentRelation || null
    this.note = options.note || null
  }
}