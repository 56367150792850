<template>
  <validation-observer v-slot="{ invalid }" slim>
    <app-modal title="Paramètres de l'agenda" :is-open.sync="settingsOpened">
      <v-container class="pa-0" fluid>
        <v-row class="agenda-days-list">
          <v-col cols="12">
            <p>Jours à afficher en vue semaine</p>
            <validation-provider
              v-slot="{ errors }"
              vid="firstNames"
              slim
              rules="required|min_length:3"
              name="jours de la semaine"
            >
              <app-buttons-group
                v-model="settings.days"
                :items="possibleDays"
                :show-icon="false"
                multiple
                :error-messages="errors"
                full-width
                mandatory
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="agenda-day-bounds">
          <v-col cols="12" sm="6">
            <v-select
              v-model="settings.startTime"
              label="Début de journée"
              hint="Début de l'agenda pour les vues jour et semaine"
              persistent-hint
              :items="possiblesStartHours"
              menu-props="auto"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="settings.endTime"
              label="Fin de journée"
              hint="Fin de l'agenda pour les vues jour et semaine"
              persistent-hint
              :items="possiblesEndHours"
              menu-props="auto"
            />
          </v-col>
        </v-row>
      </v-container>
      <template #actions>
        <app-button
          text
          color="primary"
          class="mr-2"
          @click="settingsOpened = false"
        >
          Annuler
        </app-button>
        <app-button :disabled="invalid" color="primary" @click="save">
          Enregistrer
        </app-button>
      </template>
    </app-modal>
  </validation-observer>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import AppModal from '@/components/ui/modal/AppModal.vue'
import AppButtonsGroup from '@/components/ui/buttonsGroup/AppButtonsGroup.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'AgendaSettings',
  components: {
    AppModal,
    AppButtonsGroup,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      settings: {
        days: undefined,
        startTime: undefined,
        endTime: undefined,
      },
    }
  },
  computed: {
    ...mapGetters('agenda', ['config']),
    ...mapState('app', ['isMobile']),
    settingsOpened: {
      get () {
        return this.isOpen
      },
      set (newVal) {
        this.$emit('update:isOpen', newVal)
      },
    },
    possibleDays () {
      const days = ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim']

      return days.map((day, i) => ({
        name: this.isMobile ? day.substring(0, 1) : day,
        value: i === 6 ? 0 : i + 1, // Passe le dimanche à 0 au lieu de 7
      }))
    },
    possiblesStartHours () {
      const endHour = Number(this.settings.endTime?.split(':')[0])
      return this.createHoursArray(function (hour) {
        return hour < endHour
      })
    },
    possiblesEndHours () {
      const startHour = Number(this.settings.startTime?.split(':')[0])
      return this.createHoursArray(function (hour) {
        return hour > startHour
      })
    },
  },
  async created () {
    await this.getSettings()
    this.settings.startTime = this.config.startTime
    this.settings.endTime = this.config.endTime
    this.settings.days = [...this.config.days]
  },
  methods: {
    ...mapActions('agenda', ['getSettings', 'saveSettings']),
    createHoursArray: function (condition) {
      const possibleHours = []
      for (let hour = 0; hour <= 23; hour ++) {
        if (condition(hour)) {
          possibleHours.push({
            text: `${('0' + hour).slice(- 2)} h`,
            value: `${('0' + hour).slice(- 2)}:00:00`,
          })
        }
      }
      return possibleHours
    },
    save: function () {
      this.sortDays()
      this.saveSettings(this.settings)
      this.settingsOpened = false
    },
    sortDays: function () {
      const sortedDays = this.settings.days?.sort()
      if (sortedDays.includes(0)) {
        sortedDays.splice(0, 1)
        sortedDays.push(0)
      }
      this.settings.days = sortedDays
    },
  },
}
</script>