<template>
  <app-panel
    v-ripple="hasClickEvent"
    class="app-card-list-item"
    dense
    :class="{ 'app-card-list-item--clickable': hasClickEvent }"
    data-test="item"
    @click.native="handleClick"
  >
    <slot />
  </app-panel>
</template>

<script>
import AppPanel from '@/components/ui/panel/AppPanel.vue'

/**
 * Élément de la liste de cartes de l'application
 */
export default {
  name: 'AppCardListItem',
  components: { AppPanel },
  computed: {
    hasClickEvent () {
      return this.$listeners['click'] !== undefined
    },
  },
  methods: {
    handleClick () {
      if (this.hasClickEvent) {
        this.$emit('click')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.app-card-list-item {
	overflow: hidden;

	&--clickable {
		cursor: pointer;
	}
}
</style>