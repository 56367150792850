<template>
  <div class="app-divider-wrapper">
    <v-divider class="app-divider" v-bind="{...$props, ...$attrs}" />
  </div>
</template>

<script>
/**
 * @deprecated Utiliser le composant NDivider du framework
 */
export default { name: 'AppDivider' }
</script>