<template>
  <accounting-list-item-layout class="accounting-list-item">
    <template #date>
      <app-skeleton-loader width="100px" type="text" data-test="date" />
    </template>
    <template #total-amount>
      <app-skeleton-loader width="100px" type="heading" data-test="total-amount" />
    </template>
    <template #paid-amount-label>
      <app-skeleton-loader
        type="text"
        class="my-2"
        width="60px"
        data-test="paid-amount-label"
      />
    </template>
    <template #paid-amount-value>
      <app-skeleton-loader
        type="text"
        class="my-2"
        width="60px"
        data-test="paid-amount-value"
      />
    </template>
    <template #difference-amount-label>
      <app-skeleton-loader type="text" data-test="difference-amount-label" />
    </template>
    <template #difference-amount-value>
      <app-skeleton-loader type="text" data-test="difference-amount-value" />
    </template>
    <template #billing-status>
      <app-skeleton-loader type="heading" data-test="billing-status" />
    </template>
    <template #place>
      <app-skeleton-loader type="text" data-test="place" />
    </template>
    <template #patient-name>
      <app-skeleton-loader type="text" data-test="patient-name" />
    </template>
    <template #payment-list>
      <app-skeleton-loader
        type="text"
        width="120px"
        data-test="payments-title"
        class="mb-2"
      />
      <div data-test="payment-list">
        <app-skeleton-loader type="text" />
        <app-skeleton-loader type="text" class="my-2" />
        <app-skeleton-loader type="text" />
      </div>
    </template>
  </accounting-list-item-layout>
</template>

<script>
import AccountingListItemLayout from '../layouts/AccountingListItemLayout.vue'
import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue'

export default {
  name: 'AccountingListItemSkeleton',
  components: {
    AccountingListItemLayout,
    AppSkeletonLoader,
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-skeleton-loader {
    &__text {
      margin-bottom: 0;
    }

    &__chip {
      height: 20px;
      width: auto;
    }
  }
}
</style>