import { GENDERS } from '@/constants'

const SEX_CODE_FROM_GENDERS = {
  [GENDERS.FEMALE.value]: 3,
  [GENDERS.MALE.value]: 2,
  [GENDERS.UNKNOWN.value]: 1,
}

/**
 * Permet de récupérer le nir ainsi que sa clé à partir d'un nir complet
 * @param {String} nir
 * @returns {Object}
 */
export const getNirObject = nir => ({
  s_nir: nir ? nir.slice(0, 13) : '',
  s_key: nir ? nir.slice(13, 15) : '',
})

/**
 * Retourne le code sexe correspondant à un genre donné
 * @param {String} gender
 * @returns {Number}
 */
export const getSexCodeFromGender = gender => SEX_CODE_FROM_GENDERS[gender]

/**
 * Retourne le genre correspondant à un code sexe
 * @param {String} sexCode
 * @returns {Number}
 */
export const getGenderFromSexCode = sexCode => Object.keys(SEX_CODE_FROM_GENDERS).find(gender => SEX_CODE_FROM_GENDERS[gender] === parseInt(sexCode, 10))

/**
 * Retourne la date de naissance d'un assuré au format yyyy-MM-dd
 * en tenant compte des dates de naissances lunaires
 * @param {String} insuredBirthday La date de naissance de l'assuré au format yyyyMMdd0000
 * @param {options.ignoreLunarDate} ignoreLunarDate Ignore la prise en compte du format lunaire et renverra la date sans la formatter
 * @returns {String} La date de naissance de l'assuré au format yyyy-MM-dd
 */
export const getInsuredBirthDate = (insuredBirthday, options = {}) => {
  const birthYear = insuredBirthday.substring(0, 4)
  let birthMonth = insuredBirthday.substring(4, 6)
  let birthDay = insuredBirthday.substring(6, 8)

  if (options.ignoreLunarDate) {
    return `${birthYear}-${birthMonth}-${birthDay}`
  }

  // Dans le cas d'une date lunaire, le mois sera égal à 19
  const isLunarBirthDate = insuredBirthday.substring(4, 6) === '19'
  // Dans le cas d'une date lunaire le mois et le jours de naissance seront toujours le 1er janvier
  birthMonth = isLunarBirthDate ? '01' : birthMonth
  birthDay = isLunarBirthDate ? '01' : birthDay
  return `${birthYear}-${birthMonth}-${birthDay}`
}