import Vue from 'vue'

export const RESET_MUTATION = 'RESET'

/**
 * Fonction permettant la réinitialisation du state
 * ex: ...mapStoreResetter(stateInitiator)
 * @param {Function} stateInitiator
 * @returns {Object} l'ensemble des mutations à injecter
 */
export const mapStoreResetter = (stateInitiator) => {
  return {
    [RESET_MUTATION] (state) {
      const resetedState = stateInitiator()
      Object.keys(resetedState)
        .forEach(key => Vue.set(state, key, resetedState[key]))
    },
  }
}