<template>
  <div>
    <div class="appointments-display-selector">
      <app-buttons-group
        :value="getAppointmentsDisplayType"
        :items="APPOINTMENT_DISPLAY_TYPES"
        item-text="text"
        mandatory
        data-test="appointments-display-selector"
        class="appointments-display-selector__buttons"
        :class="{ 'appointments-display-selector__buttons--disabled': disabled }"
        @input="SET_APPOINTMENTS_DISPLAY_TYPE"
      />
    </div>
    <p
      v-if="isMobile && disabled"
      class="appointments-display-selector__disabled-message"
      data-test="appointments-display-selector-message"
    >
      Non disponible en vue liste
    </p>
    <n-tooltip
      v-if="! isMobile && disabled"
      bottom
      activator=".appointments-display-selector"
      data-test="appointments-display-selector-tooltip"
      text="Non disponible en vue liste"
    />
  </div>
</template>

<script>
import AppButtonsGroup from '@/components/ui/buttonsGroup/AppButtonsGroup.vue'

import { mapState, mapGetters, mapMutations } from 'vuex'

import { APPOINTMENT_DISPLAY_TYPES } from '@/modules/agenda/constants'

export default {
  name: 'AppointmentsDisplaySelector',
  components: { AppButtonsGroup },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return { APPOINTMENT_DISPLAY_TYPES }
  },
  computed: {
    ...mapState('app', ['isMobile']),
    ...mapGetters('agenda', ['getAppointmentsDisplayType']),
  },
  methods: { ...mapMutations('agenda', ['SET_APPOINTMENTS_DISPLAY_TYPE']) },
}
</script>

<style lang="scss" scoped>
  .appointments-display-selector {
    &__buttons {
      &--disabled {
        opacity: .5;
        pointer-events: none;
      }
    }
    &__disabled-message {
      font-size: 12px;
      margin: 4px 0 0 0;
      color: var(--v-blue-grey-base);
    }
  }
</style>