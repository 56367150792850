/**
 * Permet de créer une phrase en concaténant les éléments d'un tableau
 * @param {Array} elements les valeurs à concatener
 * @param {String} [separator=', '] le caractère de séparation
 * @param {String} [lastSeparator=' et '] le caractère de séparation entre le dernier et l'avant dernier élément
 * @returns {String} La phrase des éléments concaténés
 * @example joinArray(days, ', ', ' et ') => lundi, mardi, mercredi, jeudi, vendredi, samedi et dimanche
 */
export const arrayToSentence = (elements, separator = ', ', lastSeparator = ' et ') => {
  // Supprime les éléments undefined ou null
  const compactedElements = elements.filter(word => word)

  const listStart = compactedElements.slice(0, - 1).join(separator)
  const listEnd = compactedElements.slice(- 1)
  const conjunction = compactedElements.length <= 1 ? '' : (lastSeparator || separator)
  return [listStart, listEnd].join(conjunction)
}