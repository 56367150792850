<template>
  <tr>
    <td>
      <n-truncable-text class="bio-result-list-table-row__name">
        {{ bioResult.name }}
      </n-truncable-text>
    </td>
    <td>
      <bio-result-list-value-tag :bio-result="bioResult" />
    </td>
    <td>
      <bio-result-list-unit-selector :bio-result="bioResult" :selected-unit-index="selectedUnitIndex" />
    </td>
    <td>
      {{ bioResult.getDisplayableTableItemValueRange() }}
    </td>
  </tr>
</template>

<script>
import BioResultListValueTag from '@/modules/secureMessaging/modules/documents/components/metaDatasetModal/bioResultList/BioResultListValueTag.vue'
import BioResultListUnitSelector from '@/modules/secureMessaging/modules/documents/components/metaDatasetModal/bioResultList/BioResultListUnitSelector.vue'

import BioResultTableItem from '@/modules/secureMessaging/modules/documents/components/metaDatasetModal/bioResultList/classes/BioResultTableItem'

export default {
  name: 'BioResultListTableRow',
  components: {
    BioResultListValueTag,
    BioResultListUnitSelector,
  },
  props: {
    bioResult: {
      type: BioResultTableItem,
      required: true,
    },
    selectedUnitIndex: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style lang="scss" scoped>
.bio-result-list-table-row {
  display: contents;

  &__name {
    max-width: 350px;
    padding: map-get($spacers, 2) 0;
  }
}
</style>