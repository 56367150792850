<template>
  <app-form :submit-method="submit">
    <app-number-field
      ref="totalAmountNumberField"
      v-model="localValue.fees"
      label="Montant total"
      rules="min_value:0"
    />
  </app-form>
</template>

<script>
import AppForm from '@/components/ui/form/AppForm.vue'
import AppNumberField from '@/components/ui/form/AppNumberField.vue'
import { patchToAPI } from '@/services/api'
import { LocalCopyMixin } from '@novalys/mixins'

export default {
  name: 'ConsultationFeeForm',
  components: {
    AppNumberField,
    AppForm,
  },
  mixins: [LocalCopyMixin()],
  props: {
    value: {
      type: Object,
      required: true,
    },
    consultationIri: {
      type: String,
      required: true,
    },
  },
  methods: {
    async submit () {
      await patchToAPI(this.consultationIri + '/fees', { data: { fees: this.localValue.fees } })
    },
  },
}
</script>