<template>
  <div class="pathology-autocomplete">
    <section ref="widget" />
  </div>
</template>

<script>
import widgetMixin from '@/components/lap/synapse-widgets/mixins/widget'
import autocompleteMixin from '@/components/lap/synapse-widgets/mixins/autocomplete'

export default {
  name: 'PathologyAutocomplete',
  mixins: [widgetMixin, autocompleteMixin],
  async mounted () {
    await this.initWidget()
    this.widget.setCallback('onSelectPathology', (pathology) => {
      this.$emit('input', {
        ...pathology,
        label: pathology.label.split(' ').filter(word => word !== `(${pathology.code})`).join(' '),
      })
      this.$emit('update:search-input', null)
      this.$emit('focus-input', false)
    })
  },
}
</script>