<template>
  <app-form ref="appFormRef" :submit-method="submit" class="payment-form">
    <v-row v-if="isInvoicePaid" dense data-test="paid-invoice-alert">
      <v-col>
        <app-alert type="warning">
          Attention la facture est intégralement payée
        </app-alert>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <v-row dense>
          <v-col cols="6">
            <app-date-picker
              v-model="value.date"
              label="Date"
              hide-errors="auto"
              rules="required"
            />
          </v-col>
          <v-col cols="6">
            <app-number-field
              v-model="value.amount"
              name="amount"
              label="Montant"
              data-test="payment-amount-number-form"
              suffix="€"
              type="text"
              hide-errors="auto"
              rules="required"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="6">
        <app-select
          v-model="value.type"
          :items="Object.values(PAYMENTS_TYPES)"
          name="type"
          label="Mode de règlement"
          data-test="payment-type-select-form"
          item-text="label"
          item-value="value"
          rules="required"
          hide-errors="auto"
        />
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-row v-if="isCheckPayment" dense>
        <v-col cols="12">
          <v-row dense>
            <v-col cols="6">
              <app-text-field
                v-model="value.checkDataset.holderName"
                label="Titulaire du chèque"
                hide-errors="auto"
                data-test="check-holder-name-field"
              />
            </v-col>
            <v-col cols="6">
              <app-number-field
                v-model="value.checkDataset.checkNumber"
                label="Numéro de chèque"
                data-test="check-number-field"
                hide-errors="auto"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <app-text-field
                v-model="value.checkDataset.bankName"
                label="Banque"
                hide-errors="auto"
                data-test="check-bank-name-field"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-expand-transition>
  </app-form>
</template>

<script>
import { postToAPI, putToAPI } from '@/services/api'
import { mapMutations } from 'vuex'

import AppAlert from '@/components/ui/alert/AppAlert.vue'
import AppDatePicker from '@/components/ui/form/AppDatePicker.vue'
import AppForm from '@/components/ui/form/AppForm.vue'
import AppNumberField from '@/components/ui/form/AppNumberField.vue'
import AppSelect from '@/components/ui/form/AppSelect.vue'
import AppTextField from '@/components/ui/form/AppTextField.vue'

import { PAYMENTS_TYPES } from '@/constants'

import Payment from '@/modules/accounting/models/Payment'
import CheckDataset from '@/modules/patient/models/datasets/CheckDataset'

export default {
  name: 'PaymentForm',
  components: {
    AppAlert,
    AppDatePicker,
    AppForm,
    AppNumberField,
    AppSelect,
    AppTextField,
  },
  props: {
    value: {
      type: Payment,
      required: true,
    },
    isInvoicePaid: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return { PAYMENTS_TYPES }
  },
  computed: {
    isCheckPayment () {
      return this.value.type === PAYMENTS_TYPES.CHECK.value
    },
    isEditing: function () {
      return !! this.value?.['@id']
    },
  },
  watch: {
    'value.type' () {
      if (this.isCheckPayment) {
        if (! this.value.checkDataset) {
          this.value.checkDataset = new CheckDataset()
        }
      } else {
        this.value.checkDataset = null
      }
    },
  },
  methods: {
    ...mapMutations('app', ['SET_SNACK']),
    getPaymentPayload () {
      return new Payment(this.value)
    },
    async submit () {
      if (this.isEditing) {
        const { data } = await putToAPI(this.value.getIri(), { data: new Payment(this.value) })
        this.SET_SNACK({ message: 'Le règlement a été modifié avec succès' })
        return data
      }
      const { data } = await postToAPI('/api/payments', new Payment(this.value))
      this.SET_SNACK({ message: 'Le règlement a été ajouté avec succès' })
      return data

    },
  },
}
</script>