<template>
  <app-modal
    :is-open.sync="isChartOpened"
    :title="yLabel"
    hide-footer
    scrollable
    width="980"
  >
    <div class="flex-list-2 mb-2 justify-end">
      <app-buttons-group
        v-test="'toggle-age-range'"
        item-text="label"
        :value="selectedAgeRangeIndex"
        :items="getAgeRangeButtons()"
        mandatory
        @input="selectedAgeRangeIndex = $event"
      />
    </div>
    <patient-health-book-tendance-chart
      v-if="showTendanceChart"
      :patient="patient"
      :measures="measures"
      :category="currentMeasureName"
      :health-book-age-range="currentAgeRange"
      class="chart"
    />
    <app-line-chart
      v-else
      :chart-data="chartData"
      x-label="Le"
      :y-label="yLabel"
      :y-unit="yUnit"
      class="chart"
    />
  </app-modal>
</template>

<script>
import AppButtonsGroup from '@/components/ui/buttonsGroup/AppButtonsGroup.vue'
import AppModal from '@/components/ui/modal/AppModal.vue'
import AppLineChart from '@/components/ui/chart/AppLineChart.vue'
import PatientHealthBookTendanceChart from '@/modules/patient/components/patientFile/measures/PatientHealthBookTendanceChart.vue'

import NovaTools from '@/nova-tools/NovaTools'
import { uniqBy, inRange } from 'lodash'

import Patient from '@/modules/patient/models/Patient'

import { HEALTH_BOOK_AVERAGE_DATA, HEALTH_BOOK_AGE_RANGES } from '@/modules/patient/constants'

export default {
  name: 'BiometricModalChart',
  components: {
    AppLineChart,
    PatientHealthBookTendanceChart,
    AppModal,
    AppButtonsGroup,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    patient: {
      type: Patient,
      required: true,
    },
    measures: {
      type: Array,
      default: () => [],
    },
    yLabel: {
      type: String,
      default: '',
    },
    yUnit: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      isChartOpened: false,
      selectedAgeRangeIndex: 0,
    }
  },
  computed: {
    showTendanceChart () {
      return this.selectedAgeRangeIndex !== - 1 && this.selectedAgeRangeIndex < this.ageRangesFromData.length
    },
    currentMeasureName () {
      return uniqBy(this.measures, 'name')[0]?.name
    },
    currentAgeRange () {
      return this.showTendanceChart ? this.ageRangesFromData[this.selectedAgeRangeIndex] : {}
    },
    ageRangesFromData () {
      // Vérifie s'il existe des données de carnet de santé, à partir du sexe et du type de mesure
      const patientGender = this.patient.gender?.toUpperCase()

      if (
        Object.keys(HEALTH_BOOK_AVERAGE_DATA).includes(patientGender)
        && Object.keys(HEALTH_BOOK_AVERAGE_DATA[patientGender]).includes(this.currentMeasureName?.toUpperCase())
      ) {
        const ageRangeNames = Object.keys(HEALTH_BOOK_AVERAGE_DATA[patientGender][this.currentMeasureName.toUpperCase()])
        return Object.values(HEALTH_BOOK_AGE_RANGES).filter(range => ageRangeNames.includes(range.categoryName))
      }
      return []
    },
    matchingAgeRanges () {
      const rangesArr = []
      // Vérifie si on affiche les courbes façon carnet de santé,
      // en déterminant si l'âge du patient appartient à l'intervalle d'âge déterminé
      if (this.ageRangesFromData.length > 0) {
        const fullPatientAge = this.patient.getAge({ returnAsRawInterval: true })
        const patientAge = fullPatientAge.years + fullPatientAge.months / 12 // âge en année avec décimales

        this.ageRangesFromData.forEach((ageRange) => {
          const [min, max] = ageRange.value
          if (inRange(patientAge, min * ageRange.unit.yearCoefficient, max * ageRange.unit.yearCoefficient)) {
            rangesArr.push(ageRange)
          }
        })
      }
      return rangesArr
    },
    chartData () {
      const xValues = []
      const yValues = []

      this.measures.forEach((measure) => {
        xValues.push(NovaTools.dates.format(new Date(measure.measuredAt), 'dd/MM/yy'))
        yValues.push(measure.value)
      })

      return {
        labels: xValues,
        datasets: [{
          label: this.yLabel,
          data: yValues,
        }],
      }
    },
    defaultSelectedAgeRangeIndex () {
      if (this.matchingAgeRanges.length === 0) {
        return this.ageRangesFromData.length
      }
      return this.ageRangesFromData.indexOf(this.matchingAgeRanges[0])
    },
  },
  watch: {
    isOpen (isOpen) {
      if (isOpen) {
        this.isChartOpened = true
        this.selectedAgeRangeIndex = this.defaultSelectedAgeRangeIndex
      }
    },
    isChartOpened (isChartOpened) {
      if (! isChartOpened) {
        this.$emit('update:isOpen', false)
      }
    },
  },
  methods: {
    getAgeRangeButtons () {
      const ageRangeButtons = this.ageRangesFromData.map((range, index) => {
        return {
          label: range.categoryLabel,
          value: index,
        }
      })
      return [
        ...ageRangeButtons,
        {
          label: 'Dernières mesures',
          value: this.ageRangesFromData.length,
        },
      ]
    },
  },
}
</script>

<style scoped>
.chart {
  height: 75vh;
}
</style>