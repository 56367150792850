import AgendaModule from './AgendaModule.vue'

const EventModalRouteCommons = { meta: { showEventModal: true } }

export default {
  name: 'agenda',
  path: '/agenda',
  component: AgendaModule,
  children: [
    {
      name: 'agenda.add',
      path: 'nouveau/:date/:startTime/:schedule/:duration?',
      ...EventModalRouteCommons,
    },
    {
      name: 'agenda.edit.appointment',
      path: 'rdv/:eventUuid',
      meta: {
        ...EventModalRouteCommons.meta,
        type: 'rdv',
      },
    },
    {
      name: 'agenda.edit.availability',
      path: 'dispo/:eventUuid',
      meta: {
        ...EventModalRouteCommons.meta,
        type: 'dispo',
      },
    },
    {
      name: 'agenda.edit.absence',
      path: 'absence/:eventUuid',
      meta: {
        ...EventModalRouteCommons.meta,
        type: 'absence',
      },
    },
    {
      path: '*',
      redirect: { name: 'agenda' },
    },
  ],
}