<template>
  <span
    v-test="'dmp-badge'"
    class="dmp-badge"
    :class="status"
  >
    <app-icon
      v-test="'dmp-badge-icon'"
      class="icon"
      :tooltip="label"
      icon="dmp"
      size="x-small"
      :color="color"
    />
  </span>
</template>

<script>
/**
 * Couleurs et labels des différents status DMP possibles
 */
const statusLabel = {
  sync: {
    label: 'Envoyé au DMP',
    color: 'success',
  },
  'not-sync': {
    label: 'Non envoyé au DMP',
    color: 'error',
  },
}

/**
 * Affiche un badge de couleur selon l'état de synchronisation au DMP
 */
export default {
  name: 'DocumentDmpStatusBadge',
  props: {
    dmpSync: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    status () {
      return this.dmpSync ? 'sync' : 'not-sync'
    },
    label () {
      return statusLabel[this.status].label
    },
    color () {
      return statusLabel[this.status].color
    },
  },
}
</script>

<style lang="scss" scoped>
.dmp-badge {
  cursor: help;
  border-radius: 8px;
  width: 25px;
  text-align: center;
  transition: .3s ease-out background-color;

  &.sync {
    background-color: var(--v-success-lighten5);
  }

  &.not-sync {
    background-color: var(--v-warning-lighten5);
  }

}
</style>