<template>
  <router-view v-if="isCurrentUserPractitioner" data-test="secure-messaging-module" />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import AuthenticationServiceInstance from '@/modules/secureMessaging/modules/authentication/services/AuthenticationService'

import { ROUTE_NAMES } from '@/modules/secureMessaging/constants'
import { DEFAULT_FOLDERS } from '@/modules/secureMessaging/modules/messaging/constants'

/**
 * Permet de rediriger la route en fonction de l'état de la connexion à la MSS
 */
const redirectFromAuthenticationStatus = (routeName, next) => {
  const isAuthenticated = AuthenticationServiceInstance.isAuthenticated
  if (isAuthenticated && [ROUTE_NAMES.AUTHENTICATION, ROUTE_NAMES.ROOT].includes(routeName)) {
    next({
      name: ROUTE_NAMES.FOLDER,
      params: { folderName: DEFAULT_FOLDERS.INBOX.value },
    })
    return
  }
  if (! isAuthenticated && routeName !== ROUTE_NAMES.AUTHENTICATION) {
    next({ name: ROUTE_NAMES.AUTHENTICATION })
    return
  }
  next()
}

export default {
  name: 'SecureMessagingModule',
  beforeRouteEnter (to, from, next) {
    redirectFromAuthenticationStatus(to.name, next)
  },
  beforeRouteUpdate (to, from, next) {
    redirectFromAuthenticationStatus(to.name, next)
  },
  computed: { ...mapGetters('auth', ['isCurrentUserPractitioner']) },
  created () {
    if (! this.isCurrentUserPractitioner) {
      this.SET_SNACK({
        color: 'error',
        message: 'Vous n\'avez pas l\'autorisation d\'accéder à ce module',
      })
    }
  },
  methods: { ...mapMutations('app', ['SET_SNACK']) },
}
</script>