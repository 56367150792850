export const state = () => ({ isHealthSummaryRemindersUpdated: false })

export const getters = { isHealthSummaryRemindersUpdated: state => state.isHealthSummaryRemindersUpdated }

export const mutations = {
  SET_IS_HEALTH_SUMMARY_REMINDERS_UPDATED (state, value) {
    state.isHealthSummaryRemindersUpdated = value
  },
}

export const actions = {
  setIsHealthSummaryRemindersUpdated ({ commit }, value) {
    commit('SET_IS_HEALTH_SUMMARY_REMINDERS_UPDATED', value)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}