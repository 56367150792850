import BaseHttpRequestParameter from '@/utils/classes/httpRequestParameters/BaseHttpRequestParameter'

/**
 * Classe représentant un filtre de base
 * Sert de socle à l'élaboration de filtres plus complexes
 */
export default class BaseFilter extends BaseHttpRequestParameter {

  /**
   * Permet de créer un filtre de base
   * @param {BaseFilterOptions} options
   */
  constructor (options = {}) {
    super(options)
  }

}