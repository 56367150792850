<template>
  <section class="agenda-navigation d-flex align-center">
    <v-btn
      small
      depressed
      rounded
      outlined
      color="blue-grey"
      class="today-button mr-4"
      data-test="todayBtn"
      @click="moveToToday"
    >
      Aujourd'hui
    </v-btn>

    <div class="mr-4 d-flex">
      <v-btn
        icon
        small
        color="blue-grey"
        data-test="prevBtn"
        @click="MOVE(-1)"
      >
        <v-icon>fas fa-arrow-left</v-icon>
      </v-btn>
      <v-btn
        icon
        small
        color="blue-grey"
        data-test="nextBtn"
        @click="MOVE(1)"
      >
        <v-icon>fas fa-arrow-right</v-icon>
      </v-btn>
    </div>

    <div class="agenda-navigation__interval blue-grey--text">
      <span class="mr-3">
        <span class="mr-2" data-test="month">{{ period.month }}</span>
        <span>{{ period.year }}</span>
      </span>
      <span class="agenda-navigation__interval-week" data-test="week">
        sem {{ period.week }}
      </span>
    </div>
  </section>
</template>

<script>
import {
  startOfWeek,
  endOfWeek,
  getWeek,
  format,
} from '@/utils/functions/dates.js'

import { mapState, mapMutations } from 'vuex'
import sortedUniq from 'lodash/sortedUniq'

/**
 * La navigation par semaine de l'agenda
 */
export default {
  name: 'AgendaNavigation',
  computed: {
    ...mapState('agenda', ['currentDate']),
    currentMonth () {
      const start = format(startOfWeek(this.currentDate), 'MMM').replace('.', '')
      const end = format(endOfWeek(this.currentDate), 'MMM').replace('.', '')
      return sortedUniq([start, end]).join('-')
    },
    period () {
      return {
        week: getWeek(this.currentDate),
        month: this.currentMonth,
        year: format(this.currentDate, 'yyyy'),
      }
    },
  },
  methods: {
    ...mapMutations('agenda', ['MOVE', 'SET_CURRENT_DATE']),
    moveToToday () {
      this.SET_CURRENT_DATE(new Date())
    },
  },
}
</script>

<style lang="scss" scoped>
.agenda-navigation {
  &__interval {
    font-weight: 600;
    text-transform: uppercase;
  }
  &__interval-week {
    font-weight: 400;
    font-size: 12px;
    text-transform: none;
  }
  .today-button {
    text-transform: uppercase;
  }
}
</style>