<template>
  <v-tab
    v-test="'tab'"
    class="app-tabs-header"
    :class="{ 'app-tabs-header--invalid': invalid }"
    :disabled="disabled"
  >
    <div v-test="'content'" class="flex-list-1 flex-column py-2">
      <app-icon v-if="icon" :icon="icon" />
      <v-badge
        v-if="(dotBadge && badgeColor) || isTabHeaderCountVisible(badgeContent)"
        v-test="'badge'"
        :color="badgeColor"
        :content="badgeContent"
        :dot="dotBadge"
        offset-x="4"
        offset-y="4"
      >
        <span class="pr-1">{{ label }}</span>
      </v-badge>
      <span v-else>{{ label }}</span>
    </div>
  </v-tab>
</template>

<script>
export default {
  name: 'AppTabsHeader',
  props: {
    label: {
      type: String,
      required: true,
    },
    badgeColor: {
      type: String,
      default: 'content',
    },
    badgeContent: {
      type: [String, Number],
      default: null,
    },
    dotBadge: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isTabHeaderCountVisible (count) {
      return parseInt(count, 10) > 0
    },
  },
}
</script>
<style lang="scss" scoped>
.app-tabs-header {
  font-size: 13px;
  font-weight: 600;
  text-transform: none;
  letter-spacing: initial;

  &--invalid {
    color: var(--v-error-base) !important;
    animation: v-shake 0.6s cubic-bezier(0, 0.65, 0, 1.8);
  }

  ::v-deep {
    .v-badge:not(.v-badge--dot) {
      .v-badge__badge {
        font-size: 10px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 14px;
        padding: 0 map-get($spacers, 1);
        line-height: 10;
        font-weight: 600;
      }
    }
  }
}
</style>