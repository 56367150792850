<template>
  <v-bottom-sheet
    v-model="isActive"
    class="app-mobile-bottom-navigation-more-links"
    index="2"
    overlay-color="text"
  >
    <template #activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on">
        <span class="white--text">Plus</span>
        <v-icon color="white" size="20" class="mb-1">
          fas fa-bars
        </v-icon>
      </v-btn>
    </template>
    <v-sheet
      class="app-mobile-bottom-navigation-more-links__menu pa-4"
      width="100%"
      height="100%"
    >
      <v-row dense>
        <v-col v-for="item in items" :key="item.text" cols="6">
          <v-card
            v-ripple
            class="d-flex flex-column align-center pa-3 white--text"
            rounded="lg"
            color="text"
            :to="item.route"
            data-test="menuItem"
            @click="closeBottomSheet"
          >
            <svg
              data-test="itemIcon"
              width="24"
              height="24"
              :name="item.icon"
              :class="`mb-1 icon-${item.icon}`"
            >
              <use :xlink:href="`#icon-${item.icon}`" />
            </svg>
            <span class="font-weight-medium" data-test="itemText">
              {{ item.text }}
            </span>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
/**
 * Représente le menu affiché lors du clic sur le bouton plus
 */
export default {
  name: 'TheBottomNavigationMoreLinks',
  props: {
    /**
     * Les éléments à afficher
     */
    items: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      /**
       * L'état du menu
       */
      isActive: false,
    }
  },
  methods: {
    /**
     * Ferme le menu
     */
    closeBottomSheet () {
      this.isActive = false
    },
  },
}
</script>