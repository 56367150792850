import Cim10ApiEntity from '@/models/Cim10ApiEntity'

export default class PatientCim10RiskFactor extends Cim10ApiEntity {
  constructor (options = {}) {
    super(options)

    this.patient = options.patient || null
    this.note = options.note || null
    this.important = options.important || false
  }
}