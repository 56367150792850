<template>
  <v-divider
    class="n-divider"
    :class="{
      'n-divider--vertical': vertical,
      [`m${vertical ? 'x' : 'y'}-${spacing}`]: spacing
    }"
    :vertical="vertical"
  />
</template>

<script>
/**
 * Composant utilisé pour séparer des sections ou des éléments
 */
export default {
  name: 'NDivider',
  props: {
    /**
     * Affiche le divider à la verticale
     */
    vertical: {
      type: Boolean,
      required: false,
    },
    /**
     * L'espacement appliqué par le divider sur chacun de ses côtés en fonction de son type d'affichage
     */
    spacing: {
      type: Number,
      default: 2,
    },
  },
}
</script>

<style lang="scss" scoped>
.n-divider {
  &:not(.n-divider--vertical) {
    max-width: none;
  }

  &--vertical {
    margin-right: 0;
  }
}
</style>