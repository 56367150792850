import Event from './Event'
import AbsenceSettings from './AbsenceSettings'

export default class Absence extends Event {
  constructor (options = {}) {
    super(options)

    this.setting = new AbsenceSettings(options.setting)
  }

  getLabel () {
    return this.setting?.title || 'Absence'
  }

  getColor () {
    return '#8c94b2'
  }

  isLabelTimed () {
    return false
  }
}