<template>
  <span>
    {{ item.s_name }}
    <span v-if="!disabled" class="ml-2 text--text text--lighten-3">
      {{ getCardReaderStateText(item) }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'CardReaderListItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getCardReaderStateText (item) {
      if (item.i_slotType === 2) {
        return 'Carte vitale insérée'
      } else if (item.i_slotType === 3) {
        return 'Carte CPx insérée'
      }

      return ''
    },
  },
}
</script>