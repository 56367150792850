<template>
  <v-tab-item
    :eager="alwaysMounted"
    :disabled="disabled"
    class="app-tab-item"
    :class="tabsItemsClassName"
  >
    <slot />
  </v-tab-item>
</template>

<script>
/**
 * Un élément d'onglet de l\'application
 */
export default {
  name: 'AppTabsItem',
  props: {
    /**
     * Le label de l'onglet
     */
    label: {
      type: String,
      required: true,
    },
    badgeColor: {
      type: String,
      default: 'content',
    },
    icon: {
      type: String,
      default: null,
    },
    badgeContent: {
      type: [String, Number],
      default: null,
    },
    /**
     * Applique un statut invalide sur l'onglet
     */
    invalid: {
      type: Boolean,
      default: false,
    },
    dotBadge: {
      type: Boolean,
      default: false,
    },
    /**
     * Force le montage de l'onglet même si celui-ci n'est pas visible
     */
    alwaysMounted: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tabsItemsClassName () {
      const appTabsProps = this.$parent?.$parent?.$props
      if (! appTabsProps) {
        throw 'AppTabsItem doit être utilisé en enfant direct de AppTabs'
      }

      const spacingX = (appTabsProps.spacingX !== null) ? appTabsProps.spacingX : appTabsProps.spacing
      return {
        [`pt-${appTabsProps.spacing}`]: appTabsProps.spacing,
        [`px-${spacingX}`]: spacingX,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.app-tab-item {
  padding-bottom: 4px;
}
</style>