import ApiEntity from '@/models/ApiEntity'

import { extension } from 'mime-types'
import { getFullContentURL } from '@/utils/functions/files'

export default class ApiFile extends ApiEntity {
  constructor (options = {}) {
    super(options)
    this.contentUrl = options.contentUrl || null
    this.mimeType = options.mimeType || null
  }

  /**
   * Retourne le chemin d'un fichier préfixée par l'URL racine de l'API
   * @see {@link getFullContentURL}
   */
  getFullContentURL () {
    return getFullContentURL(this.contentUrl)
  }

  getFormattedType () {
    return extension(this.mimeType).toUpperCase?.()
  }
}