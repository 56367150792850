<template>
  <div class="flex-list-6 flex-column">
    <n-alert
      v-if="! patientRelativeBeneficiary"
      v-test="'unfounded-patient-alert'"
      transparent
      type="warning"
      title="Le patient n'a pas été trouvé dans la liste des bénéficiaires"
      message="Sélectionnez le bénéficiaire correspondant dans la liste ou créez son dossier dans l'application"
    />
    <app-row>
      <app-col v-if="mainInsured" cols="12" md="6">
        <h2 class="subtitle-3 mb-1">
          Assuré
        </h2>
        <div class="flex-column flex-list-1">
          <app-dataset-item
            v-test="'insured-full-name'"
            label="Nom complet"
            :value="mainInsured.patient.getCivilState()"
          />
          <app-dataset-item v-test="'insured-nir'" label="NIR" :value="mainInsured.patient.nir" />
          <app-dataset-item v-test="'insured-birth-date'" label="Date de naissance" :value="insuredBirthDate" />
        </div>
      </app-col>
      <app-col cols="12" md="6">
        <h2 class="subtitle-3 mb-1">
          Bénéficiaire
        </h2>
        <vitale-card-insured-picker
          v-model="localSelectedBeneficiary"
          v-test="'beneficiary-selector'"
          :insureds="insureds"
          rules="required"
        />
      </app-col>
    </app-row>
  </div>
</template>

<script>
import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue'

import VitaleCardInsuredPicker from '@/components/ui/form/VitaleCardInsuredPicker.vue'

import Patient from '@/modules/patient/models/Patient'
import { softInclude } from '@/utils/functions/words'

import NovaTools from '@/nova-tools/NovaTools'

/**
 * Permet à partir d'une liste d'assuré d'en choisir un
 */
export default {
  name: 'BeneficiarySelector',
  components: {
    AppDatasetItem,
    VitaleCardInsuredPicker,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
    insureds: {
      type: Array,
      required: true,
    },
    selectedBeneficiary: {
      type: Object,
      default: null,
    },
  },
  data () {
    return { localSelectedBeneficiary: null }
  },
  computed: {
    mainInsured () {
      return this.insureds.find(insured => insured.isMainInsured)
    },
    insuredBirthDate () {
      return NovaTools.dates.format(this.mainInsured.patient.birthDate || new Date(), 'dd MMMM yyyy')
    },
    patientRelativeBeneficiary () {
      return this.insureds.find(insured => softInclude(insured.patient.getCivilState(), this.patient.getCivilState()) && (insured.patient.birthDate === this.patient.birthDate))
    },
  },
  watch: {
    selectedBeneficiary () {
      this.localSelectedBeneficiary = this.selectedBeneficiary
    },
    localSelectedBeneficiary () {
      this.$emit('update:selected-beneficiary', this.localSelectedBeneficiary)
    },
  },
}
</script>