<template>
  <transition-group name="notifications" tag="section" class="n-application-notifications-center">
    <n-alert
      v-for="notification in notifications"
      :key="notification.id"
      ref="notification"
      v-test="'notification'"
      :badge-count="notification.occurrenceCount"
      :type="notification.type"
      :title="notification.title"
      :message="notification.message"
      :actions="notification.actions"
      class="n-application-notifications-center__notification"
      :timeout="notification.timeout"
      @click:close="removeNotification(notification.id)"
      @timeout-end="removeNotification(notification.id)"
    />
  </transition-group>
</template>

<script>
import NovaTools from '@/nova-tools/NovaTools'

/**
 * Le centre de notification d'une application utilisant novalys
 */
export default {
  name: 'NApplicationNotificationsCenter',
  props: {
    /**
     * Le nombre maximum de notifications visibles.
     * Au delà de ce nombre, les notifications les plus anciennes disparaîtrons
     */
    maxVisibleNotifications: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    notifications () {
      return NovaTools.notify.getAll()
    },
  },
  watch: {
    notifications (notifications) {
      if (notifications.length > this.maxVisibleNotifications) {
        const overflowingNotification = notifications[(notifications.length - 1) - this.maxVisibleNotifications]
        this.removeNotification(overflowingNotification.id)
        return
      }
      notifications.forEach((notification, index) => {
        if (notification.timeout && notification.occurrenceCount > 1) {
          this.$refs.notification[index].restartTimeout()
        }
      })
    },
  },
  methods: {
    async removeNotification (notificationId) {
      await this.$nextTick()
      NovaTools.notify.remove(notificationId)
    },
  },
}
</script>

<style lang="scss" scoped>
.n-application-notifications-center {
  &__notification {
    transition: all .3s;
    width: 100%;
    margin-bottom: map-get($spacers, 2);
    word-break: break-word;

    &:last-child {
      margin-bottom: 0;
    }

    &.notifications-leave-active {
      position: absolute;
    }

    &.notifications-enter,
    &.notifications-leave-to {
      opacity: 0;
    }
  }
}
</style>