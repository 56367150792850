import ContactModule from '@/modules/contact/ContactModule'

import { ROUTE_NAMES } from '@/modules/contact/constants'

export default {
  path: '/contacts',
  component: ContactModule,
  children: [
    {
      path: '/',
      name: ROUTE_NAMES.CONTACT_LISTING,
      component: () => import('@/modules/contact/views/ContactsListing.vue'),
    },
    {
      path: ':type/:uuid',
      name: ROUTE_NAMES.CONTACT_VIEW,
      component: () => import('@/modules/contact/views/ContactView.vue'),
    },
  ],
}