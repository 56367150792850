<template>
  <app-text-field
    v-model="globalFilter.componentValue"
    outlined
    :dense="isMobile"
    label="Rechercher le contenu d'une consultation ou d'une note"
    clearable
    prepend-inner-icon="search"
  />
</template>

<script>
import AppTextField from '@/components/ui/form/AppTextField.vue'

import { mapGetters } from 'vuex'

import FilterComponentMixin from '@/mixins/filterComponent'

import TextFilter from '@/utils/classes/httpRequestParameters/filters/TextFilter'

export default {
  name: 'MedicalHistoryFilters',
  components: { AppTextField },
  mixins: [
    FilterComponentMixin([
      new TextFilter({
        name: 'global',
        debounce: true,
      }),
    ]),
  ],
  computed: { ...mapGetters('app', ['isMobile']) },
}
</script>