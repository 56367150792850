<template>
  <div class="vitale-card-search-modal-form-steps">
    <v-slide-y-transition>
      <app-stepper
        v-if="currentStepIndex > 0"
        v-model="currentStepIndex"
        :steps="steps"
        :class="{'mb-6': ! isMobile }"
        :vertical-alignment="isMobile"
      />
    </v-slide-y-transition>
    <v-window v-model="currentStepIndex">
      <v-window-item>
        <div v-if="isFetchingMatchesPatient" class="vitale-card-search-modal-form-steps__display">
          <app-circular-loader :size="80" :width="5" class="vitale-card-search-modal-form-steps__display__image" />
          <p class="vitale-card-search-modal-form-steps__display__title">
            Recherche de patients similaires
            dans l'organisation
          </p>
        </div>
        <div v-else>
          <div class="mb-6">
            Sélectionner un bénéficiaire

            <app-button
              title="Lire la carte vitale"
              color="primary"
              small
              class="vitale-card-search-modal-form-steps__btn-reload-card"
              @click="forceReading"
            >
              Lire la carte vitale
            </app-button>
          </div>
          <vitale-card-insured-list-selector @click:insured="handleClickInsured" />
        </div>
      </v-window-item>
      <v-window-item>
        <div v-if="patientMatches.length">
          <div class="mb-6">
            Sélectionner le dossier auquel accéder
          </div>
          <patient-compact-card-list-selector :patients="patientMatches" @update:selected-patient-iri="handleSelectPatient" />
        </div>
        <div v-else class="vitale-card-search-modal-form-steps__display">
          <svg
            height="300"
            width="100%"
            class="icon-ressource-not-found"
          >
            <use xlink:href="#icon-ressource-not-found" />
          </svg>
          <p class="vitale-card-search-modal-form-steps__display__title">
            Aucun patient correspondant n'a été trouvé
          </p>
        </div>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import AppStepper from '@/components/ui/stepper/AppStepper.vue'
import AppCircularLoader from '@/components/ui/loaders/AppCircularLoader.vue'
import VitaleCardInsuredListSelector from '@/modules/patient/components/VitaleCardInsuredListSelector.vue'
import PatientCompactCardListSelector from '@/modules/patient/components/PatientCompactCardListSelector.vue'
import AppButton from '@/components/ui/buttons/AppButton.vue'
import iCanopeeCardReaderInstance from '@/services/vendors/icanopee/classes/ICanopeeCardReader'
import { CARD_TYPES } from '@/services/card-reader/constants/cardTypes'

import { getFromAPI } from '@/services/api'
import { mapMutations } from 'vuex'

import Patient from '@/modules/patient/models/Patient'
import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'VitaleCardSearchModalFormSteps',
  components: {
    AppStepper,
    AppCircularLoader,
    VitaleCardInsuredListSelector,
    PatientCompactCardListSelector,
    AppButton,
  },
  props: {
    selectedInsured: {
      type: Patient,
      default: null,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      steps: [
        { text: 'Sélection carte vitale' },
        { text: 'Sélection du dossier patient' },
      ],
      currentStepIndex: 0,
      isFetchingMatchesPatient: false,
      patientMatches: [],
    }
  },
  watch: {
    async selectedInsured (selectedInsured) {
      if (selectedInsured) {
        await this.fetchPatientInsuredMatches(selectedInsured)
        this.currentStepIndex = 1
        return
      }
      this.currentStepIndex = 0
    },
    currentStepIndex (index) {
      if (index === 0) {
        this.$emit('update:selected-insured', null)
      }
    },
  },
  methods: {
    ...mapMutations('cardReaders', ['SET_CARD_CONTENT']),
    async fetchPatientInsuredMatches (insured) {
      this.isFetchingMatchesPatient = true
      try {
        const { firstNames, birthName, familyName, birthDate } = insured
        const searchText = [
          firstNames,
          birthName,
          familyName,
          birthDate ? NovaTools.dates.format(birthDate, 'dd/MM/yyyy') : null,
        ].filter(field => !! field).join(' ')
        const { data } = await getFromAPI('/api/patients', {
          global: searchText,
          archived: false,
        })
        this.patientMatches = data['hydra:member'].map(patient => new Patient(patient))
      } finally {
        this.isFetchingMatchesPatient = false
      }
    },
    handleClickInsured (insured) {
      this.$emit('update:selected-insured', insured)
    },
    handleSelectPatient (patientIri) {
      this.$emit('patient-selected', patientIri)
    },
    async forceReading () {
      this.$emit('reload-card')
      const cardReaderType = CARD_TYPES.VITALE
      this.SET_CARD_CONTENT({
        cardReaderType,
        content: null,
      })
      const content = await iCanopeeCardReaderInstance.fetchVitaleCardContent()
      this.SET_CARD_CONTENT({
        cardReaderType,
        content,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.vitale-card-search-modal-form-steps {
  display: flex;
  flex-direction: column;

  ::v-deep {
    .v-window,
    .v-window__container {
      height: 100%;
    }

    .v-window-item {
      min-height: 100%;
    }
  }
  &__btn-reload-card {
    position: absolute;
    right: 0px;
  }

  &__display {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: map-get($spacers, 6);
    height: 100%;

    &__image {
      min-height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__title {
      font-size: 16px;
      font-weight: 600;
      max-width: 400px;
    }
  }
}

</style>