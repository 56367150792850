<template>
  <app-emails-field
    v-model="localValue"
    :label="label"
    :rules="rules"
    :hint="hint"
    :items="searchResults"
    :item-key="['id', 'mssEmail']"
    :item-text="getSelectedReceiverLabel"
    :item-disabled="isItemDisabled"
    :loading="loading"
    class="messaging-emails-field"
    @input:search="handleSearchInput"
  >
    <template #item="{ item }">
      <section class="d-flex align-center">
        <app-icon :icon="itemIcons[item.type]" class="mr-3" />
        <div>
          <p class="messaging-emails-field__item-title">
            {{ item.civilState }}
          </p>
          <p class="messaging-emails-field__item-content">
            {{ item.mssEmail }}
          </p>
        </div>
      </section>
    </template>
    <template v-if="! noData && (! patient || (patient && isPatientDisabled(patient)))" #append-item>
      <section class="messaging-emails-field__append-item-text">
        <n-divider :spacing="0" />
        <p class="px-4 py-1">
          <span v-if="! patient">
            Seuls les patients avec une identité qualifiée et ayant consenti à l'envoi de documents par MSS peuvent être sélectionnés.
          </span>
          <span v-if="patient && isPatientDisabled(patient)">
            Le patient ne peut pas être sélectionné car il ne possède pas une identité qualifiée ou n'a pas consenti à l'envoi de documents par MSS.
          </span>
        </p>
      </section>
    </template>
    <template v-if="noData" #no-data>
      <p class="px-4 py-1">
        Aucun résultat
      </p>
    </template>
  </app-emails-field>
</template>

<script>
import AppEmailsField from '@/components/ui/form/AppEmailsField.vue'

import localCopyMixin from '@novalys/src/mixins/local-copy-mixin'

import { getFromAPI } from '@/services/api'
import { debounce } from 'lodash'

import { INS_IDENTITY_STATUS } from '@/modules/patient/constants/insIdentityStatus'

import Patient from '@/modules/patient/models/Patient'
import PatientCorrespondent from '@/modules/patient/models/PatientCorrespondent'

export default {
  name: 'MessagingEmailsField',
  components: { AppEmailsField },
  mixins: [localCopyMixin()],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Destinataires',
    },
    rules: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    patient: {
      type: Patient || null,
      default: null,
    },
  },
  data () {
    return {
      INS_IDENTITY_STATUS,
      localValue: this.value,
      searchString: '',
      searchResults: [],
      loading: false,
      itemIcons: {
        patient: 'user',
        correspondent: 'practitioner',
      },
    }
  },
  computed: {
    noData () {
      return ! this.loading && this.searchString?.length > 2 && this.searchResults.length === 0
    },
  },
  async mounted () {
    if (this.patient) {
      const { data } = await getFromAPI(`${this.patient['@id']}/correspondents?order[contact.familyName]=asc&order[contact.firstNames]=asc`)

      this.searchResults = data['hydra:member'].reduce((acc, value) => acc.concat(this.formatCorrespondentItem(value)), [])

      this.searchResults.unshift(
        { header: 'Patient' },
        this.formatPatientItem(this.patient),
        { header: 'Correspondants' },
      )
    }
  },
  methods: {
    handleSearchInput: debounce(async function (searchString) {
      if (! this.patient) {
        this.searchString = searchString

        if (! searchString) {
          this.searchResults = []
          return
        }
        if (searchString.length < 3) {
          this.searchResults = []
          return
        }

        this.loading = true
        this.searchString = searchString
        let results = []
        const patientResults = await this.fetchPatientResults(searchString)
        results = patientResults.filter(patient => patient.insIdentity?.ins).map(patient => this.formatPatientItem(patient))

        const correspondentResults = await this.fetchCorrespondentResults(searchString)

        this.searchResults = correspondentResults.reduce((acc, value) => acc.concat(this.formatCorrespondentItem(value)), results)
        this.loading = false
      }
    }, 300),
    isItemDisabled (receiver) {
      return receiver.type === 'patient' && this.isPatientDisabled(receiver)
    },
    isPatientDisabled (patient) {
      return ! patient.insIdentity?.ins || patient.insIdentity.status !== INS_IDENTITY_STATUS.QUALIFIED.value || ! patient.mssDocumentConsent
    },
    getSelectedReceiverLabel (receiver) {
      return `${receiver.civilState} <${receiver.mssEmail}>`
    },
    async fetchPatientResults (searchString) {
      const { data } = await getFromAPI('/api/patients?order[sortableName]=asc&order[firstNames]=asc', { global: searchString })
      return data['hydra:member']
    },
    async fetchCorrespondentResults (searchString) {
      const { data } = await getFromAPI('/api/correspondents?order[contact.familyName]=asc&order[contact.firstNames]=asc', { global: searchString })
      return data['hydra:member']
    },
    formatPatientItem (patient) {
      patient = new Patient(patient)
      return {
        civilState: patient.getCivilState(),
        mssEmail: patient.getPublicMssEmail(),
        type: 'patient',
        id: patient['@id'],
        insIdentity: patient.insIdentity,
        mssDocumentConsent: patient.mssDocumentConsent,
      }
    },
    formatCorrespondentItem (correspondent) {
      correspondent = new PatientCorrespondent(correspondent)
      const correspondentObject = {
        civilState: correspondent.contact.getCivilState({ firstNameFirst: true }),
        type: 'correspondent',
        id: correspondent['@id'],
      }

      const correspondentByMssEmail = []
      const mssEmailsFromContactPoints = correspondent.contact.contactPoints.filter(contactPoint => contactPoint.media === 'email' && contactPoint.label === 'mss' && contactPoint.value !== null)
      const mssEmailsFromActivityPlaces = correspondent.contact.contactActivityPlaces.filter(activityPlace => activityPlace.mssAddress === true)[0]?.contactPoints ?? []
      const mssEmails = [...mssEmailsFromContactPoints, ...mssEmailsFromActivityPlaces]

      if (mssEmails.length > 0) {
        mssEmails.forEach(mssEmail => {
          correspondentByMssEmail.push({
            ...correspondentObject,
            mssEmail: mssEmail.value,
          })
        })
      }

      return correspondentByMssEmail
    },
  },
}
</script>

<style lang="scss" scoped>
.messaging-emails-field {
  &__item-title {
    font-weight: 600;
  }
  &__item-content {
    color: var(--v-secondary-base);
  }
  &__append-item-text {
    position: sticky;
    bottom: 0;
    background: white;
    z-index: 1;
    font-size: 12px;
    color: var(--v-secondary-base);
    margin-bottom: -#{map-get($spacers, 1)} !important;
  }
}

::v-deep {
  .v-subheader {
    height: 30px;
  }
  .theme--light.v-icon {
    color: unset;
  }

}
</style>