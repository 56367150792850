export default class Mercure {
  static #eventSources = {}
  static #userData = {}

  /**
   * Structure des callback de publication
   * @callback PublicationCallback
   * @param {Object} data Données publiées par Mercure
   * @param {Object} userData Ensemble des données injectées via {@link setUserData} indexées par leur nom
   */

  /**
   * @typedef {Object} PublicationCallbacks
   * @property {PublicationCallback} onSave Méthode appelée en cas de notification de création et de mise à jour
   * @property {PublicationCallback} onDelete Méthode appelée en cas de notification de suppression
   */

  /**
   * Souscrit à un topic Mercure
   * @param {String} topic Le nom du topic
   * @param {PublicationCallbacks} callbacks Les méthodes de notification à contacter
   */
  static subscribe (topic, { onSave, onDelete }) {
    if (this.#eventSources[topic]) {
      this.unsubscribe(topic)
    }

    const url = new URL(import.meta.env.VUE_APP_MERCURE_HUB)
    url.pathname = `/${url.pathname.split('/').concat(['.well-known', 'mercure']).filter((e) => e).join('/')}`
    url.searchParams.append('topic', topic)

    const eventSource = new EventSource(url.toString())
    this.#eventSources[topic] = eventSource

    eventSource.onmessage = event => {
      const data = JSON.parse(event.data)
      const isDeleted = Object.keys(data).length === 1
      const method = isDeleted ? onDelete : onSave

      method(data, this.#userData)
    }
  }

  /**
   * Ajoute un objet personnalisé qui sera accessible globalement
   * @param {Object} userData Objet personnalisé
   */
  static setUserData (userData) {
    this.#userData = {
      ...this.#userData,
      ...userData,
    }
  }

  /**
   * Se désabonne d'un topic Mercure
   * @param {String} topic Le nom du topic
   */
  static unsubscribe (topic) {
    this.#eventSources[topic].close()
    delete this.#eventSources[topic]
  }

  /**
   *
   * @returns Se désabonne de tous les topics Mercure
   */
  static unsubscribeAll () {
    const subscriptionsTopics = Object.keys(Mercure.getSubscriptions())
    subscriptionsTopics.forEach(topic => {
      Mercure.unsubscribe(topic)
    })
  }

  /**
   * Récupère l'ensemble des abonnements actives
   * @returns Les abonnements en cours
   */
  static getSubscriptions () {
    return this.#eventSources
  }
}