<template>
  <div class="health-summaries-panel-item">
    <div class="health-summaries-panel-item__prepend">
      <app-file-icon :size="20" :type="fileType" />
    </div>
    <div class="health-summaries-panel-item__content">
      <p v-test="'title'" class="subtitle-3 health-summaries-panel-item__title" @click="openFile">
        {{ healthSummary.getTitle() }}
      </p>
      <p v-test="'name'" class="health-summaries-panel-item__subtitle">
        {{ healthSummary.name }}
      </p>
    </div>
    <document-dmp-status-badge v-test="'badge'" :dmp-sync="isSynchronisedDmp" />
    <app-actions-menu
      :key="healthSummary.getUuid()"
      :actions="getMenuActions()"
      :max-actions-visible="1"
    />
  </div>
</template>

<script>
import AppFileIcon from '@/components/ui/icon/AppFileIcon.vue'
import DocumentDmpStatusBadge from '@/modules/patient/dmp/DocumentDmpStatusBadge.vue'

import PdfFile from '@/utils/classes/files/PdfFile'
import PatientHealthSummaryDocument from '@/modules/patient/models/PatientHealthSummaryDocument'

import { lookup } from 'mime-types'
import { mapGetters } from 'vuex'
import AppActionsMenu from '@/components/ui/actionsMenu/AppActionsMenu.vue'
import Patient from '@/modules/patient/models/Patient'
import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'
import AuthenticationServiceInstance
  from '@/modules/secureMessaging/modules/authentication/services/AuthenticationService'
import NovaTools from '@/nova-tools/NovaTools'
import { SENT_PATIENT_DOC_LOG_DOCUMENT_TYPES } from '@/modules/users/constants'
import MssMessage from '@/modules/secureMessaging/modules/messaging/models/MssMessage'
import MssMessageAttachment from '@/modules/secureMessaging/modules/messaging/models/MssMessageAttachment'
import SendMessageModalForm from '@/modules/secureMessaging/modules/messaging/forms/SendMessageModalForm.vue'
import DocumentDmpSendingAction from '@/modules/patient/classes/DocumentDmpSendingAction'
import IcanopeeDocument from '@/modules/patient/models/IcanopeeDocument'
import { extension } from 'mime-types'
import ZipFile from '@/utils/classes/files/ZipFile'
export default {
  name: 'HealthSummariesPanelItem',
  components: {
    AppActionsMenu,
    AppFileIcon,
    DocumentDmpStatusBadge,
  },
  props: {
    healthSummary: {
      type: PatientHealthSummaryDocument,
      required: true,
    },
    patient: {
      type: Patient,
      required: true,
    },
  },
  computed: {
    ...mapGetters('patient', ['isDocumentSynchronizedDmp']),
    fileType () {
      return lookup('pdf')
    },
    isSynchronisedDmp () {
      return this.isDocumentSynchronizedDmp(this.healthSummary['@id']) ?? false
    },
  },
  methods: {
    openFile () {
      new PdfFile(this.healthSummary.getFullContentURL()).open()
    },
    getMenuActions () {
      const primaryActions = []
      const secondaryActions = []

      primaryActions.push(new ActionMenuItem('download', 'Télécharger',
        () => new PdfFile(this.healthSummary.getFullContentURL()).open()),
      )

      primaryActions.push(new ActionMenuItem('file-zip', 'Télécharger en IHE_XDM',
        () => {
          new ZipFile(this.healthSummary['@id'] + '/ihe_xdm').download()
        },
      ))

      secondaryActions.push(new ActionMenuItem('messaging-transfert', 'Envoyer par messagerie',
        () => {

          if (! AuthenticationServiceInstance.isAuthenticated) {
            NovaTools.notify.warning('Veuillez vous connecter au module de messagerie')
            return
          }

          const fileName = this.getDocumentName(this.healthSummary.name)
          const mimeType = lookup('pdf')
          const attachmentDocumentSource = SENT_PATIENT_DOC_LOG_DOCUMENT_TYPES.VSM.value
          const subject = this.healthSummary.name || this.healthSummary.title

          const mssMessage = new MssMessage({
            subject,
            senderEmail: this.patient?.getPublicMssEmail(),
            attachments: [
              new MssMessageAttachment({
                contentUrl: this.healthSummary.contentUrl,
                fileName,
                mimeType,
                documentSource: attachmentDocumentSource,
              }),
            ],
          })

          const { id } = NovaTools.modal.append(SendMessageModalForm, {
            title: 'Envoyer le document',
            message: mssMessage,
            isOpen: true,
            patient: this.patient,
          }, {
            cancel: () => NovaTools.modal.destroy(id),
            submitSuccess: () => NovaTools.modal.destroy(id),
            'update:is-open': () => NovaTools.modal.destroy(id),
          })
        },
      ))

      secondaryActions.push(new DocumentDmpSendingAction(this.patient, new IcanopeeDocument(this.patient.insIdentity.ins, {
        title: this.healthSummary.name ?? 'Volet de synthèse médicale',
        contentUrl: this.healthSummary.contentUrl,
        format: 'pdf',
        typeCode: 'SYNTH',
        practice: '07',
        documentType: this.healthSummary.documentType,
        iri: this.healthSummary['@id'],
      })))

      return [primaryActions, secondaryActions]
    },
    getDocumentName (name, mimeType) {
      mimeType = mimeType || 'application/pdf'
      if (name.endsWith('.' + extension(mimeType))) {
        return name
      }
      return `${name}.${extension(mimeType)}`
    },
  },
}
</script>

<style lang="scss" scoped>
.health-summaries-panel-item {
  display: flex;
  align-items: center;
  gap: map-get($spacers, 2);

  &__prepend {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: var(--v-secondary-lighten5);
    border-radius: 50%;
  }

  &__content {
    flex-grow: 1;
  }

  &__title:hover {
    color: var(--v-primary-base);
    text-decoration: underline;
    cursor: pointer;
  }

  &__subtitle {
    font-size: 12px;
    color: var(--v-secondary-base);
  }
}
</style>