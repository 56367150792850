<template>
  <ul
    v-if="loading || (items && items.length > 0)"
    class="n-list"
    :class="listClassNames"
  >
    <template v-for="(item, index) in (loading ? skeletonCount : items)">
      <li
        :key="getItemKey(item, index)"
        v-test="'item'"
        class="n-list__item"
        :class="itemClassName"
      >
        <!-- @slot Le squelette à afficher lors du chargement de la liste -->
        <slot v-if="loading" name="skeleton" />

        <!--
        @slot L'élément de la liste
        @binding {Object} item Le contenu de l'élément de la liste
        @binding {Number} index L'index de l'élément dans la liste
      -->
        <slot
          v-else
          name="item"
          :item="item"
          :index="index"
        />
      </li>
      <n-divider
        v-if="isDividerVisible(index, loading ? skeletonCount : items.length)"
        :key="`divider-${index}`"
        v-test="'item-divider'"
        :spacing="spacing"
        :vertical="inline"
        :class="`mx-n${insetX}`"
      />
    </template>
  </ul>
  <p
    v-else
    v-test="'empty'"
    class="mb-0 secondary--text"
    :class="{ [`py-${spacing}`]: hoverable }"
  >
    {{ emptyText }}
  </p>
</template>

<script>
/**
 * Ce composant à pour but de faciliter l'intégration de listes d'éléments.
 *
 * Il permet de rendre une liste d'éléments ou de squelettes de chargement en fonction d'un état de chargement défini par le développeur.
 */
export default {
  name: 'NList',
  props: {
    /**
     * Les éléments à afficher dans la liste
     */
    items: {
      type: Array,
      default: null,
    },
    /**
     * La fonction utilisée pour récupérer la clef d'un item
     * Sont passés en paramètre l'item et son index
     * @default item-index
     */
    itemsKey: {
      type: Function,
      default: null,
    },
    /**
     * L'espacement entre les éléments de la liste.
     * Plus d'information sur la gestion des espaces dans l'application : @see {@link https://vuetifyjs.com/en/styles/spacing/#how-it-works}
     */
    spacing: {
      type: Number,
      default: 2,
    },
    /**
     * Le nombre de squelettes à afficher lors du chargement de la liste
     */
    skeletonCount: {
      type: Number,
      default: 4,
    },
    /**
     * Indique si la liste est en cours de chargement
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Sépare les éléments par des dividers
     */
    divided: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche les éléments les uns à la suite des autres
     */
    inline: {
      type: Boolean,
      default: false,
    },
    /**
     * Texte à afficher quand la liste est vide
     */
    emptyText: {
      type: String,
      default: 'Aucun élément',
    },
    /**
     * Applique une retrait sur l'axe x de la liste
     */
    insetX: {
      type: Number,
      default: 0,
    },
    /**
     * Applique un effet de survol sur les éléments de la liste en tenant compte de l'espacement des éléments ou du retrait négatif de la liste
     */
    hoverable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listClassNames () {
      return {
        'n-list--inline': this.inline,
        'n-list--hoverable': this.hoverable,
        [`mr-n${this.spacing}`]: this.inline,
        [`mb-n${this.spacing}`]: this.inline,
      }
    },
    itemClassName () {
      if (this.inline) {
        return `mr-${this.divided ? 0 : this.spacing} mb-${this.spacing}`
      }
      return {
        [ `mb-${this.divided ? 0 : this.spacing}`]: ! this.hoverable,
        [`px-${this.insetX} mx-n${this.insetX}`]: this.insetX,
        [`py-${this.spacing}`]: this.hoverable,
      }
    },
  },
  methods: {
    /**
     * Permet de ne pas afficher de divider après le dernier élément de la liste
     * L'intéret étant de séparer les éléments, un divider après le dernier élément
     * n'aurait pas de sens.
     *
     * @param {Number} dividerIndex L'index du divider à traiter
     * @param {Number} itemsCount Le nombre d'éléments affichés
     * @returns {Boolean}
     */
    isDividerVisible (dividerIndex, itemsCount) {
      if (! this.divided) {
        return false
      }
      return dividerIndex < (itemsCount - 1)
    },
    /**
     * Permet de générer une clef unique pour chaque élément
     * Si aucune fonction permettant de faire ça n'a été donné
     * par la props itemsKey, alors l'index est utilisé
     */
    getItemKey (item, index) {
      if (this.itemsKey) {
        return this.itemsKey(item, index)
      }
      return `item-${index}`
    },
  },
}
</script>

<style lang="scss" scoped>
.n-list {
  padding-left: 0;

  &--hoverable &__item:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--v-content-base);
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
    transition: opacity .125s ease;
  }

  &--hoverable &__item:hover:before {
    opacity: 0.05;
  }

  &--inline {
    display: flex;
    flex-wrap: wrap;
  }

  &--inline > &__item {
    max-width: 100%;

    &:last-child {
      margin-right: 0 !important;
      max-width: 100%;
    }
  }

  :not(&--inline) > &__item:last-child {
    margin-bottom: 0 !important;
  }

  &__item {
    position: relative;
    list-style: none;
    z-index: 0;
  }
}
</style>