import ApiEntity from '@/models/ApiEntity'

import { DEFAULT_FOLDERS } from '@/modules/secureMessaging/modules/messaging/constants'
export default class MssFolder extends ApiEntity {
  constructor (options = {}) {
    super(options)
    this.name = options.name || null
    this.unreadMessagesCount = options.unreadMessagesCount || null
  }

  /**
   * Permet d'obtenir le nom en français ainsi que l'icône associée au dossier
   * @returns {{icon, text}}
   */
  getMetaData () {
    const metaData = Object.values(DEFAULT_FOLDERS).find(folder => folder.value === this.name) || {}
    return {
      icon: metaData.icon || null,
      text: metaData.text || null,
    }
  }
}