<template>
  <validation-provider
    v-slot="{ errors }"
    :name="label"
    :vid="name"
    slim
    :rules="rules"
  >
    <app-buttons-group
      ref="genderInput"
      v-model="localValue"
      data-test="civilityPicker"
      :items="civilities"
      :show-icon="false"
      :mandatory="!! value"
      :error-messages="errors"
      item-text="shortLabel"
      v-bind="{...$props, ...$attrs}"
      :label="fieldLabel"
      v-on="{...$listeners}"
    />
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

import AppButtonsGroup from '../buttonsGroup/AppButtonsGroup.vue'
import fieldMixin from '@/mixins/fields.js'

import { CIVILITIES } from '@/constants'

export default {
  name: 'AppCivilityPicker',
  components: {
    ValidationProvider,
    AppButtonsGroup,
  },
  mixins: [fieldMixin],
  props: {
    /**
     * Nom du champ
     */
    label: {
      type: String,
      default: 'Sexe',
    },
    civilities: {
      type: Array,
      default: () => CIVILITIES,
    },
  },
}
</script>