<template>
  <div>
    <n-dialog
      v-if="shouldResponseModalExists"
      v-test="'aldi-result-modal'"
      title="Liste des ALD"
      width="980"
      divided
      :is-open.sync="isResponseModalOpen"
    >
      <aldi-result-summary
        :report="responsePayload.report"
        :beneficiary="responsePayload.beneficiary"
        :ald-list="responsePayload.aldList"
      />
    </n-dialog>
  </div>
</template>

<script>
import AldiResultSummary from '@/modules/patient/components/sidebar/tlsi/aldi/AldiResultSummary.vue'

import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'AldiResultSummaryModal',
  components: { AldiResultSummary },
  props: {
    responsePayload: {
      type: Object,
      required: true,
    },
  },
  data () {
    return { isResponseModalOpen: false }
  },
  computed: {
    /**
     * Permet de ne pas rendre la modale avec certaines de ces props required à null
     */
    shouldResponseModalExists () {
      return Object.values(this.responsePayload).every(value => Array.isArray(value) ? value.length > 0 : !! value)
    },
  },
  watch: {
    responsePayload () {
      if (this.shouldResponseModalExists) {
        this.isResponseModalOpen = true
      } else {
        NovaTools.notify.info('Aucune ALD déclarée sur le patient')
      }

    },
  },
}
</script>