<template>
  <section class="agenda-page">
    <event-modal />
    <v-slide-x-transition>
      <availability-details v-if="hoveredAvailability" v-test="'availability-details'" :availability="hoveredAvailability" />
    </v-slide-x-transition>
    <base-layout>
      <template #toolbar-left>
        <agenda-toolbar-left />
      </template>
      <template #toolbar-right>
        <agenda-toolbar-right />
      </template>
      <template #main>
        <calendar
          v-if="getView !== CALENDAR_VIEWS.LIST.value"
          :appointments="getVisiblesSchedulesAppointments"
          @mouseenter:availability="showAvailabilityDetails"
          @mouseleave:availability="hideAvailabilityDetails"
        />
        <agenda-appointments-listing v-else :appointments="getVisiblesSchedulesAppointments" />
      </template>
      <template #sidebar>
        <v-slide-y-transition>
          <moved-appointment-snack v-if="getMovedAppointment" data-test="moved-appointment-snack" class="agenda-page__moved-appointment-snack" />
        </v-slide-y-transition>
        <agenda-sidebar />
      </template>
    </base-layout>
  </section>
</template>

<script>
import AgendaToolbarLeft from './components/toolbar/AgendaToolbarLeft.vue'
import AgendaToolbarRight from './components/toolbar/AgendaToolbarRight.vue'
import AgendaSidebar from './components/sidebar/AgendaSidebar.vue'
import AgendaAppointmentsListing from './components/AgendaAppointmentsListing.vue'
import AvailabilityDetails from './components/AvailabilityDetails.vue'
import BaseLayout from './layouts/BaseLayout.vue'
import Calendar from './components/calendar/Calendar.vue'
import EventModal from './components/Agenda/EventModal.vue'
import MovedAppointmentSnack from '@/modules/agenda/components/MovedAppointmentSnack.vue'

import { mapActions, mapGetters } from 'vuex'

import { CALENDAR_VIEWS } from '@/modules/agenda/constants'

export default {
  name: 'AgendaModule',
  components: {
    AgendaToolbarLeft,
    AgendaToolbarRight,
    AgendaSidebar,
    Calendar,
    EventModal,
    AgendaAppointmentsListing,
    BaseLayout,
    AvailabilityDetails,
    MovedAppointmentSnack,
  },
  data () {
    return {
      CALENDAR_VIEWS,
      hoveredAvailability: null,
    }
  },
  computed: { ...mapGetters('agenda', ['getVisiblesSchedulesAppointments', 'getView', 'getMovedAppointment']) },
  async created () {
    await this.fetchAgendaDatas()
    await this.fetchEvents()
  },
  methods: {
    ...mapActions('agenda', ['fetchEvents', 'fetchAgendaDatas']),
    showAvailabilityDetails (availability) {
      this.hoveredAvailability = availability
    },
    hideAvailabilityDetails () {
      this.hoveredAvailability = null
    },
  },
}
</script>

<style lang="scss" scoped>
$snack-margin: map-get($spacers, 4);

.agenda-page {
  position: relative;
  height: 100%;

  &__moved-appointment-snack {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: $snack-margin;
    width: calc(100% - (#{$snack-margin} * 2));
  }
}
</style>