/**
 * Liste des statuts disponibles pour un rendez-vous
 */
export const APPOINTMENT_STATES = Object.freeze({
  CONFIRMED: {
    name: 'Confirmé',
    value: 'confirmed',
    icon: 'check',
  },
  IN_WAITING_ROOM: {
    name: 'En salle d\'attente',
    value: 'in_waiting_room',
    icon: 'clock',
  },
  IN_PROGRESS: {
    name: 'Consultation en cours',
    value: 'in_progress',
    icon: 'spinner',
  },
  SEEN_PATIENT: {
    name: 'Patient vu',
    value: 'seen_patient',
    icon: 'user-check',
  },
  EXCUSED_PATIENT: {
    name: 'Patient excusé',
    value: 'excused_patient',
    icon: 'user-times',
  },
  NOT_EXCUSED_PATIENT: {
    name: 'Patient non excusé',
    value: 'not_excused_patient',
    icon: 'ban',
  },
})