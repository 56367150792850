import ApiEntity from '@/models/ApiEntity'

import { MEDICATION_TYPES } from '@/modules/patient/constants/medicationTypes'

export default class Medication extends ApiEntity {
  constructor (options = {}) {
    super(options)

    this.product = options.product || null
    this.type = options.type || MEDICATION_TYPES.EXTERNAL
    this.endDate = options.endDate || null
    this.longTerm = options.longTerm || false
    this.ald = options.ald || false
    this.chronic = options.chronic || false
    this.posologies = options.posologies || []
    this.summaries = options.summaries || []
    this.healthProductLine = options.healthProductLine || null
  }
}