/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity'
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField'
import DocumentEntityRelation from '@/modules/dataPortability/classes/DocumentEntityRelation'

const prescriptionEntity = new DocumentEntity('Prescription', 'prescriptions.xml', 'Contient les données des prescriptions')

prescriptionEntity.addField(new DocumentEntityField('name', 'Le nom'))
prescriptionEntity.addField(new DocumentEntityField('prescriptionLines', 'Les lignes de prescription', new DocumentEntityRelation('PrescriptionLine', 'id')))
prescriptionEntity.addField(new DocumentEntityField('ald', 'Est réalisé dans le cadre d\'une ALD'))
prescriptionEntity.addField(new DocumentEntityField('prescriptionLppLines', 'Les lignes de prescription de type produit ou prestation', new DocumentEntityRelation('PrescriptionLppLine', 'id')))

export default prescriptionEntity