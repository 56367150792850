import ReplacementItem from '@/components/ui/replacementContent/classes/ReplacementItem'

import { INSEE_UNKNOWN_REF } from '@/modules/patient/constants'
import { GENDERS } from '@/constants'
import { INS_IDENTITY_STATUS } from '@/modules/patient/constants/insIdentityStatus'

/**
 *
 * @param {Object} patientTraits
 * @returns
 */
export const isInsPatientTraitsValid = (patientTraits) => {
  const { birthDate, name, birthGiven, sex, birthPlace } = patientTraits
  const [birthYear, birthMonth, birthDay] = birthDate.split('-')
  const hasValidBirthYear = parseInt(birthYear) > 0
  const hasValidBirthMonth = parseInt(birthMonth) > 0
  const hasValidBirthDay = parseInt(birthDay) > 0
  const hasValidBirthPlace = birthPlace && parseInt(birthPlace) !== parseInt(INSEE_UNKNOWN_REF.value)
  const hasValidBirthName = name && name.length
  const hasValidBirthGiven = birthGiven && birthGiven.length
  const hasValidSex = sex !== 'U'
  return (
    (hasValidBirthYear && hasValidBirthMonth && hasValidBirthDay)
        && hasValidBirthName
        && hasValidBirthGiven
        && hasValidSex
        && hasValidBirthPlace
  )
}

/**
 *
 * @param {Object} patient
 * @param {Object} patientTraits
 * @returns
 */
export const getReplacementItemFromInsCallResult = (patient, patientTraits) => {
  const {
    birthDate,
    birthGiven: firstNames,
    birthPlace: birthPlaceCode,
    name: birthName,
    sex,
  } = patientTraits
  let gender = GENDERS.UNKNOWN.value
  if (sex === 'M') {
    gender = GENDERS.MALE.value
  } else if (sex === 'F') {
    gender = GENDERS.FEMALE.value
  }

  return [
    new ReplacementItem('Nom de naissance', patient.birthName, birthName, 'birthName'),
    new ReplacementItem('Prénom(s)', patient.firstNames, firstNames, 'firstNames'),
    new ReplacementItem('Date de naissance', patient.birthDate, birthDate, 'birthDate'),
    new ReplacementItem('Code lieu de naissance', patient.birthPlaceCode, birthPlaceCode, 'birthPlaceCode'),
    new ReplacementItem('Sexe', patient.gender, gender, 'gender', { valueLabelGetter: genderValue => GENDERS[genderValue?.toUpperCase()]?.label },
    ),
  ]
}

/**
 * Permet de récupérer les identités disponibles à partir des données d'une identité
 * @param {InsIdentity} insIdentity
 * @returns {Array}
 */
export const getAvailableInsStatusesFromInsIdentity = (insIdentity) => {
  if (insIdentity.ins) {
    return [
      INS_IDENTITY_STATUS.FETCHED,
      INS_IDENTITY_STATUS.QUALIFIED,
    ]
  }
  return [
    INS_IDENTITY_STATUS.TEMPORARY,
    INS_IDENTITY_STATUS.VALIDATED,
  ]
}

/**
 * Permet de récupérer les identités disponibles selon si l'ins est renseigné
 * @param {Boolean} hasIns
 * @returns {Array}
 */
export const getAvailableInsStatusesFromHasIns = (hasIns) => {
  if (hasIns) {
    return [
      INS_IDENTITY_STATUS.FETCHED,
      INS_IDENTITY_STATUS.QUALIFIED,
    ]
  }
  return [
    INS_IDENTITY_STATUS.TEMPORARY,
    INS_IDENTITY_STATUS.VALIDATED,
  ]
}

/**
 * Permet de récupérer le statut d'une identité à partir des données d'un patient
 * @param {Patient} patient
 * @returns {String}
 */
export const getInsStatusFromPatient = (patient, options = { disableAutomaticStatusSelect: false }) => {
  const hasStrongTrustIdentityDevice = patient.insIdentity.identityDevice?.strongTrustLevel
  const [defaultStatus, withDeviceStatus] = getAvailableInsStatusesFromInsIdentity(patient.insIdentity).map(status => status.value)

  if (hasStrongTrustIdentityDevice) {
    const isValidatedStatus = [INS_IDENTITY_STATUS.QUALIFIED.value, INS_IDENTITY_STATUS.VALIDATED.value].includes(patient.insIdentity.status)
    // On affecte le statut selon si le device est renseigné ou possède un fort niveau de confiance
    return (options.disableAutomaticStatusSelect && ! isValidatedStatus)
      ? defaultStatus
      : withDeviceStatus
  }
  return defaultStatus
}