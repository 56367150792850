import { pluralize } from '@/utils/functions/words'

/**
 * Retourne le chemin d'un fichier préfixée par l'URL racine de l'API
 * @param {String} contentURL L'URL du fichier
 * @returns {String} l'URL complète du fichier
 */
export const getFullContentURL = contentURL => {
  if (contentURL) {
    const separator = contentURL[0] === '/' ? '' : '/'
    return [import.meta.env.VUE_APP_ROOT_API, contentURL].join(separator)
  }
  return null
}

/**
 * Permet de télécharger un blob
 * @param {Blob} blob
 * @param {Object} options
 * @param {String} options.fileName Le nom du fichier à télécharger
 */
export const downloadBlob = (blob, { fileName }) => {
  const fileUrl = URL.createObjectURL(blob, { type: blob.type })
  const link = document.createElement('a')
  link.href = fileUrl
  link.download = fileName
  document.body.appendChild(link)

  // Le dispatch d'un évènement "click" est nécessaire pour assurer la compatibilité avec Firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  )
  document.body.removeChild(link)
  URL.revokeObjectURL(fileUrl)
}

/**
 * Permet de télécharger un CSV
 * @param {String} text
 * @param {Object} options
 * @param {String} options.fileName Le nom du fichier à télécharger
 */
export const downloadCsv = (text, { fileName }) => {
  const link = document.createElement('a')
  link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent('\uFEFF' + text))
  link.setAttribute('download', fileName)
  link.click()
}

/**
 * Retourne la base64 d'un fichier
 * @param {File} file
 * @returns {String}
 */
export const getFileContent = function (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result.split(',')[1])
    reader.onerror = error => reject(error)
  })
}

export const getFormattedSize = (fileSize) => {
  const MEGA_OCTET_IN_OCTET = 1_000_000
  const KILO_OCTET_IN_OCTET = 1_000

  if (fileSize >= MEGA_OCTET_IN_OCTET) {
    return `${(fileSize / MEGA_OCTET_IN_OCTET).toFixed(1)} Mo`
  }
  if (fileSize >= KILO_OCTET_IN_OCTET) {
    return `${(fileSize / KILO_OCTET_IN_OCTET).toFixed(0)} Ko`
  }
  if (fileSize >= 1) {
    return `${fileSize} ${pluralize('octet', fileSize)}`
  }
}