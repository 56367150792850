export const ROUTE_NAMES = Object.freeze({
  LISTING: 'patients.listing',
  PATIENT_FILE: 'patients.patientFile',
  PATIENT_CREATE: 'patients.newPatient',
  PATIENT_EDIT: 'patients.editPatient',
  CREATE_CONSULTATION: 'patients.consultation',
  CREATE_PRESCRIPTION: 'createPrescription',
  EDIT_PRESCRIPTION: 'editPrescription',
  CONSULTATION_DOCUMENT_PREVIEW: 'consultation.document',
  CONSULTATION_DOCUMENT_EDITION: 'consultation.editDocument',
  CONSULTATION_METRIC_SETTINGS: 'consultation.metricSettings',
  PATIENT_IDENTITY_GENERAL: 'patientGeneralSettings',
  PATIENT_RGPD: 'patientRgpdSettings',
  PATIENT_DOCUMENTS_EXPORT: 'patientDocumentsExportSettings',
  PATIENT_VSM_ALERT_SETTINGS: 'patientVsmAlertSettings',
  PATIENT_IDENTITY_DEVICE_SETTINGS: 'patientIdentityDeviceSettings',
  PATIENT_KEYWORDS_SETTINGS: 'patientKeywordsSettings',
})