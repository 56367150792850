<template>
  <span
    v-tooltip="{ text: tooltip, disabled: ! tooltip && ! icon }"
    data-test="app-color-dot-activator"
    class="app-color-dot"
    :class="{ 'app-color-dot--tooltiped': tooltip }"
    :style="style"
  >
    <app-icon
      v-if="icon"
      data-test="app-color-dot-icon"
      :icon="icon"
      :color="iconColor"
      :size="iconSize"
    />
  </span>
</template>

<script>
import AppIcon from '@/components/ui/icon/AppIcon.vue'

import { colorToHex } from '@/utils/functions/colors'

/**
 * Permet d'afficher un rond de couleur
 */
export default {
  name: 'AppColorDot',
  components: { AppIcon },
  props: {
    /**
     * Couleur du rond
     */
    color: {
      type: String,
      required: true,
    },
    /**
     * Taille du rond (en pixel)
     */
    size: {
      type: Number,
      default: 24,
    },
    /**
     * Nom de l'icone affichée sur le rond
     * @see /src/constants/icons.js
     */
    icon: {
      type: String,
      default: null,
    },
    /**
     * Couleur de l'icone
     */
    iconColor: {
      type: String,
      default: 'white',
    },
    /**
     * Tooltip à afficher au survol
     */
    tooltip: {
      type: String,
      default: null,
    },
  },
  computed: {
    style () {
      return {
        minWidth: `${this.size}px`,
        minHeight: `${this.size}px`,
        backgroundColor: colorToHex(this.color),
      }
    },
    iconSize () {
      const scaleFactor = 0.65 // permet une harmonie de la taille de l'icone avec la taille du rond
      return this.size * scaleFactor
    },
  },
}
</script>

<style lang="scss" scoped>
.app-color-dot {
  display: inline-flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  &--tooltiped {
    cursor: help;
  }

  .app-icon {
    display: flex;
  }
}
</style>