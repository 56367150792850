<template>
  <validation-provider
    v-slot="{ valid, errors }"
    ref="validator"
    slim
    :rules="rules"
    :name="label"
    :vid="name"
    :mode="fieldValidationMode"
  >
    <v-text-field
      ref="input"
      v-model="localValue"
      v-bind="{
        ...$props,
        ...$attrs,
        autofocus: false,
      }"
      :rules="[]"
      :label="fieldLabel"
      :error="errors.length > 0"
      :error-messages="errors"
      :outlined="outlined"
      :hint="hint"
      :hide-details="hideErrors"
      class="app-text-field"
      :class="{
        'app-text-field--outlined': outlined,
        'app-text-field--dense': dense,
        'is-valid': valid,
        ...$attrs.class
      }"
      :prepend-icon="fieldIcon.prepend"
      :append-icon="fieldIcon.append"
      :prepend-inner-icon="fieldIcon.prependInner"
      :clearable="clearable"
      v-on="{...$listeners}"
    >
      <template #append>
        <slot name="append" />
      </template>
    </v-text-field>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

import fieldMixin from '@/mixins/fields.js'
import focusableFieldMixin from '@/mixins/focusableFieldMixin'

import transformableMixin from '@/components/ui/form/mixins/TransformableMixin.js'

/**
 * Champ de texte de l'application
 */
export default {
  name: 'AppTextField',
  components: { ValidationProvider },
  mixins: [
    fieldMixin,
    focusableFieldMixin('input'),
    transformableMixin('localValue'),
  ],
  props: {
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
  },
  methods: {
    focus () {
      this.$refs.input?.focus()
    },
  },
}
</script>

<style lang="scss" scoped>
.app-text-field {
  &--outlined {
    &.v-input--is-focused {
      ::v-deep {
        .v-input__slot {
          box-shadow: 0 0 0 2px var(--v-primary-base);
        }
      }
    }
    ::v-deep {
      .v-text-field__details {
        padding: 0 map-get($spacers, 3);
      }

      .v-input {
        &__slot {
          min-height: 40px;
          border-radius: 6px;
          padding: 0 map-get($spacers, 3) !important;
          box-shadow: 0 0 0 1px var(--v-divider-base);
          gap: map-get($spacers, 3);
          transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
        }

        &__append-inner,
        &__prepend-inner {
           margin-top: auto !important;
           margin-bottom: auto !important;
           padding: 0;
        }
      }

      fieldset {
        display: none;
      }

      input {
        padding: map-get($spacers, 3) 0 0 0;
      }

      .v-label {
        font-size: 13px;
        top: 10px;

        &--active {
          left: 0 !important;
          transform: translateY(-6px) scale(0.75);
        }
      }
    }
  }

  &--dense {
    &.v-input--is-focused,
    &.v-input--is-label-active:not(.v-input--is-focused) {
      ::v-deep {
        .v-label {
          display: none;
        }
      }
    }
    ::v-deep {
      .v-text-field__details {
        padding: 0 map-get($spacers, 2);
      }
      .v-input {
        &__slot {
          min-height: 34px !important;
          padding: 0 map-get($spacers, 2) !important;
          gap: map-get($spacers, 2);
        }
      }
      input {
        padding:0;
      }
      .v-label {
        top: 7px;
      }
    }
  }
}
</style>