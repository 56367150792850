<template>
  <app-panel :title="panelTitle" :quantity="events.length > 0 ? events.length : null">
    <app-skeleton-loader
      v-if="loading"
      type="text"
      width="200"
      class="mb-4"
    />
    <app-event-patient-list
      :events="events"
      :loading="loading"
      :empty-text="`Aucun ${title.toLowerCase()}`"
    >
      <template v-if="prependTimeField" #prepend="event">
        <div v-test="'dashboard-events-time-prepend'" class="font-weight-light mr-2 text-center" style="width: 38px">
          {{ format(new Date(event[prependTimeField]), 'p') }}
        </div>
      </template>
      <template #actions="event">
        <app-button
          v-test="'restore-button'"
          icon="undo"
          :loading="isEventLoading(event)"
          :disabled="isEventLoading(event)"
          tooltip="Basculer en salle d'attente"
          @click="restoreEvent(event)"
        />
      </template>
    </app-event-patient-list>
  </app-panel>
</template>
<script>
import AppPanel from '@/components/ui/panel/AppPanel.vue'
import AppEventPatientList from '@/components/ui/listing/AppEventPatientList.vue'
import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue'

import { APPOINTMENT_STATES } from '@/modules/agenda/constants'

import { mapActions } from 'vuex'
import { format } from '@/utils/functions/dates'

export default {
  name: 'DashboardEventsSecondaryPanel',
  components: {
    AppEventPatientList,
    AppPanel,
    AppSkeletonLoader,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    prependTimeField: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      updatingAppointments: [],
      isShowMore: false,
    }
  },
  computed: {
    panelTitle () {
      return this.loading
        ? ''
        : this.title
    },
  },
  methods: {
    ...mapActions('agenda', ['updateAppointment']),
    format,
    async restoreEvent (event) {
      try {
        this.updatingAppointments.push(event)
        await this.updateAppointment({
          ...event,
          state: APPOINTMENT_STATES.CONFIRMED.value,
        })
      } catch (error) {}
      this.updatingAppointments.splice(this.updatingAppointments.indexOf(event), 1)
    },
    isEventLoading (event) {
      return this.updatingAppointments.indexOf(event) > - 1
    },
  },
}
</script>