import Vue from 'vue'
import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI'

export const state = () => ({ documentDmpSyncs: {} })

export const getters = {
  isDocumentSynchronizedDmp: state => (iri) => {
    const documentUUID = getUUIDFromIRI(iri)
    return state.documentDmpSyncs[documentUUID]
  },
}

export const mutations = {
  SET_IS_SYNCHRONIZED_DMP (state, documentDmpSync) {
    Vue.set(state.documentDmpSyncs, documentDmpSync['id'], documentDmpSync['isSynchronized'])
  },
}

export const actions = {
  setIsSynchronizedDmp ({ commit }, document) {
    commit('SET_IS_SYNCHRONIZED_DMP', {
      id: getUUIDFromIRI(document['@id']),
      isSynchronized: Boolean(document['dmpUniqueId']),
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}