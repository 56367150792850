<template>
  <n-portal to="overlays">
    <v-dialog
      :value="isOpenedState"
      :scrollable="scrollable"
      :fullscreen="isMobile"
      overlay-color="text"
      class="app-modal"
      :width="width"
      :transition="modalTransition"
      :persistent="persistent"
      data-test="app-modal-form"
      :retain-focus="false"
      @click:outside="!persistent ? cancel() : null"
      @keydown.esc="!persistent ? cancel() : null"
      @keydown="handleKey"
    >
      <app-form-wrapper
        ref="formWrapper"
        :wrapper="VCardWrapper"
        :wrapper-props="{
          color: 'app-modal__content blue-grey lighten-2'
        }"
        v-bind="{...$props, ...$attrs}"
        title=""
      >
        <template #default="{ localValue, formValidation, isSubmitting, formId, validationObserver }">
          <v-card-title class="app-modal__header px-6" :class="{ 'pt-6 pb-0': ! scrollable, ' py-6': scrollable }">
            <h2 class="app-modal__header__headline title-3">
              {{ title }}
            </h2>
          </v-card-title>

          <n-divider v-if="scrollable" :spacing="0" />

          <v-card-text
            class="pa-6 app-modal__body"
          >
            <component
              :is="form"
              :id="formId"
              ref="form"
              data-test="form"
              :value="localValue"
              v-bind="$attrs"
              :validation-observer="validationObserver"
              v-on="$listeners"
              @submitForm="(submitMethod) => submitForm($refs.formWrapper, submitMethod)"
            />
          </v-card-text>

          <n-divider v-if="scrollable" :spacing="0" />

          <v-card-actions class="app-modal__footer" :class="{ 'pt-0 pb-6 px-6': ! scrollable, ' pa-6': scrollable }">
            <slot name="actions-left" />

            <v-spacer />
            <!-- @slot Slot pour insérer les actions possibles sur la modal -->
            <slot name="actions">
              <!--
              Clic sur le bouton d'annulation de la modale
              @event cancel
            -->
              <app-button
                class="mr-2"
                text
                :color="cancelColor"
                @click="cancel"
              >
                {{ cancelText }}
              </app-button>
              <!--
              Clic sur le bouton de confirmation de la modale
              @event submit
            -->
              <app-button
                :color="submitColor"
                :loading="isSubmitting"
                :disabled="!formValidation.valid"
                type="submit"
                :form="formId"
                :label="submitText"
                data-test="app-modal-form-submit-button"
                @click="$emit('submit')"
              />
            </slot>
          </v-card-actions>
        </template>
      </app-form-wrapper>
    </v-dialog>
  </n-portal>
</template>

<script>
import AppFormWrapper from '@/components/ui/form/AppFormWrapper.vue'
import ModalFormMixin from './mixins/ModalFormMixin'

export default {
  name: 'AppModalForm',
  components: { AppFormWrapper },
  mixins: [ModalFormMixin],
  props: {
    /**
     * Constructeur du composant (fichier .vue, ou créé programmatiquement)
     */
    form: {
      type: [String, Object, Function],
      required: true,
    },
    /**
     * Modèle ou groupe d'entité utilisé pour remplir le formulaire
     * Si cette valeur est différente de null, la modale devient alors visible
     * @model
     */
    value: {
      type: [Object, Array],
      default: null,
    },
    /**
     * Le type de fenêtre modale (scrollable)
     * @default true
     */
    scrollable: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * Le titre de la modale. Affiché en haut de celle-ci
     * @default true
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * Permet de contrôler manuellement, l'état d'ouverture de la modale
     */
    isOpened: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    isOpenedState () {
      return this.isOpened !== null
        ? this.isOpened
        : !! this.value
    },
  },
  methods: {
    cancel () {
      this.$emit('cancel')

      if(this.getForm() && this.getForm().resetFields) {
        this.getForm().resetFields()
      }

      if (this.isOpened) {
        this.$emit('update:isOpened', false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.app-modal {
  &__content {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
  }

  &__header,
  &__body,
  &__footer {
    background-color: white;
  }

  &__header {
    &__headline {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__body {
    flex: 1 0 auto;
  }
}
</style>