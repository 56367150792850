<template>
  <n-expandable
    :content-spacing-top="0"
    :content-spacing-left="0"
    :icon-rotation-angle="180"
    :expanded.sync="isExpanded"
  >
    <template #header="{ toggle, expandIconStyle }">
      <app-actions-menu-item v-test="'action-group-item'" :action="action" @click="toggle">
        <template #append>
          <app-icon icon="chevron-down" size="10" :style="expandIconStyle" />
        </template>
      </app-actions-menu-item>
    </template>
    <div>
      <app-actions-menu-item
        v-for="(groupAction, groupIndex) in action.children"
        :key="groupIndex"
        v-test="'action-group-child'"
        :action="groupAction"
        @click="$emit('click:action')"
      />
    </div>
  </n-expandable>
</template>

<script>
import AppActionsMenuItem from '@/components/ui/actionsMenu/AppActionsMenuItem.vue'

import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'

export default {
  name: 'AppActionsMenuItemExpandableGroup',
  components: { AppActionsMenuItem },
  props: {
    /**
     * Les actions à afficher dans le menu
     * Sous la forme { clé: ActionMenuItem, ... } ou ActionMenuItem[]
     */
    action: {
      type: ActionMenuItem,
      required: true,
    },
  },
  data () {
    return { isExpanded: false }
  },
  watch: {
    'action.disabled' (disabled) {
      if (disabled && this.isExpanded) {
        this.isExpanded = false
      }
    },
  },
}
</script>