import efficience from './efficience/efficience_common'
import efficience_dmp from './efficience/dmp/efficience_dmp'
import efficience_manual from './efficience/manual/efficience_manual'
import efficience_background from './efficience/background/efficience_background'

export default {
  efficience,
  efficience_dmp,
  efficience_manual,
  efficience_background,
}