<template>
  <app-form v-slot="{ submit }" :submit-method="savePincode">
    <section class="app-pin-code-form">
      <p>Veuillez saisir votre code porteur</p>
      <app-row>
        <app-col v-for="(pinNumber, i) in pincode" :key="i" cols="3">
          <app-text-field
            ref="pincode"
            v-test="'pin'"
            v-mask="'#'"
            autocomplete="off"
            type="password"
            hide-errors
            :value="pincode[i]"
            :autofocus="i === 0"
            rules="required:hide"
            @input="(val) => pincode[i] = val"
            @keydown="(event) => applyKey(event, i, submit)"
          />
        </app-col>
      </app-row>
    </section>
  </app-form>
</template>

<script>
import Vue from 'vue'

import AppTextField from '@/components/ui/form/AppTextField.vue'
import AppForm from '@/components/ui/form/AppForm.vue'

import { mapGetters } from 'vuex'

import iCanopeeCardReaderInstance from '@/services/vendors/icanopee/classes/ICanopeeCardReader'

export default {
  name: 'AppPinCodePrompt',
  components: {
    AppTextField,
    AppForm,
  },
  props: {
    validationObserver: {
      type: Object,
      default: null,
    },
  },
  data () {
    return { pincode: new Array(4) }
  },
  computed: { ...mapGetters('cardReaders', ['getPincodeModalVisible']) },
  watch: {
    getPincodeModalVisible (isVisible) {
      if (! isVisible) {
        this.reset()
      }
    },
  },
  methods: {
    getInput (textField) {
      return textField.$refs.input.$refs.input
    },
    blur (currentIndex) {
      const currentInput = this.$refs.pincode[currentIndex]
      currentInput.blur()
    },
    focusPrevious (currentIndex) {
      const previousInput = this.$refs.pincode[currentIndex - 1]
      if (previousInput) {
        previousInput.focus()
        this.getInput(previousInput) && this.getInput(previousInput).select()
      }
    },
    focusNext (currentIndex) {
      const nextInput = this.$refs.pincode[currentIndex + 1]
      if (nextInput) {
        nextInput.focus()
        this.getInput(nextInput) && this.getInput(nextInput).select()
      }
    },
    applyKey (event, currentIndex, submit) {
      const { key } = event

      switch (key) {

      // Déplacement à gauche
      case 'ArrowLeft':
        this.focusPrevious(currentIndex)
        break
      // Déplacement à droite
      case 'ArrowRight':
        this.focusNext(currentIndex)
        break

      // On efface la valeur, ou on revient en arrière si vide
      case 'Backspace':
        if (! this.pincode[currentIndex]?.length) {
          this.focusPrevious(currentIndex)
        }
        Vue.set(this.pincode, currentIndex, '')
        return

      // On saisir la valeur et on passe au suivant
      default:
        if (! isNaN(parseInt(key))) {
          Vue.set(this.pincode, currentIndex, key)
          if (currentIndex === this.pincode.length - 1 && this.pincode.join('').length === this.pincode.length) {
            this.blur(currentIndex)
            submit()
          } else {
            this.focusNext(currentIndex)
          }
        }
      }

      event.preventDefault()
    },
    reset () {
      this.pincode = new Array(4)
      this.$nextTick(() => {
        this.validationObserver.reset()
      })
    },

    async savePincode () {
      const pincode = this.pincode.join('')
      await iCanopeeCardReaderInstance
        .validatePincode(pincode)
        .finally(() => {
          this.reset()
          this.$refs.pincode[0].focus()
        })

      return pincode
    },
  },
}
</script>

<style lang="scss" scoped>
  .app-pin-code-form {
    ::v-deep {
      input {
        text-align: center;
        font-size: 16px;
      }
    }
  }
</style>