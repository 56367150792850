<template>
  <portal v-test="'portal'" :to="portalId">
    <slot />
  </portal>
</template>

<script>
export default {
  name: 'NPortal',
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  data () {
    return { portalId: null }
  },
  created () {
    this.portalId = this.$portals.register({ target: this.to })
  },
  destroyed () {
    this.$portals.unregister(this.portalId)
  },
}
</script>