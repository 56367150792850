/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity'
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField'

const contactAddressEntity = new DocumentEntity('Address', 'addresses.xml', 'Contient les données des addresses')

contactAddressEntity.addField(new DocumentEntityField('label', 'L\'intitulé de l’adresse'))
contactAddressEntity.addField(new DocumentEntityField('line1', 'La première ligne'))
contactAddressEntity.addField(new DocumentEntityField('line2', 'La deuxième ligne'))
contactAddressEntity.addField(new DocumentEntityField('postalCode', 'Le code postal'))
contactAddressEntity.addField(new DocumentEntityField('city', 'La ville'))
contactAddressEntity.addField(new DocumentEntityField('country', 'Le pays'))

export default contactAddressEntity