import agendaScheduleStore from './schedules'
import agendaMotivesStore from './appointmentMotives'
import agendaAppointmentsStore from './appointments'
import agendaAvailabilitiesStore from './availabilities'
import agendaAbsencesStore from './absences'

import { mapStoreResetter } from '@/utils/functions/store'
import { CALENDAR_VIEWS } from '../constants'
import { getFromAPI, patchToAPI } from '@/services/api.js'
import { add } from '@/utils/functions/dates'

import Appointment from '../models/events/Appointment'

export const state = () => ({
  settingsPath: undefined,
  config: {
    view: CALENDAR_VIEWS.WEEK.value,
    startTime: '07:00:00',
    endTime: '19:00:00',
    days: [1, 2, 3, 4, 5],
    appointmentsDisplayType: 'patient',
  },
  isMobileSidebarOpened: false,
  movedAppointment: null,
  currentDate: new Date(),

  ...agendaScheduleStore.state(),
  ...agendaMotivesStore.state(),
  ...agendaAppointmentsStore.state(),
  ...agendaAvailabilitiesStore.state(),
  ...agendaAbsencesStore.state(),
})

export const getters = {
  config: state => state.config,
  getAppointmentsDisplayType: state => state.config.appointmentsDisplayType,
  getView: state => state.config.view,
  getMovedAppointment: state => state.movedAppointment,
  ...agendaScheduleStore.getters,
  ...agendaMotivesStore.getters,
  ...agendaAppointmentsStore.getters,
  ...agendaAvailabilitiesStore.getters,
  ...agendaAbsencesStore.getters,
}

export const mutations = {
  ...mapStoreResetter(state),
  SET_VIEW (state, view) {
    const validView = Object.values(CALENDAR_VIEWS).map(availablesType => availablesType.value).includes(view)
    if (validView) {
      state.config.view = view
    }
  },

  /* Ajoute un évènement dans la liste des évènements */
  SET_SETTINGS_PATH (state, settingsPath) {
    state.settingsPath = settingsPath
  },
  SET_APPOINTMENTS_DISPLAY_TYPE (state, type) {
    state.config.appointmentsDisplayType = type
  },
  SET_DAY_BOUNDS (state, settings) {
    state.config.startTime = settings.startTime
    state.config.endTime = settings.endTime
  },
  SET_DAYS_VIEW (state, settings) {
    state.config.days = settings.days
  },
  SET_CURRENT_DATE (state, date) {
    state.currentDate = new Date(date)
  },
  SET_MOVED_APPOINTMENT (state, appointment) {
    state.movedAppointment = appointment ? new Appointment(appointment) : null
  },
  MOVE (state, direction) {
    const { currentDate, config } = state

    const nbDaysDiff = direction * (config.view === CALENDAR_VIEWS.DAY.value || config.view === CALENDAR_VIEWS.LIST.value ? 1 : 7)
    const destDate = add(currentDate, { days: nbDaysDiff })

    this.commit('agenda/SET_CURRENT_DATE', destDate)
  },
  SET_MOBILE_SIDEBAR (state, option) {
    state.isMobileSidebarOpened = option
  },
  ...agendaScheduleStore.mutations,
  ...agendaMotivesStore.mutations,
  ...agendaAppointmentsStore.mutations,
  ...agendaAvailabilitiesStore.mutations,
  ...agendaAbsencesStore.mutations,
}

export const actions = {
  async getSettings (context) {
    const { data } = await getFromAPI('/api/user_settings', { name: 'practitioner_schedule' })
    context.commit('SET_SETTINGS_PATH', data['hydra:member'][0]['@id'])
    context.commit('SET_DAY_BOUNDS', data['hydra:member'][0].value.planningView)
    context.commit('SET_DAYS_VIEW', data['hydra:member'][0].value.planningView)
  },
  async saveSettings ({ state, commit }, planningViewSettings) {
    const { data } = await patchToAPI(state.settingsPath, { data: { value: { planningView: planningViewSettings } } })
    commit('SET_DAY_BOUNDS', data.value.planningView)
    commit('SET_DAYS_VIEW', data.value.planningView)
  },
  /**
   * Supprime l'ensemble des évènements dont leurs settings ont été supprimés
   * (par exemple lors de la suppression d'un évènement répétable)
   * @param { String } settingIri L'Iri des settings ayant été supprimés
   * @param { Array } events La liste des évènements à parcourir
   * @param { String } commitName Le commit a éxécuter lors de la suppression d'un évènement
   */
  clearEventsFromSettingsIri ({ commit }, { settingIri, events, commitName }) {
    events
      .filter(event => event.setting['@id'] === settingIri)
      .forEach(event => commit(commitName, event))
  },

  ...agendaScheduleStore.actions,
  ...agendaMotivesStore.actions,
  ...agendaAppointmentsStore.actions,
  ...agendaAvailabilitiesStore.actions,
  ...agendaAbsencesStore.actions,
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}