<template>
  <section class="analytics-section flex-list-6 flex-column">
    <h3 class="analytics-section__title">
      Documents
    </h3>
    <analytics-section-filters
      :document-type.sync="selectedDocumentType"
      :practitioner-rpps.sync="selectedRppsPractitioner"
      :from.sync="fromDate"
      :to.sync="toDate"
    />
    <n-button
      v-test="'csv-export-button'"
      label="Exporter au format CSV"
      icon="export"
      bg-color="primary"
      :loading="isExportingCsvData"
      class="analytics-section__csv-button"
      outlined
      @click="csvExport"
    />
    <app-row>
      <app-col
        v-for="(widget, index) in displayedWidgets"
        :key="index"
        md="4"
        cols="12"
      >
        <document-analytic-widget-skeleton v-if="isFetchingAnalyticsCounts" />
        <document-analytic-widget
          v-else-if="widget.logs"
          :title="widget.title"
          :document-analytic="widget.logs"
        />
      </app-col>
    </app-row>
  </section>
</template>

<script>
import AnalyticsSectionFilters from '@/modules/users/components/dashboard/analytics/AnalyticsSectionFilters.vue'
import DocumentAnalyticWidget from '@/modules/users/components/dashboard/analytics/widgets/DocumentAnalyticWidget.vue'
import DocumentAnalyticWidgetSkeleton from '@/modules/users/components/dashboard/analytics/widgets/DocumentAnalyticWidgetSkeleton.vue'

import { getFromAPI } from '@/services/api'
import { downloadCsv } from '@/utils/functions/files'

import DocumentAnalyticDataset from '@/modules/users/models/DocumentAnalyticDataset'

import { SENT_PATIENT_DOC_LOG_TYPES, SENT_PATIENT_DOC_LOG_DOCUMENT_TYPES } from '@/modules/users/constants'

export default {
  name: 'AnalyticsSection',
  components: {
    AnalyticsSectionFilters,
    DocumentAnalyticWidget,
    DocumentAnalyticWidgetSkeleton,
  },
  data () {
    return {
      SENT_PATIENT_DOC_LOG_DOCUMENT_TYPES,
      rawLogs: {},
      isFetchingAnalyticsCounts: false,
      isExportingCsvData: false,
      selectedDocumentType: SENT_PATIENT_DOC_LOG_DOCUMENT_TYPES.VSM.value,
      selectedRppsPractitioner: null,
      fromDate: null,
      toDate: null,
    }
  },
  computed: {
    sendPatientDocLogs () {
      // Le retour étant sous la forme d'un objet se basant sur les types de logs (DMP, MSS PRO et MSS Patient)
      // On parcours l'ensemble de ces types afin de pouvoir leurs attribuer une instance de DocumentAnalyticDataset
      // Pour chacun de leurs type de documents (vsm, e-Prescription, ordonnances, etc...)
      return Object.keys(this.rawLogs).reduce((acc, logType) => {
        return {
          ...acc,
          [logType]: Object.keys(this.rawLogs[logType]).reduce((docTypeAcc, docType) => ({
            ...docTypeAcc,
            [docType]: new DocumentAnalyticDataset(this.rawLogs[logType][docType]),
          }), {}),
        }
      }, {})
    },
    displayedWidgets () {
      return Object.values(SENT_PATIENT_DOC_LOG_TYPES).map(logType => ({
        title: logType.label,
        category: logType.value,
        logs: this.sendPatientDocLogs[logType.value]?.[this.selectedDocumentType],
      }))
    },
    filters () {
      return {
        documentType: this.selectedDocumentType,
        startDate: this.fromDate,
        endDate: this.toDate,
        rpps: this.selectedRppsPractitioner,
      }
    },
  },
  watch: {
    filters () {
      this.fetchDocumentAnalyticsCounts()
    },
  },
  created () {
    this.fetchDocumentAnalyticsCounts()
  },
  methods: {
    async fetchDocumentAnalyticsCounts () {
      this.isFetchingAnalyticsCounts = true
      try {
        const { data } = await getFromAPI('/api/sent_patient_doc_log_analytics', this.filters)
        this.rawLogs = data['hydra:member']
      } finally {
        this.isFetchingAnalyticsCounts = false
      }
    },
    async csvExport () {
      this.isExportingCsvData = true
      try {
        const { data } = await getFromAPI('/api/sent_patient_doc_log_analytics/csv', this.filters, { headers: { Accept: 'text/csv' } })
        downloadCsv(data, { fileName: 'easy-care_export_statistiques.csv' })
      } finally {
        this.isExportingCsvData = false
      }
    },
  },
}
</script>
<style scoped lang="scss">
.analytics-section {
  &__title {
    font-size: 16px;
    font-weight: 600;
    color: var(--v-secondary-lighten4)
  }

  &__csv-button {
    align-self: flex-start;
  }
}
</style>