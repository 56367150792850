/**
 * Conversion JSON de https://mos.esante.gouv.fr/NOS/TRE_A12-NomenclatureASTM/TRE_A12-NomenclatureASTM.xml
 * Version normalisée, simplifiée et indexée par code
 */

/* eslint-disable quote-props */
export default {
  'E1762': {
    code: 'E1762',
    codeSystem: 'ASTM',
    dateFin: '',
    displayName: 'Full Document',
  },
}