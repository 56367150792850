<template>
  <div class="biometric-measures-part">
    <div class="biometric-measures-part__title" data-test="biometrics-measures-part-title">
      {{ title }}
    </div>
    <article
      v-if="!measures.filter(item => getMeasureValue(item)).length"
      class="biometric-measures-part__no-data"
    >
      {{ noDataText }}
    </article>
    <template
      v-for="measure in measures.filter(item => getMeasureValue(item) !== '')"
      v-else
    >
      <app-hoverable-actions-wrapper :key="measure.type" :actions="measureActions(measure)">
        <div
          v-test="`measure-${measure.type}`"
          class="biometric-measures-part__row"
        >
          <n-tooltip
            v-slot="{ on, attrs }"
            right
            open-delay="300"
            :text="measure.editable ? getMeasureDate(measure.lastPatientMeasure) : getIndicatorTooltip(measure)"
          >
            <div
              class="biometric-measures-part__measure-value"
              v-bind="attrs"
              v-on="on"
            >
              <span v-if="measure.name" class="blue-grey--text mr-1">{{ measure.name }} : </span>
              <span class="font-weight-semi-bold">{{ getMeasureValue(measure) }}</span>
            </div>
          </n-tooltip>
        </div>
      </app-hoverable-actions-wrapper>
    </template>
    <biometric-modal-chart
      :patient="patient"
      :is-open.sync="isChartOpened"
      :measures="getMeasureValues(currentChartMeasure)"
      :y-label="currentChartMeasure ? currentChartMeasure.name : ''"
      :y-unit="currentChartMeasure ? currentChartMeasure.unit : ''"
    />
  </div>
</template>
<script>
import BiometricModalChart from '@/modules/patient/components/patientFile/measures/BiometricModalChart.vue'

import NovaTools from '@/nova-tools/NovaTools'
import { mapGetters } from 'vuex'

import Patient from '@/modules/patient/models/Patient'
import AppHoverableActionsWrapper from '@/components/ui/hoverableActionsWrapper/AppHoverableActionsWrapper'
import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'

export default {
  name: 'BiometricMeasuresPart',
  components: {
    AppHoverableActionsWrapper,
    BiometricModalChart,
  },
  props: {
    measures: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    patient: {
      type: Patient,
      default: null,
    },
    /**
     * Le texte à afficher si aucun élément n'est présent dans le listing
     * @default 'Aucune donnée à afficher'
     */
    noDataText: {
      type: String,
      default: 'Aucune donnée à afficher',
    },
  },
  data () {
    return {
      isChartOpened: false,
      currentChartMeasure: null,
    }
  },
  computed: { ...mapGetters('patient', ['getMeasuresFromPatientByName']) },
  methods: {
    getMeasureDate (measure) {
      const measuredAt = NovaTools.dates.format(new Date(measure.measuredAt), 'dd/MM/yyyy')
      return `le ${measuredAt}`
    },
    getIndicatorTooltip (measure) {
      if (measure.type === 'bmi') {
        const measures = this.measures.
          filter(m => ['weight', 'size'].includes(m.lastPatientMeasure?.name)).
          map(m => {
            return {
              name: m.lastPatientMeasure?.name,
              measuredAt: m.lastPatientMeasure?.measuredAt,
            }
          })
        const weight = measures.find(s => s.name === 'weight')
        const size = measures.find(s => s.name === 'size')
        if (weight?.measuredAt === size?.measuredAt) {
          return 'Calcul obtenu avec le poids et la taille du ' +
            NovaTools.dates.format(new Date(size.measuredAt), 'dd/MM/yyyy')
        }
        return 'Calcul obtenu avec le poids du ' +
          (weight?.measuredAt ? NovaTools.dates.format(new Date(weight.measuredAt), 'dd/MM/yyyy') : '-') +
          ' et la taille du ' +
          (size?.measuredAt ? NovaTools.dates.format(new Date(size.measuredAt), 'dd/MM/yyyy') : '-')
      }
      return 'Calculé'
    },
    getMeasureValue (measure) {
      return measure.lastPatientMeasure?.value ? `${measure.lastPatientMeasure.value.toLocaleString('fr-FR')} ${measure.unit}` : ''
    },
    getMeasureValues (measure) {
      return this.getMeasuresFromPatientByName(this.patient, measure?.type) || []
    },
    openChart (measure) {
      this.currentChartMeasure = measure
      this.isChartOpened = true
    },
    measureActions (measure) {
      const actions = {}
      if (measure.editable) {
        actions.openChart = new ActionMenuItem('chart', 'Afficher un graphique', () => this.openChart(measure))
      }
      return actions
    },
  },
}
</script>
<style scoped lang="scss">
  .biometric-measures-part {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    gap: map-get($spacers, 2);
    padding: map-get($spacers, 4);

    &__title {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      color: var(--v-secondary-lighten4);
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-height: 20px;
    }

    &__no-data {
       color: var(--v-secondary-base);
    }
  }
</style>