/**
 * Conversion JSON de https://mos.esante.gouv.fr/NOS/TRE_A04-Loinc/TRE_A04-Loinc.xml
 * Version normalisée, simplifiée et indexée par code
 */

/* eslint-disable quote-props */
export default {
  '10213-7': {
    code: '10213-7',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '20171013120000',
    displayName: 'CR d\'anesthésie',
    descriptionMetier: 'Surgical operation note anesthesia Document réalisé par un anesthésiste ou une infirmière anesthésiste diplômée d\'état au cours ou au décours d\'une anesthésie, décrivant son déroulement et son issue.',
  },
  '11369-6': {
    code: '11369-6',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Historique de vaccinations',
  },
  '11488-4': {
    code: '11488-4',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR ou fiche de consultation ou de visite',
    descriptionMetier: 'Consultation note {Provider} Document réalisé à l\'issue d\'une consultation médicale ou chirurgicale indiquant le motif de la consultation, les principales observations, le résultat de consultation et la conduite à tenir.',
  },
  '11490-0': {
    code: '11490-0',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Lettre de liaison à la sortie d\'un établissement de soins',
    descriptionMetier: 'Physician hospital discharge summary La lettre de sortie synthétise le cas d\'un patient à l\'occasion d\'un séjour dans un hôpital ou dans un centre de soins. Elle comprend en principe l\'ensemble des diagnostics qui ont été posés durant ce séjour. Les diagnostics actifs sont étayés par d\'autres éléments du dossier dont les conclusions sont citées. La lettre de sortie contient aussi des commentaires, ou une discussion si nécessaire, et le traitement à suivre après le départ de l\'hôpital. Enfin, la lettre de sortie donne des recommandations pour le suivi médical. Le but premier d\'une lettre de sortie est par conséquent d\'informer le médecin traitant qui suivra le patient après sa sortie.',
  },
  '11493-4': {
    code: '11493-4',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '20171013120000',
    displayName: 'CR hospitalier (séjour)',
    descriptionMetier: 'Hospital discharge studies Summary',
  },
  '11502-2': {
    code: '11502-2',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR d\'examens biologiques',
    descriptionMetier: 'LABORATORY REPORT TOTAL Compte rendu d\'analyses de biologie médicale.',
  },
  '11505-5': {
    code: '11505-5',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR d\'acte thérapeutique (autre)',
    descriptionMetier: 'Physician procedure note Document rendant compte d\'un acte thérapeutique.',
  },
  '11506-3': {
    code: '11506-3',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR ou fiche de suivi de soins par auxiliaire médical',
    descriptionMetier: 'Provider-unspecified Progress Note Document rédigé par une sage-femme, un infirmier ou un auxiliaire médical rendant compte des soins réalisés et des résultats obtenus.',
  },
  '11526-1': {
    code: '11526-1',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR d\'anatomie et de cytologie pathologiques',
    descriptionMetier: 'PATHOLOGY STUDY Document réalisé par un pathologiste rendant compte de l\'examen d\'un ou plusieurs échantillons biologiques de type pièce opératoire ou prélèvement par biopsie ou échantillon cytologique ou bien rendant compte d\'une autopsie. Différents modèles de comptes rendus peuvent exister, adaptés au type de prélèvement et à l\'organe concerné.',
  },
  '11528-7': {
    code: '11528-7',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '20171013120000',
    displayName: 'CR d\'imagerie médicale',
    descriptionMetier: 'Radiology Unspecified site and modality Study Document réalisé par un médecin radiologue qui interprète les images obtenues au cours d\'un examen utilisant une technique d\'imagerie médicale.',
  },
  '15507-7': {
    code: '15507-7',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR de passage aux urgences',
    descriptionMetier: 'Provider-unspecified ED Progress note RPU.',
  },
  '15508-5': {
    code: '15508-5',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR d\'accouchement',
    descriptionMetier: 'Labor and delivery records Document réalisé par un gynécologue obstétricien ou par une sage-femme, décrivant le déroulement d\'un accouchement, le mode de délivrance et l\'état de l\'enfant à la naissance.',
  },
  '18748-4': {
    code: '18748-4',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR d\'imagerie médicale',
  },
  '18761-7': {
    code: '18761-7',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Note de transfert (dont lettre de liaison à l\'entrée en établissement de soins)',
  },
  '18776-5': {
    code: '18776-5',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Plan personnalisé de soins',
    descriptionMetier: 'Plan of treatment Document rédigé par un professionnel de santé identifiant le(s) problème(s) du patient et le plan de prise en charge prévu pour une période déterminée.',
  },
  '24611-6': {
    code: '24611-6',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '20171013120000',
    displayName: 'CR de télé-médecine (télé-consultation)',
    descriptionMetier: 'Outpatient Consultation 2nd opinion Conclusion d\'une activité médicale de consultation réalisée à distance.',
  },
  '27899-4': {
    code: '27899-4',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '20171013120000',
    displayName: 'CR d\'acte diagnostique (autre)',
    descriptionMetier: 'Diagnostic studies.non lab Document réalisé par le médecin ayant pratiqué l\'acte qui décrit la technique utilisée, les principales observations et la conclusion de l\'examen.',
  },
  '29550-1': {
    code: '29550-1',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '20171013120000',
    displayName: 'Dispensation médicamenteuse (non codée)',
    descriptionMetier: 'Medication dispensed Document rédigé par un pharmacien, listant les médicaments dispensés au patient.',
  },
  '29551-9': {
    code: '29551-9',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '20171013120000',
    displayName: 'Prescription de médicaments (non codée)',
    descriptionMetier: 'Medication prescribed Document médico-légal rédigé par un médecin ou une sage-femme indiquant les médicaments prescrits, la posologie, la durée du traitement et le cas échéant des consignes d\'administration ou de surveillance.',
  },
  '29554-3': {
    code: '29554-3',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '20171013120000',
    displayName: 'CR d\'acte thérapeutique à visée préventive',
    descriptionMetier: 'Procedure Document réalisé par le médecin ayant pratiqué l\'acte thérapeutique dans le but de prévenir une pathologie à un stade infra clinique, Il décrit la technique utilisée, les principales observations et la conclusion de l\'intervention.',
  },
  '34112-3': {
    code: '34112-3',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR hospitalier (séjour)',
  },
  '34133-9': {
    code: '34133-9',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Synthèse d\'épisode de soins',
    descriptionMetier: 'Summarization of episode note Document réalisé par un professionnel de santé résumant l\'état clinique et l\'état de la prise en charge médicale d\'un(e) patient(e) à l\'issue d\'un épisode de soins.',
  },
  '34749-2': {
    code: '34749-2',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR de consultation pré-anesthésique',
    descriptionMetier: 'Consultation note {Anesthesia} Document réalisé par un anesthésiste, rendant compte de sa consultation, en vue d\'une anesthésie à venir.',
  },
  '34794-8': {
    code: '34794-8',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR de réunion de concertation pluridisciplinaire',
    descriptionMetier: 'Multidisciplinary evaluation and management note Document rédigé par un PS à l\'issue d\'une réunion réunissant plusieurs PS autour de la prise en charge d\'un patient, et décrivant les décisions prises à son sujet.',
  },
  '34842-5': {
    code: '34842-5',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Grille d\'évaluation médico-social',
  },
  '34874-8': {
    code: '34874-8',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR opératoire',
    descriptionMetier: 'Surgery Surgical operation note Document réalisé par un chirurgien décrivant la technique employé, le déroulement de l\'intervention chirurgicale, les pièces éventuellement prélevée, et les dispositifs médicaux éventuellement administrés.',
  },
  '39256-3': {
    code: '39256-3',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '20171013120000',
    displayName: 'CR d\'acte diagnostique à visée préventive ou de dépistage',
    descriptionMetier: 'History and physical screen assessment Document réalisé par le médecin ayant pratiqué l\'acte à visée diagnostique dans le but de prévenir une pathologie à un stade infra clinique, ou de dépister une pathologie. Il décrit la technique utilisée, les principales observations et la conclusion de l\'examen.',
  },
  '46241-6': {
    code: '46241-6',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '20171013120000',
    displayName: 'CR d\'admission',
    descriptionMetier: 'Hospital admission Dx Document réalisé à l\'issue de l\'admission d\'un patient dans un établissement de santé résumant l\'état initial du patient, et la prise en charge prévue.',
  },
  '47420-5': {
    code: '47420-5',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR de bilan fonctionnel (par auxiliaire médical)',
    descriptionMetier: 'Functional status assessment Document Document réalisé par un auxiliaire médical décrivant l\'état d\'une fonction d\'un patient',
  },
  '51969-4': {
    code: '51969-4',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR de génétique moléculaire',
  },
  '52040-3': {
    code: '52040-3',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Document encapsulant une image d\'illustration non DICOM',
    descriptionMetier: 'Dental X-rays and other images (not DICOM) attachment Images acquises au cours d\'un acte diagnostique ou thérapeutique, conservées dans un dossier partagé sous un format autre que DICOM (ex : TIFF, JPEG, PNG …).',
  },
  '52063-5': {
    code: '52063-5',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '20171013120000',
    displayName: 'Prescription (autre)',
    descriptionMetier: 'Prescription for durable medical equipment attachment Document indiquant le dispositif médical prescrit, les consignes de pose et de surveillance.',
  },
  '52521-2': {
    code: '52521-2',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '20171013120000',
    displayName: 'Prescription de soins',
    descriptionMetier: 'Overall Plan of Care and or Advance Care Directives Document rédigé par un médecin prescrivant les soins à réaliser.',
  },
  '55115-0': {
    code: '55115-0',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Demande d\'acte d\'imagerie',
    descriptionMetier: 'Demande d\'acte d\'imagerie.',
  },
  '56445-0': {
    code: '56445-0',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Bilan médicamenteux (Officine)',
    descriptionMetier: 'Bilan médicamenteux réalisé dans le cadre d\'une conciliation des traitements médicamenteux ou d\'un bilan partagé de médication.',
  },
  '57828-6': {
    code: '57828-6',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Prescription (autre)',
  },
  '57832-8': {
    code: '57832-8',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Prescription de soins',
  },
  '57833-6': {
    code: '57833-6',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Prescription de produits de santé',
    descriptionMetier: 'Prescription de produits de santé',
  },
  '60591-5': {
    code: '60591-5',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Synthèse du dossier médical',
  },
  '60593-1': {
    code: '60593-1',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Dispensation médicamenteuse',
  },
  '61357-0': {
    code: '61357-0',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Intervention pharmaceutique',
    descriptionMetier: 'Intervention du pharmacien auprès du prescripteur sur une ligne de prescription.',
  },
  '67851-6': {
    code: '67851-6',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR d\'admission',
  },
  '68599-0': {
    code: '68599-0',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Synthèse psychiatrique',
  },
  '68817-6': {
    code: '68817-6',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Bilan de santé et de prévention de l\'enfant',
  },
  '70004-7': {
    code: '70004-7',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR d\'acte diagnostique (autre)',
  },
  '74207-2': {
    code: '74207-2',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Dossier de liaison d\'urgence',
  },
  '75468-9': {
    code: '75468-9',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Renouvellement ordonnance par pharmacien correspondant',
    descriptionMetier: 'Renouvellement et adaptation des posologies par le pharmacien correspondant. Dans le cadre d\'un renouvellement périodique des traitements chroniques.',
  },
  '75482-0': {
    code: '75482-0',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR d\'acte thérapeutique à visée préventive',
  },
  '75492-9': {
    code: '75492-9',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR d\'acte diagnostique à visée préventive ou de dépistage',
  },
  '75496-0': {
    code: '75496-0',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Demande d\'acte de télémédecine',
  },
  '75497-8': {
    code: '75497-8',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '20200131120000',
    displayName: 'CR de télé-médecine',
  },
  '77436-4': {
    code: '77436-4',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR d\'anesthésie',
  },
  '77604-7': {
    code: '77604-7',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Planification thérapeutique',
    descriptionMetier: 'Plan de prise associé à la dispensation des traitements.',
  },
  '78513-9': {
    code: '78513-9',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR de consultation en ophtalmologie',
  },
  '78601-2': {
    code: '78601-2',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR d\'entretien pharmaceutique',
  },
  '80565-5': {
    code: '80565-5',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR d\'administration de médicaments',
  },
  '80788-3': {
    code: '80788-3',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Projet personnalisé d\'accompagnement',
  },
  '80820-4': {
    code: '80820-4',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Formulaire de conciliation médicamenteuse (Hôpital)',
    descriptionMetier: 'Formulaire permettant l\'analyse des divergences entre le bilan médicamenteux et la prescription en cours dans le cadre de la conciliation médicamenteuse.',
  },
  '83836-7': {
    code: '83836-7',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Schéma dentaire',
  },
  '83901-9': {
    code: '83901-9',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Bilan psychologique',
  },
  '83981-1': {
    code: '83981-1',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Lettre de liaison à la sortie d\'une structure sociale ou médico-sociale',
  },
  '85208-7': {
    code: '85208-7',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR de télémédecine',
  },
  '86531-1': {
    code: '86531-1',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Attestation de sortie',
  },
  '87273-9': {
    code: '87273-9',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Note de vaccination',
  },
  '88348-8': {
    code: '88348-8',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Lettre de liaison d\'entrée en structure sociale ou médico-sociale',
  },
  '88365-2': {
    code: '88365-2',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Avant repas',
    descriptionMetier: 'Glucose [Mass/volume] in Blood --pre-meal',
  },
  '89601-9': {
    code: '89601-9',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Certificat médical',
  },
  '93024-8': {
    code: '93024-8',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'CR de consultation pharmaceutique',
    descriptionMetier: 'Réalisation d\'un bilan de médication et/ou d\'un plan pharmaceutique personnalisé dans le cadre d\'une consultation pharmaceutique.',
  },
  '96173-0': {
    code: '96173-0',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Attestation de dépistage',
  },
  '96874-3': {
    code: '96874-3',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'COVID-19 Attestation de vaccination',
  },
  '2345-7': {
    code: '2345-7',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Glucose sanguin',
  },
  '4548-4': {
    code: '4548-4',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Hémoglobine glyquée (HbA1c)',
    descriptionMetier: 'L\'hémoglobine glyquée (HbA1C) est la part de l\'hémoglobine qui capte le sucre dans le sang.',
  },
  '53049-3': {
    code: '53049-3',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Glucose à jeun',
  },
  '16915-1': {
    code: '16915-1',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Glucose post prandial',
  },
  'LA11865-5': {
    code: 'LA11865-5',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Après exercice',
  },
  'LA15173-0': {
    code: 'LA15173-0',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Femme enceinte',
  },
  'LA46-8': {
    code: 'LA46-8',
    codeSystem: '2.16.840.1.113883.6.1',
    dateFin: '',
    displayName: 'Autre',
  },
}