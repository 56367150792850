import messagingStore from '@/modules/secureMessaging/modules/messaging/store'

import { mapStoreResetter } from '@/utils/functions/store'

export const state = () => ({ ...messagingStore.state() })

export const getters = { ...messagingStore.getters }

export const mutations = {
  ...mapStoreResetter(state),
  ...messagingStore.mutations,
}

export const actions = { ...messagingStore.actions }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}