import ConsultationLettersDocument from '@/modules/patient/models/ConsultationLettersDocument'
import { DOCUMENT_TYPES } from '@/modules/documents/constants'

export default class ConsultationFollowUpLetterDocument extends ConsultationLettersDocument {
  constructor (options = {}) {
    super(options)
    this.freeText = options.freeText || null
  }

  getLabel () {
    return DOCUMENT_TYPES.FOLLOW_UP_LETTER.title
  }

  getIcon () {
    return DOCUMENT_TYPES.FOLLOW_UP_LETTER.icon
  }
}