<template>
  <app-hoverable-actions-wrapper
    v-test="'actions-hover'"
    tag="article"
    class="patient-relatives-panel-item"
    :actions="actions"
  >
    <span v-test="'patient-relatives-civil-state'" class="patient-relatives-panel-item__civil-state">
      {{ item.getCivilState() }}
      <span v-test="'patient-relatives-sub-info'" class="ml-2 patient-relatives-panel-item__sub-infos">{{ item.getLinkLabel() }}</span>
    </span>

    <app-contact-point
      v-for="contactPoint in item.contactPoints"
      :key="contactPoint['@id']"
      v-test="'contact-point'"
      :contact-point="contactPoint"
      :show-label="false"
      :formatted-contact-point="false"
      compact
    />
  </app-hoverable-actions-wrapper>
</template>

<script>
import PatientRelative from '@/modules/patient/models/PatientRelative'
import AppContactPoint from '@/components/ui/contactPoint/AppContactPoint.vue'
import AppHoverableActionsWrapper from '@/components/ui/hoverableActionsWrapper/AppHoverableActionsWrapper.vue'
import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'

export default {
  name: 'PatientRelativesPanelItem',
  components: {
    AppContactPoint,
    AppHoverableActionsWrapper,
  },
  props: {
    item: {
      type: PatientRelative,
      required: true,
    },
  },
  computed: {
    actions () {
      return [new ActionMenuItem(
        'edit',
        'Modifier',
        () => this.$emit('update-patient-relative', this.item),
      ), new ActionMenuItem(
        'delete',
        'Supprimer',
        () => this.$emit('delete-patient-relative', this.item),
      )]
    },
  },
}
</script>

<style lang="scss" scoped>
.patient-relatives-panel-item {
  &__civil-state {
    display: flex;
    font-size: 13px;
    font-weight: 400;
    align-items: center;
  }
  &__sub-infos {
    font-size: 12px;
    color: var(--v-secondary-base);
  }
}
</style>