<template>
  <list-layout
    v-if="!isCurrentUserSecretary"
    ref="listLayout"
    api-resource-url="/api/detailed_consultations"
    :item-model-class="DetailedConsultation"
    :list-item-component="AccountingListItem"
    :list-header-component="AccountingListHeader"
    :list-filter-component="AccountingListFilters"
    :list-skeleton-component="AccountingListItemSkeleton"
  />
</template>

<script>
import ListLayout from '@/components/layouts/ListLayout.vue'

import { mapGetters } from 'vuex'

import AccountingListHeader from '@/modules/accounting/components/AccountingListHeader.vue'
import AccountingListFilters from '@/modules/accounting/components/AccountingListFilters.vue'
import AccountingListItem from '@/modules/accounting/components/AccountingListItem.vue'
import AccountingListItemSkeleton from '@/modules/accounting/components/AccountingListItemSkeleton.vue'

import DetailedConsultation from '@/modules/patient/models/DetailedConsultation'
import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'AccountingDashboardView',
  components: { ListLayout },
  data () {
    return {
      AccountingListItem,
      AccountingListItemSkeleton,
      AccountingListHeader,
      AccountingListFilters,
      DetailedConsultation,
    }
  },
  computed: { ...mapGetters('auth', ['isCurrentUserSecretary']) },
  created () {
    if (this.isCurrentUserSecretary) {
      NovaTools.notify.error('Vous n\'avez pas l\'autorisation d\'accéder à ce module')
    }
  },
}
</script>