import Vue from 'vue'

import Correspondent from '@/models/user/Correspondent'
import Contact from '@/models/user/Contact'

export const state = () => ({ referringPhysicians: {} })

export const getters = {
  getReferringPhysicianByPatient: state => (patientIri) => {
    return state.referringPhysicians[patientIri]
  },
}

export const mutations = {
  SET_REFERRING_PHYSICIAN (state, referringPhysician) {
    Vue.set(state.referringPhysicians, referringPhysician['id'], referringPhysician['referringPhysician'])
  },
}

export const actions = {
  setReferringPhysicianByContact ({ commit }, contact) {
    const storedCorrespondent = new Correspondent({
      category: contact['referringPhysician'].correspondent.category,
      contact: new Contact({ ...contact['referringPhysician'] }),
      note: contact['referringPhysician'].correspondent.note,
    })
    commit('SET_REFERRING_PHYSICIAN', {
      id: contact['patientId'],
      referringPhysician: storedCorrespondent,
    })
  },
  setReferringPhysicianByCorrespondent ({ commit }, correspondent) {
    let storedCorrespondent = null
    if (correspondent['referringPhysician']) {
      storedCorrespondent = new Correspondent({
        category: correspondent['referringPhysician'].category,
        contact: new Contact({ ...correspondent['referringPhysician'].contact }),
        note: correspondent['referringPhysician'].note,
      })
    }
    commit('SET_REFERRING_PHYSICIAN', {
      id: correspondent['patientId'],
      referringPhysician: storedCorrespondent,
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}