/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity'
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField'

const patientDrugAllergyEntity = new DocumentEntity('PatientDrugAllergy', 'patientDrugAllergies.xml', 'Contient les allergies médicamenteuses des patients')

patientDrugAllergyEntity.addField(new DocumentEntityField('label', 'Le label'))
patientDrugAllergyEntity.addField(new DocumentEntityField('note', 'La note'))

export default patientDrugAllergyEntity