import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem.js'

import NovaTools from '@/nova-tools/NovaTools'

export default class DeleteActionMenuItem extends ActionMenuItem {
  /**
   * Représente une action de menu
   * @param {String} [icon = delete] L'icône de l'action
   * @param {String} [text = Supprimer] Le texte de l'action
   * @param {Function} callback La méthode exécutée par l'action
   * @param {Object} [options = {important: true }] Options de l'ActionMenuItem
   * @param {Boolean} [options.important] Indique qu'il s’agit d'une action importante
   * @param {Boolean} [options.disabled] Désactive l'action
   * @param {Boolean} [options.disabledTooltip] Tooltip sur l'action désactivée
   * @param {Boolean} [options.loading] Met l'action dans un état de chargement
   * @param {Boolean} [options.confirmTitle] Titre de la modale de confirmation
   * @param {Boolean} [options.confirmText] Contenu de la modale
   */

  constructor ({ icon = 'delete', text = 'Supprimer', callback, options = { important: true } }) {
    super(icon, text, async (...args) => {
      if (options.confirmText) {
        NovaTools.dialog.confirm(
          options.confirmTitle || 'Êtes-vous sûr(e) ?',
          options.confirmText,
          async () => await callback(...args),
        )
      } else {
        await callback(...args)
      }
    }, {
      ...options,
      important: (typeof options.important !== 'undefined') ? options.important : true,
    })
  }
}