<template>
  <img
    class="image-file-preview"
    :src="imageData"
    alt=""
  >
</template>

<script>

import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'ImageViewer',
  props: {
    base64FileContent: {
      type: String,
      default: null,
    },
    contentUrl: {
      type: String,
      default: null,
    },
  },
  data () {
    return { localBase64: null }
  },
  computed: {
    imageBase64 () {
      return this.base64FileContent ? this.base64FileContent : this.localBase64
    },
    imageData () {
      return ['data:image/jpeg;base64', this.imageBase64].join(',')
    },
  },
  watch: {
    contentUrl: {
      immediate: true,
      async handler (newValue) {
        if (newValue) {
          this.localBase64 = await NovaTools.files.getBase64Content(newValue)
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.image-file-preview {
  max-width : 100%;
  object-fit: contain;
  object-position: center;
}
</style>