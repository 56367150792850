import PrescriptionLine from '@/modules/patient/models/prescriptionLine/PrescriptionLine'

export default class PrescriptionActLine extends PrescriptionLine {
  constructor (options = {}) {
    super(options)

    this.outOfNomenclature = options.outOfNomenclature || false
    this.description = options.description
  }

  getLabel () {
    return this.description
  }
}