/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity'
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField'

const ConsultationVaccineEntity = new DocumentEntity('ConsultationVaccine', 'consultationVaccines.xml', 'Contient les vaccins réalisés lors d\'une consultation')

ConsultationVaccineEntity.addField(new DocumentEntityField('name', 'Le nom'))
ConsultationVaccineEntity.addField(new DocumentEntityField('cipCode', 'Le code CIP'))
ConsultationVaccineEntity.addField(new DocumentEntityField('lotNumber', 'Le numéro de lot'))
ConsultationVaccineEntity.addField(new DocumentEntityField('expiryDate', 'La date d\'expiration'))
ConsultationVaccineEntity.addField(new DocumentEntityField('boosterDate', 'La date de la future dose'))

export default ConsultationVaccineEntity