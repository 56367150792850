<template>
  <synapse-autocomplete-wrapper
    :synapse-input-widget="MedicalResourceAutocomplete"
    :value="value"
    :favorites="favorites"
    :item-text="getDrugLabel"
    :item-value="getDrugValue"
    label="Rechercher un traitement"
    hide-label
    :rules="rules"
    :append-icon="appendIcon"
    class="drug-autocomplete"
    @input="onItemSelected"
    @add-favorite="onFavoriteItemAdded"
    @delete-favorite="onFavoriteItemDeleted"
  />
</template>

<script>
import SynapseAutocompleteWrapper from '@/components/lap/SynapseAutocompleteWrapper.vue'
import MedicalResourceAutocomplete from '@/components/lap/synapse-widgets/MedicalResourceAutocomplete.vue'

import WidgetFavoriteEntity from '@/components/lap/synapse-widgets/models/WidgetFavoriteEntity'

export default {
  name: 'DrugAutocomplete',
  components: { SynapseAutocompleteWrapper },
  props: {
    value: {
      type: Object,
      default: null,
    },
    appendIcon: {
      type: String,
      default: 'search',
    },
    rules: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      MedicalResourceAutocomplete,
      favorites: [],
    }
  },
  async mounted () {
    this.favorites = await WidgetFavoriteEntity.fetchAll('MedicalResourceAutocompleteWidget')
  },
  methods: {
    onItemSelected (drug) {
      this.$emit('input', drug)
    },
    getDrugLabel (drug) {
      return drug.label
    },
    getDrugValue (drug) {
      return drug.uuid ?? drug.drug_id ?? drug.uri
    },
    async onFavoriteItemAdded (drug) {
      const newItem = await WidgetFavoriteEntity.insert('MedicalResourceAutocompleteWidget', drug)
      this.favorites.push(newItem)
    },
    async onFavoriteItemDeleted (drug) {
      const deletedItemIndex = this.favorites.findIndex(fav => fav.entity === drug)
      if (deletedItemIndex > - 1) {
        await WidgetFavoriteEntity.delete(this.favorites[deletedItemIndex])
        this.favorites.splice(deletedItemIndex, 1)
      }
    },
  },
}
</script>