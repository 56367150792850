import Vue from 'vue'

import Tooltip from './Tooltip'

const state = Vue.observable({ activeTooltips: [] })

export default Vue.directive('tooltip', {
  bind: (el, binding) => {
    state.activeTooltips.push(new Tooltip(el, binding.value))
  },
  update: (el, binding) => {
    state.activeTooltips.forEach(tooltip => {
      if (tooltip.activator === el) {
        tooltip.setProps(binding.value)
      }
    })
  },
  unbind: () => {
    state.activeTooltips.forEach(tooltip => tooltip.destroy())
  },
})