<template>
  <div class="fullscreen-layout">
    <header>
      <v-sheet class="fullscreen-layout__header">
        <v-container class="fullscreen-layout__header-container" fluid>
          <div class="fullscreen-layout__left">
            <app-button
              small
              large
              text
              class="mr-2"
              icon="left"
              @click="goToPreviousPage"
            />
            <h1 class="fullscreen-layout__title d-flex align-center">
              <slot name="title">
                {{ title }}
              </slot>
            </h1>
          </div>
          <div class="fullscreen-layout__actions text-right">
            <slot name="actions" />
          </div>
        </v-container>
      </v-sheet>
      <v-sheet
        v-if="$slots['sub-header']"
        class="fullscreen-layout__sub-header"
        :height="60"
        flat
      >
        <v-container class="fullscreen-layout__sub-header-container" fluid>
          <slot name="sub-header" />
        </v-container>
      </v-sheet>
      <n-divider :spacing="0" />
    </header>
    <app-page-layout v-bind="pageLayoutMixin_props">
      <template v-if="$scopedSlots['mobile-sub-header']" #mobile-sub-header="{ toggleSidebar }">
        <slot name="mobile-sub-header" :toggle-sidebar="toggleSidebar" />
      </template>
      <template #sidebar>
        <slot name="sidebar" />
      </template>
      <slot />
    </app-page-layout>
  </div>
</template>

<script>
import AppPageLayout from './AppPageLayout.vue'

import pageLayoutMixin from '@/layout/mixins/pageLayoutMixin'

/**
 * Layout à utiliser pour les pages sans navbar
 */
export default {
  name: 'FullscreenLayout',
  components: { AppPageLayout },
  mixins: [pageLayoutMixin],
  props: {
    /**
     * Le titre de la page
     * Est affiché en haut à gauche
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * Nom de la route vers laquelle rediriger quand on appuie sur le bouton de retour
     */
    backRouteName: {
      type: String,
      default: null,
    },
  },
  methods: {
    /**
     * Permet de revenir à la page précédente
     * Soit par le nom de la route back donnée
     * Soit en émettant l'evenement de cancel
     */
    goToPreviousPage () {
      if (this.backRouteName) {
        this.$router.push({ name: this.backRouteName })
      } else {
        this.$emit('goBack')
      }
    },
  },
}
</script>

<style lang="scss">
.fullscreen-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  &__header,
  &__sub-header {
    padding-left: map-get($spacers, 4);
    padding-right: map-get($spacers, 4);
    border-bottom: 1px solid;
    border-color: var(--v-divider-base) !important;
  }

  &__header-container,
  &__sub-header-container {
    padding: 0;
  }

  &__header {
    height: 60px;
    background-color: #fff;
    padding: 1em map-get($spacers, 4);
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }

  &__header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__sub-header-container {
    height: 100%;
  }

  &__left {
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-right: 8px;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include media-md {
      font-size: 20px;
    }
  }
}
</style>