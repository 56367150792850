import PrescriptionHealthProductLine from '@/modules/patient/models/prescriptionLine/prescriptionHealthProductLine/PrescriptionHealthProductLine'
export default class PrescriptionMedicineLine extends PrescriptionHealthProductLine {
  constructor (options = {}) {
    super(options)

    this.outOfMa = options.outOfMa || false
    this.durationDays = options.durationDays || null
  }

  toString () {
    const duration = this.durationDays ? ` pendant ${this.durationDays} jour(s)` : ''
    return `${this.getLabel()}${duration}`
  }
}