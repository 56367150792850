<template>
  <v-alert
    class="app-alert"
    :type="type"
    dense
    text
    data-test="app-alert"
  >
    <p class="app-alert__content mb-0 ml-1" data-test="app-alert-content">
      <slot>{{ message }}</slot>
    </p>
    <template #prepend>
      <app-icon :icon="type" :color="type" class="mr-2" />
    </template>
  </v-alert>
</template>

<script>
/**
 * Le wrapper d'une alerte de l'application
 * @see {@link https://vuetifyjs.com/en/components/alerts/}
 */
export default {
  name: 'AppAlert',
  props: {
    /**
     * Le type d'alerte à afficher
     * @values error, warning, success
     */
    type: {
      type: String,
      required: true,
      validator: type => ['error', 'warning', 'success', 'info'].includes(type),
    },
    /**
     * Le message à afficher
     * @default null
     */
    message: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.app-alert {
  margin: 0;
  border-radius: 6px !important;

  &__content {
    font-size: 13px;
    font-weight: 500 !important;
  }
}
</style>