import '@/modules/dataPortability/classes/DocumentEntity'

export default class DocumentEntityRelation {
  /**
   * Instance de l'entité de la relation
   */
  entityName

  /**
   * Nom du champ
   */
  fieldName

  /**
   *
   * @param {String} entityName Le nom de l'entité
   * @param {String} fieldName Le nom du champ de la relation
   */
  constructor (entityName, fieldName) {
    if (! entityName) {
      throw new Error('Le nom de l\'entité de la relation doit être fourni')
    }

    if (! fieldName) {
      throw new Error('Le nom du champ de la relation doit être fourni')
    }

    this.entityName = entityName
    this.fieldName = fieldName
  }
}