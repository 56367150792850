<template>
  <div class="medical-history-item-skeleton">
    <div class="medical-history-item-skeleton__headline mb-3">
      <app-skeleton-loader type="text" width="80" />
      <app-skeleton-loader type="text" width="50" />
      <app-skeleton-loader type="text" width="100" />
    </div>
    <app-panel dense>
      <template #title>
        <div class="medical-history-item-skeleton__headline">
          <app-skeleton-loader type="avatar" width="16" height="16" />
          <app-skeleton-loader type="text" width="50%" />
          <app-skeleton-loader
            type="avatar"
            width="20"
            height="20"
            class="ml-2"
          />
        </div>
      </template>
      <div class="mb-3">
        <app-skeleton-loader type="text" width="100" class="mb-1" />
        <app-skeleton-loader type="text" width="75%" />
      </div>
      <div>
        <app-skeleton-loader type="text" width="100" class="mb-1" />
        <app-skeleton-loader type="text" width="75%" />
      </div>
      <n-divider :spacing="3" class="mx-n3" />
      <div class="medical-history-item-skeleton__headline">
        <app-skeleton-loader type="avatar" width="10" height="10" />
        <app-skeleton-loader type="text" width="120" />
      </div>
    </app-panel>
    <div class="medical-history-item-skeleton__headline mt-3">
      <app-skeleton-loader type="text" width="30%" />
    </div>
  </div>
</template>

<script>
import AppPanel from '@/components/ui/panel/AppPanel.vue'
import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue'

export default {
  name: 'MedicalHistoryItemSkeleton',
  components: {
    AppSkeletonLoader,
    AppPanel,
  },
}
</script>

<style lang="scss" scoped>
.medical-history-item-skeleton {
  ::v-deep {
    .panel__title {
      width: 100%;
    }
    .v-skeleton-loader__text {
      margin-bottom: 0;
    }
    .v-skeleton-loader__avatar {
      width: 100%;
      height: 100%;
    }
  }

  &__headline {
    display: flex;
    align-items: center;
    gap: map-get($spacers, 3);
    width: 100%;
  }
}
</style>