import PatientAntecedent from './PatientAntecedent'

import { ANTECEDENT_TYPES } from '@/modules/patient/constants'

export default class PatientFamilyAntecedent extends PatientAntecedent {
  constructor (options = {}) {
    super(options)
    this.type = ANTECEDENT_TYPES.FAMILY.value
    this.familyRelationship = options.familyRelationship || null
  }
}