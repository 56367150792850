<template>
  <app-row class="d-flex align-end">
    <app-col cols="12" md="4">
      <patient-keyword-selector v-model="selectedKeywords" multiple />
    </app-col>
    <app-col cols="12" md="4">
      <patient-pathology-autocomplete v-model="selectedPathologies" :append-icon="null" multiple />
    </app-col>
  </app-row>
</template>

<script>
import PatientKeywordSelector from '@/modules/patient/components/keywords/components/PatientKeywordSelector.vue'
import PatientPathologyAutocomplete from '@/modules/patient/components/PatientPathologyAutocomplete.vue'

import PatientKeyword from '@/modules/patient/models/PatientKeyword'
import PatientPathology from '@/modules/patient/models/riskFactors/Pathology'

export default {
  name: 'HealthSummaryRemindersPanelFilters',
  components: {
    PatientKeywordSelector,
    PatientPathologyAutocomplete,
  },
  props: {
    keywords: {
      type: Array,
      default: null,
      validator: keywords => keywords.every(keyword => keyword instanceof PatientKeyword),
    },
    pathologies: {
      type: Array,
      default: null,
      validator: pathologies => pathologies.every(pathology => pathology instanceof PatientPathology),
    },
  },
  computed: {
    selectedKeywords: {
      get () {
        return this.keywords
      },
      set (selectedKeywords) {
        this.$emit('update:keywords', selectedKeywords)
      },
    },
    selectedPathologies: {
      get () {
        return this.pathologies
      },
      set (selectedPathologies) {
        this.$emit('update:pathologies', selectedPathologies)
      },
    },
  },
}
</script>