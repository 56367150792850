<template>
  <validation-provider
    v-slot="{ errors }"
    ref="validationProvider"
    tag="div"
    :name="label"
    :class="fieldClassList"
    class="synapse-autocomplete-wrapper"
    :rules="rules"
  >
    <div class="flex-list-3">
      <app-icon v-if="appendIcon" class="synapse-autocomplete-wrapper__icon" :icon="appendIcon" />
      <section v-click-outside="handleClickOutside" class="synapse-autocomplete-wrapper__field" @mousedown="focus">
        <label v-if="displayLabel" class="synapse-autocomplete-wrapper__field__label">{{ displayedLabel }}</label>
        <span class="synapse-autocomplete-wrapper__field__selection">
          <template v-if="multiple">
            <span
              v-for="(entity, index) in selection"
              :key="index"
              class="synapse-autocomplete-wrapper__field__selection__item"
            >
              <app-tag
                v-test="'entity-multiple-item'"
                color="secondary"
                small
                closable
                @click:close="unselect(entity)"
              >{{ getItemText(entity) }}</app-tag>
            </span>
          </template>
          <span v-else-if="hasSelection">{{ getItemText(selection) }}</span>
          <!-- On affiche conditionnellement le widget de façon à simuler le clear de son input sans avoir à modifier sa valeur interne -->
          <component
            :is="synapseInputWidget"
            v-if="showSynapseInput"
            ref="synapse-input"
            v-bind="{ ...$props, ...$attrs }"
            class="synapse-autocomplete-wrapper__field__input"
            :class="{ 'synapse-autocomplete-wrapper__field__input--mobile': isMobile }"
            @input="handleSelect"
            @update:search-input="searchInput => hasSearchValue = !! searchInput"
            @keydown.native.delete="handleDelete"
            @focus-input="handleFocus"
            @add-favorite="$emit('add-favorite', $event)"
            @delete-favorite="$emit('delete-favorite', $event)"
          />
        </span>
        <app-icon
          v-if="hasSelection"
          v-test="'clear-button'"
          icon="close"
          class="synapse-autocomplete-wrapper__field__clear-icon"
          @click="clear()"
        />
      </section>
    </div>
    <p v-if="errors" class="synapse-autocomplete-wrapper__error-message">
      {{ errors.join(' ') }}
    </p>
  </validation-provider>
</template>

<script>
import AppTag from '@/components/ui/tag/AppTag.vue'

import { mapState } from 'vuex'

import { ValidationProvider, validate } from 'vee-validate'
import { getFormattedFieldLabel } from '@/utils/functions/fields'

/**
 * Composant permettant d'afficher un autocomplete basé sur un widget de Synapse
 * le widget servira alors d'input et ce composant de surcouche de façon à
 * le rendre quasi indissociable avec les champs de sélection de l'application
 */
export default {
  name: 'SynapseAutocompleteWrapper',
  components: {
    AppTag,
    ValidationProvider,
  },
  props: {
    /**
     * Le widget à utiliser comme input.
     * Doit être un widget d’autocomplétion comme
     * par exemple le widget MedicationAutocomplete au PathologyAutocomplete
     */
    synapseInputWidget: {
      type: Object,
      required: true,
    },
    value: {
      type: [Object, Array],
      default: null,
    },
    rules: {
      type: [String, Array, Object],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: String,
      default: null,
    },
    itemValue: {
      type: [String, Function],
      default: 'value',
    },
    itemText: {
      type: [String, Function],
      default: 'label',
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isFocused: false,
      hasSearchValue: false,
      currentValidationResult: null,
    }
  },
  computed: {
    ...mapState('app', ['isMobile']),
    selection () {
      return this.value
    },
    displayedLabel () {
      return getFormattedFieldLabel(this.label, this.rules)
    },
    hasSelection () {
      if (this.multiple) {
        return !! this.selection && this.selection.length > 0
      }
      return !! this.selection && this.getItemValue(this.selection)
    },
    hasErrors () {
      return this.currentValidationResult?.errors.length > 0
    },
    isActive () {
      return this.hasSearchValue || this.hasSelection
    },
    showSynapseInput () {
      if (this.multiple) {
        return this.isFocused
      }
      return this.isFocused && ! this.hasSelection
    },
    fieldClassList () {
      return {
        'synapse-autocomplete-wrapper--active': this.isActive,
        'synapse-autocomplete-wrapper--focused': this.isFocused,
        'synapse-autocomplete-wrapper--has-search-input': this.hasSearchValue,
        'synapse-autocomplete-wrapper--has-errors': this.hasErrors,
        'synapse-autocomplete-wrapper--has-append-icon': !! this.appendIcon,
      }
    },
    displayLabel () {
      return ! (this.showSynapseInput && this.hideLabel)
    },
  },
  watch: {
    selection: {
      immediate: true,
      handler (newVal) {
        if (newVal && newVal.label) {
          this.validateSelection()
        }
      },
    },
    showSynapseInput (showSynapseInput) {
      if (! showSynapseInput) {
        this.isFocused = false
      }
    },
    currentValidationResult (validationResult) {
      this.$refs.validationProvider.applyResult(validationResult)
    },
  },
  methods: {
    async validateSelection (entity = null) {
      const validatingEntity = entity ?? this.selection
      this.currentValidationResult = await validate(
        this.multiple ? validatingEntity : this.getItemValue(validatingEntity || {}),
        this.rules,
      )
    },
    getItemText (item) {
      if (typeof this.itemText === 'function') {
        return this.itemText(item)
      }
      return item[this.itemText]
    },
    getItemValue (item) {
      if (typeof this.itemValue === 'function') {
        return this.itemValue(item)
      }
      return item[this.itemValue]
    },
    select (entity) {
      if (this.hasSelection) {
        const isAlreadySelected = this.multiple
          ? this.selection.some(_entity => this.getItemValue(_entity) === this.getItemValue(entity))
          : this.getItemValue(this.selection) === this.getItemValue(entity)
        if (isAlreadySelected) {
          return
        }
      }
      this.$emit('input', this.multiple ? [...this.selection, entity] : entity)
      this.validateSelection(entity)
    },
    unselect (entity) {
      this.$emit('input', this.selection.filter(({ icd10Code }) => icd10Code !== entity.icd10Code))
      this.focus()
    },
    async focus (event) {
      this.isFocused = true

      // On ne déclenche pas le focus dans le champ de recherche synapse
      // si l'élément cliqué est un résultat de recherche ou une ligne "Voir plus de..." ou l'icône favori
      if (! event || (event.target.getAttribute('role') !== 'option' && ! event.target.innerText?.startsWith('Voir plus'))) {
        await this.$nextTick()
        requestAnimationFrame(() => this.$refs['synapse-input']?.focus())
      }
    },
    blur () {
      this.isFocused = false
      this.hasSearchValue = false
      this.validateSelection()
    },
    clear () {
      if (this.multiple) {
        this.$emit('input', [])
        return
      }
      this.$emit('input', null)
      this.validateSelection({})
    },
    resetValidation () {
      this.currentValidationResult = {
        errors: [],
        valid: true,
      }
    },
    handleDelete () {
      if (! this.hasSearchValue && this.hasSelection) {
        this.$emit('input', this.multiple ? this.selection.slice(0, this.selection.length - 1) : null)
      }
    },
    handleSelect (entity) {
      this.select(entity)
    },
    handleClickOutside () {
      if (this.isFocused) {
        this.blur()
      }
    },
    handleFocus (event) {
      event ? this.focus() : this.blur()
    },
  },
}
</script>

<style lang="scss" scoped>
$transition-property: .3s cubic-bezier(0.25, 0.8, 0.5, 1);
$menu-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
$field-height: 32px;

.synapse-autocomplete-wrapper {
  color: var(--v-content-base);

  ::v-deep {
    .v-icon {
      color: inherit;
    }
  }

  &--has-errors {
    color: var(--v-error-base);
  }
  &--focused:not(&--has-errors) {
    color: var(--v-primary-base);
  }
  &--focused {
    .synapse-autocomplete-wrapper {
      &__field {
        &:after {
          transform: scale3d(1, 1, 1) !important;
        }
        &__input {
          min-width: 64px;
        }
        &__clear-icon {
          opacity: 1;
        }
      }
    }
  }

  &--active &__field__label,
  &--focused &__field__label {
    top: 0;
    transform: translateY(-8px) scale(0.75);
    opacity: 1;
  }

  &--active {
    ::v-deep {
      input::placeholder {
        visibility: hidden;
      }
    }
  }

  &--has-errors &__field {
    margin-bottom: map-get($spacers, 2);
  }
  &--has-errors &__field__label {
    opacity: 1;
  }

  &--has-append-icon &__error-message {
    margin-left: map-get($spacers, 7);
  }

  &__icon {
    display: flex;
    align-items: center;
    color: currentColor;
    min-height: $field-height;
    transition: $transition-property;
  }

  &__field {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 0;
    min-width: 0;
    width: 100%;
    cursor: text;
    line-height: 16px;
    min-height: $field-height;
    transition: margin-bottom $transition-property;

    &:hover &__clear-icon {
      opacity: 1;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      background-color: currentColor;
    }

    &:before {
      height: 1px;
      opacity: .3;
    }

    &:after {
      height: 2px;
      transform: scale3d(0, 1, 1);
      transform-origin: center;
      transition: $transition-property;
    }

    &__label {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      transform-origin: top left;
      z-index: 5;
      pointer-events: none;
      transition: $transition-property;
      opacity: 0.6;
      color: currentColor;
    }

    &__selection {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: map-get($spacers, 1);
      width: 100%;
      padding: map-get($spacers, 2) 0;
      color: var(--v-content-base);

      &__item {
        overflow: hidden;
        max-width: 90%;
      }
    }

    &__input {
      flex: 1 1;
      min-width: 0;
      width: 100%;

      ::v-deep {
        .synapse-app input {
          padding: 0 !important;
          line-height: 16px;
          color: var(--v-content-base);
          font-size: 12px;

          &::placeholder {
            opacity: .6;
            color: var(--v-content-base);
          }
        }

        .synapse-app .component-search,
        .synapse-app .component-researchBarComposite {
          position: unset !important;
          color: var(--v-content-base);
          font-family: 'Poppins' !important;

          .component-input {
            margin-top: 0;

            .input-wrapper {
              border: none;
              padding: 0;
              height: auto;
              line-height: 16px;

              .before-props,
              .after-props {
                display: none;
              }
            }
          }
          .options {
            z-index: 10;
            width: 100%;
            border: none;
            font-size: 13px;
            box-shadow: $menu-shadow;
            overflow-y: auto;
            overflow-x: hidden;
            border-radius: 0 0 3px 3px;
            padding: map-get($spacers, 2) 0;
            cursor: default;

            .option {
              position: relative;
              background-color: transparent;
              cursor: pointer;
              min-height: 48px;
              padding: map-get($spacers, 2) map-get($spacers, 4) !important;
              display: flex;
              align-items: center;

              &:hover,
              &:focus {
                background-color: transparent;

                &:before {
                  opacity: 0.05;
                }
              }

              &:active:before {
                opacity: 0.2;
              }

              &:before {
                content: '';
                display: block !important;
                position: absolute;
                top: 0;
                left: 0;
                background-color: var(--v-content-base);
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: 0;
                transition: opacity .125s ease;
                border-radius: inherit
              }
            }
          }
        }

        // Nouveau Design System Synapse
        fieldset input {
          font-family: 'Poppins' !important;
          color: var(--v-content-base);
          font-size: 12px;

          &::placeholder {
            opacity: .6;
            color: var(--v-content-base);
          }
        }
        .autocomplete-dropdown {
          z-index: 10;
          min-width: 100%;
          max-height: 300px;
          border: none;
          box-shadow: $menu-shadow;
          overflow-y: auto;
          overflow-x: hidden !important;
          border-radius: 0 0 3px 3px;
          font-family: 'Poppins' !important;
          background-color: white !important;
          margin-top: 8px !important;

          .autocomplete-line-container {
            min-height: 48px;

            .autocomplete-line > div > span,
            .autocomplete-line > div > span > span {
              font-size: 12px;
            }
          }
        }
        // Fin Nouveau Design System Synapse
      }
    }

    &__clear-icon {
      display: flex;
      justify-content: center;
      width: 24px;
      opacity: 0;
      transition: $transition-property;
    }
  }

  &__error-message {
    color: var(--v-error-base);
    font-size: 12px;
    line-height: 12px;
    word-break: break-word;
  }
}
</style>