<template>
  <app-col
    cols="12"
    md="6"
    lg="4"
    class="mb-n5"
  >
    <app-autocomplete
      ref="field"
      v-test="'ins-identity-status-field'"
      :value="value"
      name="insIdentity.status"
      item-value="value"
      item-text="label"
      :items="insStatuses"
      :disabled="autoSelectStatus && isInsStatusFieldDisabled"
      label="Statut"
      hide-errors="auto"
      :rules="fieldRules"
      @input="value => $emit('input', value)"
    />
  </app-col>
</template>

<script>
import AppAutocomplete from '@/components/ui/form/AppAutocomplete.vue'

import { getAvailableInsStatusesFromHasIns } from '@/modules/patient/utils/ins'

import InsIdentityDevice from '@/modules/patient/models/InsIdentityDevice'

export default {
  name: 'IdentityStatusField',
  components: { AppAutocomplete },
  props: {
    value: {
      type: String,
      default: null,
    },
    hasIns: {
      type: Boolean,
      default: false,
    },
    isInsStatusFieldDisabled: {
      type: Boolean,
      default: false,
    },
    autoSelectStatus: {
      type: Boolean,
      default: false,
    },
    currentIdentityDevice: {
      type: InsIdentityDevice,
      default: null,
    },
  },
  computed: {
    insStatuses () {
      return getAvailableInsStatusesFromHasIns(this.hasIns)
    },
    fieldRules () {
      if (this.autoSelectStatus) {
        return {}
      }
      return { valid_ins_status: { device: this.currentIdentityDevice } }
    },
  },
  watch: {
    // La validation étant lié au modifications du champ statut
    // Permet de forcer la validation lors d'une modification du champ lié au dispositif
    'currentIdentityDevice.strongTrustLevel' () {
      this.$refs.field.validate()
    },
  },
}
</script>