<template>
  <app-text-field
    v-if="isEditing"
    v-model.trim="fileNameEditing"
    dense
    name="title"
    filled
    autofocus
    hide-errors="auto"
    style="width: 100%"
    @focusout="saveFileNameEditing"
    @keydown.enter="$event.target.blur()"
  />
  <div v-else class="d-flex justify-space-between" @click="openViewer()">
    <span>{{ file.title }}</span>
    <span class="text--secondary">{{ getFormattedDate(file.createdAt) }}
      <app-icon
        :size="14"
        icon="info"
        color="grey"
        :tooltip="`Créé le ${getFormattedDate(file.createdAt)}, modifié le ${getFormattedDate(file.updatedAt)} `"
      />
    </span>
  </div>
</template>

<script>

import AppTextField from '@/components/ui/form/AppTextField.vue'
import PatientDocument from '@/modules/patient/models/PatientDocument'
import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'FileItem',
  components: { AppTextField },
  props: {
    file: {
      type: PatientDocument,
      required: true,
    },
    patientIri: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      isEditing: false,
      fileNameEditing: null,
    }
  },
  watch: {
    'file.editing': {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.startEditing()
        }
      },
    },
  },
  methods: {
    async saveFileNameEditing () {
      try {
        if (this.file.title !== this.fileNameEditing) {
          await PatientDocument.update({
            ...this.file,
            patient: this.patientIri,
            title: this.fileNameEditing,
          })
        }
        this.$emit('updated')
        this.isEditing = false
      } catch (e) {
        NovaTools.notify.error(e.response.data.violations[0].message)
      }
    },
    async openViewer () {
      NovaTools.files.openDocumentViewer(this.file)
    },
    startEditing () {
      this.fileNameEditing = this.file.title
      this.isEditing = true
    },
    getFormattedDate (date) {
      return NovaTools.dates.format(date, 'dd/MM/yyyy à H:mm')
    },
  },
}
</script>