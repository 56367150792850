<template>
  <p>
    <span v-test="'birth-place-code'" :class="codeClasses">{{ birthPlace.value }}</span>
    <span v-test="'birth-place-label'" :class="labelClasses">{{ displayedLabel }}</span>
  </p>
</template>

<script>
import { getBirthPlaceFullLabel } from '@/modules/patient/utils/getBirthPlaceFullLabel'

import InseeGeographicCodeRef from '@/modules/patient/models/InseeGeographicCodeRef'

export default {
  name: 'BirthPlaceFieldItem',
  props: {
    birthPlace: {
      type: InseeGeographicCodeRef,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    displayedLabel () {
      return getBirthPlaceFullLabel(this.birthPlace.value, this.birthPlace.label)
    },
    codeClasses () {
      return this.disabled ? 'mr-2 birth-place-field-item__disabled-labels' : 'mr-2'
    },
    labelClasses () {
      return this.disabled ? 'birth-place-field-item__disabled-labels' : 'secondary--text'
    },
  },
}
</script>

<style lang="scss" scoped>
.birth-place-field-item {
  &__disabled-labels {
    color: rgba(55, 70, 122, 0.38);
  }
}
</style>