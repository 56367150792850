<template>
  <section class="app-actions-menu">
    <app-button
      v-for="(action, actionIndex) in visibleActions"
      :key="actionIndex"
      v-test="'action-button'"
      :label="action.disabled || disabled ? action.disabledTooltip : action.text"
      :icon="action.icon"
      :small="small"
      :disabled="disabled || action.disabled"
      :loading="action.loading"
      @click="handleIconActionClick(action)"
    />
    <v-menu
      v-if="menuActions.length"
      v-model="isMenuOpen"
      v-test="'actions-menu'"
      offset-y
      :disabled="disabled"
      :left="position === 'left'"
      :top="position === 'top'"
      :bottom="position === 'bottom'"
      :right="position === 'right'"
      content-class="rounded-lg"
      transition="slide-y-transition"
      :min-width="menuWidth"
      :close-on-content-click="false"
    >
      <template #activator="{ on }">
        <slot name="activator" :on="on">
          <app-button
            v-test="'actions-menu-activator'"
            icon="menu"
            :small="small"
            :disabled="disabled"
            @click.native="on.click"
          />
        </slot>
      </template>
      <n-list
        class="app-actions-menu__menu"
        :items="actionMenuSections"
        divided
        :spacing="2"
      >
        <template #item="{ item }">
          <div v-for="(action, actionIndex) in item" :key="actionIndex">
            <app-actions-menu-item-expandable-group v-if="action.children" :action="action" @click:action="closeMenu" />
            <app-actions-menu-item v-else :action="action" @click="closeMenu" />
          </div>
        </template>
      </n-list>
    </v-menu>
  </section>
</template>

<script>
import AppActionsMenuItem from '@/components/ui/actionsMenu/AppActionsMenuItem.vue'
import AppActionsMenuItemExpandableGroup from '@/components/ui/actionsMenu/AppActionsMenuItemExpandableGroup.vue'

import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'

import { filterDeep } from '@/utils/functions/arrays'
import { flattenDeep } from 'lodash'

const ACTIONS_TEXTS = Object.freeze({
  edit: 'Modifier',
  delete: 'Supprimer',
})

export default {
  name: 'AppActionsMenu',
  components: {
    AppActionsMenuItem,
    AppActionsMenuItemExpandableGroup,
  },
  props: {
    /**
     * Les actions à afficher dans le menu
     * Sous la forme { clé: ActionMenuItem, ... } ou ActionMenuItem[]
     */
    actions: {
      type: [Object, Array],
      required: true,
    },
    /**
     * Permet de définir une position
     * @values top , right, bottom, left
     */
    position: {
      type: String,
      default: 'left',
    },
    /**
     * Réduit la taille du menu d'action
     */
    small: {
      type: Boolean,
      default: false,
    },
    /**
     * Désactive le menu d'action
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Placera les actions débordantes dans un menu d'action
     */
    maxActionsVisible: {
      type: Number,
      default: 2,
    },
    menuWidth: {
      type: [String, Number],
      default: null,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return { isMenuOpen: false }
  },
  computed: {
    /**
     * Permet d'obtenir un tableau d'action, quelque soit le type de la prop "actions"
     * @returns {Array}
     */
    normalizedActions () {
      if (Array.isArray(this.actions)) {
        return this.actions
      }
      return Object.keys(this.actions).map(actionKey => {
        const action = this.actions[actionKey]
        const actionText = action.text || ACTIONS_TEXTS[actionKey]
        const actionIcon = action.icon || actionKey
        const actionCallback = typeof action === 'function' ? action : action.callback
        return new ActionMenuItem(actionIcon, actionText, actionCallback, {
          disabled: action.disabled,
          disabledTooltip: action.disabledTooltip,
          loading: action.loading,
          important: action.important || actionKey === 'delete',
        })
      })
    },
    flattenActions () {
      return flattenDeep(this.normalizedActions)
    },
    visibleActions () {
      if (this.maxActionsVisible === 0) { // pas d'action visible donc on retourne aucune option
        return []
      }
      // On retire 1 action pour prendre en compte l'icone qui affiche le menu (qui prend un slot d'affichage)
      const visibleActionsCount = this.flattenActions.length === this.maxActionsVisible ? this.flattenActions.length : this.maxActionsVisible - 1
      return this.flattenActions.slice(0, visibleActionsCount)
    },
    menuActions () {
      return filterDeep(this.normalizedActions, action => ! this.visibleActions.includes(action) && ! action.important)
    },
    actionMenuSections () {
      const defaultSections = this.menuActions.reduce((acc, action, index, original) => {
        if (Array.isArray(original[index])) {
          return [...acc, action]
        }
        if (Array.isArray(acc[acc.length - 1]) && ! Array.isArray(original[index - 1])) {
          return [
            ...acc.slice(0, acc.length - 1),
            acc[acc.length - 1].concat(action),
          ]
        }
        return [...acc, [action]]
      }, [])
      const importantSections = flattenDeep(filterDeep(this.normalizedActions, item => item.important))
      return defaultSections.concat(new Array(importantSections)).filter(section => section.length)
    },
  },
  watch: {
    isMenuOpen (isOpen) {
      this.$emit('update:is-open', isOpen)
    },
  },
  methods: {
    handleIconActionClick (action) {
      action.callback()
      this.closeMenu()
    },
    closeMenu () {
      this.isMenuOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
$list-white-space: map-get($spacers, 4);

.app-actions-menu {
  display: flex;
  align-items: center;

  &__menu {
    background-color: white;
    padding: ($list-white-space / 2) 0;

    ::v-deep {
      .n-divider {
        margin: 0 $list-white-space;
      }
    }
  }
}
</style>