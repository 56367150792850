import Contact from './Contact'
import ApiEntity from '@/models/ApiEntity'

export default class Correspondent extends ApiEntity {
  constructor (options = {}) {
    super(options)

    this.category = options.category || null
    this.contact = new Contact(options.contact || {})
    this.note = options.note || null
  }
}