<template>
  <app-panel title="VSM à régénérer">
    <health-summary-reminders-panel-filters
      class="mb-4"
      :keywords="keywordsFilter"
      :pathologies="pathologiesFilter"
      @update:keywords="keywords => $emit('update:keywords-filter', keywords)"
      @update:pathologies="pathologies => $emit('update:pathologies-filter', pathologies)"
    />
    <app-data-table
      :loading="loading"
      :headers="headers"
      :items="healthSummaryReminders"
      pageable
    >
      <template #[`item.patient`]="{ item }">
        <patient-details
          small
          :patient="item.patient"
          :show-pictograms="false"
          redirect-to-patient-file
          :show-anonymized-identifier="false"
          :show-keywords="false"
          show-birth-informations
        />
      </template>
      <template #[`item.keywords`]="{ item }">
        <patient-keywords-list v-if="item.patient.keywords.length" :patient="item.patient" :keywords="item.patient.keywords" />
      </template>
    </app-data-table>
  </app-panel>
</template>

<script>
import AppDataTable from '@/components/ui/tables/AppDataTable.vue'
import AppPanel from '@/components/ui/panel/AppPanel.vue'
import PatientDetails from '@/modules/patient/components/PatientDetails.vue'
import PatientKeywordsList from '@/modules/patient/components/keywords/components/PatientKeywordsList.vue'
import HealthSummaryRemindersPanelFilters from '@/modules/users/components/dashboard/healthSummary/HealthSummaryRemindersPanelFilters.vue'

import { format } from '@/utils/functions/dates'

import HealthSummaryReminder from '@/modules/patient/components/vsm/models/HealthSummaryReminder'
import PatientKeyword from '@/modules/patient/models/PatientKeyword'
import PatientPathology from '@/modules/patient/models/riskFactors/Pathology.js'

export default {
  name: 'HealthSummaryRemindersPanel',
  components: {
    AppPanel,
    AppDataTable,
    PatientDetails,
    PatientKeywordsList,
    HealthSummaryRemindersPanelFilters,
  },
  props: {
    healthSummaryReminders: {
      type: Array,
      default: () => [],
      validator: healthSummaryReminders => healthSummaryReminders.every(healthSummaryReminder => healthSummaryReminder instanceof HealthSummaryReminder),
    },
    keywordsFilter: {
      type: Array,
      default: null,
      validator: keywords => keywords.every(keyword => keyword instanceof PatientKeyword),
    },
    pathologiesFilter: {
      type: Array,
      default: null,
      validator: pathologies => pathologies.every(pathology => pathology instanceof PatientPathology),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      headers: [
        {
          text: 'Patient',
          value: 'patient',
        },
        {
          text: 'Pathologies',
          value: 'pathologies',
          valueGetter: healthSummaryReminder => healthSummaryReminder.patientPathologies.map(pathology => pathology.getLabel()).join(', '),
        },
        {
          text: 'Mots-clefs',
          value: 'keywords',
        },
        {
          text: 'À régénérer depuis le',
          value: 'reminderDate',
          cellClass: 'secondary--text',
          valueGetter: healthSummaryReminder => format(healthSummaryReminder.reminderDate, 'dd/MM/yyyy'),
        },
      ],
    }
  },
}
</script>