import { debounce, camelCase } from 'lodash'

const DEBOUNCE_DELAY = 300

/**
 * Permet de retourner le nom du filtre en camelCase suivi de 'Filter'
 * Cela permet de prendre en compte les filtres imbriqués natif d'API Platform (ex: 'insIdentity.ins' deviendra 'insIdentityInsFilter')
 * @param {Filter} filter
 * @returns {String}
 */
const getFilterName = (filter) => `${camelCase(filter.name)}Filter`

/**
 * Permet d'automatiser la génération des computed
 * avec une méthode get et set
 * @param {Array<Filter>} filters Le tableau des filtres préinitialisés
 * @returns {Object} L'objet contenant les filtres triés par nom
 */
const mapComputedFilters = (filters) => {
  return filters.reduce((mappedByNameFilters, filter) => {
    mappedByNameFilters[getFilterName(filter)] = () => {
      return filter
    }

    return mappedByNameFilters
  }, {})
}

/**
 * Permet d'automatiser la génération des watchs
 * Pour chaque computed qui a été généré
 * @param {Array<Filter>} filters Le tableau des filtres préinitialisés
 * @returns {Object} L'objet contenant les filtres triés par nom
 */
const mapWatchedFilters = (filters) => {
  return filters.reduce((mappedByNameFilters, filter) => {
    // Pilote la mise à jour du filtre en fonction de la valeur du composant
    mappedByNameFilters[`${getFilterName(filter)}.componentValue`] = {
      immediate: true,
      handler () {
        // On évite de debounce lorsque la valeur du composant est à null (pas d'attente au clear)
        if (filter.debounce && filter.componentValue) {
          this.isDebouncedUpdateFilterPending = true
          this.debouncedUpdateFilter(filter, this)
        } else {
          if (this.isDebouncedUpdateFilterPending) {
            this.debouncedUpdateFilter.cancel()
          }
          this.updateFilter(filter, this)
        }
      },
    }
    return mappedByNameFilters
  }, {})
}

export default (filters, options = {}) => ({
  data () {
    return {
      filters,
      isDebouncedUpdateFilterPending: false,
      /**
       * Version débouncée de updateFilter
       * Est définie dans les data pour éviter d'être bindée et de perdre l'accès aux propriétés du debounce (cancel ou flush)
       * @see {@link https://stackoverflow.com/a/52988020/12029358}
       */
      debouncedUpdateFilter: debounce(function (filter, vm) {
        this.updateFilter(filter, vm)
        this.isDebouncedUpdateFilterPending = false
      }, options.debounceDelay || DEBOUNCE_DELAY),
    }
  },
  computed: { ...mapComputedFilters(filters) },
  watch: { ...mapWatchedFilters(filters) },
  methods: {
    clearFilter (filter) {
      if (! filter.parent) {
        filter.componentValue = null
        return
      }
      filter.parent.componentValue = filter.parent.componentValue.filter(parentValue => {
        return filter.value !== filter.valueGetter(parentValue)
      })
    },

    getFilters () {
      return filters
    },

    /**
     * Permet d'effectuer un traitement lors d'un changement
     * de valeur du componentValue d'un filtre
     * @param {Filter} filter Filtre à faire remonter
     * @param {Object} vm Le composant (l'équivalent de "this" dans un composant VueJS)
     */
    updateFilter (filter, vm) {
      if (filter.onComponentValueChanged) {
        filter.onComponentValueChanged(vm)
        this.$emit('change:filtering', filters)
      }
    },

    /**
     * Permet de réinitialiser les filtres
     */
    clearFilters () {
      filters.forEach(filter => this.clearFilter(filter))
    },
  },
})