import SecureMessagingModule from '@/modules/secureMessaging/SecureMessagingModule.vue'
import AuthenticationServiceInstance from '@/modules/secureMessaging/modules/authentication/services/AuthenticationService'

import { ROUTE_NAMES } from '@/modules/secureMessaging/constants'

import axios from 'axios'

/**
 * Permet de fournir de manière automatique le jSessionId ainsi que l'adresse mss de l'utilisateur connecté
 * à l'ensemble des requêtes de l'api pour la MSS,
 * excepté pour celles ne dépendant pas d'un utilisateur deja connecté (par exemple l'envoi de l'OTP)
 */
axios.interceptors.request.use(config => {
  if (config.url.startsWith('/api/mss') && AuthenticationServiceInstance.isAuthenticated) {
    config.headers = {
      ...config.headers,
      jSessionId: AuthenticationServiceInstance.authenticationPayload.jSessionId,
    }
    config.params = {
      ...config.params,
      mssEmail: AuthenticationServiceInstance.authenticationPayload.mssEmail,
    }
  }
  return config
})

export default {
  path: '/messagerie',
  component: SecureMessagingModule,
  name: ROUTE_NAMES.ROOT,
  children: [
    {
      path: 'authentification',
      name: ROUTE_NAMES.AUTHENTICATION,
      component: () => import('@/modules/secureMessaging/modules/authentication/views/SecureMessagingAuthentication.vue'),
    },
    {
      path: ':folderName',
      name: ROUTE_NAMES.FOLDER,
      component: () => import('@/modules/secureMessaging/modules/messaging/MessagingModule.vue'),
    },
  ],
}