<template>
  <v-col
    :cols="cols"
    :sm="sm"
    :md="md"
    :lg="lg"
    :xl="xl"
  >
    <slot />
  </v-col>
</template>

<script>
/**
 * Colonne de la grille de l'application
 * @see {@link https://vuetifyjs.com/en/components/grids/}
 */
export default {
  name: 'AppCol',
  props: {
    /**
     * Représente la taille de la colonne par rapport à une grille de 12 colonnes
     * @see {@link https://vuetifyjs.com/en/api/v-col/#props-col}
     */
    cols: {
      type: String,
      default: null,
    },
    /**
     * Représente la taille de la colonne à partir du breakpoint sm
     * @see {@link https://vuetifyjs.com/en/api/v-col/#props-sm}
     */
    sm: {
      type: String,
      default: null,
    },
    /**
     * Représente la taille de la colonne à partir du breakpoint md
     * @see {@link https://vuetifyjs.com/en/api/v-col/#props-md}
     */
    md: {
      type: String,
      default: null,
    },
    /**
     * Représente la taille de la colonne à partir du breakpoint lg
     * @see {@link https://vuetifyjs.com/en/api/v-col/#props-lg}
     */
    lg: {
      type: String,
      default: null,
    },
    /**
     * Représente la taille de la colonne à partir du breakpoint xl
     * @see {@link https://vuetifyjs.com/en/api/v-col/#props-xl}
     */
    xl: {
      type: String,
      default: null,
    },
  },
}
</script>