/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity'
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField'

const identityDeviceRefEntity = new DocumentEntity('IdentityDeviceRef', 'identityDeviceRefs.xml', 'Contient les dispositifs INS')

identityDeviceRefEntity.addField(new DocumentEntityField('value', 'Le nom du dispositif'))
identityDeviceRefEntity.addField(new DocumentEntityField('strongTrustLevel', 'Est un dispositif à haut niveau de confiance'))

export default identityDeviceRefEntity