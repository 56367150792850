import { CONTACT_POINT_LABELS } from '@/constants/contactPointLabels'
import { MEDIA_TYPES } from '@/constants/mediaTypes'

export const CONTACT_POINTS = Object.freeze({
  MOBILE: {
    media: MEDIA_TYPES.TELEPHONE,
    label: CONTACT_POINT_LABELS.MOBILE,
  },
  OTHER_PHONE: {
    media: MEDIA_TYPES.TELEPHONE,
    label: CONTACT_POINT_LABELS.OTHER,
  },
  MAIL: { media: MEDIA_TYPES.EMAIL },
  MSS: {
    media: MEDIA_TYPES.EMAIL,
    label: CONTACT_POINT_LABELS.MSS,
  },
})