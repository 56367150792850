<template>
  <router-link
    active-class="active"
    class="app-navigation-panel-link"
    v-bind="$attrs"
    custom
  >
    <slot />
  </router-link>
</template>

<script>
/**
 * Représente le lien d'un panneau de navigation
 */
export default { name: 'AppMenuPanelLink' }
</script>

<style lang="scss" scoped>
.app-navigation-panel-link {
  font-weight: 400;
  text-decoration: none;

  &.active {
    font-weight: 600;
  }
}
</style>