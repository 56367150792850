/**
 * Modèle d'une action fourni à un bouton
 * @typedef {Object} Action
 * @property {Object} label label à afficher dans le bouton
 * @property {Function} onClick callback à exécuter lors du click du bouton
 * @property {String} bgColor couleur de fond du bouton (@see {@link @/constants/colors.js })
 */
export default class ButtonAction {

  constructor (label, onClick, bgColor, options = {}) {
    if (! label) {
      throw new Error('Le label d\'une action est obligatoire')
    }
    if (! onClick) {
      throw new Error('Le callback d\'une action est obligatoire')
    }
    this.label = label
    this.onClick = onClick
    this.bgColor = bgColor
    this.disabled = options.disabled
  }
}