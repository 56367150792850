<template>
  <section v-test="'tabs'" class="app-tabs" :class="tabsClassName">
    <component
      :is="headerPortalTarget ? 'n-portal' : 'div'"
      v-test="'tabs-header-wrapper'"
      class="app-tabs__headers-container"
      v-bind="tabsHeaderAttrs"
    >
      <app-tabs-headers
        v-model="activeTabIndex"
        v-test="'tabs-header'"
        :outlined="outlined"
        :bottom-lined="bottomLined"
        :tabs="tabHeaders"
        :grow="grow"
        :invalid-tab-index="invalidTabIndex"
      />
    </component>
    <v-tabs-items
      v-model="activeTabIndex"
      class="app-tabs__items"
      :class="{ [`px-${spacing} mx-n${spacing}`]: spacingX === 0 }"
      touchless
    >
      <slot />
    </v-tabs-items>
  </section>
</template>

<script>
import AppTabsItem from '@/components/ui/tabs/AppTabsItem.vue'
import AppTabsHeaders from '@/components/ui/tabs/AppTabsHeaders.vue'

/**
 * Les onglets de l'application
 */
export default {
  name: 'AppTabs',
  components: { AppTabsHeaders },
  props: {
    /**
     * L'index de l'onglet actif
     */
    value: {
      type: Number,
      default: 0,
    },
    /**
     * Le nom du portail dans lequel sera rendu l'entête des onglets
     */
    headerPortalTarget: {
      type: String,
      default: null,
    },
    /**
     * Élargi les onglets
     */
    grow: {
      type: Boolean,
      default: false,
    },
    /**
     * Ajoute des bordures top + bottom aux onglets
     */
    outlined: {
      type: Boolean,
      default: false,
    },
    /**
     * Ajoute une bordure bottom aux onglets
     */
    bottomLined: {
      type: Boolean,
      default: false,
    },
    /**
     * Supprime la couleur par défaut des onglets
     */
    transparent: {
      type: Boolean,
      default: false,
    },
    /**
     * L'espacement entre l'entête et le contenu des onglets
     */
    spacing: {
      type: Number,
      default: 4,
    },
    /**
     * L'espacement horizontal entre l'entête et le contenu des onglets
     */
    spacingX: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      tabHeaders: [],
      activeTabIndex: 0,
    }
  },
  computed: {
    tabsHeaderAttrs () {
      if (this.headerPortalTarget) {
        return { to: this.headerPortalTarget }
      }
      return {}
    },
    tabsClassName () {
      return {
        'app-tabs--outlined': this.outlined,
        'app-tabs--transparent': this.transparent,
      }
    },
    invalidTabIndex () {
      return this.tabHeaders.findIndex(tab => tab.invalid)
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (newValue) {
        this.activeTabIndex = newValue
      },
    },
    activeTabIndex (tabIndex) {
      this.$emit('input', tabIndex)
    },
  },
  beforeUpdate () {
    this.updateTabHeaders()
  },
  created () {
    this.updateTabHeaders()
  },
  methods: {
    updateTabHeaders () {
      const tabHeaders = []
      let hasInvalidSlots = false

      this.$slots.default.forEach(slot => {
        const componentOptions = slot.componentOptions?.Ctor.options

        // Dans le cas d'un v-if à false, le slot sera remonté mais vide et sans balise associée
        if (! slot.tag) {
          return
        }

        if (! componentOptions || (componentOptions.name !== AppTabsItem.name)) {
          hasInvalidSlots = true
        } else {
          tabHeaders.push(
            Object.keys(componentOptions.props).reduce((acc, propName) => {
              const componentProp = componentOptions.props[propName]
              let definedProp = slot.componentOptions.propsData[propName]
              if (componentProp.type === Boolean && !! definedProp) {
                // Si une prop de type booléen à directement été définie en tant qu'attribut (exemple : <composant ma-prop />)
                // Alors sa valeur sera une chaîne de caractère vide et non un booléen à true
                definedProp = true
              }
              return {
                ...acc,
                [propName]: (Object.getOwnPropertyNames(componentProp).includes('default') && ! definedProp) ? componentProp.default : definedProp,
              }
            }, {}),
          )
        }
      })

      if (hasInvalidSlots) {
        throw new Error(`Seul le composant ${AppTabsItem.name} peut être un enfant direct de ${this.$options.name}`)
      }
      this.tabHeaders = tabHeaders
    },
    isTabHeaderCountVisible (count) {
      return parseInt(count, 10) > 0
    },
  },
}
</script>

<style lang="scss" scoped>
.app-tabs {
  &__items {
    overflow: visible;
    padding: 0 !important;
    margin: 0 !important;
    background-color: var(--v-secondary-lighten-5);
  }

  &--transparent {
    ::v-deep {
      .v-tabs-bar {
        background-color: transparent;
      }
    }
  }
}
</style>