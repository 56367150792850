<template>
  <validation-provider
    v-slot="{ errors }"
    ref="validationProvider"
    slim
    :rules="rules"
    :name="label"
    :vid="name"
  >
    <div
      ref="appFileInput"
      class="app-file-input"
      :class="{
        'app-file-input--loading': loading,
      }"
    >
      <label
        v-if="label"
        v-test="'file-input-label'"
        class="app-file-input__label v-label mb-2"
        :class="{ 'error--text': errors.length }"
      >
        {{ fieldLabel }}
      </label>
      <div v-if="! isEmpty" key="hasContent" class="app-file-input__content mb-4">
        <slot name="default" />
      </div>
      <div
        v-else-if="$scopedSlots['empty']"
        key="noContent"
        class="pb-5"
      >
        <slot name="empty" />
      </div>

      <v-slide-y-transition v-if="showErrors">
        <div v-if="errors.length && errors[0]" v-test="'file-input-error-message'">
          <div v-for="(error, index) in errors" :key="index" class="pb-4 error--text">
            {{ error }}
          </div>
        </div>
      </v-slide-y-transition>

      <div v-if="multiple" class="app-file-button-multiple">
        <drop
          class="app-file-droppable-zone"
          @drop.prevent="dropHandler"
          @dragover.prevent
        >
          <app-icon :icon="cloudUpload" :size="26" />
          <div class="mt-2">
            {{ zoneDropLabel }}
          </div>
          <app-button
            color="primary"
            class="mt-4"
            :loading="loading"
            small
            outlined
            :label="buttonLabel"
            @click="openFileDialog()"
          >
            <app-icon :icon="uploadIcon" :size="13" />
            <span class="ml-2">{{ buttonLabel }}</span>
          </app-button>
          <app-button
            v-if="! isEmpty"
            color="error"
            class="mt-4"
            text
            :loading="loading"
            small
            outlined
            :label="buttonClearLabel"
            @click="clearUploadedFiles"
          >
            <app-icon :icon="clearIcon" :size="13" />
            <span class="ml-2">{{ buttonClearLabel }}</span>
          </app-button>
        </drop>
      </div>
      <div v-else>
        <app-button
          color="primary"
          :loading="loading"
          small
          outlined
          :label="buttonLabel"
          @click="openFileDialog()"
        >
          <app-icon :icon="uploadIcon" :size="13" />
          <span class="ml-2">{{ buttonLabel }}</span>
        </app-button>
      </div>

      <v-file-input
        v-show="false"
        ref="fileInput"
        v-model="selectedFile"
        v-test="'file-input'"
        v-bind="fileInputMultipleProp"
        :rules="[]"
        :label="showLabel ? label : ''"
        @change="selectFile"
      />
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { Drop } from 'vue-easy-dnd'
import fieldMixin from '@/mixins/fields.js'

export default {
  name: 'AppFileInput',
  components: {
    ValidationProvider,
    Drop,
  },
  mixins: [fieldMixin],
  props: {
    /**
     * Chemin vers le fichier
     * Si une valeur est défini, alors le slot de contenu sera actif
     * @model
     */
    value: {
      type: [String, Array],
      default: null,
    },
    /**
     * Texte du bouton
     * @default Envoyer
     */
    buttonLabel: {
      type: String,
      default: 'Envoyer',
    },
    buttonClearLabel: {
      type: String,
      default: 'Vider',
    },
    zoneDropLabel: {
      type: String,
      default: 'Glisser vos fichiers ici ou',
    },
    uploadIcon: {
      type: String,
      default: 'upload',
    },
    cloudUpload: {
      type: String,
      default: 'cloud-upload',
    },
    clearIcon: {
      type: String,
      default: 'delete',
    },
    /**
     * La taille maximale du fichier en Mo
     * @default null
     */
    maxMoSize: {
      type: Number,
      default: null,
    },
    /**
     * Affiche si le bouton charge
     * @default false
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Le type MIME des fichiers acceptés
     * @default *
     */
    accept: {
      type: String,
      default: '*',
    },
    /**
     * Méthode de validation du fichier
     * Retourne true si tout va bien, sinon une String avec l'erreur associée
     */
    fileValidator: {
      type: Function,
      default: () => true,
    },
    /**
     * Permet d'indiquer que plusieurs fichiers peuvent être sélectionnés
     */
    multiple: {
      type: Boolean,
      default: false,
    },
    /**
     * Permet de savoir combien de fichiers téléversés peuvent être sélectionnés au maximum
     */
    uploadedFilesMax: {
      type: Number,
      default: 1,
    },
    /**
     * Permet de savoir si des textes de fichier sont en erreurs
     */
    hasTextFieldsErrors: {
      type: Boolean,
      default: false,
    },
    /**
     * Permet de savoir si des fichiers téléversés sont en erreurs
     */
    hasUploadedFilesErrors: {
      type: Boolean,
      default: false,
    },
    /**
     * Permet de savoir combien de fichiers téléversés sont déjà sélectionnés
     */
    uploadedFilesCount: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      selectedFile: null,
      showErrors: true,
    }
  },
  computed: {
    // Il semblerait que vuetify renvoi systématiquement un tableau dès lors que la prop "multiple" est binder, même si celle-ci est false
    // Cette computed permet de ne pas binder la prop multiple si celle-ci vaut false
    // https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VFileInput/VFileInput.ts#L152
    fileInputMultipleProp () {
      if (this.multiple) {
        return { multiple: true }
      }
      return {}
    },
    isEmpty () {
      return ! this.value || ! this.value.length
    },
  },
  watch: {
    value (value) {
      if (! value || ! value.length) {
        this.selectedFile = null
      }
    },
    hasTextFieldsErrors () {
      this.manageFilesErrors()
    },
    hasUploadedFilesErrors () {
      this.manageFilesErrors()
    },
    uploadedFilesCount () {
      this.manageFilesErrors()
      if (! this.uploadedFilesCount) {
        this.clearUploadedFiles()
      }
    },
  },
  methods: {
    openFileDialog () {
      this.selectedFile = null
      if (this.multiple) {
        this.setShowErrors(false)
      }
      const fileInputElement = this.$refs.fileInput
      fileInputElement.$el.querySelector('input').click()
    },
    dropHandler (event) {
      const files = event.dataTransfer.files
      if (files.length) {
        this.selectedFile = null
        const filesArray = Array.from(files)
        this.selectFile(filesArray)
      }
    },
    setFileInputError (error) {
      // Utilise requestAnimationFrame afin de s'assurer que Vue mette correctement à jour les valeurs du provider
      // https://vee-validate.logaretm.com/v2/guide/components/validation-observer.html#resetting-forms
      requestAnimationFrame(() => this.$refs.validationProvider.setErrors([error]))
    },
    resetFileInputError () {
      // Utilise requestAnimationFrame afin de s'assurer que Vue mette correctement à jour les valeurs du provider
      // https://vee-validate.logaretm.com/v2/guide/components/validation-observer.html#resetting-forms
      requestAnimationFrame(() => this.$refs.validationProvider.setErrors([]))
    },
    /**
     * Permet de selectionner un fichier ou plusieurs si la propriété multiple est à true
     * @param {Object|Array<Object>} file
     *
     */
    selectFile (file) {
      const availableSlots = this.uploadedFilesMax - this.uploadedFilesCount
      if (availableSlots <= 0 || ! file || (this.multiple && ! file.length)) {
        return
      }
      if (this.multiple && file.length > availableSlots) {
        file.splice(availableSlots)
      }
      const filesToUpload = this.multiple ? file : [file]
      const selectedFilesErrors = filesToUpload.map(curFile => this.checkFileError(curFile))
      const hasSelectedFilesErrors = !! selectedFilesErrors.filter(error => error).length
      if (this.multiple) {
        filesToUpload.forEach((curFile, index) => {
          filesToUpload[index].longError = selectedFilesErrors[index]
          filesToUpload[index].shortError = selectedFilesErrors[index].replace(/ '[^']+'/, '')
        })
      } else if (selectedFilesErrors[0]) {
        this.setFileInputError(selectedFilesErrors[0])
      }
      if (hasSelectedFilesErrors) {
        this.$emit('invalid-file', file)
        return
      }
      this.$emit('file-selected', file)
    },
    checkFileError (curFile) {
      let fileError = ''
      const maxFileSize = this.maxMoSize * 1_000_000
      if (this.maxMoSize && curFile.size > maxFileSize) {
        fileError = `La taille du fichier '${curFile.name}' ne doit pas dépasser ${this.maxMoSize} Mo.`
      }
      if (! fileError) {
        const validationError = this.fileValidator(curFile)
        if (validationError !== true) {
          fileError = `${validationError}`
        }
      }
      return fileError
    },
    manageFilesErrors () {
      if (this.hasTextFieldsErrors || this.hasUploadedFilesErrors) {
        this.setFileInputError('')
      } else if (this.uploadedFilesCount) {
        this.resetFileInputError()
      }
    },
    hasRequiredRule () {
      return typeof this.rules === 'string' && this.rules.split('|').includes('required')

    },
    clearUploadedFiles () {
      if (! this.hasRequiredRule()) {
        this.resetFileInputError()
      }
      this.setShowErrors(true)
      this.$emit('files-cleared')
    },
    setShowErrors (state) {
      this.showErrors = state
    },
  },
}
</script>

<style lang="scss" scoped>
.app-file-input {
  pointer-events: all;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  position: relative;
  line-height: 100%;

  * {
    transition: all 300ms ease-in;
  }

  &--loading {
    opacity: 0.5;
  }

  &__label {
    margin-left: 0 !important;
    font-size: 12px;
    display: inline-block;
    pointer-events: none;
    user-select: none;
    line-height: 100%;
    color: rgba(map-get($shades, 'black'), .6);
  }

  &__content {
    width: 100%;
  }

  .app-file-button-multiple {
    width: 100%;
  }
  .app-file-droppable-zone {
    display:flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    padding: 20px;
    border: 2px dashed var(--v-blueGrey-lighten3);
    cursor: pointer;
  }
}
</style>