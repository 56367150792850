<template>
  <v-dialog v-model="localShowModal" fullscreen persistent>
    <fullscreen-layout
      title="Revenir sur easy-care"
      @goBack="closeModal"
    >
      <iframe
        v-if="localShowModal"
        id="modal-efficience-dmp"
        class="iframe-style"
        width="100%"
        loading="lazy"
        :src="url"
      />
    </fullscreen-layout>
  </v-dialog>
</template>

<script>
import FullscreenLayout from '@/layout/Fullscreen.vue'

import localCopyMixin from '@novalys/src/mixins/local-copy-mixin'

export default {
  name: 'EfficienceModalDmp',
  components: { FullscreenLayout },
  mixins: [
    localCopyMixin({
      propertyName: 'showModal',
      copyPropertyName: 'localShowModal',
    }),
  ],
  props: {
    showModal: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeModal () {
      this.localShowModal = false
    },
  },
}
</script>

<style scoped lang="scss">
  .fullscreen-layout {
    background-color: #FFFFFF;
    ::v-deep {
      .page-layout {
        .page-layout__content {
          padding: 0;
          display: flex;
        }
      }
    }
  }

  .iframe-style {
    border: none;
    flex: 1;
  }
</style>