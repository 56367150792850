<template>
  <v-tabs
    v-model="activeTabIndex"
    v-test="'tabs-header'"
    show-arrows
    center-active
    class="app-tabs-headers"
    :class="{ 'app-tabs-headers--outlined': outlined, 'app-tabs-headers--bottom-lined': bottomLined }"
    :grow="grow"
    :slider-color="sliderColor"
    :color="sliderColor"
  >
    <app-tabs-header
      v-for="(tabHeader, index) in tabs"
      :key="index"
      v-test="'tabs-header-item'"
      :badge-color="tabHeader.badgeColor"
      :badge-content="tabHeader.badgeContent"
      :dot-badge="tabHeader.dotBadge"
      :label="tabHeader.label"
      :icon="tabHeader.icon"
      :invalid="isTabInvalid(index)"
      :disabled="tabHeader.disabled"
    />
  </v-tabs>
</template>

<script>
import AppTabsHeader from '@/components/ui/tabs/AppTabsHeader.vue'

export default {
  name: 'AppTabsHeaders',
  components: { AppTabsHeader },
  props: {
    tabs: {
      type: Array,
      required: true,
      validator: tabs => tabs.every(tab => !! tab.label),
    },
    /**
     * L'index de l'onglet actif
     */
    value: {
      type: Number,
      default: 0,
    },
    /**
     * Élargi les onglets
     */
    grow: {
      type: Boolean,
      default: false,
    },
    /**
     * Ajoute des bordures top et bottom aux onglets
     */
    outlined: {
      type: Boolean,
      default: false,
    },
    /**
     * Ajoute une bordure bottom aux onglets
     */
    bottomLined: {
      type: Boolean,
      default: false,
    },
    /**
     * L'index correspondant à un onglet invalide
     */
    invalidTabIndex: {
      type: Number,
      default: null,
    },
  },
  data () {
    return { activeTabIndex: 0 }
  },
  computed: {
    sliderColor () {
      if (this.invalidTabIndex === this.value) {
        return 'error'
      }
      return 'primary'
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (newValue) {
        this.activeTabIndex = newValue
      },
    },
    activeTabIndex (tabIndex) {
      this.$emit('input', tabIndex)
    },
  },
  methods: {
    isTabInvalid (index) {
      return index === this.invalidTabIndex
    },
  },
}
</script>

<style lang="scss" scoped>
.app-tabs-headers {
  font-size: 13px;
  font-weight: 600;
  text-transform: none;
  letter-spacing: initial;

  &--outlined {
    border-top: 1px solid var(--v-divider-base);
    border-bottom: 1px solid var(--v-divider-base);
  }

  &--bottom-lined {
    border-bottom: 1px solid var(--v-divider-base);
  }

  ::v-deep {
    .v-tabs-bar {
      min-height: 48px;
      height: auto;
    }
  }
}
</style>