import MssMessage from '@/modules/secureMessaging/modules/messaging/models/MssMessage'
import MssMessageAttachment from '@/modules/secureMessaging/modules/messaging/models/MssMessageAttachment'

import { getFromAPI } from '@/services/api'
import { extension, lookup } from 'mime-types'
import { format } from '@/utils/functions/dates'

import ConsultationDocument from '@/modules/patient/models/ConsultationDocument'
import PatientDocument from '@/modules/patient/models/PatientDocument'
import Prescription from '@/modules/patient/models/Prescription'
import PatientHealthSummaryDocument from '@/modules/patient/models/PatientHealthSummaryDocument'
import ConsultationDischargeLetterDocument from '@/modules/patient/models/ConsultationDischargeLetterDocument'
import ConsultationCertificate from '@/modules/patient/components/consultation/certificates/models/ConsultationCertificate'
import ConsultationTelemedicineAct from '@/modules/patient/components/consultation/telemedicineActs/models/ConsultationTelemedicineAct'

import { SENT_PATIENT_DOC_LOG_DOCUMENT_TYPES } from '@/modules/users/constants'

const OFFICIAL_DOCUMENT_INSTANCES = [
  ConsultationDocument,
  Prescription,
  PatientHealthSummaryDocument,
  ConsultationDischargeLetterDocument,
  ConsultationCertificate,
  ConsultationTelemedicineAct,
]

/**
 * Permet de gérer les options pour l'envoi de document via la modale d'envoi de mail MSS
 */
export const getMssMessageForDocument = async (document, patient) => {

  let fileName, mimeType, attachmentDocumentSource

  if (document instanceof PatientDocument) {
    fileName = getDocumentName(document.title, document.mimeType)
    mimeType = document.mimeType
    attachmentDocumentSource = SENT_PATIENT_DOC_LOG_DOCUMENT_TYPES.PATIENT_IMPORTED_DOCUMENT.value
  } else if (document instanceof ConsultationDocument) {
    fileName = getDocumentName(document.name)
    mimeType = lookup('pdf')
    attachmentDocumentSource = SENT_PATIENT_DOC_LOG_DOCUMENT_TYPES.CONSULTATION_DOCUMENT.value
  } else if (document instanceof Prescription) {
    fileName = getDocumentName(document.name)
    mimeType = lookup('pdf')
    attachmentDocumentSource = SENT_PATIENT_DOC_LOG_DOCUMENT_TYPES[document.eprescription ? 'E_PRESCRIPTION' : 'PRESCRIPTION_DOCUMENT' ].value
  } else if(document instanceof PatientHealthSummaryDocument) {
    fileName = getDocumentName(document.name)
    mimeType = lookup('pdf')
    attachmentDocumentSource = SENT_PATIENT_DOC_LOG_DOCUMENT_TYPES.VSM.value
  } else if (document instanceof ConsultationDischargeLetterDocument) {
    fileName = getDocumentName(document.name)
    mimeType = lookup('pdf')
    attachmentDocumentSource = SENT_PATIENT_DOC_LOG_DOCUMENT_TYPES.DISCHARGE_LETTER_DOCUMENT.value
  } else if (document instanceof ConsultationCertificate) {
    fileName = getDocumentName(document.name)
    mimeType = lookup('pdf')
    attachmentDocumentSource = SENT_PATIENT_DOC_LOG_DOCUMENT_TYPES.CERTIFICATE_DOCUMENT.value
  } else if (document instanceof ConsultationTelemedicineAct) {
    fileName = getDocumentName(document.name)
    mimeType = lookup('pdf')
    attachmentDocumentSource = SENT_PATIENT_DOC_LOG_DOCUMENT_TYPES.TELEMEDICINE_ACT_DOCUMENT.value
  } else {
    if (! isSendingIheArchive(document)) {
      fileName = getDocumentName(document.name, document.mimeType)
      mimeType = document.mimeType
      attachmentDocumentSource = null
    }
  }

  if (isSendingIheArchive(document)) {
    fileName = 'IHE_XDM.ZIP'
  }

  const subject = await getMessageSubject(document)
  return new MssMessage({
    subject,
    senderEmail: patient?.getPublicMssEmail(),
    attachments: [
      new MssMessageAttachment({
        contentUrl: document.contentUrl,
        fileName,
        mimeType,
        documentSource: attachmentDocumentSource,
      }),
    ],
  })
}

const getMessageSubject = async (document) => {
  if (isSendingIheArchive(document)) {
    const archiveInfos = await getArchiveInfos(document)
    const formattedBirthDate = format(archiveInfos[0].patientBirthDate, 'dd/MM/yyyy')
    if (archiveInfos.length > 1) {
      return `XDM/1.0/DDM+${archiveInfos.length} documents ${archiveInfos[0].patientBirthName} ${archiveInfos[0].patientFirstName} ${formattedBirthDate}`
    }
    const documentType = archiveInfos[0].documentTypeLabel.substring(0, 40)
    return `XDM/1.0/DDM+${documentType} ${archiveInfos[0].patientBirthName} ${archiveInfos[0].patientFirstName} ${formattedBirthDate}`
  }
  if (OFFICIAL_DOCUMENT_INSTANCES.some(DocumentInstance => document instanceof DocumentInstance)) {
    const regex = /\(\d+\)\.pdf$/ // Supprime les chiffres entre parenthèse de versionning de fichier
    return document.name.replace(regex, '').replace('.pdf', '').replaceAll('_', ' ')
  }
  return document.name || document.title
}

const getArchiveInfos = async (document) => {
  const { data } = await getFromAPI(`${document['@id']}/mss_meta_dataset`)
  return data.cdaMssMetaDataset
}

const isSendingIheArchive = (document) => {
  return document.cda // Même si il s'appelle CDA, ce boolean est à true si c'est seulement c'est un IHE_XDM
}

const getDocumentName = (name, mimeType) => {
  mimeType = mimeType || 'application/pdf'
  if (name.endsWith('.' + extension(mimeType))) {
    return name
  }
  return `${name}.${extension(mimeType)}`
}