<template>
  <app-col
    cols="12"
    md="6"
    lg="4"
    class="mb-n5"
  >
    <app-select
      v-model="localValue"
      v-test="'ins-identity-attribute-field'"
      item-value="value"
      item-text="label"
      :items="getRefAsArray(INS_IDENTITY_ATTRIBUTE)"
      clearable
      label="Attribut"
      hide-errors="auto"
    />
  </app-col>
</template>

<script>
import { getRefAsArray } from '@/utils/functions/refs'
import { INS_IDENTITY_ATTRIBUTE } from '@/modules/patient/constants/insIdentityAttribute'
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin'
import AppSelect from '@/components/ui/form/AppSelect.vue'
export default {
  name: 'IdentityAttributeField',
  components: { AppSelect },
  mixins: [localCopyMixin()],
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data () {
    return { INS_IDENTITY_ATTRIBUTE }
  },
  methods: { getRefAsArray },
}
</script>