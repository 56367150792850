<template>
  <div
    ref="calendarWrapper"
    class="calendar-wrapper"
    :class="{
      'calendar-wrapper--dragging': isDragging,
      'calendar-wrapper--moving': getMovedAppointment,
    }"
  >
    <v-sheet height="100%">
      <v-calendar
        ref="calendar"
        v-touch="{ left: () => MOVE(1), right: () => MOVE(-1) }"
        :type="agendaView"
        :weekdays="agendaDays"
        :max-days="agendaDays.length"
        :categories="visiblesSchedulesNameArr"
        category-show-all
        :events="events"
        event-overlap-mode="column"
        event-overlap-threshold="5"
        :interval-height="cellHeight"
        :first-time="dayBounds.start"
        :interval-count="intervalCount"
        :interval-width="50"
        :event-height="12"
        :start="startDay"
        :event-category="getEventCategory"
        class="calendar"
        @mousedown:time="onTimeMouseDown"
        @mousemove:time="onTimeMouseMove"
        @mousemove:time-category="onTimeMouseMove"
        @mouseleave:time="onTimeMouseLeave"
        @mouseleave:time-category="onTimeMouseLeave"
        @mouseup:event="handleEventMouseUp"
        @click:day="handleClickDay"
        @change="resetObserver"
      >
        <template #day-header="{ date }">
          <span data-test="date">{{ getDayFormat(date) }}</span>
        </template>
        <template #event="{ eventParsed }">
          <calendar-event
            data-test="event"
            :event="eventParsed.input.eventInstance"
            @mouseenter="setCanCreateDragAndDropEvent(false)"
            @mouseleave.native="setCanCreateDragAndDropEvent(true)"
            @goToPatientFile="setCanCreateDragAndDropEvent(true)"
          />
        </template>
        <template #day-body="{ year, month, day, category }">
          <calendar-time-preview
            v-if="! getMovedAppointment && isTimePreviewVisible(day, category)"
            :hour="hoveredTime.hour"
            :minute="hoveredTime.minute"
          />
          <calendar-availability-range
            v-for="availability in getDayAvailabilities(year, month, day, category)"
            :key="availability.availability['@id']"
            v-test="'day-availability-range'"
            class="calendar__availability-range"
            :range="availability"
            :style="getAvailabilityStyle(availability, getDayAvailabilities(year, month, day, category))"
            @mouseenter="$emit('mouseenter:availability', availability.availability)"
            @mouseleave="$emit('mouseleave:availability', availability.availability)"
            @mouseenter:options="setCanCreateDragAndDropEvent(false)"
            @mouseleave:options="setCanCreateDragAndDropEvent(true)"
          />
          <calendar-drag-event
            v-if="isDragEventVisible(day, category)"
            :date="draggedEvent.date"
            :start-time="draggedEvent.startTime"
            :duration="draggedEvent.duration"
          />
        </template>
      </v-calendar>
    </v-sheet>
  </div>
</template>

<script>
import CalendarDragEvent from '@/modules/agenda/components/calendar/CalendarDragEvent.vue'
import CalendarAvailabilityRange from '@/modules/agenda/components/calendar/CalendarAvailabilityRange.vue'
import CalendarTimePreview from '@/modules/agenda/components/calendar/CalendarTimePreview.vue'

import calendarResizeObserverMixin from '@/modules/agenda/mixins/calendarResizeObserver'

import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'

import { startOfWeek, add, areIntervalsOverlapping, min, max, differenceInMinutes } from '@/utils/functions/dates'

import { leftPad, floorToNearest } from '@/utils/functions/number'
import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI'
import { objectSetNested } from '@/utils/functions/object'
import { getCategoryName } from '@/modules/agenda/utils/calendar'
import { CALENDAR_VIEWS } from '@/modules/agenda/constants'

import Appointment from '@/modules/agenda/models/events/Appointment'
import CalendarEvent from '@/modules/agenda/components/calendar/CalendarEvent'
import NovaTools from '@/nova-tools/NovaTools'

const DEFAULT_DRAGGED_EVENT_DURATION = 20
const MOUSEOVER_MINUTE_STEP = 5

export default {
  name: 'Calendar',
  components: {
    CalendarEvent,
    CalendarAvailabilityRange,
    CalendarDragEvent,
    CalendarTimePreview,
  },
  mixins: [calendarResizeObserverMixin],
  props: {
    appointments: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      /*
       * Objet contenant les informations de temps de l'évènement
       * de prévisualisation actuellement visible (survol de la grille de date)
       */
      hoveredTime: {},
      previousAgendaView: '',
      dayAvailabilitiesCache: {},
      canCreateDragAndDropEvent: true,
      isDragging: false,
      draggedEvent: {},
      currentMovedAppointment: null,
    }
  },
  computed: {
    ...mapState('agenda', ['currentDate', 'schedules', 'visibleScheduleIDs']),
    ...mapState('app', ['isMobile']),
    ...mapGetters('agenda', [
      'config',
      'getSchedules',
      'getVisibleSchedules',
      'getVisibleAvailabilities',
      'getVisibleAbsences',
      'getMovedAppointment',
      'getScheduleFromIri',
    ]),
    agendaView () {
      return this.config.view
    },
    agendaDays () {
      return this.config.days
    },
    visiblesSchedulesNameArr () {
      return this.getVisibleSchedules.map(schedule => schedule.name)
    },
    events () {
      const appointmentsEvents = this.appointments.map(appointment => {
        return appointment.toAgendaEvent(this.config, this.getScheduleFromIri(appointment.schedule).name)
      })
      const absencesEvents = this.getVisibleAbsences.map(absence => absence.toAgendaEvent(this.config, absence.setting.schedule.name))
      const events = [...appointmentsEvents, ...absencesEvents]
      if (this.getMovedAppointment && this.currentMovedAppointment) {
        const scheduleName = this.getVisibleSchedules.find(schedule => schedule['@id'] === this.getMovedAppointment.schedule)?.name || null
        events.push(this.currentMovedAppointment.toAgendaEvent(
          this.config,
          scheduleName,
        ))
      }
      return events
    },
    dayBounds () {
      return {
        start: this.config.startTime,
        end: this.config.endTime,
      }
    },
    intervalCount () {
      if (this.dayBounds.end && this.dayBounds.start) {
        return (
          this.dayBounds.end.split(':')[0] - this.dayBounds.start.split(':')[0]
        )
      }
      return undefined
    },
    startDay () {
      return this.agendaView === CALENDAR_VIEWS.WEEK.value
        ? add(startOfWeek(this.currentDate), { days: this.agendaDays[0] })
        : this.currentDate
    },
    dayStartMinute () {
      const [startHour, startMinute] = this.config.startTime.split(':').map(time => parseInt(time))
      return startHour * 60 + startMinute
    },
  },
  watch: {
    dayBounds () {
      this.setCellHeight()
    },
    agendaView () {
      this.fetchEvents()
    },
    isMobile: {
      immediate: true,
      handler () {
        if (this.isMobile) {
          this.previousAgendaView = this.agendaView
        }
        this.SET_VIEW(this.isMobile ? CALENDAR_VIEWS.DAY.value : this.previousAgendaView)
      },
    },
    currentDate () {
      this.fetchEvents()
    },
    getVisibleAvailabilities () {
      // Reset le cache des disponibilités quand elles changent
      this.dayAvailabilitiesCache = {}
    },
    getMovedAppointment (appointment) {
      this.canCreateDragAndDropEvent = appointment === null
    },
  },
  beforeDestroy () {
    document.removeEventListener('mouseup', this.onMouseUp)
  },
  methods: {
    ...mapMutations('agenda', ['SET_VIEW', 'SET_CURRENT_DATE', 'SET_MOVED_APPOINTMENT', 'MOVE']),
    ...mapMutations('app', ['SET_SNACK']),
    ...mapActions('agenda', ['fetchEvents', 'updateAppointment']),
    setCanCreateDragAndDropEvent (state) {
      this.canCreateDragAndDropEvent = state
    },
    setCurrentMovedAppointment ({ date, hour, minute }) {
      const startDateTime = new Date(date + 'T' + this.getFlooredTime(hour, minute))
      const endDateTime = add(startDateTime, { minutes: this.getMovedAppointment.getDuration() })

      this.currentMovedAppointment = new Appointment({
        ...this.getMovedAppointment,
        '@id': null, // L'uuid est retiré de façon à ce que le rendez-vous ne soit pas considéré comme un rendez-vous fixé dans le calendrier
        startDateTime: startDateTime.toISOString(),
        endDateTime: endDateTime.toISOString(),
      })
    },
    getEventCategory (event) {
      return getCategoryName(event.category)
    },
    getDayFormat (date) {
      return NovaTools.dates.newDate(date).toLocaleDateString('fr-FR', {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
      })
    },
    getFlooredTime (hour, minute) {
      minute = floorToNearest(minute, MOUSEOVER_MINUTE_STEP)
      return leftPad(hour, 2) + ':' + leftPad(minute, 2)
    },
    onTimeMouseDown ({ date, hour, minute }) {
      if (this.canCreateDragAndDropEvent) {
        this.draggedEvent.date = date
        this.draggedEvent.startTime = this.getFlooredTime(hour, minute)
        this.draggedEvent.duration = DEFAULT_DRAGGED_EVENT_DURATION
      }

      // On applique l'écouteur de manière globale de façon à pouvoir gérer
      // le cas d'un relanchement de la souris en dehors de l'agenda
      document.addEventListener('mouseup', this.onMouseUp)
    },
    onDrag (tms) {
      const eventDuration = differenceInMinutes(
        new Date(this.draggedEvent.date + 'T' + this.getFlooredTime(tms.hour, tms.minute)),
        new Date(this.draggedEvent.date + 'T' + this.draggedEvent.startTime),
      )
      if (eventDuration >= DEFAULT_DRAGGED_EVENT_DURATION) {
        this.draggedEvent.duration = eventDuration
      }
    },
    onTimeMouseMove (tms) {
      if (this.getMovedAppointment) {
        this.setCurrentMovedAppointment(tms)
      }
      if (this.draggedEvent.startTime) {

        // Ne fait apparaitre le drag event que lorsque l'on a commencé à dragger
        // Afin d'éviter l'apparition de ce dernier lors d'un simple clic
        this.isDragging = true
        this.onDrag(tms)
      }
      // Recalcul des minutes pour avoir la valeur par pas et non à chaque pixel
      tms.minute = floorToNearest(tms.minute, MOUSEOVER_MINUTE_STEP)

      // Est-ce qu'on passe à une tranche différente ?
      const isNewStep = ! this.hoveredTime
        ? true
        : this.hoveredTime.minute !== tms.minute || this.hoveredTime.date !== tms.date

      if (isNewStep) {
        const tmsCategoryName = getCategoryName(tms.category)
        this.draggedEvent.category = tmsCategoryName
        this.draggedEvent.date = tms.date

        if (this.agendaView !== CALENDAR_VIEWS.DAY.value || (this.agendaView === CALENDAR_VIEWS.DAY.value && tmsCategoryName)) {
          // N'affiche la preview qu'en cas d'horaire sans dépassement
          const tmsMinute = (tms.hour * 60) + tms.minute
          const isAfterStart = tmsMinute >= this.dayStartMinute
          this.hoveredTime = isAfterStart ? tms : {}
        }
      }
    },
    removeDraggedEvent () {
      this.isDragging = false
      this.draggedEvent = {}
      document.removeEventListener('mouseup', this.onMouseUp)
    },
    async onMouseUp () {
      if (this.getMovedAppointment) {
        try {
          const appointment = new Appointment({
            ...this.currentMovedAppointment,
            '@id': this.getMovedAppointment['@id'],
          })
          this.SET_MOVED_APPOINTMENT(null)
          await this.updateAppointment(appointment)
          this.SET_SNACK({ message: 'Le rendez-vous a été déplacé avec succès' })
        } catch(e) {}
      } else {
        this.manageAppointment({
          category: getCategoryName(this.draggedEvent.category),
          start: new Date(this.draggedEvent.date + 'T' + this.draggedEvent.startTime),
        })
      }
      this.removeDraggedEvent()
    },
    onTimeMouseLeave () {
      this.hoveredTime = {}
    },
    handleClickDay ({ date }) {
      this.SET_VIEW(CALENDAR_VIEWS.DAY.value)
      this.SET_CURRENT_DATE(NovaTools.dates.newDate(date))
    },
    handleEventMouseUp ({ event, nativeEvent }) {
      // Empêche d'afficher la modale de création d'un rdv si le relachement
      // de la souris est éffectué sur un event
      if (! this.isDragging) {
        nativeEvent.stopPropagation()
        this.manageAppointment(event)
        this.removeDraggedEvent()
      }
    },
    manageAppointment (event) {
      if (! event['@id']) {
        const { start } = event
        if (NovaTools.dates.isValidDate(start)) {
          this.$router.push({
            name: 'agenda.add',
            params: {
              date: NovaTools.dates.format(start, 'yyyy-MM-dd'),
              startTime: NovaTools.dates.format(start, 'HH:mm'),
              schedule: getCategoryName(event.category) || this.getVisibleSchedules[0].name,
              duration: this.isDragging ? this.draggedEvent.duration : undefined,
            },
          })
        }
      } else {
        const isAppointment = event.eventInstance instanceof Appointment
        this.$router.push({
          name: `agenda.edit.${isAppointment ? 'appointment' : 'absence'}`,
          params: { eventUuid: getUUIDFromIRI(isAppointment ? event['@id'] : event.setting['@id']) },
        })
      }
    },
    /**
     * Pour un jour ou une catégorie spécifique (year/month/day || category),
     * retourne l'ensemble des disponibilités associées
     * et les ajuste en tenant compte des horaires visibles.
     *
     * Un cache est utilisé car sans cela le traitement est effectué a chaque fois qu'il y a l'event move.
     * Cela est du à la réactivité nécessaire présente dans le slot day-body du calendrier
     */
    getDayAvailabilities (year, month, day, category) {
      const categoryName = getCategoryName(category)

      const cachedDayAvailabilities = this.dayAvailabilitiesCache?.[year]?.[month]?.[day]?.[categoryName]
      if (cachedDayAvailabilities) {
        return cachedDayAvailabilities
      }

      const visibleScheduleAvailabilities = this.getVisibleAvailabilities.filter(availability => {
        if (! categoryName) {
          return this.visibleScheduleIDs.includes(availability.setting.schedule['@id'])
        }

        return categoryName === availability.setting.schedule.name
      })

      const currentDayAvailability = visibleScheduleAvailabilities.filter(availability => areIntervalsOverlapping({
        start: new Date(year, month - 1, day),
        end: new Date(year, month - 1, day, 23, 59, 59, 999),
      }, {
        start: availability.getStartDateTime(),
        end: availability.getEndDateTime(),
      }))

      const dayAvailabilities = currentDayAvailability.map(availability => {
        const [hourStart, minuteStart, secondStart] = this.dayBounds.start.split(':')
        const [hourEnd, minuteEnd, secondEnd] = this.dayBounds.end.split(':')

        const croppedInterval = {}

        // Défini le début de l'intervale
        const calendarDayStart = new Date(year, month - 1, day, hourStart, minuteStart, secondStart)
        croppedInterval.start = max([availability.getStartDateTime(), calendarDayStart])

        // Défini la fin de l'intervale
        const calendarDayEnd = new Date(year, month - 1, day, hourEnd, minuteEnd, secondEnd)
        croppedInterval.end = min([availability.getEndDateTime(), calendarDayEnd])

        croppedInterval.availability = availability

        return croppedInterval
      })

      objectSetNested(this.dayAvailabilitiesCache, [year, month, day, categoryName], dayAvailabilities)
      return dayAvailabilities
    },
    getAvailabilityStyle (availability, dayAvailabilities) {
      const overlappingIntervals = dayAvailabilities.filter(_availability => areIntervalsOverlapping({
        start: availability.start,
        end: availability.end,
      }, {
        start: _availability.start,
        end: _availability.end,
      }))
      if (overlappingIntervals.length) {
        const stackOrder = overlappingIntervals.findIndex(_availability => _availability === availability)
        const widthPercent = 100 / overlappingIntervals.length
        return {
          position: 'absolute',
          width: `${widthPercent}%`,
          left: `${widthPercent * stackOrder}%`,
        }
      }
    },
    isTimePreviewVisible (day, category) {
      const isSameDay = day === this.hoveredTime.day
      const isSameCategory = getCategoryName(category) === getCategoryName(this.hoveredTime?.category)
      return ! this.isDragging && isSameDay && isSameCategory
    },
    isDragEventVisible (day, category) {
      if (category) {
        return this.isDragging && getCategoryName(category) === getCategoryName(this.draggedEvent.category)
      }
      return this.isDragging && day === parseInt(this.draggedEvent.date.split('-')[2], 10)
    },
  },
}
</script>

<style lang="scss">
$agenda-border-color: var(--v-blue-grey-lighten1);
$agenda-border-color--active: var(--v-primary-base);
$agenda-background-color: var(--v-blue-grey-lighten2);
$agenda-interval-space: 4px;

.calendar {
  &__availability-range {
    width: 100%;
    transition: .3s ease;
  }
}

.v-calendar-daily_head-weekday,
.v-calendar-daily_head-day-label {
  display: none;
}

.calendar-wrapper {
  &--dragging {
    .v-calendar-category__column,
    .availability-range-wrapper,
    .v-calendar-daily__day-interval {
      cursor: row-resize !important;
    }
  }

  &--moving {
    .v-calendar-category__column,
    .availability-range-wrapper,
    .v-calendar-daily__day-interval {
      cursor: all-scroll !important;
    }
    .calendar-event {
      pointer-events: none;
    }
  }

  .theme--light.v-calendar-daily {
    border-left: none;
    border-top: none;

    .v-calendar-daily_head-day {
      background-color: transparent;
      font-size: 12px;
      min-height: 45px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      border-radius: 0;
      border-right: none;
      padding: 5px;
      text-align: center;
      margin-right: $agenda-interval-space + 1;
      cursor: pointer;
      user-select: none;

      &:last-child {
        margin-right: 0;
      }

      &.v-present {
        color: $agenda-border-color--active !important;
        border-color: $agenda-border-color--active !important;
        border: 1px solid;
        border-bottom: none;
      }
    }

    .v-calendar-daily_head-day,
    .v-calendar-daily__day,
    .v-calendar-daily__day-interval,
    .v-calendar-daily__day-interval:first-child {
      border-color: $agenda-border-color;
    }

    .v-calendar-daily_head-day {
      flex: 1 0 auto;
    }

    .v-calendar-daily__intervals-head,
    .v-calendar-daily_head-day {
      min-height: 35px;
    }

    .v-calendar-daily__intervals {
      &-head,
      &-body {
        margin-right: 2px;
        @include media-md {
          margin-right: 10px;
        }
      }

      &-head {
        border-right: none;

        &:after {
          display: none;
        }
      }

      &-body {
        background-color: $agenda-background-color;
        border-right: none;

        .v-calendar-daily__interval {
          padding-right: 0;

          &:after {
            display: none;
          }

          &-text {
            color: var(--v-text-base);
            padding-right: 0;
            text-align: center;
          }
        }
      }
    }

    .v-calendar-category__columns {
      z-index: 2;
    }

    .v-calendar-daily {
      &__head {
        margin-right: 0;
      }

      &__body {
        overflow: visible;
      }

      &__scroll-area {
        overflow-y: visible;
        padding-bottom: 0;

        @include media-md {
          padding-bottom: 20px;
        }
      }

      &__scroll-area > .v-calendar-daily__pane {
        overflow-y: visible;
        height: 100% !important;
      }

      &__day,
      &__intervals-body {
        display: flex;
        flex-direction: column;
      }

      &__day-interval,
      &__interval {
        flex: 1 1 40px;
        height: auto !important;
      }

      &__day {
        margin-right: $agenda-interval-space;
        border-radius: 0;
        background-color: $agenda-background-color;

        &:last-child {
          margin-right: 0;
        }

        &.v-present {
          border-left: 1px solid $agenda-border-color--active;
          border-right: 1px solid $agenda-border-color--active;
          border-bottom: 1px solid $agenda-border-color--active;

          .v-calendar-daily__day-interval:first-child {
            border-top: 1px solid $agenda-border-color !important;
          }
        }

        &-interval {
          position: relative;
          cursor: pointer;

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 100%;
            height: 1px;
            border-bottom: 1px dashed $agenda-border-color;
          }
        }
      }
    }

    /** Events CSS */
    .v-event-timed {
      padding: 0;
      border: none !important;
      pointer-events: none;
      background-color: transparent !important;
    }

    .v-event.v-event-start.v-event-end {
      line-height: 1;
      width: 100% !important;

      padding: 0;
      border: none !important;
      pointer-events: none;

      .event-wrapper {
        padding-top: 0;
        padding-bottom: 0;

        .event-time {
          display: none;
        }
      }
    }

    .v-event-timed-container {
      z-index: 99 !important;
      margin-right: 30px;
    }
  }
}

.calendar-wrapper .theme--light.v-calendar-category {
  .v-calendar-daily_head-day {
    display: block;
  }
  .v-calendar-category__column {
    cursor: pointer;
  }
}
</style>