<template>
  <tr>
    <td>
      <p class="pl-4 secondary--text">
        {{ effectiveTimeText }}
      </p>
    </td>
    <td>
      <bio-result-list-value-tag :bio-result="bioResult" :selected-unit-index="selectedUnitIndex" />
    </td>
    <td colspan="2" />
  </tr>
</template>

<script>
import BioResultListValueTag from '@/modules/secureMessaging/modules/documents/components/metaDatasetModal/bioResultList/BioResultListValueTag.vue'

import { format } from '@/utils/functions/dates'

import BioResultTableItem from '@/modules/secureMessaging/modules/documents/components/metaDatasetModal/bioResultList/classes/BioResultTableItem'

export default {
  name: 'BioResultListSubGroupRow',
  components: { BioResultListValueTag },
  props: {
    bioResult: {
      type: BioResultTableItem,
      required: true,
    },
    selectedUnitIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    effectiveTimeText () {
      const [
        year,
        day,
        month,
        hour,
        minute,
        utc,
      ] = this.bioResult.effectiveTime.match(/^([0-9]{4})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})([+0-9]+)$/).filter((result, i) => i > 0)
      return `${format(new Date(`${year}-${month}-${day}T${hour}:${minute}${utc.startsWith('+') ? utc : utc.slice(2, 7)}`))}`
    },
  },
}
</script>