<template>
  <cim10-entity-autocomplete
    ref="field"
    v-model="selection"
    v-test="'antecedent-content-field'"
    :append-icon="appendIcon"
    :label="label"
    :item-value="itemValue"
    :rules="rules"
    :multiple="multiple"
  />
</template>

<script>
import Cim10EntityAutocomplete from '@/components/ui/form/Cim10EntityAutocomplete.vue'

import PatientPathology from '@/modules/patient/models/riskFactors/Pathology'

export default {
  name: 'PatientPathologyAutocomplete',
  components: { Cim10EntityAutocomplete },
  props: {
    value: {
      type: [PatientPathology, Array],
      default: null,
    },
    patientIri: {
      type: String,
      default: null,
    },
    rules: {
      type: [String, Array, Object],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: null,
    },
    label: {
      type: String,
      default: 'Pathologie',
    },
    appendIcon: {
      type: String,
      default: 'pathology',
    },
    itemValue: {
      type: String,
      default: 'icd10Code',
    },
  },
  computed: {
    selection: {
      get () {
        return this.value
      },
      set (selection) {
        this.$emit('input', this.multiple ? selection.map(this.normalizePathologyFromCim10Entity) : this.normalizePathologyFromCim10Entity(selection))
      },
    },
  },
  methods: {
    normalizePathologyFromCim10Entity (cim10Entity) {
      return new PatientPathology({
        ...cim10Entity,
        patient: this.patientIri,
      })
    },
    resetValidation () {
      this.$refs.field.resetValidation()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .synapse-autocomplete-wrapper__field__input {
    .options {
      width: 424px !important;
    }
  }
  .synapse-autocomplete-wrapper__field__input--mobile {
    .options {
      width: 80% !important;
    }
  }
}
</style>