<template>
  <div v-test="'mss-contact-point'" class="mss-contact-point">
    <app-icon icon="email" color="var(--v-secondary-lighten4)" class="mr-2 ml-3" />
    <div>
      <app-contact-point
        v-for="mss in mssContactPoints"
        :key="mss"
        :contact-point="{ value: mss }"
        :formatted-contact-point="false"
        :show-label="false"
        compact
      />
    </div>
  </div>
</template>

<script>
import AppIcon from '@/components/ui/icon/AppIcon.vue'
import AppContactPoint from '@/components/ui/contactPoint/AppContactPoint'

export default {
  name: 'CorrespondentsPanelItemMssContactPoint',
  components: {
    AppIcon,
    AppContactPoint,
  },
  props: {
    mssContactPoints: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.mss-contact-point {
  display: flex;
  gap: 10px;
}
</style>