import ApiEntity from '@/models/ApiEntity'
import { add, format, isFuture } from '@/utils/functions/dates'
import { getFromAPI } from '@/services/api'

export default class PatientFemaleMedicalDataset extends ApiEntity {
  constructor (options = {}) {
    super(options)
    this.patient = options.patient || null
    this.pregnant = options.pregnant || false
    this.breastfeeding = options.breastfeeding || false
    this.lastPeriodDate = options.lastPeriodDate ? format(options.lastPeriodDate, 'yyyy-MM-dd') : null
    this.conceptionDate = options.conceptionDate ? format(options.conceptionDate, 'yyyy-MM-dd') : this.getDefaultConceptionDate()
    this.deliveryDate = options.deliveryDate ? format(options.deliveryDate, 'yyyy-MM-dd') : this.getDefaultDeliveryDate()
    this.amenorrheaWeeks = options.amenorrheaWeeks || null
  }

  getDefaultConceptionDate () {
    const conceptionDate = (this.lastPeriodDate ? format(add(new Date(this.lastPeriodDate), { days: 14 }), 'yyyy-MM-dd') : null)
    if (! isFuture(new Date(conceptionDate))) {
      return conceptionDate
    }
    return null
  }

  getDefaultDeliveryDate () {
    return (this.conceptionDate ? format(add(new Date(this.conceptionDate), { days: 275 }), 'yyyy-MM-dd') : null)
  }

  static async fetch (uuid) {
    const { data } = await getFromAPI(uuid)
    return new PatientFemaleMedicalDataset(data)
  }
}