<template>
  <app-row>
    <app-col cols="12" md="4">
      <app-select
        v-test="'document-type-filter'"
        :value="documentType"
        label="Type de document"
        item-text="text"
        prepend-icon="keyword"
        :items="Object.values(SENT_PATIENT_DOC_LOG_DOCUMENT_TYPES)"
        @input="$emit('update:document-type', $event)"
      />
    </app-col>
    <app-col cols="12" md="4">
      <app-period-field
        :from="from"
        :to="to"
        @update:from="$emit('update:from', $event)"
        @update:to="$emit('update:to', $event)"
      />
    </app-col>
    <app-col cols="12" md="4">
      <organisation-practitioner-selector
        :value="practitionerRpps"
        clearable
        item-value="contact.practitionerProfile.rpps"
        @input="$emit('update:practitioner-rpps', $event)"
      />
    </app-col>
  </app-row>
</template>

<script>
import AppSelect from '@/components/ui/form/AppSelect.vue'
import AppPeriodField from '@/components/ui/form/AppPeriodField.vue'
import OrganisationPractitionerSelector from '@/components/ui/form/OrganisationPractitionerSelector.vue'

import { SENT_PATIENT_DOC_LOG_DOCUMENT_TYPES } from '@/modules/users/constants'

export default {
  name: 'AnalyticsSectionFilters',
  components: {
    AppPeriodField,
    OrganisationPractitionerSelector,
    AppSelect,
  },
  props: {
    documentType: {
      type: String,
      default: null,
      validator: value => Object.values(SENT_PATIENT_DOC_LOG_DOCUMENT_TYPES).find(type => type.value === value),
    },
    practitionerRpps: {
      type: String,
      default: null,
    },
    from: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
  },
  data () {
    return { SENT_PATIENT_DOC_LOG_DOCUMENT_TYPES }
  },
}
</script>