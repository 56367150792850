import { buildWidget, tokenService } from '@/services/synapse'

export default {
  props: {
    prescriptions: {
      type: Array,
      default: () => ([]),
    },
  },
  data () {
    return { widget: buildWidget(this.$options.name) }
  },
  computed: {
    securablePrescriptions () {
      return this.prescriptions.filter(medicineLine => medicineLine.isSecurable())
    },
    posologyAnalyzablePrescriptions () {
      return this.prescriptions.filter(medicineLine => medicineLine.isPosologyAnalyzable())
    },
    includesNotAnalysablePrescriptions () {
      return this.prescriptions.some(medicineLine => ! medicineLine.isPosologyAnalyzable())
    },
  },
  methods: {
    initWidget (data) {
      if (! tokenService.getToken()) {
        return new Promise((res) => {
          tokenService.onRefresh(() => {
            this.widget.init(this.$refs.widget, data)
            res()
          })
        })
      }
      this.widget.init(this.$refs.widget, data)
    },
    updateWidget (data) {
      this.widget.update(data)
    },
    destroyWidget () {
      this.widget.destroy()
    },
    /**
     * Event delegate de click :
     * Empêche la soumission d'un formulaire au click d'un bouton
     * Ex : Le bouton "Afficher/Masquer détails"
     */
    preventClickSubmit (event) {
      if (! event.target.closest('button')) {
        return
      }
      event.preventDefault()
    },
    preventEnterSubmit (event) {
      if (event.code !== 'Enter') {
        return
      }
      event.preventDefault()
    },
  },
  async created () {
    // Évite de refresh 2 fois le token
    if (tokenService.isRefreshing) {
      return
    }
    if (! tokenService.getToken()) {
      tokenService.refreshTokenAsync()
    }
  },
  mounted () {
    if (! this.$refs.widget) {
      throw new Error('Une ref "widget" doit être fournie pour afficher le widget.')
    }
    this.$refs.widget.addEventListener('click', this.preventClickSubmit, false)
    this.$refs.widget.addEventListener('keydown', this.preventEnterSubmit, false)

    this.widget.setCallback('hasResults', results => this.$emit('has-results-update', results))
  },
  beforeDestroy () {
    this.$refs.widget.removeEventListener('click', this.preventClickSubmit, false)
    this.$refs.widget.removeEventListener('keydown', this.preventEnterSubmit, false)
  },
  destroyed () {
    this.widget.onDestroy()
  },
}