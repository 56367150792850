<template>
  <app-modal
    :is-open="isOpen"
    title="Rechercher une e-prescription"
    persistent
    width="720"
  >
    <search-prescription-filter v-if="isOpen" ref="prescriptionFilter" @change="searchPrescription" />
    <search-prescription-result-list v-if="isOpen" v-model="searchResults" :is-fetching="isFetching" />
    <template #actions>
      <app-button
        color="primary"
        @click="close"
      >
        Fermer
      </app-button>
    </template>
  </app-modal>
</template>

<script>
import AppModal from '@/components/ui/modal/AppModal'
import ModalMixin from '@/components/ui/modal/mixins/ModalMixin'
import { Buffer } from 'buffer'
import { getFromAPI, postToAPI } from '@/services/api'
import iCanopeeCardReaderInstance from '@/services/vendors/icanopee/classes/ICanopeeCardReader'
import { mapGetters } from 'vuex'
import SearchPrescriptionResultList from '@/layout/default/components/header/searchMenu/searchPrescription/SearchPrescriptionResultList'
import SearchPrescriptionFilter from '@/layout/default/components/header/searchMenu/searchPrescription/SearchPrescriptionFilter'
import { mapMutations } from 'vuex'

export default {
  name: 'SearchPrescriptionModal',
  components: {
    SearchPrescriptionFilter,
    SearchPrescriptionResultList,
    AppModal,
  },
  mixins: [ModalMixin],
  data () {
    return {
      params: null,
      searchResults: [],
      isFetching: false,
    }
  },
  computed: { ...mapGetters('cardReaders', ['getPincode']) },
  methods: {
    ...mapMutations('app', ['SET_SNACK']),
    async searchPrescription (filterParameters) {
      this.isFetching = true
      try {
        const searchTemplate = await this.getTemplateCps('rechercherEPrescription')
        const consultationTemplate = await this.getTemplateCps('consulterEPrescription')

        // réinitialise le scan si celui-çi ne correspond pas à l'identifiant saisi
        if (! ((filterParameters.scan ? JSON.parse(filterParameters.scan) : null)?.D?.ID === filterParameters.amId)) {
          filterParameters.scan = null
        }

        const params = {
          ...filterParameters,
          searchTemplate,
          consultationTemplate,
        }
        const { data } = await postToAPI('/api/eprescription/search', params)

        if(data['hydra:member']) {
          this.searchResults = data['hydra:member']
        } else {
          this.SET_SNACK({
            color: 'info',
            message: data['hydra:description'],
          })
          this.searchResults = []
        }
      } catch (e) {
        if (e?.response?.data?.violations) {
          this.$refs.prescriptionFilter.showError(e)
        } else {
          if (e instanceof String) {
            this.SET_SNACK({
              color: 'warning',
              message: e,
            })
          }
        }
        this.searchResults = []
      }
      this.isFetching = false
    },
    async getTemplateCps (operation) {
      const { data: softwareInformations } = await getFromAPI('/api/software_information')
      const template = await iCanopeeCardReaderInstance.getEPrescriptionTemplate(
        softwareInformations,
        operation,
      )
      return template ? Buffer.from(template).toString('base64') : null
    },
    reset () {
      this.params = null
      this.searchResults = []
    },
    close () {
      this.reset()
      this.handleCancel()
    },
  },
}
</script>