import { cloneDeep, uniqBy } from 'lodash'
import { getFromAPI } from '@/services/api'
import NovaTools from '@/nova-tools/NovaTools'
import { isWithinInterval } from '@/utils/functions/dates'
import { pluralize, softInclude } from '@/utils/functions/words'
import PendingMssDocument from '@/modules/secureMessaging/modules/documents/models/PendingMssDocument'
import AuthenticationServiceInstance
  from '@/modules/secureMessaging/modules/authentication/services/AuthenticationService'

/**
 * Retourne une liste filtrée de documents non traités par numéro de version, sans afficher les versions précédentes d'un même document
 * @param {PendingMssDocument[]} pendingMssDocuments
 * @returns {PendingMssDocument[]}
 */
export const getDisplayablePendingMssDocuments = (pendingMssDocuments) => {
  const allPendingMssDocuments = pendingMssDocuments.map(pendingMssDocument => new PendingMssDocument(pendingMssDocument))
  return [
    ...allPendingMssDocuments.filter(document => ! document.metaDataset?.isReportOfBiologicalExaminations()),
    // Ne conserve que la dernière version des comptes rendus bio dans le cas d'une réception d'une version plus récente
    ...uniqBy(
      allPendingMssDocuments
        .filter(document => document.metaDataset?.isReportOfBiologicalExaminations())
        .sort((a, b) => b.metaDataset.versionNumber - a.metaDataset.versionNumber),
      bioResultDocument => bioResultDocument.metaDataset.setId,
    ),
  ]
}

/**
 * Retourne la liste des documents importés, sans les versions précédentes d'un même document
 * @returns {PendingMssDocument[]}
 */
export const fetchPendingMssDocuments = async () => {
  const { data } = await getFromAPI('/api/pending_mss_documents')
  return getDisplayablePendingMssDocuments(data['hydra:member'])
}

/**
 * Récupère les documents non traités de la messagerie de l'utilisateur, en le notifiant si besoin
 * @returns {PendingMssDocument[]}
 */
export const refreshPendingMssDocuments = async (options = {}) => {
  const { data } = await getFromAPI('/api/mss/retrieve_pending_documents', { mssEmail: AuthenticationServiceInstance.authenticationPayload.mssEmail }, { headers: { jSessionId: AuthenticationServiceInstance.authenticationPayload.jSessionId } })
  const documents = await fetchPendingMssDocuments()
  if (data.insertedPendingMssDocumentsCount > 0) {
    const count = data.insertedPendingMssDocumentsCount
    if (documents.some(pendingMssDocument => pendingMssDocument.metaDataset?.bioExamRelevantClinicalElemCritical)) {
      NovaTools.notify.warning('Des analyses biologiques avec valeurs critiques ont été reçues', options.onNewDocumentsNotifyOptions || null)
    } else {
      NovaTools.notify.success(`${count} ${pluralize('document', count)} ${pluralize('récupéré', count)}`, options.onNewDocumentsNotifyOptions || null)
    }
    return documents
  }
  NovaTools.notify.info('Pas de nouveau document à traiter')
  return documents
}

/**
 * Retourne une liste triée des documents en fonction du label associé au code de leurs métadonnées
 * @param {PendingMssDocument[]} documents
 * @param {Boolean} options.sortDesc
 * @returns {PendingMssDocument[]}
 */
export const sortByMetaDatasetCodeLabel = (documents, options = {}) => {
  const documentsClone = cloneDeep(documents)
  documentsClone.sort((a, b) => {
    if (! a.metaDataset) {
      return 0
    }
    if (! b.metaDataset) {
      return - 1
    }
    const leftType = a.metaDataset.getCodeDisplayName()
    const rightType = b.metaDataset.getCodeDisplayName()
    return options.sortDesc ? rightType.localeCompare(leftType) : leftType.localeCompare(rightType)
  })
  return documentsClone
}

/**
 * Retourne une liste triée des documents en fonction du patient associé à leurs métadonnées
 * @param {PendingMssDocument[]} documents
 * @param {Boolean} options.sortDesc
 * @returns {PendingMssDocument[]}
 */
export const sortByMetaDatasetPatient = (documents, options = {}) => {
  const documentsClone = cloneDeep(documents)
  documentsClone.sort((a, b) => {
    if (! a.metaDataset) {
      return 0
    }
    if (! b.metaDataset) {
      return - 1
    }
    const leftCivilState = `${a.metaDataset.patientBirthName} ${a.metaDataset.patientFirstNames}`
    const rightCivilState = `${b.metaDataset.patientBirthName} ${b.metaDataset.patientFirstNames}`
    return options.sortDesc ? rightCivilState.localeCompare(leftCivilState) : leftCivilState.localeCompare(rightCivilState)
  })
  return documentsClone
}

/**
 * Retourne une liste filtré des documents en fonction de différents paramètres
 * @param {PendingMssDocument[]} documents
 * @param {Boolean} options.patient Le patient associé aux métadonnées
 * @param {Boolean} options.type le label associé au code des métadonnées
 * @param {Boolean} options.fromDate la date minimale pour laquelle le document a été traité
 * @param {Boolean} options.toDate la date maximale pour laquelle le document a été traité
 * @returns {PendingMssDocument[]}
 */
export const getFilteredPendingMssDocuments = (documents, options = {}) => {
  const { patient, type, fromDate, toDate } = options
  return documents
    .filter(pendingDocument => {
      if ((patient || type) && ! pendingDocument.metaDataset) {
        return false
      }
      if (patient) {
        const { metaDataset } = pendingDocument
        const { patientFirstNames, patientBirthName } = metaDataset

        if (! patientFirstNames && ! patientBirthName) {
          return false
        }
        if (! softInclude(`${patientBirthName} ${patientFirstNames}`, patient)) {
          return false
        }
      }
      if (type) {
        const { metaDataset } = pendingDocument
        const { code } = metaDataset

        if (! code) {
          return false
        }
        if (! softInclude(metaDataset.getCodeDisplayName(), type)) {
          return false
        }
      }
      if (fromDate || toDate) {
        const { effectiveTime } = pendingDocument
        const startDate = fromDate ? new Date(fromDate) : new Date(0)
        const endDate = toDate ? new Date(toDate) : new Date()
        endDate.setHours(23)
        endDate.setMinutes(59)
        endDate.setSeconds(59)
        endDate.setMilliseconds(999)
        if (! isWithinInterval(new Date(effectiveTime), {
          start: startDate,
          end: endDate,
        })) {
          return false
        }
      }
      return pendingDocument
    })
}