import PortalVue from 'portal-vue'

/**
 * Le service permettant de gérer l'ensemble des portails de l'application
 */
export default {
  install (Vue) {
    Vue.use(PortalVue)

    Vue.prototype.$portals = Vue.observable({
      _index: 0,
      _portals: {},

      /**
       * Permet de retourner l'ensemble des portails d'une cible
       * @param {String} target Le nom du portail cible
       * @returns {Array}
       */
      getPortals (target) {
        return this._portals[target]
      },

      /**
       * Permet d'enregistrer un portail à une cible
       * @param {String} options.target Le nom du portail cible
       * @returns {String} L'id généré du portail
       */
      register ({ target }) {
        const portalId = `${target}_${this._index}`
        if (! this._portals[target]) {
          Vue.set(this._portals, target, [portalId])
        } else {
          this._portals[target].push(portalId)
        }
        this._index ++
        return portalId
      },

      /**
       * Permet de retirer un portail d'une cible
       * @param {String} portalId
       */
      unregister (portalId) {
        const target = portalId.split('_')[0]
        const portalIndex = this._portals[target]?.indexOf(portalId)
        if (portalIndex >= 0) {
          this._portals[target].splice(portalIndex, 1)
        }
      },

      /**
       * Permet de réinitialiser le plugin
       */
      reset () {
        this._index = 0,
        this._portals = {}
      },
    })
  },
}