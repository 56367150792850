<template>
  <app-col
    cols="12"
    md="6"
    lg="4"
    class="mb-n5"
  >
    <app-dataset-item
      label="Matricule INS"
      :value="localValue"
      vertical
      data-sentry-mask
      empty-sentence="Non renseigné"
      :separator-char="localValue ? `(${insTypeLabel})` : ''"
    />
  </app-col>
</template>

<script>
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin'
import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue'

export default {
  name: 'IdentityNirField',
  components: { AppDatasetItem },
  mixins: [localCopyMixin()],
  props: {
    value: {
      type: String,
      default: null,
    },
    oid: {
      type: String,
      default: null,
    },
  },
  computed: {
    insTypeLabel () {
      // https://esante.gouv.fr/sites/default/files/media_entity/documents/asip_referentiel_identifiant_national_sante-liste-des-oid-des-autorites-d-affectation-des-ins_v0.1.pdf
      const INS_NIA_AUTHORITY = '1.2.250.1.213.1.4.9'
      return this.oid === INS_NIA_AUTHORITY ? 'NIA' : 'NIR'
    },
  },
}
</script>