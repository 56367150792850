import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'

import IcanopeeDocument from '@/modules/patient/models/IcanopeeDocument'
import Patient from '@/modules/patient/models/Patient'
import NovaTools from '@/nova-tools/NovaTools'

export default class DocumentDmpSendingAction extends ActionMenuItem {
  /**
   *
   * @param {Patient} patient
   * @param {IcanopeeDocument} icanopeeDocument
   * @param {object} options les options de l'ActionMenuItem (@see /components/ui/actionsMenu/classes/ActionMenuItem.js)
   */
  constructor (patient, icanopeeDocument, options = {}) {
    super('upload', 'Envoyer dans le DMP', undefined, options)

    if (! patient) {
      throw 'Un patient est requis pour cette action'
    }
    if (! (patient instanceof Patient)) {
      throw 'Le patient doit être une instance de Patient'
    }
    if (! icanopeeDocument) {
      throw 'Un document est requis pour cette action'
    }
    if (! (icanopeeDocument instanceof IcanopeeDocument)) {
      throw 'Le document doit être une instance de IcanopeeDocument'
    }

    this.onSendingError = options.onSendingError

    if (! options.disabled) {
      if (! patient.hasIns()) {
        this.disabled = true
        this.disabledTooltip = 'Indisponible pour un patient sans INS'
      } else if (! NovaTools.icanopee.efficience.isSupportedFormat(icanopeeDocument.format)) {
        this.disabled = true
        this.disabledTooltip = `Le format de fichier ${icanopeeDocument.format?.toUpperCase() || ''} n'est pas accepté pour le dépôt dans le DMP`
      } else {
        this.callback = async () => {
          await NovaTools.icanopee.efficience.sendDocumentByIframe(patient, icanopeeDocument)
        }
      }
    }
  }
}