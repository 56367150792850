/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity'
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField'

const contactContactPointEntity = new DocumentEntity('ContactPoint', 'contactPoints.xml', 'Contient les points de contact des contacts')

contactContactPointEntity.addField(new DocumentEntityField('value', 'La valeur'))
contactContactPointEntity.addField(new DocumentEntityField('media', 'Le type de media (email | telephone | fax)'))
contactContactPointEntity.addField(new DocumentEntityField('label', 'Le label (*vide* | mss | mms-reply-to | secretariat | personal | business | mobile | other)'))

export default contactContactPointEntity