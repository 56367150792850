<template>
  <patient-compact-card-list-selector :patients="patientMatches" :selected-patient-iri="value" @update:selected-patient-iri="patientIri => $emit('input', patientIri)">
    <template #below="{ patient, isSelected }">
      <v-expand-transition>
        <div v-if="isSelected && !patient.insIdentity.isValidated()">
          <n-alert
            class="mt-2"
            transparent
            type="warning"
            :title="patient.insIdentity.getLabel()"
            message="Une vérification est nécessaire pour rattacher le document"
          />
          <patient-identity-verification-button
            v-test="'ins-verification'"
            class="mt-2"
            :patient="relatedPatient"
            @check-ins="updatePatientMatchIns(patient, $event)"
          />
        </div>
      </v-expand-transition>
    </template>
  </patient-compact-card-list-selector>
</template>

<script>
import PatientCompactCardListSelector from '@/modules/patient/components/PatientCompactCardListSelector.vue'
import PatientIdentityVerificationButton from '@/modules/patient/components/PatientIdentityVerificationButton.vue'

import Patient from '@/modules/patient/models/Patient'

import { INS_IDENTITY_STATUS } from '@/modules/patient/constants'
import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'PatientPendingDocumentMatchesList',
  components: {
    PatientCompactCardListSelector,
    PatientIdentityVerificationButton,
  },
  props: {
    relatedPatient: {
      type: Patient,
      required: true,
    },
    patientMatches: {
      type: Array,
      default: null,
      validator: patients => patients.every(patient => patient instanceof Patient),
    },
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async updatePatientMatchIns (patient, isValid) {
      if (isValid) {
        const patientToUpdateIdentity = new Patient({
          ...patient,
          insIdentity: {
            ...this.relatedPatient.insIdentity,
            '@id': patient.insIdentity['@id'],
            identityDevice: patient.insIdentity.identityDevice,
            status: patient.insIdentity.status === INS_IDENTITY_STATUS.VALIDATED.value
              ? INS_IDENTITY_STATUS.QUALIFIED.value
              : INS_IDENTITY_STATUS.FETCHED.value,
          },
        })
        await Patient.update(patientToUpdateIdentity)
        const patientMatchesIndex = this.patientMatches.findIndex(patientMatch => patientMatch['@id'] === patient['@id'])
        this.$emit(
          'update:patient-matches',
          this.patientMatches.map((patient, index) => index === patientMatchesIndex ? patientToUpdateIdentity : patient,
          ),
        )
        NovaTools.notify.info('Le document peut lui être rattaché', { title: `${patientToUpdateIdentity.getCivilState()} à désormais une ${patientToUpdateIdentity.insIdentity.getLabel().toLowerCase()}` })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .panel.panel--outlined.patient-matches-list__result--selected {
    border-color: var(--v-primary-base) !important;
  }
}

.patient-matches-list {
  color: var(--v-content-base);
  font-size: 13px;

  &--disabled {
    opacity: .5;
    pointer-events: none;
  }

  &__result {
    cursor: pointer;
    user-select: none;
  }
}
</style>