import ButtonAction from '@novalys/src/models/ButtonAction'

/**
 * Modèle d'une action de suppression fournie à un bouton
 * @property {Function} onClick callback à exécuter lors du click du bouton
 */
export default class DeleteButtonAction extends ButtonAction {
  constructor (onClick) {
    super('Supprimer', onClick, 'error')
  }
}