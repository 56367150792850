<template>
  <div class="px-4 py-2">
    <app-textarea-field
      v-model="medicalNote"
      v-test="'textarea-medical-note'"
      placeholder="Aucune remarque"
      :auto-grow="false"
      rows="10"
      autocomplete="on"
      @blur="submit"
    />
  </div>
</template>

<script>
import Patient from '@/modules/patient/models/Patient'
import AppTextareaField from '@/components/ui/form/AppTextareaField'
import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'MedicalNote',
  components: { AppTextareaField },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return { medicalNote: '' }
  },
  watch: {
    patient: {
      immediate: true,
      deep: true,
      handler () {
        this.medicalNote = this.patient.medicalNote
      },
    },
  },
  methods: {
    submit () {
      if (this.medicalNote !== this.patient.medicalNote) {
        const patient = new Patient({ ...this.patient })
        patient.medicalNote = this.medicalNote
        Patient.update(patient)
        NovaTools.notify.success('Les remarques ont été mises à jour avec succès')
      }
    },
  },
}
</script>