import { debounce } from 'lodash'

/**
 * Exporte une mixin pour autofocus un champ, avec décalage si nécessaire
 */
export default (inputRefName) => ({
  props: {
    /**
     * Autofocus
     */
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return { intersectionObserver: null }
  },
  watch: {
    autofocus: {
      immediate: true,
      handler () {
        this.focus()
      },
    },
  },
  methods: {
    /**
     * Debounce permettant d'éviter de stacker des appels sur une frame
     * Ce qui a pour effet de faire glitch les animations CSS
     */
    focus: debounce(function () {
      if (! this.autofocus || ! inputRefName) {
        return
      }

      this?.$refs?.[inputRefName]?.focus()
    }, 0, {
      leading: true,
      trailing: false,
    }),
    onIntersect (evt) {
      if (evt[0].isIntersecting) {
        this.focus()
      }
    },
  },
  mounted () {
    if (! this.autofocus || ! inputRefName) {
      return
    }

    this.intersectionObserver = new IntersectionObserver(this.onIntersect, {
      root: null,
      rootMargin: '0px',
      threshold: [1.0],
    })
    this.intersectionObserver.observe(this.$el.querySelector('input'))
  },
  destroyed () {
    if (! this.autofocus || ! inputRefName) {
      return
    }

    this.intersectionObserver.unobserve(this.$el.querySelector('input'))
  },
})