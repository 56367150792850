<template>
  <v-dialog
    v-model="opened"
    :transition="modalTransition"
    :fullscreen="fullscreen"
    :persistent="persistent"
  >
    <slot name="default" />
  </v-dialog>
</template>

<script>
import ModalMixin from '@/components/ui/modal/mixins/ModalMixin'
import { mapState } from 'vuex'

/**
 * Affiche une fenêtre modale
 */
export default {
  name: 'AppDialogVue',
  mixins: [ModalMixin],
  props: {
    /**
     * Rend la modale plein écran
     * @default false
     */
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Empêche la fermeture de la modale avec la touche Echap
     * @default false
     */
    persistent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState('app', ['isMobile']),
    modalTransition () {
      return this.isMobile ? 'dialog-bottom-transition' : 'dialog-transition'
    },
  },
}
</script>

<style scoped>

</style>