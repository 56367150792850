import ApiFile from '@/models/ApiFile'
import MssDocumentMetaDataset from '@/modules/secureMessaging/modules/documents/models/MssDocumentMetaDataset'

import { deleteFromAPI, getFileFromAPI, getFromAPI, postFileToAPI, putToAPI } from '@/services/api'
import context from '@/services/appContext'

import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'
import DeleteActionMenuItem from '@/components/ui/actionsMenu/classes/DeleteActionMenuItem'
import IcanopeeDocument from '@/modules/patient/models/IcanopeeDocument'
import MessagingBioResultsViewerAction from '@/modules/secureMessaging/modules/documents/classes/MessagingBioResultsViewerAction.js'
import DocumentMessagingSendingAction from '@/modules/patient/classes/DocumentMessagingSendingAction.js'
import DocumentDmpSendingAction from '@/modules/patient/classes/DocumentDmpSendingAction.js'

import { PATIENT_MESSAGING_DOCUMENT_SENDING_DENIAL_TEXT } from '@/modules/patient/constants'
import PatientDocumentFolder
  from '@/modules/patient/modules/patientFile/modules/documents/components/documentsExplorer/models/PatientDocumentFolder'
import NovaTools from '@/nova-tools/NovaTools'

export default class PatientDocument extends ApiFile {
  constructor (options = {}) {
    super(options)
    this.patient = options.patient || null
    this.title = options.title || null
    this.description = options.description || null
    this.fromMss = options.fromMss || false
    this.cda = options.cda || false
    this.dmpUniqueId = options.dmpUniqueId || null
    this.metaDataset = options.metaDataset ? new MssDocumentMetaDataset(options.metaDataset) : null
    this.editing = options.editing || false
  }

  startEditing () {
    this.editing = true
  }

  /**
   * Permet de récupérer l'ensemble des documents pour un patient
   * @param {String} patient L'UUID du patient concerné
   * @returns {PatientDocument[]}
   */
  static async fetchAll ({ patient }) {
    const { data } = await getFromAPI(`/api/patients/${patient}/imported_documents`)
    return data['hydra:member'].map(document => new PatientDocument(document))
  }

  /**
   * Permet de récupérer l'ensemble des documents pour un patient sous forme d'arbre de dossiers
   * @param {String} patient L'UUID du patient concerné
   * @param {String} title
   * @param {String} type
   * @param {String} date
   * @returns {Object}
   */
  static async fetchAsTree ({ patient, title, type, date }) {
    const { data } = await getFromAPI(`/api/patients/${patient}/imported_documents/tree`, {
      title,
      type,
      date,
    })
    return [PatientDocument.getStructuredTreeItem(data)][0].children
  }

  static getStructuredTreeItem (data, level = 0) {
    const children = []
    data.children.forEach((item) => {
      children.push(PatientDocument.getStructuredTreeItem(item, level + 1))
    })
    data.documents.forEach((item) => {
      children.push({
        name: item.title,
        file: item.mimeType,
        data: new PatientDocument(item),
        parent: level > 0 ? data['@id'] : null,
      })
    })
    return {
      name: data.title,
      children,
      data: new PatientDocumentFolder(data),
      level,
    }
  }

  /**
   * Mettre à jours un fichier à partir de l'API
   * @param {PatientDocument} patientDocument
   * @param {Boolean} showNotification Permet d'afficher le message de succès
   * @returns {PatientDocument}
   */
  static async update (patientDocument, showNotification = true) {
    const { data } = await putToAPI(patientDocument['@id'], { data: patientDocument })
    if (showNotification) {
      NovaTools.notify.success('Le fichier a été modifié avec succès')
    }
    return new PatientDocument(data)
  }

  /**
   * Permet l'upload d'un fichier vers un dossier patient
   * @param {{ patientDocument, file }}
   * @returns {PatientDocument}
   */
  static async upload ({ patientDocument, file, folder }) {
    const formData = new FormData()
    formData.append('file', file, file.name)
    formData.append('title', patientDocument.title)
    formData.append('patient', patientDocument.patient)
    if (folder) {
      formData.append('folder', folder)
    }
    const { data: document } = await postFileToAPI('/api/patient_imported_documents', formData)
    return new PatientDocument(document)
  }

  static async multiUpload ({ files, patient, folder }) {
    const postResults = []
    let hasSuccess = 0
    let hasError = 0
    for (const [index, curFile] of files.entries()) {
      try {
        const uploadPatientDocument = new PatientDocument(curFile.fileUrl)
        uploadPatientDocument.title = curFile.title
        uploadPatientDocument.patient = patient
        await this.upload({
          patientDocument: uploadPatientDocument,
          file: curFile.file,
          folder,
        })
        hasSuccess = 1
        postResults.push({
          success: true,
          index: index,
        })
      } catch (error) {
        let errorMessage = 'Une erreur s\'est produite lors du téléversement'
        if (error.response && error.response.data && error.response.data.violations) {
          // Gérér plusieurs erreurs si possible - violations[1], ...[n]
          errorMessage = error.response.data.violations[0].message
        } else if (error.message) {
          errorMessage = error.message
        }
        hasError = 1
        postResults.push({
          success: false,
          index: index,
          error: errorMessage,
        })
      }
    }
    if (hasError) {
      if (hasSuccess) {
        NovaTools.notify.success('Certains fichiers ont été importés avec succès')
      }
      return postResults
    }
    NovaTools.notify.success('Les fichiers ont été importés avec succès')
  }

  static async moveToFolder (documentIri, folderIri) {
    const { data } = await putToAPI(documentIri, { data: { folder: folderIri } })
    return new PatientDocument(data)
  }

  getFormattedDescription () {
    if (this.metaDataset?.isReportOfBiologicalExaminations()) {
      return `Version ${this.metaDataset.versionNumber || 'inconnue'}`
    }
    return this.description || 'Pas de description'
  }

  getIconsMetadata () {
    const icons = []
    if (this.fromMss) {
      icons.push({
        icon: 'email',
        color: 'content',
        tooltip: 'Importé depuis la messagerie',
      })
    }
    if (this.metaDataset) {
      icons.push(this.metaDataset.getBioIconPropsFromMetaDataset())
    }
    return icons
  }

  /**
   * Retourne l'ensemble des actions
   * @param {Object} options
   * @param {Object} options.patient L'instance du patient
   * @param {Function} options.onOpenImportFilesModal callback à l'ouverture de la modal pour importer des fichiers
   * @param {Function} options.onOpenNewDirectoryModal callback à louverture de la modal pour créer un dossier
   * @param {Function} options.onDeleted callback à l'ouverture de la modal pour supprimer un document
   * @returns {Array}
   */
  getMenuActions (options = {}) {
    const document = this
    const { patient } = document?.patient['@type'] === 'Patient' ? document : context.patientDocument.getContext({ patientIri: document.patient })
    const { isCurrentUserPractitioner } = context.user.getContext()

    const primaryActions = []
    const secondaryActions = []

    primaryActions.push(new ActionMenuItem('download', 'Télécharger', () => window.open(document.getFullContentURL(), '_blank').focus()))
    if (isCurrentUserPractitioner) {
      let disabled = false, disabledTooltip = null
      if (options?.patient) {
        const isDisabled = ! options.patient.mssDocumentConsent
        disabled = isDisabled
        disabledTooltip = isDisabled ? PATIENT_MESSAGING_DOCUMENT_SENDING_DENIAL_TEXT : null
      }
      document.patient = patient
      primaryActions.push(new DocumentMessagingSendingAction(document, {
        disabled,
        disabledTooltip,
      }))
    }
    if (document.metaDataset) {
      primaryActions.push(new MessagingBioResultsViewerAction(document))
    }
    if (document.cda) {
      primaryActions.push(new ActionMenuItem('visualize', 'Visualiser l\'archive', async () => {
        const { data: documentBlob } = await getFileFromAPI(`${this.getIri()}/cda`)
        window.open(
          window.URL.createObjectURL(documentBlob, { type: 'text/html' }),
          '_blank',
        )
      }))
    }
    primaryActions.push(new DocumentDmpSendingAction(patient, new IcanopeeDocument(patient.insIdentity?.ins, {
      iri: document['@id'],
      title: document.title,
      description: document.description,
      contentUrl: document.getFullContentURL(),
      format: NovaTools.icanopee.efficience.convertToIcanopeeFormat(document.mimeType),
      dmpUniqueId: document.dmpUniqueId,
      documentType: document['@type'],
      // Doit être implémenté dans l'US #1831
      // typeCode: document.category,
    })))
    secondaryActions.push(new ActionMenuItem('edit', 'Renommer', () => {
      document.startEditing()
    }))
    secondaryActions.push(new DeleteActionMenuItem({
      callback: async () => {
        await deleteFromAPI(document['@id'])
        options.onDeleted?.(document)
      },
      options: {
        confirmTitle: 'Supprimer le fichier',
        confirmText: `Êtes-vous sûr(e) de vouloir supprimer le fichier "${document.title}" ?`,
      },
    }))
    return [primaryActions, secondaryActions]
  }
}