import Consultation from '@/modules/patient/models/Consultation'
import Payment from '@/modules/accounting/models/Payment'

export default class DetailedConsultation extends Consultation {
  constructor (options = {}) {
    super(options)
    this.payments = options.payments
      ? options.payments.map(p => new Payment(p))
      : []
  }
}