<template>
  <app-form :submit-method="saveNonDrugAllergy">
    <app-text-field
      v-model="localNonDrugAllergy.label"
      label="Nom de l'allergie"
      hide-errors="auto"
      rules="required"
      data-test="non-drug-allergy-label-field"
      class="mb-2"
    />
    <app-textarea-field
      v-model="localNonDrugAllergy.note"
      label="Commentaire (optionnel)"
      hide-errors="auto"
      data-test="non-drug-allergy-note-field"
      rows="1"
      auto-grow
    />
  </app-form>
</template>

<script>
import AppForm from '@/components/ui/form/AppForm.vue'
import AppTextField from '@/components/ui/form/AppTextField.vue'
import AppTextareaField from '@/components/ui/form/AppTextareaField.vue'

import { mapActions, mapMutations } from 'vuex'

import Patient from '@/modules/patient/models/Patient'
import PatientNonDrugAllergy from '@/modules/patient/models/riskFactors/PatientNonDrugAllergy'

export default {
  name: 'NonDrugAllergyForm',
  components: {
    AppForm,
    AppTextField,
    AppTextareaField,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return { localNonDrugAllergy: new PatientNonDrugAllergy({ patient: this.patient['@id'] }) }
  },
  computed: {
    isEditing () {
      return !! this.localNonDrugAllergy['@id']
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler (value) {
        this.localNonDrugAllergy = new PatientNonDrugAllergy(value ? {
          ...value,
          patient: this.patient['@id'],
        } : { patient: this.patient['@id'] })
      },
    },
  },
  methods: {
    ...mapMutations('app', ['SET_SNACK']),
    ...mapActions('patient', ['insertPatientNonDrugAllergy', 'updatePatientNonDrugAllergy']),
    async saveNonDrugAllergy () {
      let message
      if (this.isEditing) {
        await this.updatePatientNonDrugAllergy(this.localNonDrugAllergy)
        message = 'L\'allergie a été modifiée avec succès'
      } else {
        await this.insertPatientNonDrugAllergy(this.localNonDrugAllergy)
        message = 'L\'allergie a été ajoutée avec succès'
      }
      this.SET_SNACK({ message })
    },
  },
}
</script>

<style>

</style>