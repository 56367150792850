/* eslint-disable object-property-newline */

import DocumentEntity from '@/modules/dataPortability/classes/DocumentEntity'
import DocumentEntityField from '@/modules/dataPortability/classes/DocumentEntityField'
import DocumentEntityRelation from '@/modules/dataPortability/classes/DocumentEntityRelation'

const patientInsIdentityEntity = new DocumentEntity('PatientInsIdentity', 'patientInsIdentities.xml', 'Contient les données INS des patients')

patientInsIdentityEntity.addField(new DocumentEntityField('status', 'Le statut (temporary | fetched | validated | qualified)'))
patientInsIdentityEntity.addField(new DocumentEntityField('attribute', 'L\'attribut (homonym | suspicious | fictitious)'))
patientInsIdentityEntity.addField(new DocumentEntityField('ins', 'L\'INS'))
patientInsIdentityEntity.addField(new DocumentEntityField('oid', 'L\'OID'))
patientInsIdentityEntity.addField(new DocumentEntityField('device', 'Le dispositif', new DocumentEntityRelation('IdentityDeviceRef', 'id')))

export default patientInsIdentityEntity