<template>
  <div class="dashboard-events-timeline">
    <app-timeline
      :items="events"
      :time-key="isMobile ? null : 'startDateTime'"
      :loading="loading"
      data-test="timeline"
    >
      <template #default="{ item }">
        <dashboard-events-timeline-appointment :appointment="item" data-test="timeline-appointment" class="dashboard-events-timeline__appointment" />
      </template>
    </app-timeline>
  </div>
</template>

<script>
import AppTimeline from '@/components/ui/timeline/AppTimeline.vue'
import DashboardEventsTimelineAppointment from './appointment/DashboardEventsTimelineAppointment.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'DashboardEventsTimeline',
  components: {
    AppTimeline,
    DashboardEventsTimelineAppointment,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: { ...mapGetters('app', ['isMobile']) },
}
</script>

<style lang="scss" scoped>
.dashboard-events-timeline {
  padding-top: 4px;
  overflow: visible !important;

  ::v-deep {
      .v-timeline-item {
        &__body {
          margin-top: -6px;
        }
      }
  }
}
</style>