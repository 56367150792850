<template>
  <div>
    <app-modal-form
      :title="tlsiModalMixin_modalFormTitle"
      submit-text="Transmettre"
      :form="AldiForm"
      :value="patient"
      width="720"
      :is-opened.sync="tlsiModalMixin_isOpen"
      @cancel="tlsiModalMixin_closeModalForm"
      @submitError="tlsiModalMixin_closeModalForm"
      @submitSuccess="handleAldiRequestSuccess"
    />
    <aldi-result-summary-modal :response-payload="responsePayload" />
  </div>
</template>

<script>
import AppModalForm from '@/components/ui/modal/AppModalForm.vue'
import AldiForm from '@/modules/patient/components/sidebar/tlsi/aldi/AldiForm.vue'
import AldiResultSummaryModal from '@/modules/patient/components/sidebar/tlsi/aldi/AldiResultSummaryModal'

import tlsiModalMixin from '@/modules/patient/components/sidebar/tlsi/tlsiModalMixin'

import Patient from '@/modules/patient/models/Patient'

import { TLSI_TYPES } from '@/modules/patient/constants'

export default {
  name: 'AldiModalForm',
  components: {
    AppModalForm,
    AldiResultSummaryModal,
  },
  mixins: [tlsiModalMixin(TLSI_TYPES.ALDI)],
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      AldiForm,
      responsePayload: {
        report: null,
        beneficiary: null,
        aldList: [],
      },
    }
  },
  computed: {
    /**
     * Permet de ne pas rendre la modale avec certaines de ces props required à null
     */
    shouldResponseModalExists () {
      return Object.values(this.responsePayload).every(value => !! value)
    },
  },
  methods: {
    handleAldiRequestSuccess (formattedAldiResult) {
      this.tlsiModalMixin_closeModalForm()
      this.responsePayload = formattedAldiResult
    },
  },
}
</script>