<template>
  <div class="patient-dashboard">
    <app-tabs v-if="isMobile" v-test="'mobile-tabs'" class="patient-dashboard__mobile-tabs">
      <app-tabs-item v-test="'medical-history-tab'" label="Historique médical">
        <medical-history ref="medical-history" v-test="'medical-history'" :patient="patient" />
      </app-tabs-item>
      <app-tabs-item v-test="'complements-tab'" label="Compléments">
        <app-row>
          <app-col class="patient-dashboard__right-column" cols="12">
            <referring-physician-panel v-test="'patient-referring-physician'" :patient="patient" />
          </app-col>
        </app-row>
        <app-row>
          <app-col class="patient-dashboard__right-column" cols="12">
            <appointments-panel v-test="'patient-appointments-panel'" :patient="patient" />
          </app-col>
        </app-row>
      </app-tabs-item>
    </app-tabs>
    <app-row v-else v-test="'desktop-grid'" class="patient-dashboard__desktop-grid">
      <app-col cols="6" md="8">
        <medical-history
          ref="medical-history"
          v-test="'medical-history'"
          :patient="patient"
          class="patient-dashboard__desktop-grid__medical-history"
        />
      </app-col>
      <app-col cols="6" md="4">
        <app-row>
          <app-col class="patient-dashboard__right-column" cols="12">
            <referring-physician-panel v-test="'patient-referring-physician'" :patient="patient" />
          </app-col>
        </app-row>
        <app-row>
          <app-col class="patient-dashboard__right-column" cols="12">
            <appointments-panel v-test="'patient-appointments-panel'" :patient="patient" />
          </app-col>
        </app-row>
      </app-col>
    </app-row>
  </div>
</template>

<script>
import MedicalHistory from '@/modules/patient/modules/patientFile/modules/overview/components/medicalHistory/MedicalHistory.vue'
import AppointmentsPanel from '@/modules/patient/modules/patientFile/modules/overview/components/appointmentsPanel/AppointmentsPanel.vue'
import ReferringPhysicianPanel from '@/modules/patient/modules/patientFile/modules/overview/components/referringPhysicianPanel/ReferringPhysicianPanel.vue'

import Patient from '@/modules/patient/models/Patient'

import { mapGetters } from 'vuex'

export default {
  name: 'PatientOverviewModule',
  components: {
    MedicalHistory,
    AppointmentsPanel,
    ReferringPhysicianPanel,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  computed: { ...mapGetters('app', ['isMobile']) },
  methods: {
    clearMedicalHistoryFilters () {
      this.$refs['medical-history'].clearFilters()
    },
  },
}
</script>

<style lang="scss" scoped>
$tabs-height: 48px;

.patient-dashboard {
  position: relative;
  height: 100%;

  &__desktop-grid {
    height: 100%;

    &__medical-history {
      height: 100%;
    }
  }

  &__mobile-tabs {
    margin: 0 -#{map-get($spacers, 4)};
  }

  &__right-column {
    display: flex;
    flex-direction: column;
    gap: map-get($spacers, 6);
  }
}
</style>