import BaseFilter from './BaseFilter'

/**
 * Callback pour la résolution de la valeur
 * @callback ValueGetterCallback
 * @param {*} componentValue La valeur du v-model
 * @returns {*} La nouvelle valeur de filtre
 */

/**
 * Structure définissant les options complémentaires à BaseFilter
 * @typedef {Object} ToggleableFilterExtendedOptions
 * @property {ValueGetterCallback} valueGetter Permet de fournir la nouvelle valeur en fonction du v-model
 */

/**
 * Structure définissant les options de configuration d'un filtre toggleable
 * @typedef {import('@/utils/classes/httpRequestParameters/filters/BaseFilter').BaseFilterOptions & ToggleableFilterExtendedOptions} ToggleableFilterOptions
 */

/**
 * Classe représentant un filtre avec pour valeur :
 * - Une valeur falsy
 *   OU
 * - N'importe quelle autre valeur truthy
 */
export default class ToggleableFilter extends BaseFilter {
  /**
   * Callback permettant d'affecter une valeur au filtre lorsque le toggleable est différent de faux
   * @type {ValueGetterCallback}
   */
  valueGetter

  labelGetter

  /**
   * Permet de créer un nouveau filtre toggleable
   * @param {ToggleableFilterOptions} options Les options de création de filtre toggleable
   */
  constructor (options = {}) {
    super(options)
    this.valueGetter = options.valueGetter
    this.labelGetter = options.labelGetter
    if (! this.valueGetter) {
      throw new Error('Les filtres toggleables doivent fournir la méthode "valueGetter"')
    }

    this.onComponentValueChanged = (vm) => {
      if (typeof this.componentValue !== 'boolean') {
        this.updateValue(null)
        return
      }
      if (this.labelGetter) {
        this.label = this.labelGetter(this.componentValue, vm)
      }
      this.updateValue(this.valueGetter(this.componentValue, vm))
    }
  }
}