const PREVIOUS_RESULT_SECTION_TYPE = 'REFR'

export default class BioResultTableItem {
  constructor (bioResult, options = {}) {
    this.name = bioResult.displayName
    this.interpretationCode = bioResult.interpretationCode
    this.effectiveTime = bioResult.effectiveTime
    this.values = bioResult.values
    this.previousResults = bioResult.sections?.filter(section => section.type === PREVIOUS_RESULT_SECTION_TYPE).map(result => new BioResultTableItem(result, options)) || [],

    this.category = options.category || null
    this.selectedUnitIndex = options.selectedUnitIndex || 0
  }

  getDisplayableTableItemValueRange () {
    const range = this.values[this.selectedUnitIndex].range
    if (! range.low && ! range.high) {
      return '-'
    }
    return `(${parseFloat(range.low)} - ${parseFloat(range.high)})`
  }
}