<template>
  <div class="app-stepper" :class="{'d-flex align-center': ! verticalAlignment}">
    <template v-for="(step, stepIndex) in steps">
      <div
        :key="stepIndex"
        v-test="'step'"
        class="app-stepper__step"
        :class="getStepClasses(stepIndex)"
        @click="handleClickStep(stepIndex)"
      >
        <span v-test="'step-index'" class="app-stepper__step__badge">
          {{ stepIndex + 1 }}
        </span>
        <p v-test="'step-name'" class="app-stepper__step__name">
          {{ step.text }}
        </p>
      </div>
      <n-divider v-if="stepIndex !== steps.length - 1" :key="`divider-${stepIndex}`" class="mx-6" />
    </template>
  </div>
</template>

<script>
/**
 * Le composant d'étapes de l'application
 * Utile pour représenter une timeline d'actions pour un formulaire par exemple
 */
export default {
  name: 'AppStepper',
  props: {
    /**
     * Les différentes étapes à afficher
     * @example
     * [ { text: 'Étape 1' }, { text: 'Étape 2' }, ... ]
     */
    steps: {
      type: Array,
      required: true,
    },
    /**
     * L'index de l'étape courante
     * @model
     */
    value: {
      type: Number,
      default: 0,
    },
    /**
     * Détermine si les étapes sont affichées verticalement
     */
    verticalAlignment: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getStepClasses (stepIndex) {
      return {
        'app-stepper--active': stepIndex === this.value,
        'app-stepper--passed': stepIndex < this.value,
        'app-stepper--coming': stepIndex > this.value,
      }
    },
    handleClickStep (stepIndex) {
      this.$emit('input', stepIndex)
    },
  },
}
</script>

<style lang="scss" scoped>
$badge-size: 24px;

.app-stepper {
  &--active &__step__badge {
    background-color: var(--v-primary-base);
    color: white;
  }
  &--active &__step__name {
    font-weight: 500;
    color: var(--v-content-base);
  }
  &--passed &__step__badge {
    background-color: var(--v-secondary-base);
    color: white;
  }
  &--passed &__step__name {
    color: var(--v-content-base);
  }
  &--coming {
    pointer-events: none;
  }
  &--coming &__step__badge {
    background-color: var(--v-secondary-lighten5);
    color: var(--v-secondary-base);
  }
  &--coming &__step__name {
    color: var(--v-secondary-base);
  }

  &__step {
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;

    &__badge {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      min-width: $badge-size;
      width: $badge-size;
      height: $badge-size;
      font-size: 13px;
      font-weight: 500;
      user-select: none;
      margin-right: map-get($spacers, 2);
    }

    &__name {
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>