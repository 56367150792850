import Vue from 'vue'

import { getFromAPI, postToAPI, putToAPI } from '@/services/api'
import Footer from '@/modules/documents/models/Footer'

export const state = () => ({
  footers: {},
  isLoadingFooters: false,
})

export const getters = {
  getFooters: state => Object.values(state.footers),
  getFooterByUuid: state => footerUuid => state.footers['/api/document_footers/' + footerUuid],
  isLoadingFooters: state => state.isLoadingFooters,
}

export const mutations = {
  SET_FOOTER (state, footer) {
    Vue.set(state.footers, footer['@id'], new Footer(footer))
  },
  SET_IS_LOADING_FOOTERS (state, isLoadingFooters) {
    state.isLoadingFooters = isLoadingFooters
  },
}

export const actions = {
  async fetchFooters ({ commit }) {
    commit('SET_IS_LOADING_FOOTERS', true)
    try {
      const response = await getFromAPI('/api/document_footers')
      response.data['hydra:member'].forEach(footer => commit('SET_FOOTER', footer))
    } catch (error) {}
    commit('SET_IS_LOADING_FOOTERS', false)
  },
  async fetchOneFooter ({ commit }, footerId) {
    const response = await getFromAPI('/api/document_footers/' + footerId)
    commit('SET_FOOTER', response.data)
  },
  async insertFooter ({ commit }, footer) {
    const response = await postToAPI('/api/document_footers', footer)
    commit('SET_FOOTER', response.data)
  },
  async updateFooter ({ commit }, footer) {
    const { data } = await putToAPI(footer['@id'], { data: footer })
    commit('SET_FOOTER', data)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}