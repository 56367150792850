<template>
  <div class="time-preview" :style="{ top: posY, height }">
    {{ formattedTime }}
  </div>
</template>

<script>
import { leftPad } from '@/utils/functions/number'

const DEFAULT_DURATION = 30

export default {
  name: 'CalendarTimePreview',
  props: {
    hour: {
      type: Number,
      default: 0,
    },
    minute: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    posY () {
      return this.$parent.timeToY({
        hour: this.hour,
        minute: this.minute,
      }) + 'px'
    },
    height () {
      return this.$parent.minutesToPixels(DEFAULT_DURATION) + 'px'
    },
    formattedTime () {
      return `${leftPad(this.hour, 2)}:${leftPad(this.minute, 2)}`
    },
  },
}
</script>

<style lang="scss" scoped>
.time-preview {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--v-content-base);
  color: #fff;
  padding: 0 map-get($spacers, 1);
  width: 100%;
  height: 100%;
  font-size: 10px;
  pointer-events: none;
  user-select: none;
  z-index: 10;
}
</style>