import security from '@/nova-tools/security'
import dialog from '@/nova-tools/dialog/dialog'
import files from '@/nova-tools/files/files'
import modal from '@/nova-tools/modal/modal'
import notify from '@/nova-tools/notify/notify'
import dates from '@/nova-tools/dates/dates'
import base64 from '@/nova-tools/base64/Base64'
import icanopee from '@/nova-tools/icanopee'
import requests from '@/nova-tools/request/requests'
import dmp from '@/nova-tools/dmp/dmp'
import utils from '@/nova-tools/utils/utils'
import traces from '@/nova-tools/traces/traces'
export default {
  security,
  dialog,
  files,
  modal,
  notify,
  dates,
  base64,
  icanopee,
  requests,
  dmp,
  utils,
  traces,
}