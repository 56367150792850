export const DOCUMENT_TYPES = Object.freeze({
  DISCHARGE_LETTER: {
    icon: 'email',
    title: 'Lettre de liaison',
    value: 'dischargeLetter',
  },
  FOLLOW_UP_LETTER: {
    icon: 'email',
    title: 'Lettre de suivi',
    value: 'followUpLetter',
  },
  CERTIFICATE: {
    icon: 'certificate',
    title: 'Certificat',
    value: 'certificate',
  },
  TELEMEDCINE_ACT: {
    title: 'Acte de télémédecine',
    icon: 'telemedicine',
    value: 'telemedicineAct',
  },
})