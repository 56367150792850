export const PRESCRIPTION_TYPES = Object.freeze({
  HEALTH_PRODUCT: {
    text: 'Produit(s) de santé',
    value: 'health_product',
  },
  NURSE: {
    text: 'Infirmier(s)',
    value: 'nurse',
  },
  PEDICURE: {
    text: 'Pédicurie',
    value: 'pedicure',
  },
  PHYSICAL: {
    text: 'Kinésithérapie',
    value: 'physical',
  },
  ORTHOPTICS: {
    text: 'Orthoptie',
    value: 'orthoptics',
  },
  SPEECH: {
    text: 'Orthophonie',
    value: 'speech',
  },
  BIO: {
    text: 'Biologie médicale',
    value: 'bio',
  },
  MEDICAL_IMAGING: {
    text: 'Imagerie Médicale',
    value: 'medical_imaging',
    preventEprescription: true,
  },
})