<template>
  <app-modal
    :is-open="isOpen"
    title="Sélectionnez un assuré de la carte vitale"
    hide-footer
    scrollable
    @cancel="close"
  >
    <vitale-card-insured-list-selector @click:insured="handleClickInsured" />
  </app-modal>
</template>

<script>
import AppModal from '@/components/ui/modal/AppModal.vue'
import VitaleCardInsuredListSelector from '@/modules/patient/components/VitaleCardInsuredListSelector.vue'

export default {
  name: 'VitalCardInsuredsModal',
  components: {
    AppModal,
    VitaleCardInsuredListSelector,
  },
  props: {
    insureds: {
      type: Array,
      default: null,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClickInsured (insuredRelatedPatient) {
      this.$emit('click:insured', insuredRelatedPatient)
      this.close()
    },
    close () {
      this.$emit('update:isOpen', false)
    },
  },
}
</script>