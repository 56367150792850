import { getCivilityShortLabel } from '@/utils/functions/refs'
import { arrayToSentence } from '@/utils/functions/joiner'
import { capitalize } from '@/utils/functions/words'

/**
 * Permet de récupérer l'état civil d'une personne
 * Civilité + nom d'usage + prénom
 * ex : M POUPARD Erwan
 * @param {Object} person L'entité d'une personne fournie par l'api
 * @param {Object} options
 * @param {Boolean} options.shortenFirstNames Raccourci les prénoms à une initiale (David => D.)
 * @param {Boolean} options.hideCivility Retire la civilité (Dr DAVID James => DAVID James)
 * @param {Boolean} options.firstNameFirst Affiche le prénom avant le nom (DAVID James => James DAVID)
 * @returns {String} l'état civil complet
 */

export const getCivilState = (person = {}, { shortenFirstNames = false, hideCivility = false, firstNameFirst = false } = {}) => {
  const { firstName, firstNames, familyName, civility } = person

  let shortCivility, firstNameInitials
  const processedFirstName = firstName ? firstName : firstNames

  if (civility && ! hideCivility) {
    shortCivility = getCivilityShortLabel(civility)
  }
  if (shortenFirstNames) {
    firstNameInitials = processedFirstName.split('-').map(firstName => firstName[0]).join('') + '.'
  }

  if (firstNameFirst) {
    return arrayToSentence(
      [
        shortCivility || '',
        processedFirstName ? firstNameInitials || capitalize(processedFirstName) : '',
        familyName ? familyName.toUpperCase() : '',
      ], ' ', ' ')
  }

  return arrayToSentence(
    [
      shortCivility || '',
      familyName ? familyName.toUpperCase() : '',
      processedFirstName ? firstNameInitials || capitalize(processedFirstName) : '',
    ], ' ', ' ')
}