import ApiEntity from '@/models/ApiEntity'
import RecurrencePattern from './RecurrencePattern'
import Motive from '@/modules/agenda/models/Motive'
export default class EventSetting extends ApiEntity {
  constructor (options = {}) {
    super(options)
    this.title = options.title || null
    this.date = options.date || new Date().toISOString().substr(0, 10)
    this.intervals = options.intervals || [{
      start: '09:00',
      end: '12:00',
    }]
    this.recurrencePattern = options.recurrencePattern
      ? new RecurrencePattern(options.recurrencePattern)
      : null
    this.schedule = options.schedule || null
    this.appointmentMotives = options.appointmentMotives?.length > 0
      ? options.appointmentMotives.map(motive => new Motive(motive))
      : []
  }

  getFirstStartDateTime () {
    return new Date(this.date + 'T' + this.intervals[0].start)
  }

  getFirstEndDateTime () {
    return new Date(this.date + 'T' + this.intervals[0].end)
  }
}