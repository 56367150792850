/**
 * Couleurs par défault
 */
export const entityColors = [
  ['#f44336', '#3f51b5', '#009688', '#ffeb3b', '#795548'],
  ['#e91e63', '#2196f3', '#4caf50', '#ffc107', '#9e9e9e'],
  ['#9c27b0', '#03a9f4', '#8bc34a', '#ff9800', '#607d8b'],
  ['#673ab7', '#00bcd4', '#cddc39', '#ff5722'],
]

export const defaultEntityColor = entityColors[0][1]