<template>
  <app-panel title="Historique des appels">
    <div class="mx-n4">
      <app-data-table
        :headers="headers"
        :items="items"
        :loading="isLoading"
        pageable
        expandable-rows
      >
        <template #expanded-row="{ item }">
          <div class="pa-3 secondary lighten-5 flex-list-2 flex-column">
            <template v-if="item.getTrace()">
              <h3 class="subtitle-3">
                Trace complète
              </h3>
              <pre v-test="'log-detail-trace'" class="patient-logs-modal__trace-field">{{ item.getTrace() }}</pre>
            </template>
            <p v-else v-test="'undefined-trace'">
              Aucune trace à afficher
            </p>
          </div>
        </template>
      </app-data-table>
    </div>
  </app-panel>
</template>

<script>
import AppDataTable from '@/components/ui/tables/AppDataTable.vue'
import AppPanel from '@/components/ui/panel/AppPanel.vue'

import { format } from '@/utils/functions/dates'
import { getFromAPI } from '@/services/api'

import ApiLog from '@/models/ApiLog'

export default {
  name: 'InsCallLogsPanel',
  components: {
    AppPanel,
    AppDataTable,
  },
  data () {
    return {
      headers: [
        {
          value: 'createdAt',
          text: 'Date d\'enregistrement',
          valueGetter: log => format(log.createdAt, 'dd MMM yyyy à HH:mm'),
        },
        {
          value: 'value',
          text: 'Description',
          valueGetter: log => log.getDescription(),
        },
      ],
      items: [],
      isLoading: false,
    }
  },
  created () {
    this.initInsCallLogs()
  },
  methods: {
    async fetchInsCallLogs () {
      const { data } = await getFromAPI('/api/logs_consulting/insi_call')
      this.items = data['hydra:member'].map(insLog => new ApiLog(insLog))
    },
    async initInsCallLogs () {
      this.isLoading = true
      try {
        await this.fetchInsCallLogs()
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>