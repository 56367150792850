<template>
  <div class="correspondents-panel-item">
    <div class="referring-physician-wrapper">
      <app-tag
        v-if="correspondent.isReferringPhysician"
        label
        small
        data-test="correspondent-referring-physician-tag"
        class="mt-1"
      >
        Médecin traitant
      </app-tag>
    </div>
    <header>
      <h1 class="correspondents-panel-item__civil-state" data-test="correspondent-civil-state">
        <app-icon icon="practitioner" class="mr-2" />{{ correspondentContact.getCivilState() }}
      </h1>
      <p v-if="correspondentSubInfos" class="mb-2 correspondents-panel-item__sub-infos" data-test="correspondent-sub-infos">
        {{ correspondentSubInfos }}
      </p>
    </header>
    <p v-if="rpps" v-test="'correspondents-rpps'" class="ml-3">
      <span class="correspondents-panel-item__subtitle">RPPS :</span>&nbsp;
      <span>{{ rpps }}</span>
    </p>
    <correspondents-panel-item-mss-contact-point
      v-if="0 !== mssContactPoints.length"
      v-test="'correspondents-mss-contact-point'"
      :mss-contact-points="mssContactPoints"
    />
    <correspondents-panel-item-contact-activity-places
      v-if="correspondentContact"
      v-test="'correspondents-contact-activity-places'"
      :correspondent-contact="correspondentContact"
    />
    <correspondents-panel-item-additional-informations :correspondent="correspondent" />
  </div>
</template>
<script>
import AppTag from '@/components/ui/tag/AppTag'
import AppIcon from '@/components/ui/icon/AppIcon.vue'
import CorrespondentsPanelItemMssContactPoint from './CorrespondentsPanelItemMssContactPoint'
import CorrespondentsPanelItemContactActivityPlaces from './CorrespondentsPanelItemContactActivityPlaces'
import CorrespondentsPanelItemAdditionalInformations from './CorrespondentsPanelItemAdditionalInformations'
import PatientCorrespondent from '@/modules/patient/models/PatientCorrespondent'

export default {
  name: 'CorrespondentsItemBody',
  components: {
    AppIcon,
    CorrespondentsPanelItemMssContactPoint,
    CorrespondentsPanelItemContactActivityPlaces,
    CorrespondentsPanelItemAdditionalInformations,
    AppTag,
  },
  props: {
    correspondent: {
      type: PatientCorrespondent,
      required: true,
    },
  },
  computed: {
    correspondentContact () {
      return this.correspondent.contact
    },
    correspondentSubInfos () {
      return this.correspondentContact.practitionerProfile?.qualifications ?? this.correspondentContact.profession
    },
    mssContactPoints () {
      return this.correspondent.contact.getMssContactPoints()
    },
    rpps () {
      return this.correspondent.contact.practitionerProfile?.rpps
    },
  },
}
</script>

<style lang="scss" scoped>
.correspondents-panel-item {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__civil-state {
    font-size: 13px;
    font-weight: bolder;
  }

  &__sub-infos {
    font-size: 12px;
    color: var(--v-secondary-lighten4);
  }

  &__subtitle {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: var(--v-secondary-base);
  }

  &__referring-physician-wrapper {
    width: 100px;
  }

  .referring-physician-wrapper {
    @include media-lg {
      position: absolute;
      top: 10px;
      right: 70px;
    }
  }
}
</style>