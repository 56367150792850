import { parseNumber } from '@/utils/functions/number'

/**
 * Permet calculer l'IMC sur la base d'un jeu de mesure contenant weight et size
 * @param {Array} measures Tableau de données de suivi patient
 * @returns {float|null} Valeur IMC correspondante, null si weight/size manquant/égal à 0
 */
export const makeBmiFromMeasures = (measures) => {
  const weight = measures.find(m => (m.label ? m.name : m.type) === 'weight')?.value
  const size = measures.find(m => (m.label ? m.name : m.type) === 'size')?.value
  return makeBmi(weight, size)
}

/**
 * Calcule l'IMC depuis valeur brute weight et size
 * @param {float} weight Poids de la personne
 * @param {float} size Taille de la personne
 * @returns {float|null} Valeur IMC correspondante, null si weight/size manquant/égal à 0
 */
export const makeBmi = (weight, size) => {
  if (weight > 0 && size > 0) {
    const imc = weight / Math.pow(size / 100.0, 2)
    return parseNumber(imc.toFixed(2))
  }
  return null
}