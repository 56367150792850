<template>
  <app-modal
    class="app-confirm-modal"
    :title="title"
    :is-open.sync="opened"
    :is-submitting="isSubmitting"
    :cancel-text="cancelText"
    :submit-text="confirmText"
    :submit-color="confirmColor"
    persistent
    @cancel="handleCancel"
    @submit="$emit('confirm')"
  >
    <div class="app-confirm-modal__message">
      <!--
        @slot Slot pour inserer un contenu HTML
        en lieu et place de la props "contentText"
      -->
      <slot>
        {{ contentText }}
      </slot>
    </div>
    <template #actions>
      <slot name="actions" />
    </template>
  </app-modal>
</template>

<script>
import AppModal from './AppModal.vue'
import ModalMixin from './mixins/ModalMixin'

export default {
  /**
   * Permet d'afficher une fenêtre dialogue de confirmation personnalisable
   */
  name: 'AppConfirmModal',
  components: { AppModal },
  mixins: [ModalMixin],
  props: {
    /**
     * Titre de l'entête de la modale
     * @default "Êtes-vous sûr(e) ?""
     */
    title: {
      type: String,
      default: 'Êtes-vous sûr(e) ?',
    },
    /**
     * Contenu textuel de la modale. Peut être ignoré au profit du slot
     * par défaut s'il y a besoin d'insérer du HTML et pas juste du texte
     */
    contentText: {
      type: String,
      default: '',
    },
    /**
     * Texte du bouton d'annulation
     * @default "Annuler""
     */
    cancelText: {
      type: String,
      default: 'Annuler',
    },
    /**
     * Texte du bouton de confirmation
     * @default "Oui"
     */
    confirmText: {
      type: String,
      default: 'Oui',
    },
    /**
     * Couleur du bouton de confirmation
     * @default "success"
     */
    confirmColor: {
      type: String,
      default: 'success',
    },
    /**
     * État de chargement de la modale
    */
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.app-confirm-modal {
  &__message {
    font-size: 13px;
  }
}
</style>