import ApiEntity from '@/models/ApiEntity'
import MssDocumentMetaDataset from '@/modules/secureMessaging/modules/documents/models/MssDocumentMetaDataset'

export default class PendingMssDocument extends ApiEntity {
  constructor (options = {}) {
    super(options)
    this.effectiveTime = options.effectiveTime || null
    this.metaDataset = options.metaDataset ? new MssDocumentMetaDataset(options.metaDataset) : null
    this.senderEmail = options.senderEmail || null
    this.senderName = options.senderName || null
    this.title = options.title || null
    this.documentAttachmentFailedAt = options.documentAttachmentFailedAt || null
  }
}