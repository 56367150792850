import { NTooltip } from '@/nova-ui'
import NovaTools from '@/nova-tools/NovaTools'

/**
 * Représente une instance programmatique de NTooltip, permettant de l'intégrer dans une directive ou un plugin.
 */
export default class Tooltip {
  constructor (activator, props) {
    this.tooltipComponentId = null
    this.activator = activator

    this.init(activator, props)
  }

  /**
   * Attribut une instance de NTooltip à un élément HTML
   * @param {HTMLElement} activator
   * @param {String | Object} props
   */
  init (activator, props) {
    const componentProps = this.getFormattedTooltipProps(props)
    const { id } = NovaTools.modal.append(NTooltip, {
      activator,
      ...componentProps,
    })
    this.tooltipComponentId = id
  }

  /**
   * Permet d'obtenir des props au format du NTooltip
   * @param {String | Object} props
   * @returns {Object}
   */
  getFormattedTooltipProps (props) {
    if (typeof props === 'string') {
      return { text: props }
    }
    return props
  }

  setProps (props) {
    NovaTools.modal.setProps(this.tooltipComponentId, this.getFormattedTooltipProps(props))
  }

  destroy () {
    NovaTools.modal.destroy(this.tooltipComponentId)
  }
}