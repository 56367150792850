import { CONTACTS_TYPES } from '@/constants/contactsTypes'
import { GENDERS } from '@/constants/genders'

export const AVATARS = Object.freeze({
  [CONTACTS_TYPES.OTHER.value]: {
    [GENDERS.FEMALE.value]: {
      color: '#ff6d19',
      name: 'avatar_female',
      icon: 'icon-avatar_female',
    },
    [GENDERS.MALE.value]: {
      color: '#00bda5',
      name: 'avatar_male',
      icon: 'icon-avatar_male',
    },
    [GENDERS.UNKNOWN.value]: { color: '#c7dceb' },
  },
  [CONTACTS_TYPES.MEDICAL.value]: {
    [GENDERS.FEMALE.value]: {
      color: '#3e8ecc',
      name: 'avatar-medical-women',
      icon: 'icon-avatar-medical-women',
    },
    [GENDERS.MALE.value]: {
      color: '#3e8ecc',
      name: 'avatar-medical-men',
      icon: 'icon-avatar-medical-men',
    },
    [GENDERS.UNKNOWN.value]: { color: '#3e8ecc' },
  },
  [CONTACTS_TYPES.PARAMEDICAL.value]: {
    [GENDERS.FEMALE.value]: {
      color: '#ffc819',
      name: 'avatar-paramedical-women',
      icon: 'icon-avatar-paramedical-women',
    },
    [GENDERS.MALE.value]: {
      color: '#ffc819',
      name: 'avatar-paramedical-men',
      icon: 'icon-avatar-paramedical-men',
    },
    [GENDERS.UNKNOWN.value]: { color: '#ffc819' },
  },
})