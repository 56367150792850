<template>
  <app-form :submit-method="submit">
    <app-api-autocomplete
      url="/api/prescription_templates"
      clear-on-select
      :clear-on-blur="false"
      item-value-field="@id"
      item-text-field="name"
      name="name"
      label="Nom de l'ordonnance type"
      close-on-content-click
      hide-errors="auto"
      :search.sync="searchValue"
      @selected="selectExistingTemplate"
    >
      <template #no-data>
        <v-list-item v-if="searchValue && searchValue.length > 0" @click="setNewTemplate(searchValue)">
          <p>Créer une nouvelle ordonnance type : <strong class="mx-2">"{{ searchValue }}"</strong></p>
        </v-list-item>
      </template>
    </app-api-autocomplete>
    <n-alert
      v-if="templateName"
      type="info"
      transparent
      :message="message"
      class="mt-5"
    />
  </app-form>
</template>

<script>
import AppForm from '@/components/ui/form/AppForm.vue'

import AppApiAutocomplete from '@/components/ui/form/apiAutocomplete/AppApiAutocomplete.vue'
import Prescription from '@/modules/patient/models/Prescription'
import NAlert from '@/nova-ui/NAlert/NAlert.vue'
import prescriptionTemplates
  from '@/modules/patient/components/consultation/prescription/templates/models/PrescriptionTemplate'

export default {
  name: 'PrescriptionTemplateForm',
  components: {
    NAlert,
    AppApiAutocomplete,
    AppForm,
  },
  props: {
    prescription: {
      type: Prescription,
      required: true,
    },
  },
  data () {
    return {
      templateName: null,
      selectedPrescriptionTemplate: null,
      newPrescriptionTemplate: null,
      searchValue: null,
    }
  },
  computed: {
    message () {
      return `L'ordonnance type "${this.templateName}" sera ${this.selectedPrescriptionTemplate ? 'remplacée' : 'créée' }`
    },
  },
  methods: {
    selectExistingTemplate (prescriptionTemplate) {
      this.selectedPrescriptionTemplate = prescriptionTemplate
      this.templateName = prescriptionTemplate.name
    },
    setNewTemplate (name) {
      this.selectedPrescriptionTemplate = null
      this.templateName = name
    },
    async submit () {
      this.selectedPrescriptionTemplate ?
        await prescriptionTemplates.update(this.getPrescriptionData(this.selectedPrescriptionTemplate.name), true) :
        await prescriptionTemplates.insert(this.getPrescriptionData(this.templateName), true)
      this.$emit('close')
    },
    getPrescriptionData (templateName) {
      const iri = this.selectedPrescriptionTemplate ? this.selectedPrescriptionTemplate['@id'] : this.prescription['@id']
      return {
        ...this.prescription,
        '@id': iri,
        codifiedLppLines: this.prescription.codifiedLppLines.map(lppLine => ({
          ...lppLine,
          product: lppLine.product['@id'],
        })),
        dispensingPharmacy: null,
        name: templateName,
      }
    },
  },
}
</script>