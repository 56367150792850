import { cloneDeep, isEqual } from 'lodash'

/**
 * @typedef {Object} LocalMixinOptions
 * @property {String} propertyName Nom de la props ou data à copier
 * @property {String} copyPropertyName Nom de la copie (accessible par this.***)
 * @property {String} event
 */

/**
 * @type LocalMixinOptions
 */
const DEFAULT_OPTIONS = {
  propertyName: 'value',
  copyPropertyName: 'localValue',
}

/**
 * Copie une props en version locale
 * Et émet un évènement en cas de modification de contenu
 * @param {LocalMixinOptions} options Les options de la mixin
 */
export default function (options = DEFAULT_OPTIONS) {

  const { propertyName, copyPropertyName } = {
    ...DEFAULT_OPTIONS,
    ...options,
  }

  return {
    data () {
      return { [copyPropertyName]: null }
    },
    watch: {
      [propertyName]: {
        deep: true,
        immediate: true,
        handler (newValue) {
          // On créé une copie seulement si le pointeur a changé
          if (this[copyPropertyName] === newValue) {
            return
          }

          this[copyPropertyName] = cloneDeep(newValue)
        },
      },
      [copyPropertyName]: {
        deep: true,
        handler (newValue) {
          if (! isEqual(newValue, this[propertyName])) {
            /**
             * On notifie le parent en cas de changement
             * "input" en cas de :value ou v-model
             * "update:propertyName" en cas de nom de props custom (pour usage avec prop.sync)
             * @property {any} newValue La copie modifiée
            */
            if (options.event) {
              this.$emit(options.event, newValue)
              return
            }
            this.$emit(
              propertyName === 'value'
                ? 'input'
                : `update:${propertyName}`,
              newValue,
            )
          }
        },
      },
    },
  }
}