import PatientRiskFactor from '@/modules/patient/models/riskFactors/PatientRiskFactor'

import { getFromAPI, putToAPI, deleteFromAPI } from '@/services/api'
import NovaTools from '@/nova-tools/NovaTools'

export default class PatientHabitusRiskFactor extends PatientRiskFactor {
  constructor (options = {}) {
    super(options),
    this.habitus = options.habitus || null
    this.habitusParameter = options.habitusParameter || null
    this.value = options.value || null
  }

  /**
   * Retourne le type d'habitus suivi de ses détails
   * Exemple: "Exercice physique (2 Fois / Semaine)"
   * @returns {String}
   */
  getDetailedHabitusLabel () {
    let habitusDetails = null
    if(this.value) {
      const units = this.habitus.parameters?.units
      const currentUnit = units.filter(unit => unit.code === this.habitusParameter)[0]
      habitusDetails = ` (${this.value} ${this.habitus.parameters.target} / ${currentUnit.label})`
    }
    return [this.habitus.label, habitusDetails].filter(item => !! item).join('')
  }

  /**
   * Permet de récupérer les catégories d'habitus depuis l'API
   * @returns {Array}
   */
  static async fetchHabitusCategories () {
    const { data } = await getFromAPI('/api/habitus_refs')
    return data['hydra:member']
  }

  /**
   * Permet de récupérer les habitus et modes de vie depuis l'API
   * @param {String} patientIri
   * @returns {PatientHabitusRiskFactor[]}
   */
  static async fetchAll (patientIri) {
    const { data } = await getFromAPI(`${patientIri}/medical_antecedents?exists[habitus]=true`)
    return data['hydra:member'].map(habitusRiskFactor => new PatientHabitusRiskFactor(habitusRiskFactor))
  }

  /**
   * Insère un habitus vers l'API
   * @param {String} patientIri
   * @param {Object} habitusRiskFactor
   * @returns {PatientHabitusRiskFactor}
   */
  static async insert (patientIri, habitusRiskFactor) {
    const { data } = await putToAPI(patientIri, { data: { newMedicalAntecedentList: [habitusRiskFactor] } })
    NovaTools.notify.success('L\'habitus, mode de vie a été créé avec succès')
    return new PatientHabitusRiskFactor(data)
  }

  /**
   * Met à jour un habitus à partir de l'API
   * @param {Object} habitusRiskFactor
   * @returns {PatientHabitusRiskFactor}
   */
  static async update (habitusRiskFactor) {
    const { data } = await putToAPI(habitusRiskFactor['@id'], { data: habitusRiskFactor })
    NovaTools.notify.success('L\'habitus, mode de vie a été mis à jour avec succès')
    return new PatientHabitusRiskFactor(data)
  }

  /**
   * Supprime un habitus à partir de l'API
   * @param {String} habitusRiskFactorIri
   */
  static async delete (habitusRiskFactorIri) {
    await deleteFromAPI(habitusRiskFactorIri)
    NovaTools.notify.success('L\'habitus, mode de vie a été supprimé avec succès')
  }
}