<template>
  <div class="base-layout">
    <div class="base-layout__toolbar">
      <v-toolbar
        dense
        flat
        class="agenda-toolbar"
        elevation="2"
        :height="isMobile ? '48px' : '60px'"
      >
        <v-app-bar-nav-icon v-if="isMobile" data-test="mobile-nav-btn" @click="$store.commit('agenda/SET_MOBILE_SIDEBAR', true)" />
        <slot name="toolbar-left" />
        <v-spacer />
        <slot name="toolbar-right" />
      </v-toolbar>
    </div>
    <div class="base-layout__main">
      <slot name="main" />
    </div>
    <div class="base-layout__sidebar">
      <component
        :is="sidebarWrapper"
        v-model="drawer"
        left
        fixed
        width="300"
        data-test="sidebar-wrapper"
      >
        <slot name="sidebar" />
      </component>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { VNavigationDrawer } from 'vuetify/lib'

export default {
  name: 'BaseLayout',
  components: { VNavigationDrawer },
  computed: {
    ...mapState('app', ['isMobile']),
    ...mapState('agenda', ['isMobileSidebarOpened']),
    drawer: {
      get () {
        return this.isMobileSidebarOpened
      },
      set (option) {
        this.$store.commit('agenda/SET_MOBILE_SIDEBAR', option)
      },
    },
    sidebarWrapper () {
      if (this.isMobile) {
        return VNavigationDrawer
      }
      return 'div'

    },
  },
}
</script>

<style lang="scss" scoped>
.base-layout {
  height: 100%;
  display: grid;
  grid-template-rows: 48px auto;
  grid-template-areas: "toolbar"
                      "calendar";
    &__toolbar {
      grid-area: toolbar;
      position: relative;
      z-index: 2;
    }
    &__sidebar {
      background: #FFF;
      grid-area: sidebar;
      position: absolute;

      > * {
        height: 100%;
        overflow-y: auto;
      }
    }
    &__main {
      grid-area: calendar;
      position: relative;
      z-index: 1;
      > * {
        height: 100%;
        overflow-y: auto;
      }
    }
  @include media-md {
      grid-template-areas: "toolbar toolbar"
                          "sidebar calendar";
      grid-template-rows: 60px auto;
      grid-template-rows: 60px auto;
      grid-template-columns: 320px auto;
      &__sidebar {
        position: relative;
      }
    }
}

</style>