import PrescriptionLine from '@/modules/patient/models/prescriptionLine/PrescriptionLine'
import { capitalize } from '@/utils/functions/words'

export default class PrescriptionParapharmacyLines extends PrescriptionLine {
  constructor (options = {}) {
    super(options)

    this.brand = options.brand || null
    this.code = options.code || null
    this.title = options.title || null
    this.comment = options.comment || null
    this.packageCount = options.packageCount || 1
  }

  getLabel () {
    return this.title
  }

  getBrand () {
    return this.brand ? capitalize(this.brand) : this.brand
  }

  getPackageDetails () {
    return `Quantité : ${this.packageCount}`
  }
}