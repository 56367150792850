import Vue from 'vue'
import { getFromAPI, postToAPI, putToAPI, deleteFromAPI } from '@/services/api'
import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI'

import MedicalHistoryNote from '@/modules/patient/models/medicalHistory/MedicalHistoryNote'

export const state = () => ({ medicalHistoryNotes: {} })

export const getters = {
  getPatientMedicalHistoryNotes: state => patientUUID => {
    return Object.values(state.medicalHistoryNotes[patientUUID] || {})
  },
  getPatientMedicalHistoryNoteFromIRI: state => (patientIRI, noteIRI) => {
    const patientUuid = getUUIDFromIRI(patientIRI)
    const noteUuid = getUUIDFromIRI(noteIRI)
    return state.medicalHistoryNotes[patientUuid]?.[noteUuid]
  },
}

export const mutations = {
  SET_MEDICAL_HISTORY_NOTE (state, medicalHistoryNote) {
    const patientUuid = getUUIDFromIRI(medicalHistoryNote.patient)
    const noteUuid = getUUIDFromIRI(medicalHistoryNote['@id'])

    if (! state.medicalHistoryNotes[patientUuid]) {
      Vue.set(state.medicalHistoryNotes, patientUuid, {})
    }

    Vue.set(state.medicalHistoryNotes[patientUuid], noteUuid, new MedicalHistoryNote(medicalHistoryNote))
  },
  DELETE_MEDICAL_HISTORY_NOTE (state, note) {
    const patientUuid = getUUIDFromIRI(note.patient)
    const noteUuid = getUUIDFromIRI(note['@id'])

    Vue.delete(state.medicalHistoryNotes[patientUuid], noteUuid)
  },
}

export const actions = {
  async fetchPatientMedicalHistoryNotes ({ commit }, patientUUID) {
    const { data } = await getFromAPI(`/api/patients/${patientUUID}/medical_history_notes`)

    data['hydra:member'].forEach(medicalHistoryNote => {
      commit('SET_MEDICAL_HISTORY_NOTE', medicalHistoryNote)
    })
  },
  async deletePatientMedicalHistoryNotes ({ commit }, note) {
    await deleteFromAPI(`${note['@id']}`)
    commit('DELETE_MEDICAL_HISTORY_NOTE', note)
  },
  async insertPatientMedicalHistoryNotes ({ commit }, note) {
    const { data } = await postToAPI('/api/medical_history_notes', serializeNote(note))
    commit('SET_MEDICAL_HISTORY_NOTE', data)
  },
  async savePatientMedicalHistoryNotes ({ commit }, note) {
    const { data } = await putToAPI(note['@id'], { data: serializeNote(note) })
    commit('SET_MEDICAL_HISTORY_NOTE', data)
  },
}

// Évite l'insertion d'espaces en début/fin des notes
const serializeNote = (note) => ({
  ...note,
  content: note.content.trim(),
})

export default {
  state,
  getters,
  mutations,
  actions,
}