<template>
  <patient-sidebar-panel-listing
    title="Allergies médicamenteuses"
    create-label="Ajouter une allergie médicamenteuse"
    edit-label="Modifier une allergie médicamenteuse"
    :items="drugAllergies"
    :form="DrugAllergyForm"
    :loading="isLoadingDrugAllergies && !hasLoadedDrugAllergies"
    :patient="patient"
    no-data-text="Aucune donnée"
    :item-model-class="PatientDrugAllergy"
  >
    <template #item="{ item, edit }">
      <patient-sidebar-risk-factor :risk-factor="item" :actions="getActions(item, edit)" />
    </template>
  </patient-sidebar-panel-listing>
</template>

<script>
import DrugAllergyForm from './DrugAllergyForm.vue'
import PatientSidebarPanelListing from '@/modules/patient/components/patientFile/PatientSidebarPanelListing.vue'
import PatientSidebarRiskFactor from '@/modules/patient/components/PatientSidebarRiskFactor.vue'

import { mapGetters, mapActions } from 'vuex'

import Patient from '@/modules/patient/models/Patient'
import PatientDrugAllergy from '@/modules/patient/models/riskFactors/PatientDrugAllergy'
import DeleteActionMenuItem from '@/components/ui/actionsMenu/classes/DeleteActionMenuItem'
import EditActionMenuItem from '@/components/ui/actionsMenu/classes/EditActionMenuItem'
import NovaTools from '@/nova-tools/NovaTools'

export default {
  name: 'DrugAllergiesListing',
  components: {
    PatientSidebarPanelListing,
    PatientSidebarRiskFactor,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      DrugAllergyForm,
      PatientDrugAllergy,
    }
  },
  computed: {
    ...mapGetters('patient', ['getPatientDrugAllergies', 'isLoadingDrugAllergies', 'getHasLoadedDrugAllergiesByPatientUuid']),
    drugAllergies () {
      return this.getPatientDrugAllergies(this.patient.getUuid())
    },
    hasLoadedDrugAllergies () {
      return this.getHasLoadedDrugAllergiesByPatientUuid(this.patient.getUuid())
    },
  },
  watch: {
    patient: {
      immediate: true,
      handler (patient) {
        if (patient['@id']) {
          this.fetchPatientDrugAllergies(this.patient.getUuid())
        }
      },
    },
  },
  methods: {
    ...mapActions('patient', ['fetchPatientDrugAllergies', 'deletePatientDrugAllergy']),
    getActions (item, editMethod) {
      return [
        new EditActionMenuItem({ callback: () => editMethod(item) }),
        new DeleteActionMenuItem({
          callback: () => {
            NovaTools.dialog.confirm(
              'Supprimer l\'allergie',
              `Êtes-vous sûr(e) de vouloir supprimer l'allergie ${item?.label} ?`,
              async () => {
                await this.deletePatientDrugAllergy(item)
                NovaTools.notify.success('L\'allergie a été supprimée avec succès')
              })
          },
        })]
    },
  },
}
</script>