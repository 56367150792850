import PrescriptionMedicineLine from '@/modules/patient/models/prescriptionLine/prescriptionHealthProductLine/PrescriptionMedicineLine'

export default class PrescriptionCompoundedMedicineLine extends PrescriptionMedicineLine {
  constructor (options = {}) {
    super(options)

    this.description = options.description
  }

  isComplete () {
    return !! this.description
  }

  getLabel () {
    return this.description
  }
}