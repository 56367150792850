export default class DocumentEntityField {
  /**
   * Nom de la propriété de l'entité
   */
  name

  /**
   * Description de l'entité
   */
  description

  /**
   * Référence vers un "DocumentEntityRelation"
   */
  linkedTo

  constructor (name, description, linkedTo = null) {
    if (! name) {
      throw new Error('Le nom du champ de l\'entité doit être fourni')
    }

    if (! description) {
      throw new Error('La description du champ de l\'entité doit être fournie')
    }

    this.name = name
    this.description = description
    this.linkedTo = linkedTo
  }
}