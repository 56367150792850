import { mapGetters } from 'vuex'

/**
 * Permet de contrôler une modale liée à un tlsi
 * @param {String} tlsi Le TLSI à utiliser {@link TLSI_TYPES}
 */
export default (tlsi) => {
  return {
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters('cardReaders', ['getHasCpxCard']),
      tlsiModalMixin_isOpen: {
        get () {
          return this.isOpen
        },
        set (isOpen) {
          this.$emit('update:is-open', isOpen)
        },
      },
      tlsiModalMixin_modalFormTitle () {
        return `Téléservice ${tlsi.label}`
      },
    },
    watch: {
      getHasCpxCard (hasCpxCard) {
        if (! hasCpxCard) {
          this.tlsiModalMixin_closeModalForm()
        }
      },
    },
    methods: {
      tlsiModalMixin_closeModalForm () {
        this.$emit('update:is-open', false)
      },
    },
  }
}