import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'

import DocumentMetaDatasetModal from '@/modules/secureMessaging/modules/documents/components/metaDatasetModal/DocumentMetaDatasetModal.vue'
import NovaTools from '@/nova-tools/NovaTools'

export default class MessagingBioResultsViewerAction extends ActionMenuItem {
  constructor (document) {
    if (! document) {
      throw 'Un document est requis pour cette action'
    }
    super('magnifying-glass-plus', 'Détails', () => {
      const { id } = NovaTools.modal.append(DocumentMetaDatasetModal, {
        value: true,
        title: document.title,
        documentMetaDataset: document.metaDataset,
      }, { cancel: () => NovaTools.modal.destroy(id) })
    }, { disabled: ! document.metaDataset })
  }
}