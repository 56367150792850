<template class="appointment-editor">
  <div>
    <app-confirm-modal
      :is-open.sync="isConfirmModalVisible"
      :is-submitting="isDeletingEvent"
      confirm-text="Supprimer"
      confirm-color="error"
      data-test="confirm-modal"
      @confirm="deleteEvent"
    >
      <p :class="{ 'mb-0': ! deleteConfirmModalText.note }" data-test="confirm-modal-title">
        {{ deleteConfirmModalText.title }}
      </p>
      <p v-if="deleteConfirmModalText.note" class="mb-0" data-test="confirm-modal-note">
        <b>{{ deleteConfirmModalText.note }}</b>
      </p>
    </app-confirm-modal>
    <app-modal-tabbed-forms
      v-if="!isEditing"
      v-model="currentTab"
      :is-visible="isModalVisible"
      :tabs="createFormTabs"
      persistent
      data-test="tabbed-modal-form"
      @cancel="closeModal"
      @submitSuccess="closeModal"
    />
    <app-modal-form
      v-else
      :form="editForm.formComponent"
      :value="editForm.value"
      :title="editForm.text.title"
      :submit-text="editForm.text.submit"
      :width="editForm.width"
      persistent
      data-test="modal-form"
      @cancel="closeModal"
      @submitSuccess="closeModal"
    >
      <template v-if="isMobile && editForm.text.delete && !isSourceDoctolib" #actions-left>
        <app-actions-menu
          v-if="Object.keys(mobileActions).length > 1"
          :actions="mobileActions"
          position="top"
          data-test="mobile-menu-list"
        />
        <app-button
          v-else
          color="error"
          data-test="mobile-menu-button"
          @click="isConfirmModalVisible = true"
        >
          {{ editForm.text.delete }}
        </app-button>
      </template>
      <template v-else-if="editForm.text.delete && !isSourceDoctolib" #actions-left>
        <app-button color="error" data-test="delete-button" @click="isConfirmModalVisible = true">
          {{ editForm.text.delete }}
        </app-button>
        <app-button
          v-if="isEditing && appointment"
          class="ml-4"
          text
          data-test="move-appointment-button"
          @click="setMovedAppointment"
        >
          <app-icon icon="move" class="mr-2" />
          Déplacer le rendez-vous
        </app-button>
      </template>
    </app-modal-form>
  </div>
</template>

<script>
import AppModalForm from '@/components/ui/modal/AppModalForm.vue'
import AppModalTabbedForms from '@/components/ui/modal/AppModalTabbedForms.vue'
import AppConfirmModal from '@/components/ui/modal/AppConfirmModal.vue'

import AppointmentForm from './EventModal/AppointmentForm.vue'
import AbsenceForm from './EventModal/AbsenceForm.vue'
import AvailabilityForm from '@/components/settings/views/agenda/availabilities/AvailabilityForm.vue'

import Appointment from '@/modules/agenda/models/events/Appointment'
import AbsenceSettings from '@/modules/agenda/models/events/AbsenceSettings'
import AvailabilitySettings from '@/modules/agenda/models/events/AvailabilitySettings'

import { EDIT_EVENT_ROUTE_TYPES } from '@/modules/agenda/constants'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { add, format } from '@/utils/functions/dates'
import AppActionsMenu from '@/components/ui/actionsMenu/AppActionsMenu'
import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'

export default {
  name: 'EventModal',
  components: {
    AppActionsMenu,
    AppModalForm,
    AppModalTabbedForms,
    AppConfirmModal,
  },
  data () {
    return {
      currentTab: 0,
      isDeletingEvent: false,
      isConfirmModalVisible: false,
    }
  },
  computed: {
    ...mapGetters('app', ['isMobile']),
    ...mapGetters('agenda', ['getSchedules', 'getAppointmentByUuid', 'getAbsenceSettingByUuid', 'getAvailabilitySettingByUuid']),
    isEditing () {
      return !! this.$route.params.eventUuid
    },
    isModalVisible () {
      return this.$route?.meta?.showEventModal
    },
    isManagingAvailability () {
      return this.$route.meta.type === 'dispo'
    },
    currentSchedule () {
      const { schedule } = this.$route.params
      return this.getSchedules.find(oneSchedule => oneSchedule.name === schedule) || undefined
    },
    isSourceDoctolib () {
      return this.appointment?.source === 'doctolib';
    },
    appointment () {
      if(! this.isModalVisible) {
        return null
      }
      if(this.isEditing) {
        return this.getAppointmentByUuid(this.$route.params.eventUuid)
      }
      const { date, startTime } = this.$route.params
      const startDateTime = new Date(`${date}T${startTime}`).toISOString()
      const endDateTime = add(new Date(startDateTime), { minutes: 30 }).toISOString()
      return new Appointment({
        startDateTime,
        endDateTime,
        schedule: this.currentSchedule?.['@id'],
        motive: this.currentSchedule?.defaultAppointmentMotive?.['@id'],
      })
    },
    absenceSettings () {
      return this.isEditing
        ? this.getAbsenceSettingByUuid(this.$route.params.eventUuid)
        : this.getEventSettingValuesFromRoute(AbsenceSettings)
    },
    availabilitySettings () {
      return this.isEditing
        ? this.getAvailabilitySettingByUuid(this.$route.params.eventUuid)
        : this.getEventSettingValuesFromRoute(AvailabilitySettings)
    },
    createFormTabs () {
      return [
        {
          title: this.appointmentModalText.title,
          form: AppointmentForm,
          value: this.appointment,
          submitText: this.appointmentModalText.submit,
        },
        {
          title: this.absenceModalText.title,
          form: AbsenceForm,
          value: this.absenceSettings,
          submitText: this.absenceModalText.submit,
        },
        {
          title: this.availabilityModalText.title,
          form: AvailabilityForm,
          value: this.availabilitySettings,
          submitText: this.availabilityModalText.submit,
        },
      ]
    },
    editForm () {
      const { type } = this.$route.meta
      const formDataByRouteTypes = {
        [EDIT_EVENT_ROUTE_TYPES.appointment]: {
          formComponent: AppointmentForm,
          value: this.appointment,
          text: this.appointmentModalText,
        },
        [EDIT_EVENT_ROUTE_TYPES.absence]: {
          formComponent: AbsenceForm,
          value: this.absenceSettings,
          text: this.absenceModalText,
        },
        [EDIT_EVENT_ROUTE_TYPES.availability]: {
          formComponent: AvailabilityForm,
          value: this.availabilitySettings,
          text: this.availabilityModalText,
        },
      }
      return {
        ...formDataByRouteTypes[type] || formDataByRouteTypes[EDIT_EVENT_ROUTE_TYPES.appointment],
        width: (type === EDIT_EVENT_ROUTE_TYPES.appointment) ? '940px' : '600px',
      }
    },
    deleteConfirmModalText () {
      if (this.editForm.value instanceof AbsenceSettings) {
        const isRecurrentAbsence = this.absenceSettings?.recurrencePattern !== null
        return {
          title: 'Êtes-vous sûr(e) de vouloir supprimer cette absence ?',
          note: isRecurrentAbsence ? 'Attention, cette absence est récurrente. Toutes les occurrences seront supprimées.' : null,
        }
      } else if (this.editForm.value instanceof Appointment) {
        return {
          title: 'Êtes-vous sûr(e) de vouloir supprimer ce RDV ?',
          note: null,
        }
      }
      return {
        title: '',
        note: null,
      }
    },
    appointmentModalText () {
      return {
        title: this.isEditing ? 'Modifier un rendez-vous' : 'Rdv',
        submit: this.isEditing ? 'Modifier' : 'Programmer',
        delete: 'Supprimer le rendez-vous',
      }
    },
    absenceModalText () {
      return {
        title: this.isEditing ? 'Modifier une absence' : 'Absence',
        submit: this.isEditing ? 'Modifier' : 'Programmer',
        delete: 'Supprimer',
      }
    },
    availabilityModalText () {
      return {
        title: this.isEditing ? 'Modifier la plage de disponibilité' : 'Disponibilité',
        submit: this.isEditing ? 'Enregistrer' : 'Programmer la plage de disponibilité',
      }
    },
    mobileActions () {
      const defaultActions = { delete: new ActionMenuItem('delete', this.editForm.text.delete, () => this.isConfirmModalVisible = true, { important: true }) }
      if (this.isEditing && this.appointment) {
        return {
          move: new ActionMenuItem('move', 'Déplacer le rendez-vous', this.setMovedAppointment),
          ...defaultActions,
        }
      }
      return defaultActions
    },
  },
  watch: {
    isModalVisible: {
      immediate: true,
      handler () {
        if (this.isModalVisible) {
          const isCreatingDispo = !! this.$route.params.duration
          this.currentTab = isCreatingDispo || this.isManagingAvailability
            ? this.createFormTabs.findIndex(tab => tab.value instanceof AvailabilitySettings)
            : 0
        }
      },
    },
  },
  async created () {
    if (this.isEditing) {
      await this.fetchOneEvent()
    }
  },
  methods: {
    ...mapActions('agenda', ['fetchOneAppointment', 'fetchAvailabilities', 'fetchOneAbsenceSetting', 'deleteAbsence', 'fetchOneAvailabilitySetting', 'deleteAppointment']),
    ...mapMutations('agenda', ['SET_MOVED_APPOINTMENT']),
    ...mapMutations('app', ['SET_SNACK']),
    setMovedAppointment () {
      this.SET_MOVED_APPOINTMENT(this.appointment)
      this.$router.push({ name: 'agenda' })
    },
    closeModal () {
      this.$router.push({ name: 'agenda' })
    },
    async fetchOneEvent () {
      const { type } = this.$route.meta
      const { eventUuid } = this.$route.params
      const fetchActionsByRouteType = {
        [EDIT_EVENT_ROUTE_TYPES.appointment]: this.fetchOneAppointment,
        [EDIT_EVENT_ROUTE_TYPES.absence]: this.fetchOneAbsenceSetting,
        [EDIT_EVENT_ROUTE_TYPES.availability]: this.fetchOneAvailabilitySetting,
      }
      await fetchActionsByRouteType[type](eventUuid)
    },
    /**
     * Retourne une instance d'un objet héritant de EventSetting,
     * hydraté avec les paramètres fournis par l'url
     * @param { (AvailabilitySettings | AbsenceSettings) } EventSettingModel Un modèle héritant de EventSetting
     * @returns { (AvailabilitySettings | AbsenceSettings) } Une nouvelle instance du modèle hydraté
     */
    getEventSettingValuesFromRoute (EventSettingModel) {
      if(! this.isModalVisible) {
        return null
      }

      const { date, startTime, duration } = this.$route.params
      const startDate = new Date(`${date}T${startTime}`)
      const endDate = add(startDate, { minutes: duration || 60 })

      return new EventSettingModel({
        schedule: this.currentSchedule,
        date,
        intervals: [{
          start: startTime,
          end: format(endDate, 'HH:mm'),
        }],
      })
    },
    async deleteEvent () {
      this.isDeletingEvent = true
      try {
        if (this.editForm.value instanceof AbsenceSettings) {
          await this.deleteAbsence(this.absenceSettings['@id'])
          this.SET_SNACK({ message: 'L\'absence a été supprimée avec succès' })
        }
        if (this.editForm.value instanceof Appointment) {
          await this.deleteAppointment(this.appointment['@id'])
          this.SET_SNACK({ message: 'Le RDV a été supprimé avec succès' })
        }
        this.$router.replace({ name: 'agenda' })
      } catch(e) {} finally {
        this.isDeletingEvent = false
        this.isConfirmModalVisible = false
      }
    },
  },
}
</script>