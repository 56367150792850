import DataPortabilityModule from '@/modules/dataPortability/DataPortabilityModule.vue'

import { ROUTE_NAMES } from '@/modules/dataPortability/constants'

export default {
  path: '/portabilite-des-donnees-de-sante',
  component: DataPortabilityModule,
  children: [
    {
      path: '/',
      name: ROUTE_NAMES.DOCUMENTATION,
      component: () => import('@/modules/dataPortability/views/DocumentationView.vue'),
    },
  ],
}