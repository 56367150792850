import ApiFile from '@/models/ApiFile'

export default class ConsultationBaseDocument extends ApiFile {
  constructor (options = {}) {
    super(options)

    this.name = options.name || null
    this.consultation = options.consultation || null
    this.dmpUniqueId = options.dmpUniqueId || null
    this.dmpSync = options.dmpSync || false
    this.dmpSyncDate = options.dmpSyncDate || null
    this.category = options.category || '11488-4'
  }

}