import ApiEntity from '@/models/ApiEntity'
import Address from '@/models/Address'
import ContactPoint from '@/models/ContactPoint'

import { getCivilState } from '@/utils/functions/persons'
import { groupBy } from 'lodash'

import { CONTACT_POINTS } from '@/constants'

export default class Person extends ApiEntity {
  constructor (options = {}) {
    super(options)
    this.gender = options.gender || null
    this.civility = options.civility || null
    this.profession = options.profession || null
    this.familyName = options.familyName || null
    this.firstName = options.firstName || null
    this.firstNames = options.firstNames || null
    this.birthDate = options.birthDate || null
    this.address = options.address ? new Address(options.address) : null
    this.contactPoints = options.contactPoints ? options.contactPoints?.map(contactPoint => new ContactPoint(contactPoint)) : []

    /**
     * On créer l'ensemble des ContactPoints et on fourni la valeur si elle existe
     * On fixe les index en local en restant agnostique de l'ordre fourni par le backend
     * (Le backend ne fourni pas les éléments absents et les index peuvent varier)
     */
    Object.values(CONTACT_POINTS).
      forEach(({ label: labelDef, media: mediaDef }) => {
        const label = labelDef?.value || null
        const media = mediaDef?.value || null

        const savedContactPoint = this.contactPoints.find(contactPoint => {
          return contactPoint.label === label && contactPoint.media === media
        })

        if (! savedContactPoint) {
          this.contactPoints.push(new ContactPoint({
            ...savedContactPoint,
            label,
            media,
          }))
        }
      })
  }

  /**
   * Retourne l'état civil de la personne ("Civilité - Prénom - Nom de famille")
   * @param {Object} options Options de formatage
   * @param {Boolean} options.shortenFirstNames Raccourci les prénoms à une initiale (David => D.)
   * @param {Boolean} options.hideCivility Retire la civilité (Dr DAVID James => DAVID James)
   * @returns {String} l'état civil de la personne
   */
  getCivilState ({ shortenFirstNames = false, hideCivility = false, firstNameFirst = false } = {}) {
    return getCivilState(this, {
      shortenFirstNames,
      hideCivility,
      firstNameFirst,
    })
  }

  /**
   * Retourne l'ensemble des points de contact de la personne regroupés par média
   * @returns { Object }
   */
  getContactPointsByMedia () {
    return groupBy(this.contactPoints.filter(contactPoint => contactPoint.value), 'media')
  }

  /**
   * Retourne un contact point en fonction de son label
   * @param { String } label
   * @returns { ContactPoint }
   */
  getContactPointByLabel (label) {
    return this.contactPoints.find(contactPoint => contactPoint.label === label)
  }
}