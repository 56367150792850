<template>
  <consultation-file-list
    :items-icon="getItemIcon"
    :items="consultationDocuments"
    :patient-iri="patientIri"
    :consultation-uuid="consultationUuid"
    :dense="dense"
    @click:edit="editDocument"
    @click:download-ihe_xdm="downloadDocumentIheXdm"
  >
    <template #no-data>
      Aucun document
    </template>

    <template #item-title="{ item }">
      {{ getItemTitle(item) }}
    </template>

    <template #item-description="{ item }">
      {{ item.name }}
    </template>
  </consultation-file-list>
</template>

<script>
import ConsultationFileList from '@/modules/patient/components/consultation/ConsultationFileList.vue'

import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI'
import { mapGetters } from 'vuex'
import ZipFile from '@/utils/classes/files/ZipFile'

import { ROUTE_NAMES } from '@/modules/patient/constants'

import ConsultationLettersDocument from '@/modules/patient/models/ConsultationLettersDocument'
import ConsultationCertificate from '@/modules/patient/components/consultation/certificates/models/ConsultationCertificate'
import ConsultationTelemedicineAct from '@/modules/patient/components/consultation/telemedicineActs/models/ConsultationTelemedicineAct'
export default {
  name: 'ConsultationDocumentsList',
  components: { ConsultationFileList },
  props: {
    consultationUuid: {
      type: String,
      required: true,
    },
    patientIri: {
      type: String,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideItemIcons: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('patient', ['getConsultationDocuments', 'getConsultationLetterDocuments', 'getConsultationCertificates', 'getConsultationTelemedicineAct']),
    consultationDocuments () {
      return [
        ...this.getConsultationLetterDocuments(this.consultationUuid),
        ...this.getConsultationDocuments(this.consultationUuid),
        ...this.getConsultationCertificates(this.consultationUuid),
        ...[this.getConsultationTelemedicineAct(this.consultationUuid)],
      ].filter(item => !! item)
    },
    consultationCertificates () {
      return this.getConsultationCertificates(this.consultationUuid)
    },
    consultationTelemedicineAct () {
      return this.getConsultationTelemedicineAct(this.consultationUuid)
    },
  },
  methods: {
    getItemIcon (item) {
      return typeof item.getIcon !== 'undefined' ? item.getIcon() : 'file'
    },
    getItemTitle (item) {
      if (item instanceof ConsultationLettersDocument ||
        item instanceof ConsultationCertificate ||
        item instanceof ConsultationTelemedicineAct) {
        return item.getLabel()
      }
      return item.title
    },
    editDocument (document) {
      this.$router.push({
        name: ROUTE_NAMES.CONSULTATION_DOCUMENT_EDITION,
        params: {
          consultationUUID: this.consultationUuid,
          documentUuid: getUUIDFromIRI(document['@id']),
        },
      })
    },
    async downloadDocumentIheXdm (document) {
      new ZipFile(document['@id'] + '/ihe_xdm').download()
    },
  },
}
</script>