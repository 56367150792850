export default class ApiLog {
  constructor (options = {}) {
    this.value = options.value || null
    this.detail = options.detail || null
    this.createdAt = options.createdAt || null
  }
  getDescription () {
    return this.value
  }
  getTrace () {
    return this.detail.trace
  }
}