<template>
  <app-panel title="Informations complémentaires">
    <app-autocomplete
      v-model="localFamilySituation"
      item-value="value"
      item-text="label"
      :items="familySituations"
      clearable
      label="Situation Familiale"
      hide-errors="auto"
      data-test="family-situation-field"
    />
    <app-text-field
      v-model="localProfession"
      label="Profession"
      data-test="profession-field"
      autocomplete="off"
    />
    <app-textarea-field
      v-model="localNote"
      label="Notes"
      no-resize
      rows="1"
      auto-grow
      data-test="note-field"
      autocomplete="off"
    />
  </app-panel>
</template>

<script>
import AppPanel from '@/components/ui/panel/AppPanel.vue'
import AppTextareaField from '@/components/ui/form/AppTextareaField.vue'
import AppTextField from '@/components/ui/form/AppTextField.vue'
import AppAutocomplete from '@/components/ui/form/AppAutocomplete'
import { FAMILY_SITUATIONS } from '@/constants'
import { getRefAsArray } from '@/utils/functions/refs'

export default {
  name: 'OtherInformationsFieldset',
  components: {
    AppAutocomplete,
    AppPanel,
    AppTextareaField,
    AppTextField,
  },
  props: {
    note: {
      type: String,
      default: null,
    },
    profession: {
      type: String,
      default: null,
    },
    familySituation: {
      type: String,
      default: null,
    },
  },
  computed: {
    localNote: {
      get () {
        return this.note
      },
      set (newVal) {
        this.$emit('update:note', newVal)
      },
    },
    localProfession: {
      get () {
        return this.profession
      },
      set (newVal) {
        this.$emit('update:profession', newVal)
      },
    },
    localFamilySituation: {
      get () {
        return this.familySituation
      },
      set (newVal) {
        this.$emit('update:familySituation', newVal)
      },
    },
    familySituations () {
      return getRefAsArray(FAMILY_SITUATIONS)
    },
  },
}
</script>