import NovaTools from '@/nova-tools/NovaTools'

export class CodePin {
  codePin = null
  getCodePin () {
    return this.codePin
  }
  setCodePin (codePin) {
    this.codePin = codePin
    if (codePin) {
      NovaTools.icanopee.efficience_background.startSynchro()
    } else {
      NovaTools.icanopee.efficience_background.stopSynchro()
    }
  }
}

export default new CodePin()