<template>
  <n-expandable :icon-rotation-angle="[-90, 0]" :content-spacing-top="0">
    <template #header="{ toggle, expandIconStyle }">
      <h3 :class="getHeaderExpandClass" @click="toggle">
        <app-icon
          v-if="hasMoreToDisplay"
          icon="caret"
          size="12"
          color="secondary"
          :style="expandIconStyle"
        />
        <span class="ml-2">{{ 'Données de suivi' }}</span>
        <app-tag
          x-small
          color="#c0d0fe"
          class="px-2 ml-2"
        >
          {{ getMeasuresWithValueCount }}
        </app-tag>
        <v-btn
          v-if="consultationMeasuresEditable"
          v-test="`consultation-measures-list-edit-btn`"
          icon
          small
          title="Modifier les données de suivi"
          @click.stop="openMeasureForm()"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </h3>
      <app-modal-form
        v-model="modalMeasures"
        title="Modifier les données de suivi"
        :form="ConsultationMeasuresEditForm"
        :consultation="consultation"
        :submit-text="'Enregistrer'"
        :cancel-text="'Annuler'"
        @update:measure="updateMeasure"
        @delete:measure="deleteMeasure"
        @submitSuccess="submit"
        @cancel="cancel"
      />
      <section v-if="moreBegIndex > 0">
        <div class="pl-4 pt-0">
          <v-container>
            <v-row>
              <v-col
                v-for="(measure, i) in getConsultationMeasures.slice(0, moreBegIndex)"
                :key="i"
                cols="12"
                md="6"
                class="pb-1 pt-1"
              >
                <consultation-measures-item :measure="measure" />
              </v-col>
            </v-row>
          </v-container>
        </div>
      </section>
    </template>
    <section v-if="hasMoreToDisplay">
      <v-container>
        <v-row v-test="'consultation-measures-list__measures_more'">
          <v-col
            v-for="(measure, i) in getConsultationMeasures.slice(moreBegIndex)"
            :key="i"
            cols="12"
            md="6"
            class="pb-1 pt-1"
          >
            <consultation-measures-item :measure="measure" />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </n-expandable>
</template>

<script>
import { mapGetters } from 'vuex'
import AppModalForm from '@/components/ui/modal/AppModalForm.vue'
import AppTag from '@/components/ui/tag/AppTag.vue'
import { cloneDeep } from 'lodash'
import localCopyMixin from '@novalys/src/mixins/local-copy-mixin'

import ConsultationMeasuresItem from './ConsultationMeasuresItem.vue'
import ConsultationMeasuresEditForm from './ConsultationMeasuresEditForm.vue'
import Consultation from '@/modules/patient/models/Consultation'
import Measure from '@/modules/patient/models/Measure'
import { makeBmiFromMeasures } from '@/modules/patient/utils/patientMeasures'

export default {
  name: 'ConsultationMeasuresList',
  components: {
    ConsultationMeasuresItem,
    AppModalForm,
    AppTag,
  },
  mixins: [
    localCopyMixin({
      copyPropertyName: 'localConsultation',
      propertyName: 'consultation',
    }),
  ],
  props: {
    consultation: {
      type: Consultation,
      required: true,
    },
    /**
     * Indice max de nb de mesures avant affichage dans la partie "expendable"
     */
    moreBegIndex: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      consultationMeasuresSorted: [],
      modalMeasures: null,
      ConsultationMeasuresEditForm,
    }
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUser', 'isCurrentUserPractitioner', 'isCurrentUserSecretary']),
    getConsultationMeasures () {
      return this.consultationMeasuresSorted
    },
    getMeasuresCount () {
      return this.consultationMeasuresSorted?.length
    },
    getMeasuresWithValueCount () {
      return this.consultationMeasuresSorted?.filter((s) => s.value).length
    },
    getHeaderExpandClass () {
      const headerDefaultClass = 'subtitle-3 consultation-measures-list__show-measures'
      return headerDefaultClass + (this.hasMoreToDisplay ? '' : '-no-more')
    },
    hasMoreToDisplay () {
      return this.getMeasuresCount > this.moreBegIndex
    },
    consultationMeasuresEditable () {
      if (this.isCurrentUserPractitioner) {
        return this.consultation.activityPlacePractitionerLink?.practitioner['@id']
            === this.getCurrentUser.contact['@id']
      } else if (this.isCurrentUserSecretary) {
        return this.consultation.activityPlacePractitionerLink !== null
      }
      return false
    },
  },
  created () {
    this.fetchConsultationMeasures()
  },
  methods: {
    ...mapGetters('auth', ['getPatientMeasureUserSettings']),
    calculateBmi () {
      const bmi = this.consultationMeasuresSorted.find(m => m.name === 'bmi')
      if (bmi) {
        bmi.value = makeBmiFromMeasures(this.consultationMeasuresSorted)
      }
    },
    fetchConsultationMeasures () {
      this.consultationMeasuresSorted = []

      const consultationMeasures = this.localConsultation.patientMeasures
      this.getPatientMeasureUserSettings()
        .filter((setting) => setting.enabled)
        .sort((a, b) => (a.sortIndex - b.sortIndex))
        .forEach((userSetting) => {
          let consultationMeasure = consultationMeasures.find((measure) => measure.name === userSetting.type)
          if (! consultationMeasure) {
            consultationMeasure = new Measure({
              name: userSetting.type,
              consultation: this.consultation,
            })
          }

          const measure = {
            ...consultationMeasure,
            sortIndex: parseInt(userSetting.sortIndex),
            editable: userSetting.editable,
            unit: userSetting.unit,
            label: userSetting.name,
          }
          this.consultationMeasuresSorted.push(measure)
        })
      this.calculateBmi()
    },
    openMeasureForm () {
      if (! this.consultationMeasuresEditable) {
        return
      }
      this.modalMeasures = cloneDeep(this.consultationMeasuresSorted)
    },
    cancel () {
      this.modalMeasures = null
    },
    submit () {
      // On recrée consultationMeasuresSorted pour être sûr d'être à jour
      this.fetchConsultationMeasures()
      this.modalMeasures = null
    },
    updateMeasure (measure) {
      const index = this.localConsultation.patientMeasures.findIndex(m => m.name === measure.name)
      if (index >= 0) {
        this.localConsultation.patientMeasures[index] = measure
      } else {
        this.localConsultation.patientMeasures.push(measure)
      }
    },
    deleteMeasure (measure) {
      const index = this.localConsultation.patientMeasures.findIndex(m => m.name === measure.name)
      if (index >= 0) {
        delete this.localConsultation.patientMeasures[index].value
        this.localConsultation.patientMeasures[index]['@id'] = null
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.consultation-measures-list {
  &__infos {
    display: flex;
    flex-direction: column;
    gap: map-get($spacers, 2);

    &__item {
      font-size: 12px;
      display: grid;

      &__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--v-secondary-base);
      }
    }
  }

  &__show-measures  {
    user-select: none;
    cursor: pointer;
  }
  &__show-measures-no-more  {
    cursor: default;
  }
  &__measures-count {
    font-weight: 400;
    font-size: 13px;
    color: var(--v-secondary-lighten4);
    margin-left: map-get($spacers, 2);
  }
}
</style>