<template>
  <v-container fluid class="agenda-appointments-listing pa-0 white">
    <h2 v-if="!isMobile" data-test="agenda-listing-heading" class="title-h4 font-weight-light px-4 pt-4 pb-10">
      {{ heading }}
    </h2>
    <app-data-table
      :items="currentDateAppointments"
      :headers="headers"
      hide-default-footer
      must-sort
      disable-pagination
      sort-by="startDateTime"
      class="agenda-appointments-listing__table"
      @click:row="editAppointment"
    >
      <template #[`item.startDateTime`]="{ item }">
        <span class="agenda-appointments-listing__startTime">{{ getStartTime(item.startDateTime) }}</span>
      </template>
      <template #[`item.patient`]="{ item }">
        <div class="d-flex align-center">
          <patient-details
            :patient="item.patient"
            x-small
            class="mr-4"
            redirect-to-patient-file
            data-test="patient-details"
          />
          <span data-test="patient-age" class="text--text text--lighten-3">
            {{ item.patient.getAge() }}
          </span>
        </div>
      </template>
      <template #[`item.motive`]="{ item }">
        <div class="d-flex align-center">
          <motive-label :motive="getAppointmentMotiveFromIri(item.motive)" small />
        </div>
      </template>
      <template #[`item.schedule`]="{ item }">
        <span class="mr-2">
          {{ getSchedulePractitioner(item.schedule) }}
        </span>
        <span class="agenda-appointments-listing__schedule-name">
          {{ schedules[item.schedule].name }}
        </span>
      </template>
    </app-data-table>
  </v-container>
</template>

<script>
import AppDataTable from '@/components/ui/tables/AppDataTable.vue'
import PatientDetails from '@/modules/patient/components/PatientDetails.vue'
import MotiveLabel from '@/modules/agenda/components/motives/MotiveLabel.vue'

import NovaTools from '@/nova-tools/NovaTools'

import { mapGetters, mapState } from 'vuex'
import { isSameDay, isToday } from '@/utils/functions/dates'
import { getCivilityShortLabel } from '@/utils/functions/refs'
import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI'

export default {
  name: 'AgendaListing',
  components: {
    AppDataTable,
    PatientDetails,
    MotiveLabel,
  },
  props: {
    appointments: {
      type: Array,
      default: () => ([]),
    },
  },
  data () {
    return {
      headers: [
        {
          text: 'Heure',
          value: 'startDateTime',
        },
        {
          text: 'Patient',
          value: 'patient',
          sortable: false,
        },
        {
          text: 'Motif',
          value: 'motive',
          sortable: false,
        },
        {
          text: 'Agenda',
          value: 'schedule',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('app', ['isMobile']),
    ...mapState('agenda', ['currentDate', 'schedules']),
    ...mapGetters('agenda', ['getAppointmentMotiveFromIri']),
    currentDateAppointments () {
      return this.appointments.filter(appointment => isSameDay(new Date(appointment.startDateTime), this.currentDate))
    },
    heading () {
      const day = isToday(this.currentDate) ? 'aujourd\'hui' : `- ${NovaTools.dates.format(this.currentDate, 'dd MMMM yyyy')}`
      const appointmentsCount = this.currentDateAppointments.length
      return `${appointmentsCount > 0 ? appointmentsCount : 'Aucun'} rendez-vous ${day}`
    },
  },
  methods: {
    getStartTime (date) {
      return NovaTools.dates.format(new Date(date), 'kk:mm')
    },
    getSchedulePractitioner (scheduleIri) {
      const schedulePractitioner = this.schedules[scheduleIri].getPractitioner()
      const civility = getCivilityShortLabel(schedulePractitioner.civility)
      return `${civility} ${schedulePractitioner.firstNames} ${schedulePractitioner.familyName}`
    },
    editAppointment (item) {
      this.$router.push({
        name: 'agenda.edit.appointment',
        params: { uuid: getUUIDFromIRI(item['@id']) },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.agenda-appointments-listing {
  border-left: 1px solid;

  &, &__table {
    border-color: var(--v-text-lighten4) !important;
  }

  &__table {
    border-bottom: 1px solid;
  }

  &__startTime {
    font-weight: 700;
    font-size: 24px;

    @include media-md {
      font-size: 13px;
    }
  }

  &__schedule-name {
    color: var(--v-text-lighten3)
  }

  .patient-civility {
    a {
      color: var(--v-color-text);

      &:hover {
          color: var(--v-primary-base);
          text-decoration: underline;
      }
    }
  }
}
</style>