<template>
  <v-chip
    v-test="'app-tag'"
    class="app-tag"
    :class="{
      'app-tag--small': small,
      'app-tag--x-small': xSmall,
      'app-tag--label': label,
      'app-tag--clickable': hasClickEvent,
    }"
    :color="tagBackgroundColor"
    :text-color="tagTextColor"
    :small="small"
    :x-small="xSmall"
    :ripple="hasClickEvent"
    :outlined="outlined"
    :close="closable"
    close-icon="fas fa-times"
    @click:close="$emit('click:close')"
    @click="$emit('click')"
  >
    <n-truncable-text class="app-tag__content">
      <slot />
    </n-truncable-text>
  </v-chip>
</template>

<script>
import { getColorContrast, hexToRgba } from '@/utils/functions/colors'

import { COLORS } from '@/constants/colors'

/**
 * Représente un mot clé de l'application
 */
export default {
  name: 'AppTag',
  props: {
    /**
     * Couleur du mot clé
     */
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
    /**
     * Affiche le mot clé sous la form d'un label discret
     */
    label: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche le tag avec une petite taille
     */
    small: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche le tag avec une très petite taille
     */
    xSmall: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche une croix
     */
    closable: {
      type: Boolean,
      default: false,
    },
    /**
     * Applique la couleur uniquement sur les bordures et le texte
     */
    outlined: {
      type: Boolean,
      default: false,
    },
    /*
     * Couleur du texte du mot clé
     */
    textColor: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    tagTitle () {
      return this.$slots.default?.[0].text?.trim()
    },
    hasClickEvent () {
      return !! this.$listeners.click
    },
    /**
     * Retourne la couleur du texte selon le type d'affichage du mot clé
     * @return {String}
     */
    tagTextColor () {
      if (this.textColor) {
        return this.textColor
      }
      if (this.outlined) {
        return this.color
      }
      if (this.label) {
        return this.color
      }
      return getColorContrast(this.color.startsWith('#') || this.color.startsWith('rgb') ? this.color : this.colorFromConstant)
    },
    /**
     * Retourne la couleur de fond du mot clé
     * @returns {String}
     */
    tagBackgroundColor () {
      if (! this.label) {
        return this.color
      }
      if (this.color.startsWith('#')) {
        return hexToRgba(this.color, 0.2)
      }
      if (this.color.includes('rgb')) {
        return this.color.replace(')', ', 0.2)').replace('rgb', 'rgba')
      }
      return hexToRgba(this.colorFromConstant, 0.2)
    },
    colorFromConstant () {
      if (COLORS[this.color] && COLORS[this.color].base) {
        return COLORS[this.color].base
      }
      // Pour les couleurs non "base", ex. "primary.lighten4", "content.darken4" etc.
      const splitColor = this.color.split('.')
      return COLORS[splitColor[0]][splitColor[1]]
    },
  },
}
</script>

<style lang="scss" scoped>
.app-tag {
  position: relative;
  cursor: default;
  height: 26px;

  &--clickable {
    cursor: pointer;
  }

  &--label {
    border-radius: 3px !important;

    &:before {
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &--small {
    height: 22px;

    ::v-deep {
      .v-icon {
        font-size: 14px !important;
      }
    }
    .app-tag__content {
      font-size: 12px;
      font-weight: 400;
    }
  }

  &--x-small {
    height: 16px;

    .app-tag__content {
      font-size: 10px;
      font-weight: 400;
    }
  }

  &__content {
    overflow: hidden;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
  }

  :v-deep {
    &:hover:before {
      opacity: 0 !important;
    }

    .v-chip__content {
      display: flex;
      height: auto;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .v-chip__close {
      pointer-events: all;
      margin-right: 0 !important;
    }
  }
}

</style>