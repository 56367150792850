export const COUNTRIES = Object.freeze({
  FR: {
    value: 'FR',
    label: 'France',
  },
  MC: {
    value: 'MC',
    label: 'Monaco',
  },
  BE: {
    value: 'BE',
    label: 'Belgique',
  },
  CH: {
    value: 'CH',
    label: 'Suisse',
  },
  AD: {
    value: 'AD',
    label: 'Andorre',
  },
  LU: {
    value: 'LU',
    label: 'Luxembourg',
  },
})