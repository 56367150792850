<template>
  <app-panel
    :actions="panelActions"
    :max-actions-visible="2"
    header-divided
    title="Volets de synthèses médicales"
  >
    <app-alert
      v-if="reminders.length"
      v-test="'reminder-alert'"
      message="Le VSM de ce patient doit être régénéré"
      type="warning"
    />
    <n-list
      :inset-x="4"
      :items="healthSummaryDocuments"
      :loading="isFetchingHealthSummaryDocuments"
      :skeleton-count="2"
      empty-text="Aucun fichier"
      hoverable
    >
      <template #item="{ item }">
        <health-summaries-panel-item :health-summary="item" :patient="patient" />
      </template>
      <template #skeleton>
        <div class="d-flex">
          <app-skeleton-loader type="list-item-one-line" />
        </div>
      </template>
    </n-list>
    <health-summary-preview-modal
      :is-open.sync="isPreviewingVsm"
      :patient="patient"
      @health-summary-generated="onVsmGenerated"
    />
  </app-panel>
</template>

<script>
import AppPanel from '@/components/ui/panel/AppPanel.vue'
import AppAlert from '@/components/ui/alert/AppAlert.vue'
import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue'
import HealthSummariesPanelItem
  from '@/modules/patient/modules/patientFile/modules/documents/components/healthSummariesPanel/HealthSummariesPanelItem.vue'
import HealthSummaryPreviewModal
  from '@/modules/patient/modules/patientFile/modules/documents/components/healthSummariesPanel/HealthSummaryPreviewModal.vue'
import NovaTools from '@/nova-tools/NovaTools'

import ZipFile from '@/utils/classes/files/ZipFile'
import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem'
import PatientHealthSummaryDocument from '@/modules/patient/models/PatientHealthSummaryDocument'
import Patient from '@/modules/patient/models/Patient'
import HealthSummaryReminder from '@/modules/patient/components/vsm/models/HealthSummaryReminder.js'

import { getFromAPI } from '@/services/api'

const DEFAULT_DOCUMENTS_SKELETONS = 3

export default {
  name: 'HealthSummariesPanel',
  components: {
    HealthSummariesPanelItem,
    AppPanel,
    AppAlert,
    AppSkeletonLoader,
    HealthSummaryPreviewModal,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      DEFAULT_DOCUMENTS_SKELETONS,
      isPreviewingVsm: false,
      downloadCda: false,
      isFetchingHealthSummaryDocuments: false,
      healthSummaryDocuments: [],
      reminders: [],
    }
  },
  computed: {
    panelActions () {
      let disabled, disabledTooltip
      if (! this.patient.referringPhysician) {
        disabled = true
        disabledTooltip = 'Le patient n\'a pas de médecin traitant'
      }
      return [
        new ActionMenuItem('add', 'Générer un volet de synthèse médicale', () => this.previewVsm(), {
          disabled,
          disabledTooltip,
        }),
      ]
    },
  },
  created () {
    this.fetchPatientHealthSummaryDocuments()
    this.fetchReminders()
  },
  methods: {
    async fetchPatientHealthSummaryDocuments () {
      this.isFetchingHealthSummaryDocuments = true
      const { data } = await getFromAPI(`${this.patient.getIri()}/health_summary_documents`)
      this.healthSummaryDocuments = data['hydra:member'].map(item => new PatientHealthSummaryDocument(item))
      this.isFetchingHealthSummaryDocuments = false
      this.healthSummaryDocuments.forEach((healthSummaryDocument) => {
        NovaTools.icanopee.efficience.updateDocumentInStore(new PatientHealthSummaryDocument(healthSummaryDocument))
      })
    },
    async fetchReminders () {
      const { data } = await getFromAPI(`${this.patient.getIri()}/health_summary_reminders`)
      this.reminders = data['hydra:member'].map(item => new HealthSummaryReminder(item))
    },
    previewVsm () {
      if (! this.patient.referringPhysician) {
        return
      }
      this.isPreviewingVsm = true
    },
    onVsmGenerated (patientHealthSummaryDocument) {
      this.isPreviewingVsm = false
      this.healthSummaryDocuments.unshift(new PatientHealthSummaryDocument(patientHealthSummaryDocument))
      NovaTools.icanopee.efficience.sendAutoFromVsm(patientHealthSummaryDocument)
      if (this.downloadCda) {
        this.downloadCdaArchive(patientHealthSummaryDocument)
        this.downloadCda = false
      }
      this.fetchReminders()
    },
    downloadCdaArchive (healthSummary) {
      new ZipFile(healthSummary['@id'] + '/ihe_xdm').download()
    },
  },
}
</script>