export default class TlsiNir {
  constructor (options = {}) {
    this.num = options.num || null
    this.key = options.key || null
  }

  getFullNir () {
    const fullNir = [this.num, this.key].filter(value => !! value).join(' ')
    return fullNir || null
  }
}